import { Box, Button, Paper } from "@mui/material";
import { Page } from "components";
import { useModal } from "../../../../utilities/use-modal";
import { ApiKeyList } from "../../components";
import { GenerateApiKeyModal } from "../../components/GenerateApiKeyModal";
import { useQueryAccessTokens } from "../../utils";

export const ApiKeysPage = () => {
  const createAPIKeyModal = useModal();
  const { items, refetch, loading } = useQueryAccessTokens();

  return (
    <Page
      title="API Keys"
      titleDescription="Api keys can be used to create, read, update and delete your data in
  Retailer Roam. Or to integrate with other platforms."
      primaryAction={{
        onAction: createAPIKeyModal.onOpen,
        content: "Generate API Key",
      }}
    >
      <Paper variant="outlined">
        <ApiKeyList
          afterItemDeleteSuccess={refetch}
          items={items}
          loading={loading}
        />
      </Paper>
      <GenerateApiKeyModal
        {...createAPIKeyModal}
        afterSubmitSuccess={refetch}
      />
    </Page>
  );
};
