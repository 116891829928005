import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { classNames } from "@towersystems/roam-common/lib/css";
import React from "react";
import styles from "./CardDetailList.module.scss";
export type DetailListItem = {
  key: string;
  title: string;
  subTitle?: string | React.ReactNode;
  value?: string | React.ReactNode;
  iconComponent?: React.ReactNode;
  iconComponentWrapperClassName?: string;
  variant?: "destructive" | "default";
  onClick?: () => void;
};

export interface CardDetailListProps {
  items: DetailListItem[];
  divider?: boolean;
  title?: string;
  emptyComponent?: string | React.ReactNode;
}

export const CardDetailList = ({
  items,
  title,
  emptyComponent = "No items",
  divider = true,
}: CardDetailListProps) => {
  const titleMarkup = title ? (
    <Box marginBottom={2}>
      <Typography fontWeight={"bold"} textTransform={"uppercase"}>
        {title}
      </Typography>
    </Box>
  ) : undefined;

  const emptyComponentMarkup =
    items.length <= 0 ? (
      React.isValidElement(emptyComponent) ? (
        emptyComponent
      ) : (
        <Box padding={2}>
          <Typography variant="subdued">{emptyComponent}</Typography>
        </Box>
      )
    ) : undefined;

  return (
    <Box>
      {titleMarkup}
      <Paper variant="outlined">
        <Stack divider={divider ? <Divider /> : undefined}>
          {emptyComponentMarkup}
          {items.map((i) => {
            const { iconComponentWrapperClassName, iconComponent } = i;

            const iconMarkup = iconComponent ? (
              <Box className={iconComponentWrapperClassName}>
                {iconComponent}
              </Box>
            ) : undefined;

            const subtitleMarkup = React.isValidElement(i.subTitle) ? (
              i.subTitle
            ) : i.subTitle ? (
              <Typography variant="body2" color="textSecondary">
                {i.subTitle}
              </Typography>
            ) : undefined;

            const valueMarkup = React.isValidElement(i.value) ? (
              i.value
            ) : i.value ? (
              <Typography variant="h6" fontWeight="normal">
                {i.value}
              </Typography>
            ) : undefined;

            const className = classNames(
              styles.Item,
              i.onClick && styles.Clickable
            );

            return (
              <Box
                onClick={i.onClick}
                padding={2}
                key={i.key}
                display="flex"
                alignItems={"center"}
                className={className}
              >
                <Box>{iconMarkup}</Box>
                <Box display="flex" justifyContent={"space-between"} flex={1}>
                  <Box>
                    <Typography
                      variant="h6"
                      color={
                        i.variant == "destructive"
                          ? "destructive.main"
                          : "inherit"
                      }
                      fontWeight={"normal"}
                    >
                      {i.title}
                    </Typography>
                    {subtitleMarkup}
                  </Box>
                  <Box>{valueMarkup}</Box>
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Paper>
    </Box>
  );
};
