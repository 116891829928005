import { useMutation } from "@apollo/client";
import {
  CreateStaffUserAccountInput,
  Staff,
  UpdateStaffInput,
  UpdateStaffUserAccountInput,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import {
  MUTATION_CREATE_STAFF_USER_ACCOUNT,
  MUTATION_UPDATE_STAFF,
  MUTATION_UPDATE_STAFF_USER_ACCOUNT,
} from "./constants";

export const useMutationUpdateStaff = () => {
  const [m] = useMutation<{
    updateStaff: Staff;
  }>(MUTATION_UPDATE_STAFF);
  const updateStaff = useCallback(
    (input: UpdateStaffInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updateStaff;
      });
    },
    [m]
  );
  return {
    updateStaff,
  };
};

export const useMutationMigrateStaffUserAccount = () => {
  const [m] = useMutation<{
    createStaffUserAccount: Staff;
  }>(MUTATION_CREATE_STAFF_USER_ACCOUNT);

  const migrateStaffUserAccount = useCallback(
    (input: CreateStaffUserAccountInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.createStaffUserAccount;
      });
    },
    [m]
  );
  return {
    migrateStaffUserAccount,
  };
};

export const useMutationUpdateStaffUserAccount = () => {
  const [m] = useMutation<{
    updateStaffUserAccount: Staff;
  }>(MUTATION_UPDATE_STAFF_USER_ACCOUNT);

  const updateStaffUserAccount = useCallback(
    (input: UpdateStaffUserAccountInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updateStaffUserAccount;
      });
    },
    [m]
  );
  return {
    updateStaffUserAccount,
  };
};
