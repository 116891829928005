import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { LoginFormValues } from "../../../../utils/login";
import { Button } from "../../../../../../components/Button";
import { AuthStrategyRadioButton } from "../AuthStrategyRadioButton";
import { Stack } from "../../../../../../components";

export interface LoginFieldsetProps {}

export const LoginFieldset = ({}: LoginFieldsetProps) => {
  const formContext = useFormContext<LoginFormValues>();
  const { register, watch, control, formState } = formContext;
  const { errors, isValid, isSubmitting, isDirty } = formState;
  const watchedStrategy = watch("strategy");
  const hasError = errors.talinkUsername?.message || errors.password?.message;
  const isDisabled = !isValid || !isDirty || isSubmitting;

  return (
    <Box>
      <Box>
        <Controller
          control={control}
          name="strategy"
          render={({ field: { onChange, value } }) => {
            return (
              <Stack spacing={2} direction={"row"}>
                <AuthStrategyRadioButton
                  label="TA-Link Account"
                  name="strategy"
                  value="talink"
                  id={"talink"}
                  checked={value === "talink"}
                  onChange={onChange}
                />
                <AuthStrategyRadioButton
                  onChange={onChange}
                  name="strategy"
                  label="Staff Account"
                  value="native"
                  checked={value === "native"}
                  id={"native"}
                />
              </Stack>
            );
          }}
        />
      </Box>
      <Box sx={{ mt: 1, width: "100%" }}>
        <TextField
          label={
            watchedStrategy === "talink"
              ? "Talink Username"
              : "Talink Account Name"
          }
          fullWidth
          required
          margin="normal"
          {...register("talinkUsername")}
        ></TextField>
        {watchedStrategy === "native" && (
          <TextField
            label="Staff Member Username"
            fullWidth
            required
            margin="normal"
            {...register("staffUsername")}
          ></TextField>
        )}
        <TextField
          type="password"
          label="Password"
          required
          fullWidth
          margin="normal"
          {...register("password")}
        ></TextField>
        <FormControlLabel
          label="Remember me"
          control={<Checkbox {...register("rememberMe")} />}
        />
      </Box>
      {errors.root?.serverError && (
        <Typography color="error">{errors.root.serverError.message}</Typography>
      )}
      {hasError && (
        <Box sx={{ mt: 1 }}>
          <Typography color="error">Invalid username or password</Typography>
        </Box>
      )}

      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Button
          variant="tile"
          type="submit"
          color="primary"
          size="large"
          sx={{ alignSelf: "flex-end" }}
          disabled={isDisabled}
          fullWidth
        >
          <Typography fontWeight="bold">Sign In</Typography>
        </Button>
      </Box>
    </Box>
  );
};
