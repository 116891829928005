import { RouteObject, defer } from "react-router-dom";
import { queryClient } from "../../utilities/query-client";
import { QUERY_DEPARTMENT } from "./utils/departments/constants";
import { ListDepartmentsPage, ViewDepartmentPage } from "./pages";
import { QUERY_CATEGORY } from "./utils";
import { ViewCategoryPage } from "./pages/ViewCategoryPage";
import { RouteObjectWithPermissions } from "../../routes";

export const routes: RouteObjectWithPermissions[] = [
  {
    path: "departments",
    element: <ListDepartmentsPage />,
    permissions: ["ManageDepartments"],
  },
  {
    path: "departments/view/:id/categories/:categoryId",
    element: <ViewCategoryPage />,
    permissions: ["ManageDepartments"],
    loader: async ({ params }) => {
      const f = await queryClient({
        query: QUERY_CATEGORY,
        variables: { id: params.categoryId },
      })
        .then((res) => res.data.category)
        .catch((err) => {
          throw new Response("Not Found", { status: 404 });
        });

      return defer({
        data: Promise.all([f]),
      });
    },
  },
  {
    path: "departments/view/:id",
    element: <ViewDepartmentPage />,
    permissions: ["ManageDepartments"],
    loader: async ({ params }) => {
      const f = await queryClient({
        query: QUERY_DEPARTMENT,
        variables: { id: params.id },
      })
        .then((res) => res.data.department)
        .catch((err) => {
          throw new Response("Not Found", { status: 404 });
        });

      return defer({
        data: Promise.all([f]),
      });
    },
  },
];
