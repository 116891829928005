import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import {
  PaymentMethod,
  Terminal,
  UpdateTerminalInput,
} from "@towersystems/roam-common/lib/generated-types";
import { Accordion, Sheet, Stack, SuspendAwait } from "components";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAccordionController } from "utilities/accordion";
import { UseModalReturn } from "../../../../utilities/use-modal";
import {
  MUTATION_CREATE_TERMINAL,
  TerminalFormSections,
  defaultTerminalFormValues,
  terminalFormSchema,
} from "../../utils/terminals";
import { TerminalFormValues } from "../../utils/terminals/types";
import { UseAwaitReturn } from "../../utils/use-await";
import { TerminalDetailsForm } from "../TerminalDetailsForm";
import { TerminalInvoiceForm } from "../TerminalInvoiceForm";
import { TerminalPaymentsForm } from "../TerminalPaymentsForm";
import { TerminalSettingsForm } from "../TerminalSettingsForm";

export interface CreateTerminalModalProps extends UseModalReturn {
  paymentMethodsState: UseAwaitReturn<PaymentMethod[]>;
  onSubmitSuccess(terminal: Terminal): void;
}

type TerminalInput = UpdateTerminalInput;

const defaultAccordionOpenSections = [
  TerminalFormSections.DETAILS,
  TerminalFormSections.INVOICE,
  TerminalFormSections.PAYMENTS,
  TerminalFormSections.SETTINGS,
];

export function CreateTerminalSheet({
  onSubmitSuccess,
  paymentMethodsState,
  ...drawerController
}: CreateTerminalModalProps) {
  const methods = useForm<TerminalFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultTerminalFormValues,
    resolver: yupResolver(terminalFormSchema),
  });

  const [m] = useMutation<{ createTerminal: Terminal }>(
    MUTATION_CREATE_TERMINAL
  );

  const { getAccordionState } = useAccordionController(
    defaultAccordionOpenSections
  );

  const { handleSubmit, formState } = methods;
  const { isValid, isDirty } = formState;

  const handleOnSubmit = useCallback(async (formValues: TerminalFormValues) => {
    const { id, ...input } = formValues;
    try {
      const result = await m({
        variables: { input: input },
      });
      return (
        result.data?.createTerminal &&
        onSubmitSuccess(result.data?.createTerminal)
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  const primaryAction = {
    content: "Save",
    onAction: handleSubmit(handleOnSubmit),
    disabled: !isValid && !isDirty,
  };

  const secondaryAction = {
    content: "Cancel",
    onAction: drawerController.onClose,
  };

  return (
    <Sheet
      title="Add Terminal"
      {...{ primaryAction, secondaryAction }}
      onTransitionEnd={methods.reset}
      {...drawerController}
    >
      <FormProvider {...methods}>
        <Stack fullWidth spacing={2}>
          <Accordion {...getAccordionState(TerminalFormSections.DETAILS)}>
            <Accordion.Section>
              <TerminalDetailsForm />
            </Accordion.Section>
          </Accordion>
          <Accordion {...getAccordionState(TerminalFormSections.INVOICE)}>
            <Accordion.Section>
              <TerminalInvoiceForm />
            </Accordion.Section>
          </Accordion>
          <Accordion {...getAccordionState(TerminalFormSections.PAYMENTS)}>
            <Accordion.Section>
              <SuspendAwait
                resolve={paymentMethodsState}
                fallback={<CircularProgress />}
              >
                {([paymentMethods]) => (
                  <TerminalPaymentsForm paymentMethods={paymentMethods} />
                )}
              </SuspendAwait>
            </Accordion.Section>
          </Accordion>

          <Accordion {...getAccordionState(TerminalFormSections.SETTINGS)}>
            <Accordion.Section>
              <TerminalSettingsForm />
            </Accordion.Section>
          </Accordion>
        </Stack>
      </FormProvider>
    </Sheet>
  );
}
