import { gql } from "@apollo/client";
import {
  AuthenticationMethodDeepNestingFragment,
  MfaMethodDeepNestingFragment,
  RoleDeepNestingFragment,
  UserV2DeepNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const ActiveUserQuery = gql`
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}
  query QueryActiveUser {
    activeUser {
      ...UserV2DeepNesting
    }
  }
`;

export const SwitchActiveUserMutation = gql`
  mutation SwitchUser(
    $input: AuthenticationInput!
    $mfa: AuthenticationMfaInput
  ) {
    switchUser(input: $input, mfa: $mfa) {
      ... on CurrentUserV2 {
        id
        identifier
        token
        permissions
      }
      ... on InvalidCredentialsError {
        message
        errorCode
        authenticationError
      }
      ... on MfaRequiredError {
        message
        errorCode
        strategies
      }
      ... on InvalidMfaCodeError {
        message
        errorCode
      }
    }
  }
`;

export const UpdateMeMutation = gql`
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      ...UserV2DeepNesting
    }
  }
`;
