import * as yup from "yup";

const uiShape = yup.object().shape({
  mfaRequired: yup.boolean(),
  strategies: yup.array().of(yup.string()),
});

export const schema = yup.object().shape({
  strategy: yup.string().required(),
  identifier: yup.string().when("strategy", {
    is: "native",
    then: yup.string().required("Please enter your  username"),
  }),
  password: yup.string().required(),
  mfaStrategy: yup.string().when("ui.mfaRequired", {
    is: true,
    then: yup.string().required("Strategy field is required"),
  }),
  mfaCode: yup.string().when("ui.mfaCodeSent", {
    is: true,
    then: yup.string().required("Code field is required"),
  }),
  ui: uiShape,
});
