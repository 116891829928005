import { MenuItem, TextField } from "@mui/material";
import { FormLayout, Select } from "components";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useServerConfigProvider } from "utilities/server-config";
import { PaymentMethodFormValues } from "../../utils/payment-methods/types";

export interface PaymentMethodFormProps {
  payTypeDisabled?: boolean;
}

export const PaymentMethodDetailsForm = ({
  payTypeDisabled,
}: PaymentMethodFormProps) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<PaymentMethodFormValues>();

  const serverConfig = useServerConfigProvider();

  const leadingMarkup = (
    <MenuItem disabled value="">
      <em>Select Payment Method</em>
    </MenuItem>
  );

  const watchedPayTypeId = useWatch({ control, name: "payTypeId" });

  function handlePayTypeChange(newPayTypeId: string) {
    setValue("payTypeId", newPayTypeId, { shouldDirty: true });
  }

  const payTypeOptions = useMemo(() => {
    return serverConfig.availablePayTypes.map((i) => ({
      value: `${i.id}`,
      label: i.name,
    }));
  }, [serverConfig.availablePayTypes]);

  const payTypeMarkup = !payTypeDisabled ? (
    <Select
      labelId="test-select-label"
      label="Roam Pay Type"
      fullWidth
      placeholder="Select Payment Method"
      defaultValue=""
      disabled={payTypeDisabled}
      leadingChildren={leadingMarkup}
      value={watchedPayTypeId}
      onChange={(e) => handlePayTypeChange(e.target.value as string)}
      options={payTypeOptions}
    />
  ) : undefined;

  return (
    <FormLayout>
      <TextField
        label="Name"
        fullWidth
        {...register("name")}
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
      />
      {payTypeMarkup}
    </FormLayout>
  );
};
