import {
  Skeleton as BaseSkeleton,
  SkeletonProps as SkeletonBaseProps,
} from "@mui/material";

export interface SkeletonProps extends SkeletonBaseProps {}

export function Skeleton(props: SkeletonProps) {
  return <BaseSkeleton animation="wave" {...props} />;
}
