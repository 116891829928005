import {
  Category,
  Department,
} from "@towersystems/roam-common/lib/generated-types";
import React from "react";
import { useScheduler } from "../../../../../../utilities/use-scheduler";
import { QUERY_CATEGORY_LIST } from "../../../../utils";
import { usePaginatedList } from "../../../../../../utilities/paginated-list";
import {
  Box,
  InputAdornment,
  Pagination,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CategoryResourceList } from "../../../../components/CategoryResourceList";
import { Page } from "../../../../../../components";
import { useSearch } from "../../../../../../utilities/search/hooks";
import { SearchContext } from "../../../../../../utilities/search/context";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AllCategoriesTabView, InactiveCategoriesTabView } from "./components";

export interface CategoryListProps {
  department: Department;
}

export const CategoryList = ({ department }: CategoryListProps) => {
  const search = useSearch();
  const toolbarMarkup = (
    <Box>
      <TextField
        value={search.query}
        onChange={(e) => search.setQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search categories..."
        fullWidth
        className="CardSearchInput"
        variant="standard"
      ></TextField>
    </Box>
  );

  return (
    <SearchContext.Provider value={search}>
      <Box>
        <Box marginBottom={2}>
          <Typography fontWeight={"bold"} textTransform={"uppercase"}>
            Categories
          </Typography>
        </Box>
        <Paper variant="outlined">
          {toolbarMarkup}
          <Tabs>
            <TabList>
              <Tab>All Departments</Tab>
              <Tab>Inactive Departments</Tab>
            </TabList>
            <TabPanel>
              <AllCategoriesTabView departmentId={department.id} />
            </TabPanel>
            <TabPanel>
              <InactiveCategoriesTabView departmentId={department.id} />
            </TabPanel>
          </Tabs>
        </Paper>
      </Box>
    </SearchContext.Provider>
  );
};
