import { useCallback, useState } from "react";
import { UseModalReturn } from "../../utilities/use-modal";
import { Modal } from "../Modal";
import { useDropzone } from "react-dropzone";
import styles from "./UploadImageDialogue.module.scss";
import { Stack } from "../Stack";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Box, Divider, Typography } from "@mui/material";
import { Button } from "../Button";
import { CardDetailList, DetailListItem } from "../CardDetailList";
import { NavigateRightButton } from "../NavigateRightButton";
import { ProductImageThumbnail } from "../ProductImageThumbnail";

export type FileWithPreview = File & { preview: string };

export type FileOptions = {};

export interface UploadImageDialogueProps extends UseModalReturn {
  onSubmit: (file: FileWithPreview) => Promise<boolean>;
  showDelete?: boolean;
  deleteThumbnailSrc?: string;
  onDelete?(): Promise<boolean>;
}

export const UploadImageDialogue = ({
  onSubmit,
  showDelete = false,
  deleteThumbnailSrc,
  onDelete,
  ...modalProps
}: UploadImageDialogueProps) => {
  const [file, setFile] = useState<FileWithPreview | undefined>(undefined);
  const [submitting, setSubmitting] = useState(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length >= 1) {
      const acceptedFile = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      setFile(acceptedFile);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const thumbnail = file ? (
    <div key={file.name}>
      <div>
        <img src={file.preview} className={styles.Thumbnail} />
      </div>
    </div>
  ) : undefined;

  const uploadOptions: DetailListItem[] = [];

  if (!file && showDelete && onDelete) {
    uploadOptions.push({
      key: "delete_image",
      title: "Delete Image",
      subTitle: "This cannot be undone",
      onClick: onDelete,
      value: <NavigateRightButton />,
      variant: "destructive",
      iconComponent: (
        <Box marginRight={2}>
          <ProductImageThumbnail src={deleteThumbnailSrc} />
        </Box>
      ),
    });
  }

  const handleOnSubmit = useCallback(async () => {
    if (!file) {
      return;
    }
    setSubmitting(true);
    return onSubmit(file)
      .then((result) => {
        if (true === result) {
          modalProps.onClose();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  }, [file, modalProps.onClose, onSubmit]);

  const disabled = submitting || !file;

  return (
    <Modal title="Upload Image" {...modalProps}>
      <Stack spacing={3}>
        <section>
          <div className={styles.DropZone} {...getRootProps()}>
            <input {...getInputProps()} />
            <Box style={{ textAlign: "center" }}>
              {thumbnail ? (
                thumbnail
              ) : (
                <Stack spacing={1}>
                  <Box>
                    <CloudUploadOutlinedIcon fontSize="large" />
                  </Box>
                  <Typography>Drag 'n' drop some files here.</Typography>
                  <Typography variant="subdued">or</Typography>
                  <Button variant="tile" color="primary">
                    Click to select files
                  </Button>
                </Stack>
              )}
            </Box>
          </div>
        </section>
        {uploadOptions.length >= 1 ? (
          <CardDetailList items={uploadOptions}></CardDetailList>
        ) : null}
        <Divider />
        <Stack direction="row" spacing={2}>
          <Button
            onClick={handleOnSubmit}
            disabled={disabled}
            variant="tile"
            color="primary"
          >
            Upload
          </Button>
          <Button onClick={modalProps.onClose} variant="tile">
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
