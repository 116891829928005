import { gql } from "@apollo/client";
import { VendorNoNestingFragment } from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const CurrentVendorQuery = gql`
  ${VendorNoNestingFragment}
  query CurrentVendor {
    currentVendor {
      ...VendorNoNesting
    }
  }
`;
