import { Box, Typography } from "@mui/material";
import { Button } from "../Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export interface SwitchUserButtonProps {
  onClick: () => void;
  label: string;
}

export function SwitchUserButton({ onClick, label }: SwitchUserButtonProps) {
  return (
    <Button
      style={{ textAlign: "left", alignItems: "flex-start" }}
      onClick={onClick}
    >
      <Box
        paddingTop={2}
        paddingBottom={2}
        style={{ width: "100%" }}
        justifyContent="space-between"
        alignItems="center"
        display={"flex"}
      >
        <Box flexGrow={1}>
          <Typography fontSize={20}>{label}</Typography>
        </Box>
        <Box>
          <ArrowForwardIosIcon />
        </Box>
      </Box>
    </Button>
  );
}
