import { gql } from "@apollo/client";
import {
  AccessTokenSessionNoNestingFragment,
  DeletedNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as Yup from "yup";

export const defaultGenerateAccessTokenFormValues = {
  name: "",
};

export const generateAccessTokenFormSchema = Yup.object({
  name: Yup.string().required("Please enter a name"),
})
  .required()
  .noUnknown();

export const QUERY_ALL_ACCESS_TOKENS = gql`
  ${AccessTokenSessionNoNestingFragment}
  query QueryAccessTokens {
    accessTokens {
      ...AccessTokenSessionNoNesting
    }
  }
`;

export const MUTATION_GENERATE_ACCESS_TOKEN = gql`
  ${AccessTokenSessionNoNestingFragment}
  mutation GenerateAccessToken($input: GenerateAccessTokenInput!) {
    generateAccessToken(input: $input) {
      ...AccessTokenSessionNoNesting
    }
  }
`;

export const MUTATION_DELETE_ACCESS_TOKEN = gql`
  ${DeletedNoNestingFragment}
  mutation DeleteAccessToken($id: ID!) {
    deleteAccessToken(id: $id) {
      ...DeletedNoNesting
    }
  }
`;
