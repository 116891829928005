import { SettingCard } from "../../../SettingCard";

export interface PaymentMethodSettingsSettingCardProps {
  children?: React.ReactNode;
}

export function PaymentMethodSettingsSettingCard({
  children,
}: PaymentMethodSettingsSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Payment Method Settings"
      description="Configure the settings for this payment method."
    >
      {children}
    </SettingCard>
  );
}
