import { Sale } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";

export interface SaleCustomerDetailsCardProps {
  sale: Sale;
}

export const SaleCustomerDetailsCard = ({
  sale,
}: SaleCustomerDetailsCardProps) => {
  if (!sale.customer?.id) {
    return null;
  }

  return (
    <CardDetailList
      title="Customer"
      items={
        sale?.customer
          ? [
              {
                key: "customer",
                title: `${sale.customer.firstName} ${sale.customer.lastName}`,
                subTitle: sale.customer.emailAddress,
              },
            ]
          : []
      }
    ></CardDetailList>
  );
};
