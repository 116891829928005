import { useCallback, useContext, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ActiveUserQuery,
  USER_KEY,
  USER_TOKEN_KEY,
  loginMutation,
  requestMfaCodeMutation,
} from "./constants";
import { AuthContextType, AuthenticationContext, MeContext } from "./context";
import {
  AuthenticationResultV2,
  MfaCodeStrategy,
  Success,
  UserV2,
} from "@towersystems/roam-common/lib/generated-types";
import { LoginFnOptions, LoginStrategy } from "./types";
import { userIsAdmin } from "../user-is-admin";

export const useAuthentication = (): AuthContextType => {
  const [token, setToken] = useState<string | undefined | null>(
    localStorage.getItem(USER_TOKEN_KEY)
  );

  const [user, setUser] = useState<UserV2 | undefined>(undefined);
  const [bootstraped, setBootstraped] = useState(false);

  const [loginFn] = useMutation<{ authenticate: AuthenticationResultV2 }>(
    loginMutation
  );

  const [queryActiveUserFn] = useLazyQuery<{ activeUser: UserV2 }>(
    ActiveUserQuery
  );

  const [requestMfaCodeFn] = useMutation<{ requestMfaCode: Success }>(
    requestMfaCodeMutation
  );

  const bootstrapAsync = useCallback(async () => {
    try {
      setBootstraped(false);
      const token = localStorage.getItem(USER_TOKEN_KEY);
      const user = localStorage.getItem(USER_KEY);
      setToken(token);
      setUser(user ? JSON.parse(user) : undefined);
      setBootstraped(true);
      return token;
    } catch (error) {
      console.log(error);
    }
    setToken(null);
    setBootstraped(true);
    return null;
  }, []);

  const login = useCallback(
    async ({
      strategy,
      talinkUsername,
      password,
      rememberMe,
      staffUsername,
      mfaCode,
      mfaStrategy,
    }: LoginFnOptions): Promise<AuthenticationResultV2> => {
      return loginFn({
        variables: {
          ...(mfaCode && mfaStrategy
            ? {
                mfa: {
                  mfaCode,
                  strategy: mfaStrategy,
                },
              }
            : {}),
          input: {
            [strategy]: {
              talinkUsername,
              password,
              ...(strategy === "native" ? { username: staffUsername } : {}),
            },
          },
          rememberMe,
        },
      }).then((r) => {
        if (r.data?.authenticate) {
          if ("currentUser" in r.data?.authenticate) {
            const { token } = r.data.authenticate.currentUser;

            localStorage.setItem(USER_TOKEN_KEY, token);
            setToken(token);
          }
          return r.data?.authenticate;
        } else {
          throw new Error();
        }
      });
    },
    [loginFn]
  );

  const logout = useCallback(async () => {
    localStorage.removeItem(USER_TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    window.location.href = "/login";
  }, [setToken]);

  const hasPermission = useCallback(
    (permissions: string[]) => {
      if (permissions.length === 0) {
        return true;
      }

      if (!token || !user) {
        return false;
      }

      // temp fix to prevent users with old roles from logging in
      // remove htis in the future
      if (user && user?.roles === undefined) {
        logout();
        return false;
      }

      if (true === userIsAdmin(user)) {
        return true;
      }

      if (permissions.length === 0) {
        return true;
      }

      const flatPermissions = user.roles.reduce((acc, role) => {
        return [...acc, ...role.permissions];
      }, [] as string[]);

      // if the user has any of the permissions, they can access the page
      return permissions.some((permission) =>
        flatPermissions.includes(permission)
      );
    },
    [user, token]
  );

  const me = useCallback(async () => {
    return queryActiveUserFn().then((r) => {
      if (r.data?.activeUser) {
        const activeUser = r.data.activeUser;

        localStorage.setItem(USER_KEY, JSON.stringify(activeUser));
        setUser(activeUser);
        return activeUser;
      } else {
        throw new Error();
      }
    });
  }, [queryActiveUserFn]);

  const requestMfaCode = useCallback(
    async (
      {
        strategy,
        talinkUsername,
        password,
        rememberMe,
        staffUsername,
      }: LoginFnOptions,
      mfaStrategy: MfaCodeStrategy
    ) => {
      return requestMfaCodeFn({
        variables: {
          mfaStrategy,
          input: {
            [strategy]: {
              talinkUsername,
              password,
              ...(strategy === "native" ? { username: staffUsername } : {}),
            },
          },
          rememberMe,
        },
      }).then((r) => {
        if (r.data?.requestMfaCode) {
          return r.data?.requestMfaCode;
        } else {
          throw new Error("Something went wrong");
        }
      });
    },
    []
  );

  return {
    isLoggedIn: Boolean(token),
    bootstraped,
    user,
    token: token ? token : undefined,
    login,
    logout,
    me,
    hasPermission,
    bootstrapAsync,
    requestMfaCode,
  };
};

export const useAuthenticationProvider = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error("No AuthenticationContext context was provided.");
  }
  return context;
};

export const useMeProvider = () => {
  const context = useContext(MeContext);
  if (!context) {
    throw new Error("No MeContext context was provided.");
  }
  return context;
};
