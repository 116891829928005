import { Box, Card, Grid, SxProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Section } from "./components";
import { wrappedPadding } from "./constants";

export interface SettingCardProps {
  title: string;
  description?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[] | null;
  wrapped?: boolean;
  beforeCard?: React.ReactNode;
  cardSx?: SxProps;
}

export const SettingCard = ({
  title,
  wrapped,
  children,
  description,
  beforeCard,
  cardSx,
}: SettingCardProps) => {
  const titleMarkup = <Typography>{title}</Typography>;

  const descriptionMarkup = description ? (
    typeof description === "string" ? (
      <Typography fontSize="small" color="GrayText">
        {description}
      </Typography>
    ) : (
      description
    )
  ) : null;

  const content = wrapped ? (
    <Card sx={{ p: wrappedPadding, ...cardSx }}>{children}</Card>
  ) : (
    children
  );

  const beforeCardContent = beforeCard ? (
    <Box sx={{ mb: 3 }}>{beforeCard}</Box>
  ) : null;

  return (
    <Grid container>
      <Grid container item xs={4} direction="column">
        <Box sx={{ mr: 10 }}>
          {titleMarkup}
          {descriptionMarkup}
        </Box>
      </Grid>
      <Grid item xs={8} container direction="column">
        {beforeCardContent}
        {content}
      </Grid>
    </Grid>
  );
};

SettingCard.Section = Section;
