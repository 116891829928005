import {
  Sale,
  SaleLineItem,
} from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";
import { Box, Typography } from "@mui/material";
import { toTitleCase } from "@towersystems/roam-common/lib/shared-utils";
import { moneyToFormattedString } from "../../../../utilities/money";
import { ProductImageThumbnail } from "../../../../components/ProductImageThumbnail";

export interface SaleItemDetailsCardProps {
  sale: Sale;
}

export const SaleItemDetailsCard = ({ sale }: SaleItemDetailsCardProps) => {
  return (
    <CardDetailList
      title="Items"
      emptyComponent="No sale items."
      items={sale.lineItems.map((i: SaleLineItem) => {
        const subTitle = (
          <Box>
            <Typography variant="subdued">{i.product?.displayName}</Typography>
            <Typography variant="subdued">{` Stock Number: ${
              i.stockNumber || i.product?.stockNumber
            }`}</Typography>
            {i.serialNumber ? (
              <Typography variant="subdued">S/N: {i.serialNumber}</Typography>
            ) : null}
            {i.metadata?.surcharge === true ? (
              <Typography variant="subdued">
                {i.metadata?.surchargeDescription}
              </Typography>
            ) : null}
          </Box>
        );
        const adjustmentPrice =
          i.adjustments.length >= 1 ? (
            <Typography
              variant="subdued"
              style={{
                textDecorationLine: "line-through",
              }}
            >
              {moneyToFormattedString(i.totalBasePriceInclTax)}
            </Typography>
          ) : undefined;

        return {
          key: i.id,
          title: toTitleCase(i.product?.name || ""),
          subTitle: subTitle,
          value: (
            <Box>
              <Typography variant="h6">
                {moneyToFormattedString(i.totalPriceInclusiveTax)}
              </Typography>
              {adjustmentPrice}
            </Box>
          ),
          iconComponent: (
            <Box marginRight={2}>
              <ProductImageThumbnail
                quantity={i.quantity}
                src={i.product?.image?.path}
              />
            </Box>
          ),
        };
      })}
    />
  );
};
