import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { AccessTokenSession } from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UseModalReturn } from "../../../../utilities/use-modal";
import {
  defaultGenerateAccessTokenFormValues,
  generateAccessTokenFormSchema,
  useGenerateAccessToken,
} from "../../utils";
import { GenerateAccessTokenFormValues } from "../../utils/access-token-sessions/types";
import { Button } from "../../../../components/Button";
import { Stack } from "../../../../components";

export interface GenerateApiKeyModalProps extends UseModalReturn {
  afterSubmitSuccess?(accessToken: AccessTokenSession): void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.default",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

export const GenerateApiKeyModal = ({
  open,
  onClose,
  afterSubmitSuccess,
}: GenerateApiKeyModalProps) => {
  const { generateAccessToken } = useGenerateAccessToken();

  const methods = useForm<GenerateAccessTokenFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultGenerateAccessTokenFormValues,
    resolver: yupResolver(generateAccessTokenFormSchema),
  });

  const { register, handleSubmit, formState } = methods;
  const { errors, isValid, isSubmitSuccessful, isSubmitting, isDirty } =
    formState;

  const handleOnClose = useCallback(() => {
    methods.reset();
    onClose();
  }, [methods.reset, onClose]);

  const handleOnSubmit = useCallback(
    async (input: GenerateAccessTokenFormValues) => {
      const result = await generateAccessToken(input.name);
      afterSubmitSuccess && afterSubmitSuccess(result);
      handleOnClose();
    },
    []
  );

  const isDisabled = !isValid || !isDirty || isSubmitting || isSubmitSuccessful;

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      closeAfterTransition
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <FormProvider {...methods}>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Generate API Key
            </Typography>
            <TextField
              label="Name"
              fullWidth
              required
              margin="normal"
              error={Boolean(errors.name?.message)}
              helperText={errors.name?.message?.toString()}
              {...register("name")}
            ></TextField>
            <Stack mt={2} direction="row" spacing={2}>
              <Button
                color="primary"
                type="submit"
                fullWidth
                onClick={handleSubmit(handleOnSubmit)}
                disabled={isDisabled}
              >
                Continue
              </Button>
              <Button
                type="submit"
                fullWidth
                onClick={handleOnClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </FormProvider>
      </Box>
    </Modal>
  );
};
