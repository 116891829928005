import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { ContextualSaveBar, Page, Stack } from "../../../../components";
import { SelfCheckoutLocationSettingsFormProvider } from "../../components/SelfCheckoutLocationSettingsForm/SelfCheckoutLocationSettingsFormProvider";
import React from "react";
import {
  SelfCheckoutSettings,
  Location,
  SelfCheckoutLocationSettings,
  Terminal,
} from "@towersystems/roam-common/lib/generated-types";
import { Alert, AlertTitle, Box, Card } from "@mui/material";
import { SelfCheckoutLocationSettingsForm } from "../../components/SelfCheckoutLocationSettingsForm/SelfCheckoutLocationSettingsForm";
import { useFormContext } from "react-hook-form";
import { SelfCheckoutLocationSettingsFormValues } from "../../components/SelfCheckoutLocationSettingsForm";
import { SelfCheckoutSettingsFormValues } from "../../components/SelfCheckoutSettingsForm/types";
import { useMutationSelfCheckoutLocationSettingsUpdate } from "../../utils/self-checkout-settings";

export interface AddSelfCheckoutLocationSettingPageProps {}

const Content = ({
  selfCheckoutSettings,
  locations,
  terminals,
}: AddSelfCheckoutLocationSettingPageProps & {
  selfCheckoutSettings: SelfCheckoutSettings;
  locations: Location[];
  terminals: Terminal[];
}) => {
  const {
    formState: { isValid, isSubmitting, isSubmitSuccessful, isDirty, errors },
    setError,
    handleSubmit,
    reset,
  } = useFormContext<SelfCheckoutLocationSettingsFormValues>();

  const { mutation } = useMutationSelfCheckoutLocationSettingsUpdate();

  const navigate = useNavigate();

  const normalizedLocations = React.useMemo(
    () =>
      locations.filter(
        (l) =>
          !selfCheckoutSettings.locationSettings.find(
            (sl) => sl.locationId == l.id
          )
      ),
    [locations, selfCheckoutSettings]
  );

  const handleOnSubmit = React.useCallback(
    async (values: SelfCheckoutLocationSettingsFormValues) => {
      return mutation({
        terminalId: values.terminalId,
        locationId: values.locationId,
        enabled: values.enabled,
        onlineCheckoutEnabled: values.onlineCheckoutEnabled,
        offlineCheckoutEnabled: values.offlineCheckoutEnabled,
        payAtCounterHeading: values.payAtCounterHeading,
        payAtCounterSubheading: values.payAtCounterSubheading,
        checkoutSuccessHeading: values.checkoutSuccessHeading,
      })
        .then(async (result) => {
          navigate(
            `/settings/self-checkout/locations/${result.locationId}/edit`
          );
        })
        .catch((err) => console.log(err));
    },
    []
  );

  const disabled = !isValid || !isDirty || isSubmitting;

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit(handleOnSubmit),
        disabled: disabled,
      }}
      cancelAction={{ onAction: () => reset() }}
    />
  ) : undefined;

  const noLocations = normalizedLocations.length === 0;
  const noLocationWarningMarkup = noLocations ? (
    <Card>
      <Box>
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          No available locations to add. Please create a location first.
        </Alert>
      </Box>
    </Card>
  ) : undefined;

  return (
    <Page
      title="Create Self Checkout Location"
      titleDescription="Create a new self checkout location"
      goBack={{ onAction: () => navigate("/settings/self-checkout") }}
    >
      <Stack spacing={3}>
        {noLocationWarningMarkup}
        {noLocations === false && (
          <SelfCheckoutLocationSettingsForm
            locations={normalizedLocations}
            isEdit={false}
            terminals={terminals}
          />
        )}
      </Stack>
      {contextualSaveBar}
    </Page>
  );
};

export const AddSelfCheckoutLocationSettingPage = (
  props: AddSelfCheckoutLocationSettingPageProps
) => {
  const prefetchData: any = useLoaderData();

  return (
    <React.Suspense fallback={<div>loading</div>}>
      <Await resolve={prefetchData.data}>
        {([selfCheckoutSettings, locations, terminals]: [
          SelfCheckoutSettings,
          Location[],
          Terminal[]
        ]) => {
          return (
            <SelfCheckoutLocationSettingsFormProvider>
              <Content
                locations={locations}
                selfCheckoutSettings={selfCheckoutSettings}
                terminals={terminals}
                {...props}
              />
            </SelfCheckoutLocationSettingsFormProvider>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
