import { InputHTMLAttributes } from "react";
import styles from "./AuthStrategyRadioButton.module.scss";
import { Typography } from "@mui/material";

export interface AuthStrategyRadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  description?: string;
}

export const AuthStrategyRadioButton = ({
  label,
  value,
  ...props
}: AuthStrategyRadioButtonProps) => {
  const { id, ...rest } = props;

  const normalizedId = `${id}-value`;

  return (
    <label htmlFor={normalizedId} className={styles.Wrapper}>
      <input
        {...rest}
        type="radio"
        value={value}
        id={normalizedId}
        className={styles.Radio}
      />
      <div className={styles.Strategy}>
        <Typography className={styles.Label} fontWeight="bold">
          {label}
        </Typography>
      </div>
    </label>
  );
};
