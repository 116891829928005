import { createContext } from "react";
import { ContextualSaveBarProps } from "./types";

export interface FrameContextType {
  mobileViewActive: boolean;
  drawerOpen: boolean;
  toggleDrawer(): void;
  removeContextualSaveBar(): void;
  windowWidth: number;
  setContextualSaveBar(props: ContextualSaveBarProps): void;
}

export const FrameContext = createContext<FrameContextType | undefined>(
  undefined
);
