import { RouteObject, defer } from "react-router-dom";
import { ProductListPage } from "./pages";
import { ViewProductPage } from "./pages/ViewProductPage";
import { queryClient } from "../../utilities/query-client";
import { QUERY_PRODUCT } from "./utils";
import { RouteObjectWithPermissions } from "../../routes";

export const routes: RouteObjectWithPermissions[] = [
  {
    path: "products",
    element: <ProductListPage />,
    permissions: ["ManageProducts"],
  },
  {
    path: "products/view/:id",
    element: <ViewProductPage />,
    permissions: ["ManageProducts"],
    loader: async ({ params }) => {
      const f = queryClient({
        query: QUERY_PRODUCT,
        variables: { id: params.id },
      })
        .then((res) => res.data.product)
        .catch((err) => {
          throw new Response("Not Found", { status: 404 });
        });

      return defer({
        data: Promise.all([f]),
      });
    },
  },
];
