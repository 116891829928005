import { yupResolver } from "@hookform/resolvers/yup";
import {
  CreatePaymentMethodInput,
  PaymentMethod,
  RetailerPayType,
} from "@towersystems/roam-common/lib/generated-types";
import { Accordion, Select, Sheet, Stack, SuspendAwait } from "components";
import {
  defaultPaymentMethodFormValues,
  paymentMethodFormSchema,
  PaymentMethodFormSections,
} from "modules/settings/utils/payment-methods";
import { useMutationCreatePaymentMethod } from "modules/settings/utils/payment-methods/hooks";
import { PaymentMethodFormValues } from "modules/settings/utils/payment-methods/types";
import { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAccordionController } from "utilities/accordion";
import { UseModalReturn } from "utilities/use-modal";
import { PaymentMethodDetailsForm } from "../PaymentMethodDetailsForm";
import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import { CircularProgress, MenuItem } from "@mui/material";
import { useAwait } from "../../utils/use-await";
import { PaymentMethodSettingsForm } from "../PaymentMethodSettingsForm";

export type CreatePaymentMethodSheetProps = UseModalReturn & {
  onSubmitSuccess(paymentMethod: PaymentMethod): void;
};

const defaultAccordionOpenSections = [
  PaymentMethodFormSections.DETAILS,
  PaymentMethodFormSections.SETTINGS,
];

export function CreatePaymentMethodSheet({
  onSubmitSuccess,
  ...drawerController
}: CreatePaymentMethodSheetProps) {
  const { data } = useLoaderData() as { data: Promise<RetailerPayType[]> };

  const retailerPayTypesState = useAwait(data, [data]);

  const methods = useForm<PaymentMethodFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultPaymentMethodFormValues,
    resolver: yupResolver(paymentMethodFormSchema),
  });

  const { getAccordionState } = useAccordionController(
    defaultAccordionOpenSections
  );

  const { handleSubmit, formState } = methods;
  const { isValid } = formState;

  const { createPaymentMethod } = useMutationCreatePaymentMethod();

  const handleOnSubmit = useCallback(
    async (formValues: PaymentMethodFormValues) => {
      try {
        const result = await createPaymentMethod(
          formValues as CreatePaymentMethodInput
        );

        onSubmitSuccess && onSubmitSuccess(result);
      } catch (error) {
        console.error(error);
      }
    },
    [createPaymentMethod]
  );

  const primaryAction = {
    content: "Save",
    onAction: handleSubmit(handleOnSubmit),
    disabled: !isValid,
  };

  const secondaryAction = {
    content: "Cancel",
    onAction: drawerController.onClose,
  };

  return (
    <Sheet
      title="Add New Payment Method"
      {...{ primaryAction, secondaryAction }}
      onTransitionEnd={methods.reset}
      {...drawerController}
    >
      <FormProvider {...methods}>
        <Stack fullWidth spacing={2}>
          <Accordion {...getAccordionState(PaymentMethodFormSections.DETAILS)}>
            <SuspendAwait
              resolve={retailerPayTypesState}
              fallback={
                <Accordion.Section>
                  <CircularProgress />
                </Accordion.Section>
              }
            >
              {([retailerPayTypes]) => {
                return (
                  <>
                    <Accordion.Section>
                      <PaymentMethodDetailsForm />
                    </Accordion.Section>
                    <Accordion.Section>
                      <Controller
                        control={methods.control}
                        name="retailerPayTypeId"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label="Retailer PayType"
                            fullWidth
                            placeholder="Select Retailer PayType"
                            leadingChildren={
                              <MenuItem disabled>
                                <em>Select PayType</em>
                              </MenuItem>
                            }
                            value={value}
                            onChange={onChange}
                            options={retailerPayTypes.map(
                              (i: RetailerPayType) => {
                                return {
                                  value: `${i.id}`,
                                  label: i.payDescription || "UNKNOWN",
                                };
                              }
                            )}
                          ></Select>
                        )}
                      />
                    </Accordion.Section>
                  </>
                );
              }}
            </SuspendAwait>
          </Accordion>
          <Accordion {...getAccordionState(PaymentMethodFormSections.SETTINGS)}>
            <Accordion.Section>
              <PaymentMethodSettingsForm />
            </Accordion.Section>
          </Accordion>
        </Stack>
      </FormProvider>
    </Sheet>
  );
}
