import React from "react";
import { CurrentVendorContext } from "../../utilities/vendor/context";
import { useCurrentVendor } from "../../utilities/vendor/hooks";

export interface VendorControllerProps {
  children?: React.ReactNode;
}

export const VendorController = ({ children }: VendorControllerProps) => {
  const { currentVendor, queryCurrentVendor } = useCurrentVendor();

  React.useEffect(() => {
    queryCurrentVendor().catch((err) => console.log(err));
  }, []);

  if (!currentVendor) {
    return null;
  }

  return (
    <CurrentVendorContext.Provider value={{ currentVendor }}>
      {children}
    </CurrentVendorContext.Provider>
  );
};
