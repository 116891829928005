import { HeadCell } from "../../../../utilities/table";

export const headCells: readonly HeadCell[] = [
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "",
    width: 72,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Product",
    width: 345,
  },
  {
    id: "stockCode",
    numeric: false,
    disablePadding: true,
    label: "Stock Code",
    width: 185,
  },
  {
    id: "status",
    numeric: false,
    label: "status",
    width: 140,
  },
  {
    id: "hasVariants",
    numeric: false,
    disablePadding: true,
    label: "Variants",
    width: 147,
  },
  {
    id: "totalInventory",
    numeric: false,
    disablePadding: true,
    label: "Inventory",
    width: 161,
    sortable: true,
  },
];
