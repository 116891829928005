import {
  MfaCodeStrategy,
  Setup2faStrategyResult,
  Verify2faStrategyResult,
} from "@towersystems/roam-common/lib/generated-types";
import { Modal } from "../../../../components";
import { useActiveUserProvider } from "../../../../utilities/active-user";
import { useSetupNative2faProvider } from "../../utils/2fa/hooks";
import { SetupNative2faFormProvider } from "../SetupNative2faForm/SetupNative2faFormProvider";
import { SetupNative2faForm } from "../SetupNative2faForm";
import { SetupNative2faFormValues } from "../SetupNative2faForm/types";
import { useMutation } from "@apollo/client";
import {
  Setup2faStrategyMutation,
  Verify2faStrategyMutation,
} from "../../utils";
import { useFormContext } from "react-hook-form";
import {
  useCurrentVendor,
  useCurrentVendorProvider,
} from "../../../../utilities/vendor/hooks";
import { userIsAdmin } from "../../../../utilities/user-is-admin";

export interface SetupNative2faModalProps {}
export interface SetupNative2faProps {}

const DEFAULT_STRATEGIES = [MfaCodeStrategy.EMAIL, MfaCodeStrategy.SMS];

const SetupNative2fa = ({}: SetupNative2faProps) => {
  const formContext = useFormContext<SetupNative2faFormValues>();
  const { setError, setValue } = formContext;
  const { activeUser } = useActiveUserProvider();
  const { currentVendor } = useCurrentVendorProvider();

  const [setup2faStrategyFn] = useMutation<{
    setup2faStrategy: Setup2faStrategyResult;
  }>(Setup2faStrategyMutation);

  const [verify2faStrategyFn] = useMutation<{
    verify2faStrategy: Verify2faStrategyResult;
  }>(Verify2faStrategyMutation);

  const handleOnSubmit = (values: SetupNative2faFormValues) => {
    const { data, ui } = values;

    if (false === ui.codeSent) {
      return setup2faStrategyFn({
        variables: {
          input: {
            strategy: data.strategy,
            identifier: data.identifier,
          },
        },
      })
        .then((result) => {
          if (result.data) {
            if ("errorCode" in result.data.setup2faStrategy) {
              setError("root.serverError", {
                type: "400",
                message: result.data.setup2faStrategy.errorCode,
              });
            } else {
              setValue("ui.codeSent", true);
            }
          }
        })
        .catch((err) => {
          setError("root.serverError", {
            type: "400",
            message: err.message,
          });
        });
    }

    if (true === ui.codeSent) {
      const currentUserIsOwner = userIsAdmin(activeUser);

      let credentialsInput;

      if (currentUserIsOwner) {
        credentialsInput = {
          ["talink"]: {
            password: data.password,
            talinkUsername: activeUser.identifier,
          },
        };
      } else {
        credentialsInput = {
          ["native"]: {
            password: data.password,
            username: activeUser.identifier,
            talinkUsername: currentVendor?.talinkUsername,
          },
        };
      }

      return verify2faStrategyFn({
        variables: {
          credentials: credentialsInput,
          code: data.code,
          strategy: data.strategy,
        },
      })
        .then((result) => {
          if (result.data) {
            if ("errorCode" in result.data.verify2faStrategy) {
              setError("root.serverError", {
                type: "400",
                message: result.data.verify2faStrategy.errorCode,
              });
              return;
            } else {
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          setError("root.serverError", {
            type: "400",
            message: err.message,
          });
        });
    }

    setError("root.serverError", {
      type: "400",
      message: "error.something-went-wrong",
    });
  };

  return <SetupNative2faForm onSubmit={handleOnSubmit} />;
};

export const SetupNative2faModal = ({}: SetupNative2faModalProps) => {
  const { modal } = useSetupNative2faProvider();
  const { activeUser } = useActiveUserProvider();

  const strategies = DEFAULT_STRATEGIES.filter((strategy) => {
    return !activeUser.mfaMethods.find(
      (i) => i.verified === true && i.strategy === strategy
    );
  });

  return (
    <Modal {...modal} title="Setup MFA">
      <SetupNative2faFormProvider strategies={strategies}>
        <SetupNative2fa />
      </SetupNative2faFormProvider>
    </Modal>
  );
};
