import { Controller, useFormContext } from "react-hook-form";
import { SetupNative2faFormValues } from "../../types";
import { Box, Stack, Typography } from "@mui/material";
import { mfaStrategyInfoMap } from "../../constants";
import { MfaStrategyRadioButton } from "../MfaStrategyRadioButton";

export interface SelectMfaStrategyFieldsetProps {}

export const SelectMfaStrategyFieldset =
  ({}: SelectMfaStrategyFieldsetProps) => {
    const formContext = useFormContext<SetupNative2faFormValues>();
    const { watch, control } = formContext;
    const watchedStrategies = watch("ui.strategies");

    const emptyMethodMarkup = watchedStrategies.length === 0 && (
      <Typography>
        No two-factor authentication methods are available for your account.
      </Typography>
    );
    return (
      <Stack spacing={2} direction={"column"}>
        <Typography fontWeight={"bold"}>Select MFA Method</Typography>
        {emptyMethodMarkup}
        <Controller
          control={control}
          name="data.strategy"
          render={({ field: { onChange } }) => {
            return (
              <Stack spacing={2} direction={"column"}>
                {watchedStrategies.map((s) => {
                  const strategyData = mfaStrategyInfoMap[s];
                  if (!strategyData) {
                    return null;
                  }
                  return (
                    <MfaStrategyRadioButton
                      {...strategyData}
                      name="data.strategy"
                      onChange={onChange}
                      key={s}
                      value={s}
                      id={s}
                    />
                  );
                })}
              </Stack>
            );
          }}
        />
      </Stack>
    );
  };
