import { Box, Typography } from "@mui/material";
import { Stack } from "../../../../../../components";
import { Controller, useFormContext } from "react-hook-form";
import { LoginFormValues, strategiesMap } from "../../../../utils/login";
import { StrategyRadioButton } from "../StrategyRadioButton";
import { Button } from "../../../../../../components/Button";
import React from "react";
import { useAuthenticationProvider } from "../../../../../../utilities/authentication/hooks";
import { MfaCodeStrategy } from "@towersystems/roam-common/lib/generated-types";

export interface MfaFieldsetProps {
  goToStep: (step: number) => void;
  strategies: string[];
}

export const SelectMfaMethodFieldset = ({
  strategies,
  goToStep,
}: MfaFieldsetProps) => {
  const formContext = useFormContext<LoginFormValues>();
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const { watch, control, getValues } = formContext;
  const { requestMfaCode } = useAuthenticationProvider();

  const watchedStrategy = watch("mfaStrategy");

  const canGoNextStep =
    watchedStrategy !== undefined && watchedStrategy.length >= 1;

  const handleGoToStep = React.useCallback(async () => {
    setRequesting(true);
    if (canGoNextStep) {
      const values = getValues();
      return requestMfaCode(
        {
          strategy: values.strategy,
          talinkUsername: values.talinkUsername,
          staffUsername: values.staffUsername,
          password: values.password,
          rememberMe: values.rememberMe,
        },
        values.mfaStrategy as MfaCodeStrategy
      )
        .then(() => {
          goToStep(3);
        })
        .catch((err) => console.log(err))
        .finally(() => setRequesting(false));
    }
  }, [canGoNextStep]);

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h6">
            Two-Factor Authentication Required
          </Typography>
          <Typography>
            Please select a two-factor authentication method.
          </Typography>
        </Box>

        <Box>
          <Controller
            control={control}
            name="mfaStrategy"
            render={({ field: { onChange } }) => {
              return (
                <Stack spacing={2} direction={"column"}>
                  {strategies.map((s) => {
                    const strategyData = strategiesMap[s];
                    if (!strategyData) {
                      return null;
                    }
                    return (
                      <StrategyRadioButton
                        {...strategyData}
                        name="mfaStrategy"
                        onChange={onChange}
                        key={s}
                        value={s}
                        id={s}
                      />
                    );
                  })}
                </Stack>
              );
            }}
          />
        </Box>
        <Button
          variant="tile"
          type="submit"
          color="primary"
          size="large"
          sx={{ alignSelf: "flex-end" }}
          onClick={handleGoToStep}
          disabled={!canGoNextStep || requesting}
          fullWidth
        >
          <Typography fontWeight="bold">Continue</Typography>
        </Button>
      </Stack>
    </Box>
  );
};
