import { useMutation } from "@apollo/client";
import {
  CreateLocationInput,
  Location,
  UpdateLocationInput,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import {
  MUTATION_LOCATION_METHOD,
  MUTATION_UPDATE_LOCATION,
} from "./constants";

export const useMutationCreateLocation = () => {
  const [m] = useMutation<{
    createLocation: Location;
  }>(MUTATION_LOCATION_METHOD);
  const createLocation = useCallback(
    (input: CreateLocationInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.createLocation;
      });
    },
    [m]
  );
  return {
    createLocation,
  };
};

export const useMutationUpdateLocation = () => {
  const [m] = useMutation<{
    updateLocation: Location;
  }>(MUTATION_UPDATE_LOCATION);
  const updateLocation = useCallback(
    (input: UpdateLocationInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updateLocation;
      });
    },
    [m]
  );
  return {
    updateLocation,
  };
};
