import { RouteObjectWithPermissions } from "../../routes";
import { UpdateMePage } from "./pages";

export const routes: RouteObjectWithPermissions[] = [
  {
    path: "me",
    element: <UpdateMePage />,
    permissions: [],
  },
];
