import { Stack as MuiStack, StackProps as MuiStackProps } from "@mui/material";

export interface StackProps extends MuiStackProps {
  fullWidth?: boolean;
}

export function Stack({ fullWidth, ...props }: StackProps) {
  return (
    <MuiStack
      {...props}
      sx={{
        width: fullWidth ? "100%" : undefined,
        ...props.sx,
      }}
    />
  );
}
