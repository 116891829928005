import { TextField } from "@mui/material";
import { FormLayout } from "components";
import { useFormContext } from "react-hook-form";
import { StaffFormValues } from "../../utils/staffs/types";

export interface StaffDetailsFormProps {}

export function StaffDetailsForm(props: StaffDetailsFormProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<StaffFormValues>();

  return (
    <FormLayout>
      <TextField
        label="Full Name"
        fullWidth
        {...register("fullName")}
        error={Boolean(errors.fullName)}
        helperText={errors.fullName?.message}
      />
      <TextField
        label="Initials"
        fullWidth
        {...register("initials")}
        error={Boolean(errors.initials)}
        helperText={errors.initials?.message}
      />
    </FormLayout>
  );
}
