import {
  AuthenticationResultV2,
  MfaCodeStrategy,
  Success,
  UserV2,
} from "@towersystems/roam-common/lib/generated-types";
import { createContext } from "react";
import { LoginFnOptions } from "./types";

export interface AuthContextType {
  token?: string;
  isLoggedIn: boolean;
  user?: UserV2 | null;
  me(): Promise<UserV2>;
  login(options: LoginFnOptions): Promise<AuthenticationResultV2>;
  logout(): Promise<void>;
  bootstraped: boolean;
  bootstrapAsync(): Promise<string | null>;
  hasPermission(permissions: string[]): boolean;
  requestMfaCode(
    input: LoginFnOptions,
    mfaStrategy: MfaCodeStrategy
  ): Promise<Success>;
}

export interface MeContextType {
  me: UserV2;
}

export const AuthenticationContext = createContext<AuthContextType | undefined>(
  undefined
);

export const MeContext = createContext<MeContextType | undefined>(undefined);
