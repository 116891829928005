import { useAuthenticationProvider } from "../../utilities/authentication/hooks";

export interface PermissionVisabilityHiddenProps {
  permissions: string[];
  children: React.ReactNode;
}

export const PermissionVisabilityHidden = ({
  permissions,
  children,
}: PermissionVisabilityHiddenProps) => {
  const { hasPermission } = useAuthenticationProvider();
  if (hasPermission(permissions)) {
    return <>{children}</>;
  }
  return <></>;
};
