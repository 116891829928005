import { TableCell } from "@mui/material";
import { HeadCell as HeadCellType } from "../../utilities/table";
import classnames from "classnames";
import styles from "./HeadCell.module.scss";
import { UnionMap } from "../../utilities/sort";
import { SortOrder } from "@towersystems/roam-common/lib/generated-types";
import SortTwoToneIcon from "@mui/icons-material/SortTwoTone";
import { useCallback } from "react";

export interface HeadCellProps {
  headCell: HeadCellType;
  dynamicSort?: UnionMap<any, SortOrder>;
  toggleSort?: (key: any) => void;
}

export function HeadCell({ headCell, dynamicSort, toggleSort }: HeadCellProps) {
  const { id, label, sortable } = headCell;

  const activeSortKey = dynamicSort ? Object.keys(dynamicSort)[0] : undefined;
  const activeSortValue = dynamicSort
    ? Object.values(dynamicSort)[0]
    : undefined;

  const isActiveSortable =
    sortable && id === activeSortKey && !!activeSortValue;

  const handleOnClick = useCallback(() => {
    toggleSort?.(id);
  }, []);

  return (
    <TableCell key={id}>
      <div
        className={classnames(
          styles.HeadCell,
          sortable && styles.HeadCellSortable
        )}
        onClick={sortable ? handleOnClick : undefined}
      >
        {label}
        {isActiveSortable && renderSortedIcon(activeSortValue)}
      </div>
    </TableCell>
  );
}

const renderSortedIcon = (sortOrder?: SortOrder) => {
  const flipStyle = { transform: "scaleY(-1)" };

  switch (sortOrder) {
    case SortOrder.ASC:
      return <SortTwoToneIcon style={flipStyle} />;
    case SortOrder.DESC:
      return <SortTwoToneIcon />;
    default:
      return undefined;
  }
};
