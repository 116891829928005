import { yupResolver } from "@hookform/resolvers/yup";
import { Divider } from "@mui/material";
import { Location } from "@towersystems/roam-common/lib/generated-types";
import { ContextualSaveBar, Stack } from "components";
import { useMutationUpdateLocation } from "modules/settings/utils/locations/hooks";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useFlashMessages } from "utilities/flash-messages";
import { normalizeSelectedValues } from "../../../../utilities/transforms";
import {
  defaultLocationFormValues,
  locationFormSchema,
} from "../../utils/locations";
import { LocationFormValues } from "../../utils/locations/types";
import { LocationDetailsForm } from "../LocationDetailsForm";
import { LocationSettingsForm } from "../LocationSettingsForm";
import {
  LocationInformationSettingCard,
  LocationSettingsSettingCard,
} from "./components";

export interface LocationFormProps {
  location: Location;
}

export function EditLocationForm({ location }: LocationFormProps) {
  const { showMessage } = useFlashMessages();
  const methods = useForm<LocationFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: normalizeSelectedValues(defaultLocationFormValues, location),
    resolver: yupResolver(locationFormSchema),
  });

  const { updateLocation } = useMutationUpdateLocation();

  const { handleSubmit, formState } = methods;
  const { isDirty } = formState;

  const handleOnSubmit = useCallback(
    async (input: LocationFormValues) => {
      await updateLocation({ ...input, id: location.id });
      showMessage({
        message: "Location updated successfully",
        severity: "success",
      });
      methods.reset(input);
    },
    [updateLocation]
  );

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{ onAction: handleSubmit(handleOnSubmit) }}
      cancelAction={{ onAction: () => methods.reset() }}
    />
  ) : undefined;

  return (
    <FormProvider {...methods}>
      {contextualSaveBar}
      <Stack spacing={3} divider={<Divider />}>
        <LocationInformationSettingCard>
          <LocationDetailsForm />
        </LocationInformationSettingCard>
        <LocationSettingsSettingCard>
          <LocationSettingsForm />
        </LocationSettingsSettingCard>
      </Stack>
    </FormProvider>
  );
}
