import React, { useCallback } from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { Page } from "components";
import { EditTerminalForm, TerminalPageSkeleton } from "../../components";
import { TerminalFormValues } from "../../utils/terminals/types";
import { useFlashMessages } from "utilities/flash-messages";
import { Terminal } from "@towersystems/roam-common/lib/generated-types";

export const EditTerminalPage = () => {
  const navigate = useNavigate();
  const { showMessage } = useFlashMessages();
  const { data }: any = useLoaderData();

  const handleOnSubmitSuccess = useCallback(async (terminal: Terminal) => {
    showMessage({
      message: "Terminal updated successfully",
      severity: "success",
    });
  }, []);

  return (
    <React.Suspense fallback={<TerminalPageSkeleton />}>
      <Await resolve={data}>
        {([terminal, paymentMethods, globalSettings]) => {
          return (
            <Page
              title={terminal.name}
              titleDescription="Edit Terminal"
              goBack={{ onAction: () => navigate("/settings/terminals") }}
            >
              <EditTerminalForm
                defaultCashPaymentMethodId={
                  globalSettings?.cashManagedPaymentMethodId
                }
                paymentMethods={paymentMethods as any}
                {...{ terminal }}
                onSubmitSuccess={handleOnSubmitSuccess}
              />
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
