import { gql } from "@apollo/client";
import {
  SelfCheckoutLocationSettingsNoNestingFragment,
  SelfCheckoutSettingsNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_SELF_CHECKOUT_SETTINGS = gql`
  ${SelfCheckoutSettingsNoNestingFragment}
  ${SelfCheckoutLocationSettingsNoNestingFragment}
  query QuerySelfCheckoutSettings {
    selfCheckoutSettings {
      ...SelfCheckoutSettingsNoNesting
      locationSettings {
        ...SelfCheckoutLocationSettingsNoNesting
        location {
          id
          name
        }
        terminal {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_SELF_CHECKOUT_LOCATION_SETTINGS = gql`
  ${SelfCheckoutLocationSettingsNoNestingFragment}
  query QuerySelfCheckoutLocationSettings($locationId: ID!) {
    selfCheckoutLocationSettings(locationId: $locationId) {
      ...SelfCheckoutLocationSettingsNoNesting
      location {
        id
        name
      }
      terminal {
        id
        name
      }
    }
  }
`;

export const QUERY_SELF_CHECKOUT_LOCATION_URL = gql`
  query QuerySelfCheckoutUrl($locationId: ID!) {
    selfCheckoutLocationURL(locationId: $locationId)
  }
`;

export const MUTATION_UPDATE_SELF_CHECKOUT_SETTINGS = gql`
  ${SelfCheckoutSettingsNoNestingFragment}
  mutation MutationUpdateSelfCheckoutSettings(
    $input: UpdateSelfCheckoutSettingsInput!
  ) {
    updateSelfCheckoutSettings(input: $input) {
      ...SelfCheckoutSettingsNoNesting
    }
  }
`;

export const MUTATION_UPDATE_SELF_CHECKOUT_LOCATION_SETTINGS = gql`
  ${SelfCheckoutLocationSettingsNoNestingFragment}
  mutation MutationUpdateSelfCheckoutLocationSettings(
    $input: UpdateSelfCheckoutLocationSettingsInput!
  ) {
    updateSelfCheckoutLocationSettings(input: $input) {
      ...SelfCheckoutLocationSettingsNoNesting
    }
  }
`;
