import { FormProvider, useForm } from "react-hook-form";
import { SelfCheckoutLocationSettingsFormValues } from "./types";
import { defaultFormValues, schema } from "./constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { normalizeSelectedValues } from "../../../../utilities/transforms";

export interface SelfCheckoutLocationSettingsFormProviderProps {
  defaultValues?: Partial<SelfCheckoutLocationSettingsFormValues>;
  children?: React.ReactNode;
  isEdit?: boolean;
}

export const SelfCheckoutLocationSettingsFormProvider = ({
  children,
  defaultValues,
}: SelfCheckoutLocationSettingsFormProviderProps) => {
  const normalizedDefaultValues = normalizeSelectedValues(
    defaultFormValues,
    defaultValues
  );

  const methods = useForm<SelfCheckoutLocationSettingsFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: normalizedDefaultValues,
    resolver: yupResolver(schema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
