import { memo, useEffect } from "react";
import { ContextualSaveBarProps, useFrame } from "../../utilities/frame";

export const ContextualSaveBar = memo(function ContextualSaveBar({
  message,
  saveAction,
  cancelAction,
  secondaryAction,
  fullWidth,
  contextControl,
}: ContextualSaveBarProps) {
  const { setContextualSaveBar, removeContextualSaveBar } = useFrame();

  useEffect(() => {
    setContextualSaveBar({
      message,
      saveAction,
      cancelAction,
      secondaryAction,
      fullWidth,
      contextControl,
    });
  }, [
    message,
    saveAction,
    cancelAction,
    secondaryAction,
    fullWidth,
    contextControl,
  ]);

  useEffect(() => {
    return removeContextualSaveBar;
  }, [removeContextualSaveBar]);

  return null;
});
