import { yupResolver } from "@hookform/resolvers/yup";
import {
  CreateProductInput,
  Product,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Accordion, Sheet, Stack } from "components";
import { useAccordionController } from "../../../../utilities/accordion";
import { UseModalReturn } from "../../../../utilities/use-modal";
import {
  createProductFormSchema,
  defaultCreateProductFormValues,
  ProductFormSections,
  ProductFormValues,
  useMutationCreateProduct,
} from "../../utils/products";
import { ProductDetailsForm } from "../ProductDetailsForm";
import { PricingForm } from "../PricingForm";

export type CreateProductSheetProps = UseModalReturn & {
  onSubmitSuccess(product: Product): void;
};

const defaultAccordionOpenSections = [
  ProductFormSections.DETAILS,
  ProductFormSections.PRICING,
];

export function CreateProductSheet({
  onSubmitSuccess,
  ...drawerController
}: CreateProductSheetProps) {
  const methods = useForm<ProductFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultCreateProductFormValues,
    resolver: yupResolver(createProductFormSchema),
  });

  const { getAccordionState } = useAccordionController(
    defaultAccordionOpenSections
  );

  const { handleSubmit, formState } = methods;
  const { isValid } = formState;

  const { createProduct } = useMutationCreateProduct();

  const handleOnSubmit = useCallback(
    async (formValues: ProductFormValues) => {
      try {
        const result = await createProduct(formValues as CreateProductInput);

        onSubmitSuccess && onSubmitSuccess(result);
      } catch (error) {
        console.error(error);
      }
    },
    [createProduct]
  );

  const primaryAction = {
    content: "Add New Product",
    onAction: handleSubmit(handleOnSubmit),
    disabled: !isValid,
  };

  const secondaryAction = {
    content: "Cancel",
    onAction: drawerController.onClose,
  };

  return (
    <Sheet
      title="Add Product"
      {...{ primaryAction, secondaryAction }}
      onTransitionEnd={methods.reset}
      {...drawerController}
    >
      <FormProvider {...methods}>
        <Stack fullWidth spacing={2}>
          <Accordion {...getAccordionState(ProductFormSections.DETAILS)}>
            <Accordion.Section>
              <ProductDetailsForm />
            </Accordion.Section>
          </Accordion>

          <Accordion {...getAccordionState(ProductFormSections.PRICING)}>
            <Accordion.Section>
              <PricingForm />
            </Accordion.Section>
          </Accordion>
        </Stack>
      </FormProvider>
    </Sheet>
  );
}
