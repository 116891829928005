import { SkeletonPage, Stack } from "../../../../components";
import { InputSkeleton } from "../InputSkeleton";
import { RemovalAiSettingCard } from "../RemovalAiSettingCard";
import { RemovalAiSettingsSettingCard } from "../RemovalAiSettingsSettingCard";

export interface BackgroundEraserPageSkeletonProps {}

export function BackgroundEraserPageSkeleton(
  props: BackgroundEraserPageSkeletonProps
) {
  return (
    <SkeletonPage
      title="Background Eraser"
      titleDescription="Remove backgrounds from product images."
    >
      <Stack spacing={3}>
        <RemovalAiSettingCard>
          <InputSkeleton />
        </RemovalAiSettingCard>
        <RemovalAiSettingsSettingCard>
          <InputSkeleton />
        </RemovalAiSettingsSettingCard>
      </Stack>
    </SkeletonPage>
  );
}
