import {
  PaymentMethod,
  Terminal,
  UserAccountType,
} from "@towersystems/roam-common/lib/generated-types";
import { Page } from "components";
import { CreateTerminalSheet } from "modules/settings/components/CreateTerminalSheet";
import React from "react";
import { useCallback } from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { useFlashMessages } from "../../../../utilities/flash-messages";
import { usePaginatedList } from "../../../../utilities/paginated-list";
import { useModal } from "../../../../utilities/use-modal";
import { TerminalsList } from "../../components";
import { QUERY_TERMINALS_LIST } from "../../utils/terminals";
import { Alert, AlertTitle, Card, Paper, Stack } from "@mui/material";
import { useMeProvider } from "../../../../utilities/authentication/hooks";
import { useAwait } from "../../utils/use-await";
import { useCurrentVendorProvider } from "../../../../utilities/vendor/hooks";

export const TerminalsPage = () => {
  const { me } = useMeProvider();
  const addTerminalModal = useModal();
  const navigate = useNavigate();
  const { currentVendor } = useCurrentVendorProvider();

  const { data } = useLoaderData() as { data: Promise<PaymentMethod[]> };

  const paymentMethodsState = useAwait(data, [data]);

  const { showMessage } = useFlashMessages();

  const paginatedList = usePaginatedList<Terminal>({
    query: QUERY_TERMINALS_LIST as any,
    resultKey: "terminals",
  });

  const handleOnSubmitSuccess = useCallback(
    ({ id }: Terminal) => {
      showMessage({
        message: "Terminal created successfully",
        severity: "success",
      });
      navigate(`/settings/terminals/edit/${id}`);
    },
    [navigate, showMessage]
  );

  const items: Terminal[] = paginatedList.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];

  const isAdmin = currentVendor.userAccountType === UserAccountType.TOWER_STAFF;

  const adminMarkupNotice = isAdmin ? (
    <Card>
      <Alert severity="info">
        <AlertTitle>Info</AlertTitle>
        As an admin you can create and manage unlimited terminals.
      </Alert>
    </Card>
  ) : null;

  const numberActiveTerminals = items.filter((item) => item.enabled).length;

  const terminalLimitReached =
    !isAdmin && numberActiveTerminals >= currentVendor.numberAvailableTerminals;

  const terminalLimitNotice = terminalLimitReached ? (
    <Card>
      <Alert severity="warning">
        <AlertTitle>Notice</AlertTitle>
        {`You have reached your limit of ${currentVendor.numberAvailableTerminals} active terminals. Please contact support
        to increase your limit.`}
      </Alert>
    </Card>
  ) : undefined;

  const terminalRemainingNotice =
    !terminalLimitNotice && !adminMarkupNotice ? (
      <Card>
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          {`You have ${
            currentVendor.numberAvailableTerminals - numberActiveTerminals
          } remaining terminals.`}
        </Alert>
      </Card>
    ) : undefined;

  const primaryAction = {
    content: "Add Terminal",
    onAction: addTerminalModal.onOpen,
    disabled: terminalLimitReached,
  };

  return (
    <Page
      title="Terminals"
      titleDescription="Manage which of your terminals are visible on Point of Sale. Terminals represent the physical devices that you use to process payments in your store. You can create and manage terminals here."
      {...{ primaryAction }}
    >
      <Stack spacing={3}>
        {terminalLimitNotice}
        {adminMarkupNotice}
        {terminalRemainingNotice}
        <Paper variant="outlined">
          <TerminalsList loading={paginatedList.loading} items={items} />
        </Paper>
        <CreateTerminalSheet
          onSubmitSuccess={handleOnSubmitSuccess}
          {...{
            paymentMethodsState,
          }}
          {...addTerminalModal}
        />
      </Stack>
    </Page>
  );
};
