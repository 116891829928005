import { Box, InputAdornment, Paper, TextField } from "@mui/material";
import { Page } from "components";
import SearchIcon from "@mui/icons-material/Search";
import { useSearch } from "../../../../utilities/search/hooks";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AllCustomersTabView } from "./components/AllCustomersTabView/AllCustomersTabView";
import { SearchContext } from "../../../../utilities/search/context";
import { AccountCustomersTabView } from "./components/AccountCustomersTabView";
import { NonRetailerCustomersTabView } from "./components/NonRetailerCustomersTabView";

export const CustomerListPage = () => {
  const search = useSearch();

  const toolbarMarkup = (
    <Box>
      <TextField
        value={search.query}
        onChange={(e) => search.setQuery(e.target.value)}
        style={{ border: 0, height: 50 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search customers"
        fullWidth
        className="CardSearchInput"
        variant="standard"
      ></TextField>
    </Box>
  );

  return (
    <SearchContext.Provider value={search}>
      <Page title="Customers" titleDescription="Manage your customers.">
        <Paper variant="outlined">
          {toolbarMarkup}
          <Tabs>
            <TabList>
              <Tab>All Customers</Tab>
              <Tab>Account Customers</Tab>
              <Tab>Non Retailer Customers</Tab>
            </TabList>
            <TabPanel>
              <AllCustomersTabView />
            </TabPanel>
            <TabPanel>
              <AccountCustomersTabView />
            </TabPanel>
            <TabPanel>
              <NonRetailerCustomersTabView />
            </TabPanel>
          </Tabs>
        </Paper>
      </Page>
    </SearchContext.Provider>
  );
};
