import { Box } from "@mui/material";
import { Modal } from "../../../../components";
import { UseModalReturn } from "../../../../utilities/use-modal";
import { QRCodeSVG } from "qrcode.react";

export interface SelfCheckoutQRCodeModalProps {
  modal: UseModalReturn;
  url: string;
  filename?: string;
}

export const SelfCheckoutQRCodeModal = ({
  modal,
  filename = "self-checkout-qr-code",
  url,
}: SelfCheckoutQRCodeModalProps) => {
  const handleOnDownload = () => {
    const svgElement = document.getElementById("QRCODE") as any;
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);

    const downloadLink = document.createElement("a");
    downloadLink.href = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
      svgString
    )}`;
    downloadLink.download = `${filename}.svg`;
    downloadLink.click();
  };

  const primaryAction = {
    content: "Download QR code SVG",
    onAction: handleOnDownload,
  };

  return (
    <Modal
      primaryAction={primaryAction}
      size="sm"
      title="Your QR code"
      {...modal}
    >
      <Box display="flex" alignContent="center" justifyContent="center">
        <QRCodeSVG id={"QRCODE"} height={350} width={350} value={url} />,
      </Box>
    </Modal>
  );
};
