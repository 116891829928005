import { MfaCodeStrategy } from "@towersystems/roam-common/lib/generated-types";
import { FormProvider, useForm } from "react-hook-form";
import { Remove2faFormValues } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./constants";

export interface Remove2faFormProviderProps {
  strategy: MfaCodeStrategy;
  children?: React.ReactNode;
}

export const Remove2faFormProvider = ({
  strategy,
  children,
}: Remove2faFormProviderProps) => {
  const methods = useForm<Remove2faFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      ui: {
        codeSent: false,
      },
      data: {
        strategy: strategy,
        code: undefined,
        password: undefined,
      },
    },
    resolver: yupResolver(schema),
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};
