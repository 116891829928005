import { Box, TextField, Typography } from "@mui/material";
import { Stack } from "../../../../../../components";
import { SetupNative2faFormValues } from "../../types";
import { Controller, useFormContext } from "react-hook-form";
import OtpInput from "react18-input-otp";
import styles from "./VerifyMfaCodeField.module.scss";
import { Button } from "../../../../../../components/Button";

export interface VerifyMfaCodeFieldsetProps {}

export const VerifyMfaCodeFieldset = ({}: VerifyMfaCodeFieldsetProps) => {
  const formContext = useFormContext<SetupNative2faFormValues>();
  const { register, control, formState } = formContext;
  const { errors, isValid, isDirty, isSubmitting } = formState;
  const isDisabled = !isValid || !isDirty || isSubmitting;

  return (
    <Stack spacing={4}>
      <Box mb={5}>
        <Box>
          <Typography variant="h6">Confirm your password</Typography>
        </Box>
        <TextField
          type="password"
          label="Password"
          required
          fullWidth
          margin="normal"
          {...register("data.password")}
          //error={Boolean(errors.password)}
          //helperText={errors[].password?.message}
          {...register("data.password")}
        ></TextField>
      </Box>

      <Box>
        <Box mb={2}>
          <Typography variant="h6">Enter MFA Code</Typography>
          <Typography variant="body1">
            Please enter code from your chosen MFA method.
          </Typography>
        </Box>
        <Controller
          control={control}
          name="data.code"
          render={({ field: { onChange, value } }) => {
            return (
              <OtpInput
                inputProps={{ inputMode: "numeric" }}
                inputStyle={styles.Input}
                value={value}
                onChange={onChange}
                numInputs={6}
                isInputNum={true}
              />
            );
          }}
        />
      </Box>
      {errors.root?.serverError &&
        errors.root.serverError.message !== `MFA_REQUIRED_ERROR` && (
          <Typography color="error">
            {errors.root.serverError.message}
          </Typography>
        )}
      <Box sx={{ mt: 1, width: "100%" }}>
        <Button
          variant="tile"
          type="submit"
          color="primary"
          size="large"
          sx={{ alignSelf: "flex-end" }}
          disabled={isDisabled}
          fullWidth
        >
          <Typography fontWeight="bold">Confirm Code</Typography>
        </Button>
      </Box>
    </Stack>
  );
};
