import { gql } from "@apollo/client";
import { RetailerPayTypeNoNestingFragment } from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_RETAILER_PAY_TYPES = gql`
  ${RetailerPayTypeNoNestingFragment}

  query paymentMethods {
    retailerPayTypes(options: { limit: 1000 }) {
      items {
        ...RetailerPayTypeNoNesting
      }
    }
  }
`;
