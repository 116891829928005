import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GlobalSettings,
  UpdateGlobalSettingsInput,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import {
  MUTATION_UPDATE_GLOBAL_SETTINGS,
  QUERY_GLOBAL_SETTINGS,
} from "./constants";

export const useQueryGlobalSettings = () => {
  const { loading, error, data, refetch } = useQuery<{
    globalSettings: Omit<GlobalSettings, "serverConfig" | "currency">;
  }>(QUERY_GLOBAL_SETTINGS);

  return {
    items: data?.globalSettings,
    loading,
    error,
    refetch,
  };
};

export const useLazyQueryGlobalSettings = () => {
  const [query, { loading, error, data, refetch }] = useLazyQuery<{
    globalSettings: Omit<GlobalSettings, "serverConfig" | "currency">;
  }>(QUERY_GLOBAL_SETTINGS);
  return {
    query,
    items: data?.globalSettings,
    loading,
    error,
    refetch,
  };
};

export const useMutationUpdateGlobalSettings = () => {
  const [m] = useMutation<{
    updateGlobalSettings: Omit<GlobalSettings, "serverConfig" | "currency">;
  }>(MUTATION_UPDATE_GLOBAL_SETTINGS);

  const updateGlobalSettings = useCallback(
    (input: UpdateGlobalSettingsInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updateGlobalSettings;
      });
    },
    [m]
  );

  return {
    updateGlobalSettings,
  };
};
