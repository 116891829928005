import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { Page, Stack } from "../../../../components";
import { CustomerContactInformationDetailsCard } from "../../components/CustomerContactInformationDetailsCard";
import { Badge } from "../../../../components/Badge";
import { Alert, AlertTitle, Card } from "@mui/material";
import { CustomerAccountInformationDetailsCard } from "../../components/CustomerAccountInformationDetailsCard";
import { CustomerPageSkeleton } from "../../components";
import { MetaDetailsCard } from "../../../../components/MetaDetailsCard";

export interface ViewCustomerPageProps {}

export const ViewCustomerPage = ({}: ViewCustomerPageProps) => {
  const { data }: any = useLoaderData();
  const navigate = useNavigate();

  return (
    <React.Suspense fallback={<CustomerPageSkeleton />}>
      <Await resolve={data}>
        {([customer]) => {
          const badgeMarkup = (
            <Badge
              retailerState={
                customer.retailerId
                  ? "retailer-customer"
                  : "non-retailer-customer"
              }
            />
          );

          const nonRetailerCustomerNotice = !customer.retailerId ? (
            <Card>
              <Alert severity="warning">
                <AlertTitle>Notice</AlertTitle>
                This customer is a non-retailer customer. Please sync data by
                matching email address in Retailer to link with Roam.
              </Alert>
            </Card>
          ) : undefined;

          return (
            <Page
              title={`${customer.firstName} ${customer.lastName}`}
              goBack={{ onAction: () => navigate("/customers") }}
              badges={badgeMarkup}
            >
              <Stack spacing={8}>
                {nonRetailerCustomerNotice}
                <CustomerAccountInformationDetailsCard customer={customer} />
                <CustomerContactInformationDetailsCard customer={customer} />
                <MetaDetailsCard resource={customer} />
              </Stack>
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
