import { Staff } from "@towersystems/roam-common/lib/generated-types";
import { Page } from "components";

import { usePaginatedList } from "../../../../utilities/paginated-list";
import { StaffsList } from "../../components";
import { QUERY_STAFFS_LIST } from "../../utils/staffs";
import { Paper } from "@mui/material";
import { CreateStaffSheet } from "../../components/CreateStaffSheet";
import { useFlashMessages } from "../../../../utilities/flash-messages";
import { useCallback } from "react";
import { useModal } from "../../../../utilities/use-modal";
import { useNavigate } from "react-router-dom";

export const StaffsPage = () => {
  const modal = useModal();

  const paginatedList = usePaginatedList<Staff>({
    query: QUERY_STAFFS_LIST as any,
    resultKey: "staffs",
  });
  const { showMessage } = useFlashMessages();
  const navigate = useNavigate();

  const items: Staff[] = paginatedList.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];

  const handleOnSubmitSuccess = useCallback(
    ({ id }: Staff) => {
      showMessage({
        message: "Staff created successfully",
        severity: "success",
      });
      navigate(`/settings/staff/edit/${id}`);
    },
    [navigate, showMessage]
  );

  const primaryAction = {
    content: "Add Staff",
    onAction: modal.onOpen,
  };

  return (
    <Page
      primaryAction={primaryAction}
      title="Staff"
      titleDescription="Manage your staff account logins. Staff sent from Retailer will need to be upgraded to a staff account to sign in."
    >
      <Paper variant="outlined">
        <StaffsList loading={paginatedList.loading} items={items} />
        <CreateStaffSheet onSubmitSuccess={handleOnSubmitSuccess} {...modal} />
      </Paper>
    </Page>
  );
};
