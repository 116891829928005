import { Box, Stack, Switch, Typography } from "@mui/material";
import { StaffFormValues } from "modules/settings/utils/staffs/types";
import { Controller, useFormContext } from "react-hook-form";

export interface StaffSettingsFormProps {}

export function StaffSettingsForm(props: StaffSettingsFormProps) {
  const { control } = useFormContext<StaffFormValues>();

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"center"}
      >
        <Box>
          <Controller
            name="enabled"
            control={control}
            render={({ field }) => (
              <Switch {...field} checked={field.value} color="primary" />
            )}
          />
        </Box>
        <Box flex={1} paddingTop={1}>
          <Typography fontWeight={"bold"}>Enabled</Typography>
          <Typography>Allow staff to be used</Typography>
        </Box>
      </Stack>
    </Box>
  );
}
