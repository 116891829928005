import { toTitleCase } from "@towersystems/roam-common/lib/shared-utils";
import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { Page, Stack } from "../../../../components";
import { Badge } from "../../../../components/Badge";
import { ProductInventoryDetailsCard } from "../../components/ProductInventoryDetailsCard";
import { ProductVariantDetailsCard } from "../../components/ProductVariantDetailsCard";
import { ProductPricingDetailsCard } from "../../components/ProductPricingDetailsCard";
import { ProductDescriptionDetailsCard } from "../../components/ProductDescriptionDetailsCard";
import { Button } from "../../../../components/Button";
import { useMutation } from "@apollo/client";
import {
  MUTATION_DELETE_PRODUCT_IMAGE,
  MUTATION_UPDATE_PRODUCT,
  MUTATION_UPLOAD_PRODUCT_IMAGE,
} from "../../utils";
import {
  Product,
  UpdateProductInput,
} from "@towersystems/roam-common/lib/generated-types";
import { ProductImageThumbnail } from "../../../../components/ProductImageThumbnail";
import { UploadProductImageDialogue } from "../../../../components/UploadProductImageDialogue";
import { useModal } from "../../../../utilities/use-modal";
import { ProductPageSkeleton } from "../../components/ProductPageSkeleton";
import { MetaDetailsCard } from "../../../../components/MetaDetailsCard";

export interface ViewProductPageProps {}

export const ViewProductPage = (props: ViewProductPageProps) => {
  const { data }: any = useLoaderData();
  const imageModal = useModal();

  const navigate = useNavigate();
  const [q] = useMutation<{ updateProduct: Product }>(MUTATION_UPDATE_PRODUCT);
  const [uploadImageMutation] = useMutation(MUTATION_UPLOAD_PRODUCT_IMAGE);
  const [deleteProductImageMutation] = useMutation(
    MUTATION_DELETE_PRODUCT_IMAGE
  );

  const updateProduct = async (input: UpdateProductInput) => {
    return q({
      variables: {
        input: {
          ...input,
        },
      },
    })
      .then((res) => navigate(".", { replace: true }))
      .catch((err) => console.log(err));
  };

  return (
    <React.Suspense fallback={<ProductPageSkeleton />}>
      <Await resolve={data}>
        {([product]) => {
          const title = toTitleCase(product.name);

          const subtitle = product.hasVariants
            ? `${product.totalVariants} variants`
            : product.stockNumber;

          const badgeMarkup = (
            <Badge state={product.active ? "active" : "disabled"} />
          );

          const isContainerProduct = product.hasVariants;

          let buttons = [];

          if (product.active) {
            buttons.push(
              <Button
                key={1}
                variant="posTile"
                color="warning"
                onClick={() =>
                  updateProduct({
                    id: product.id,
                    active: false,
                  })
                }
              >
                Disable
              </Button>
            );
          } else {
            buttons.push(
              <Button
                key={2}
                variant="posTile"
                color="primary"
                onClick={() =>
                  updateProduct({
                    id: product.id,
                    active: true,
                  })
                }
              >
                Enable
              </Button>
            );
          }

          const imgPath = product.image?.path;

          const handleOnImageUpload = async (file: any, options: any) => {
            return uploadImageMutation({
              variables: {
                input: {
                  productId: product.id,
                  file,
                  sendRetailer: true,
                },
              },
            })
              .then((res) => {
                navigate(".", { replace: true });
                return true;
              })
              .catch((err) => false)
              .finally(() => false);
          };

          const handleOnDeleteProductImage = async () => {
            if (product.image?.id) {
              return deleteProductImageMutation({
                variables: {
                  imageId: product.image?.id,
                },
              })
                .then((res) => navigate(".", { replace: true }))
                .then(() => true)
                .catch((err) => false)
                .finally(() => false);
            }
            return false;
          };

          return (
            <Page
              adornment={
                <ProductImageThumbnail
                  variant={imgPath ? "default" : "upload"}
                  size="large"
                  src={product.image?.path}
                  onClick={imageModal.onOpen}
                />
              }
              title={title}
              titleDescription={subtitle}
              goBack={{ onAction: () => navigate("/products") }}
              badges={badgeMarkup}
            >
              <Stack spacing={8}>
                {buttons.length >= 1 ? (
                  <Stack direction="row" spacing={4}>
                    {buttons}
                  </Stack>
                ) : undefined}
                {isContainerProduct && (
                  <ProductVariantDetailsCard product={product} />
                )}
                {!isContainerProduct ? (
                  <>
                    <ProductInventoryDetailsCard product={product} />
                    <ProductPricingDetailsCard product={product} />
                    <ProductDescriptionDetailsCard product={product} />
                    <MetaDetailsCard resource={product} />
                  </>
                ) : undefined}
              </Stack>
              {imageModal.open && (
                <UploadProductImageDialogue
                  onDelete={handleOnDeleteProductImage}
                  showDelete={Boolean(product.image?.id)}
                  onSubmit={handleOnImageUpload}
                  deleteThumbnailSrc={product.image?.path}
                  {...imageModal}
                />
              )}
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
