import { Box, Divider } from "@mui/material";
import { SkeletonHeader, SkeletonHeaderProps } from "../SkeletonHeader";

export interface SkeletonPageProps extends SkeletonHeaderProps {
  children?: React.ReactNode;
  divider?: boolean;
}

export function SkeletonPage({
  children,
  divider = true,
  ...headerProps
}: SkeletonPageProps) {
  const header = <SkeletonHeader {...headerProps} />;
  return (
    <Box paddingBottom={6} paddingTop={3} height="100%">
      {header}
      {divider && <Divider sx={{ mt: 3, mb: 3 }} />}
      <Box height="100%">{children}</Box>
    </Box>
  );
}
