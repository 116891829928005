import { yupResolver } from "@hookform/resolvers/yup";
import {
  PaymentMethod,
  Terminal,
  UpdateTerminalInput,
} from "@towersystems/roam-common/lib/generated-types";
import { ContextualSaveBar, Stack } from "components";
import {
  defaultTerminalFormValues,
  terminalFormSchema,
} from "modules/settings/utils/terminals";
import { useMutationUpdateTerminal } from "modules/settings/utils/terminals/hooks";
import { TerminalFormValues } from "modules/settings/utils/terminals/types";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRevalidator } from "react-router-dom";
import { normalizeSelectedValues } from "utilities/transforms";
import { TerminalDetailsForm } from "../TerminalDetailsForm";
import { TerminalInvoiceForm } from "../TerminalInvoiceForm";
import { TerminalPaymentsForm } from "../TerminalPaymentsForm";
import { TerminalSettingsForm } from "../TerminalSettingsForm";
import {
  TerminalInformationSettingCard,
  TerminalInvoiceConfigurationSettingCard,
  TerminalPaymentsSettingCard,
  TerminalSettingsSettingCard,
} from "./components";

export interface EditTerminalFormProps {
  onSubmitSuccess(terminal: Terminal): void;
  terminal: Terminal;
  paymentMethods: PaymentMethod[];
  defaultCashPaymentMethodId?: string;
}

export function EditTerminalForm({
  onSubmitSuccess,
  terminal,
  paymentMethods,
  defaultCashPaymentMethodId,
}: EditTerminalFormProps) {
  const methods = useForm<TerminalFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: normalizeSelectedValues(defaultTerminalFormValues, terminal),
    resolver: yupResolver(terminalFormSchema),
  });
  const revalidator = useRevalidator();
  const { handleSubmit, formState } = methods;
  const { isDirty } = formState;

  const { updateTerminal } = useMutationUpdateTerminal();

  const handleOnSubmit = useCallback(
    async (formValues: TerminalFormValues) => {
      try {
        const result = await updateTerminal(formValues as UpdateTerminalInput);

        onSubmitSuccess && onSubmitSuccess(result);
        methods.reset(formValues); // reset defaults to the updated values
        revalidator.revalidate(); // updates prefetched data
      } catch (error) {
        console.error(error);
      }
    },
    [updateTerminal]
  );

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{ onAction: handleSubmit(handleOnSubmit) }}
      cancelAction={{ onAction: () => methods.reset() }}
    />
  ) : undefined;

  return (
    <FormProvider {...methods}>
      {contextualSaveBar}
      <Stack spacing={5}>
        <TerminalInformationSettingCard>
          <TerminalDetailsForm />
        </TerminalInformationSettingCard>
        <TerminalInvoiceConfigurationSettingCard>
          <TerminalInvoiceForm />
        </TerminalInvoiceConfigurationSettingCard>
        <TerminalPaymentsSettingCard>
          <TerminalPaymentsForm
            defaultCashPaymentMethodId={defaultCashPaymentMethodId}
            {...{ paymentMethods }}
          />
        </TerminalPaymentsSettingCard>
        <TerminalSettingsSettingCard>
          <TerminalSettingsForm />
        </TerminalSettingsSettingCard>
      </Stack>
    </FormProvider>
  );
}
