import { Link } from "@mui/material";
import { SettingCard } from "../SettingCard";

export interface RemovalAiSettingCardProps {
  children?: React.ReactNode;
  strategy?: string;
}

export function RemovalAiSettingCard({
  children,
  strategy,
}: RemovalAiSettingCardProps) {
  const description =
    strategy === "RemovalAiStrategy" ? (
      <>
        <Link
          href="https://removal.ai/"
          target="_blank"
          rel="noreferrer"
          fontSize="small"
          aria-label="For more information, visit https://removal.ai/ which opens in a new window"
        >
          Visit their website for more information.
        </Link>
      </>
    ) : (
      <>
        <Link
          href="https://www.remove.bg/tools-api"
          target="_blank"
          rel="noreferrer"
          fontSize="small"
          aria-label="For more information, visit https://www.remove.bg which opens in a new window"
        >
          Visit their website for more information.
        </Link>
      </>
    );

  return (
    <SettingCard wrapped title="API details" description={description}>
      {children}
    </SettingCard>
  );
}
