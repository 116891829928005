import { SettingCard } from "../../../SettingCard";

export interface ReceiptSettingsSettingCardProps {
  children?: React.ReactNode;
}

export function ReceiptSettingsSettingCard({
  children,
}: ReceiptSettingsSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Receipt Settings"
      description="Modify settings applied to this receipt."
    >
      {children}
    </SettingCard>
  );
}
