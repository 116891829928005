import { Page } from "components";
import React, { useCallback } from "react";
import { Await, useLoaderData } from "react-router-dom";
import {
  BackgroundEraserForm,
  BackgroundEraserPageSkeleton,
} from "../../components";
import { BackgroundEraserFormValues } from "../../components/BackgroundEraserForm/types";
import { useMutationUpdateGlobalSettings } from "../../utils/global-settings";
import { UpdateGlobalSettingsInput } from "@towersystems/roam-common/lib/generated-types";

export const BackgroundEraserPage = () => {
  const prefetchData: any = useLoaderData();
  const { updateGlobalSettings } = useMutationUpdateGlobalSettings();

  const handleOnSubmit = useCallback(
    async (input: BackgroundEraserFormValues) => {
      let i: UpdateGlobalSettingsInput = {
        automaticBackgroundRemoval: input.automaticBackgroundRemoval,
        bgRemovalStrategy: input.bgRemovalStrategy as any,
      };

      if (
        !input.bgRemovalStrategy ||
        input.bgRemovalStrategy == "RemovalBgStrategy"
      ) {
        i.removalBgDetails = { apiKey: input.bgApiKey };
      } else {
        i.removalAiDetails = { apiKey: input.aiApiKey };
      }

      const result = await updateGlobalSettings(i);

      const {
        removalAiDetails,
        automaticBackgroundRemoval,
        bgRemovalStrategy,
      } = result;

      return {
        removalAiDetails,
        automaticBackgroundRemoval,
        bgRemovalStrategy,
      };
    },
    []
  );

  return (
    <React.Suspense fallback={<BackgroundEraserPageSkeleton />}>
      <Await resolve={prefetchData.data}>
        {([partialGlobalSettings]) => {
          return (
            <Page
              title="Background Eraser"
              titleDescription="Remove backgrounds from product images."
            >
              <BackgroundEraserForm
                onSubmit={handleOnSubmit}
                backgroundEraserFormValues={{
                  bgApiKey: partialGlobalSettings?.removalBgDetails?.apiKey,
                  aiApiKey: partialGlobalSettings?.removalAiDetails?.apiKey,
                  automaticBackgroundRemoval:
                    partialGlobalSettings?.automaticBackgroundRemoval,
                  bgRemovalStrategy: partialGlobalSettings?.bgRemovalStrategy,
                }}
              />
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
