import { PaymentMethod } from "@towersystems/roam-common/lib/generated-types";
import { Page } from "components";
import { CreatePaymentMethodSheet } from "modules/settings/components/CreatePaymentMethodSheet";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useFlashMessages } from "../../../../utilities/flash-messages";
import { useModal } from "../../../../utilities/use-modal";
import { PaymentMethodList } from "../../components";
import { useQueryPaymentMethods } from "../../utils/payment-methods/hooks";
import { Paper } from "@mui/material";

export const PaymentMethodsPage = () => {
  const { items, loading } = useQueryPaymentMethods();
  const addPaymentMethodModal = useModal();
  const { showMessage } = useFlashMessages();
  const navigate = useNavigate();

  const handleOnSubmitSuccess = useCallback(
    ({ id }: PaymentMethod) => {
      showMessage({
        message: "Payment Method created successfully",
        severity: "success",
      });
      navigate(`/settings/payment-methods/edit/${id}`);
    },
    [navigate, showMessage]
  );

  const primaryAction = {
    content: "Add Payment Method",
    onAction: addPaymentMethodModal.onOpen,
  };

  return (
    <Page
      title="Payment Methods"
      titleDescription="Create and manage payments you accept at your locations"
      primaryAction={primaryAction}
    >
      <Paper variant="outlined">
        <PaymentMethodList items={items as any} loading={loading} />
      </Paper>
      <CreatePaymentMethodSheet
        onSubmitSuccess={handleOnSubmitSuccess}
        {...addPaymentMethodModal}
      />
    </Page>
  );
};
