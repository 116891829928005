import { useContext, useState } from "react";
import { SearchContext } from "./context";

export const useSearch = (initialQuery: string = "") => {
  const [touched, setTouched] = useState(false);
  const [query, setQuery] = useState(initialQuery);

  const handleSetQuery = (q: string) => {
    setTouched(true);
    setQuery(q);
  };

  const clearQuery = () => setQuery("");
  return { query, touched, setQuery: handleSetQuery, clearQuery };
};

export const useSearchQueryProvider = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("No SearchContext context was provided.");
  }
  return context;
};
