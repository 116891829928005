import ReplayIcon from "@mui/icons-material/Replay";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IconButton, Stack, Typography } from "@mui/material";
import { Page } from "../Page";

export interface PageErrorOccurredProps {
  errorMessage?: string;
  onRetry?: () => void;
}

const GENERIC_ERROR_MESSAGE = "An error occurred, retry or contact support.";

export function PageErrorOccurred({
  errorMessage,
  onRetry,
}: PageErrorOccurredProps) {
  function handleRetry() {
    if (!!onRetry) {
      onRetry();
    } else {
      window.location.reload();
    }
  }

  const retryMarkup = (
    <IconButton onClick={handleRetry}>
      <ReplayIcon />
    </IconButton>
  );

  return (
    <Page divider={false}>
      <Stack
        spacing={3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1">Whoops...</Typography>
        <WarningAmberIcon style={{ fontSize: 72 }} color="warning" />
        <Typography>{errorMessage || GENERIC_ERROR_MESSAGE}</Typography>
        {retryMarkup}
      </Stack>
    </Page>
  );
}
