import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";
import { FormLayout, RichTextField } from "components";
import { Controller, useFormContext } from "react-hook-form";
import { ProductFormValues } from "../../utils";

export interface ProductDetailsFormProps {}

export function ProductDetailsForm(props: ProductDetailsFormProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<ProductFormValues>();

  return (
    <FormLayout>
      <TextField
        label="Name"
        fullWidth
        {...register("name")}
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
      />

      <RichTextField label="Description" {...register("description")} />

      <FormLayout.Group>
        <TextField
          label="Stock Code"
          fullWidth
          {...register("stockCode")}
          error={Boolean(errors.stockCode)}
          helperText={errors.stockCode?.message}
        />
        <TextField
          label="Stock Number"
          fullWidth
          {...register("stockNumber")}
          error={Boolean(errors.stockNumber)}
          helperText={errors.stockNumber?.message}
        />
      </FormLayout.Group>

      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Settings</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} checked={field.value} color="primary" />
                )}
              />
            }
            label="Active"
            labelPlacement="end"
          />
          <FormControlLabel
            control={
              <Controller
                name="taxable"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} checked={field.value} color="primary" />
                )}
              />
            }
            label="Taxable"
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
    </FormLayout>
  );
}
