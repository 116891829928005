import { gql } from "@apollo/client";
import {
  PayTypeDeepNestingFragment,
  ReceiptTemplateTypeDeepNestingFragment,
  ServerConfigDeepNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_SERVER_CONFIG = gql`
  ${ServerConfigDeepNestingFragment}
  ${PayTypeDeepNestingFragment}
  ${ReceiptTemplateTypeDeepNestingFragment}
  query ServerConfig {
    serverConfig {
      ...ServerConfigDeepNesting
    }
  }
`;
