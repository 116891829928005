import { useMutation, useQuery } from "@apollo/client";
import {
  Receipt,
  ReceiptInput,
  ReceiptList,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import {
  MUTATION_CREATE_RECEIPT,
  MUTATION_UPDATE_RECEIPT,
  QUERY_RECEIPTS,
} from "./constants";

export function toReceiptInput(receipt: Receipt): ReceiptInput {
  const {
    __typename: _ignore1,
    createdAt,
    updatedAt,
    deletedAt,
    ...receiptCommon
  } = receipt;
  const { __typename: _ignore2, logoURL, ...settingsCommon } = receipt.settings;

  return {
    ...receiptCommon,
    settings: { logo: undefined, ...settingsCommon },
  };
}

export const useQueryReceipts = () => {
  const { loading, error, data, refetch } = useQuery<{ receipts: ReceiptList }>(
    QUERY_RECEIPTS
  );

  return {
    items: data?.receipts?.items,
    loading,
    error,
    refetch,
  };
};

export const useMutationCreateReceipt = () => {
  const [m] = useMutation<{
    createReceipt: Receipt;
  }>(MUTATION_CREATE_RECEIPT);
  const createReceipt = useCallback(
    (input: ReceiptInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.createReceipt;
      });
    },
    [m]
  );
  return {
    createReceipt,
  };
};

export const useMutationUpdateReceipt = () => {
  const [m] = useMutation<{
    updateReceipt: Receipt;
  }>(MUTATION_UPDATE_RECEIPT);
  const updateReceipt = useCallback(
    (input: ReceiptInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updateReceipt;
      });
    },
    [m]
  );
  return {
    updateReceipt,
  };
};
