import { useRef } from "react";

const DEFAULT_SCHEDULE_DELAY = 500;

/**
 * Schedules function for execution after delay
 * @param delay wait in ms before action is executed
 */
export function useScheduler(delay: number = DEFAULT_SCHEDULE_DELAY) {
  const timerRef = useRef<NodeJS.Timeout>();

  function schedule(func: () => void) {
    cancel();
    timerRef.current = setTimeout(func, delay);
  }

  function cancel() {
    if (timerRef.current) clearTimeout(timerRef.current);
  }

  return {
    schedule,
    cancel,
  };
}
