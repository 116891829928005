import { UserV2 } from "@towersystems/roam-common/lib/generated-types";
import { SettingCard } from "../../../../../settings/components";
import { UpdatePasswordFormProvider } from "../../../../components/UpdatePasswordForm/UpdatePasswordFormProvider";
import { UpdatePasswordForm } from "../../../../components/UpdatePasswordForm";
import React from "react";
import { UpdatePasswordFormValues } from "../../../../components/UpdatePasswordForm/types";
import { useMutation } from "@apollo/client";
import { UpdateMeMutation } from "../../../../../../utilities/active-user";
import { useFormContext } from "react-hook-form";

export interface UpdateCredentialsSettingsCardProps {
  user: UserV2;
}

interface UpdateCredentialsSettingsProps
  extends UpdateCredentialsSettingsCardProps {}

const UpdateCredentialsSettings = ({}: UpdateCredentialsSettingsProps) => {
  const { setError, formState, reset } = useFormContext();
  const { isSubmitSuccessful } = formState;

  const [updateMeMutation] = useMutation<{
    updateMe: UserV2;
  }>(UpdateMeMutation);

  const handleOnSubmit = React.useCallback(
    (values: UpdatePasswordFormValues) => {
      console.log("values", values);
      return updateMeMutation({
        variables: {
          input: {
            newPassword: values.newPassword,
            currentPassword: values.oldPassword,
          },
        },
      })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          if (err.message == "error.invalid-credentials") {
            setError("oldPassword", {
              type: "400",
              message: "Invalid password",
            });
          }
        });
    },
    []
  );

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  return <UpdatePasswordForm onSubmit={handleOnSubmit} />;
};

export const UpdateCredentialsSettingsCard = ({
  ...props
}: UpdateCredentialsSettingsCardProps) => {
  return (
    <SettingCard
      wrapped
      title={"Credentials"}
      description={"Update your credentials"}
    >
      <UpdatePasswordFormProvider>
        <UpdateCredentialsSettings {...props} />
      </UpdatePasswordFormProvider>
    </SettingCard>
  );
};
