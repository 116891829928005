import { ContextualSaveBar, Page, Stack } from "components";
import { EditStaffForm } from "modules/settings/components/EditStaffForm";
import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { StaffPageSkeleton } from "../../components";
import { Button } from "../../../../components/Button";
import { Staff } from "@towersystems/roam-common/lib/generated-types";
import { UpdateStaffAccountFormProvider } from "../../components/UpdateStaffAccountForm/UpdateStaffAccountFormProvider";
import { UpdateStaffAccountFormValues } from "../../components/UpdateStaffAccountForm/types";
import { useFormContext } from "react-hook-form";
import { UpdateStaffAccountForm } from "../../components/UpdateStaffAccountForm";
import { useMutationUpdateStaffUserAccount } from "../../utils/staffs/hooks";

interface EditStaffAccountProps {
  staff: Staff;
}

const EditStaffAccount = ({ staff }: EditStaffAccountProps) => {
  const { handleSubmit, reset, setError, formState } =
    useFormContext<UpdateStaffAccountFormValues>();
  const { updateStaffUserAccount } = useMutationUpdateStaffUserAccount();

  const { isDirty } = formState;
  const navigate = useNavigate();

  const goBackURL = `/settings/staff`;

  const handleOnSubmit = React.useCallback(
    async (formValues: UpdateStaffAccountFormValues) => {
      return updateStaffUserAccount({
        staffId: formValues.staffId,
        username: formValues.username,
        newPassword: formValues.password ?? undefined,
        emailAddress: formValues.emailAddress,
        enabled: formValues.enabled,
        initials: formValues.initials,
        fullName: formValues.fullName,
      })
        .then((staff) => {
          if (staff) {
            // reload the page to clear the stafe incase logged in user
            navigate(0);
          }
        })
        .catch((err) => {
          if (err.message === "error.username-already-exists") {
            setError("username", {
              message: "Username already exists",
            });
          }
        });
    },
    []
  );

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{ onAction: handleSubmit(handleOnSubmit) }}
      cancelAction={{ onAction: () => reset() }}
    />
  ) : undefined;

  return (
    <Page
      title="Edit Staff Account"
      titleDescription="Edit staff account details."
      goBack={{
        onAction: () => navigate(goBackURL),
      }}
    >
      {contextualSaveBar}
      <UpdateStaffAccountForm staff={staff} />
    </Page>
  );
};

export interface EditStaffPageProps {}

export function EditStaffPage(props: EditStaffPageProps) {
  const navigate = useNavigate();
  const { data }: any = useLoaderData();

  return (
    <React.Suspense fallback={<StaffPageSkeleton />}>
      <Await resolve={data}>
        {([resolvedStaff]) => {
          let buttons: any[] = [];

          if (false === resolvedStaff.hasUserAccount) {
            buttons.push(
              <Button
                variant="posTile"
                color="primary"
                onClick={() =>
                  navigate(
                    `/settings/staffs/edit/${resolvedStaff.id}/migrate-account`
                  )
                }
              >
                Upgrade to Account
              </Button>
            );
          }

          if (resolvedStaff.hasUserAccount) {
            return (
              <UpdateStaffAccountFormProvider staff={resolvedStaff}>
                <EditStaffAccount staff={resolvedStaff} />
              </UpdateStaffAccountFormProvider>
            );
          }

          return (
            <Page
              title={resolvedStaff.fullName}
              titleDescription="Edit Staff"
              goBack={{ onAction: () => navigate("/settings/staff") }}
            >
              {buttons.length >= 1 ? (
                <Stack style={{ marginBottom: 32 }} spacing={4}>
                  {buttons}
                </Stack>
              ) : undefined}
              <EditStaffForm staff={resolvedStaff} />
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
}
