import { useContext } from "react";
import { useModal } from "../../../../utilities/use-modal";
import { Remove2faContext, SetupNative2faContext } from "./context";
import { Remove2faContextType, SetupNative2faContextType } from "./types";
import React from "react";
import { MfaCodeStrategy } from "@towersystems/roam-common/lib/generated-types";

export const useSetupNative2fa = (): SetupNative2faContextType => {
  const modal = useModal();
  return {
    modal,
  };
};

export const useRemove2fa = (): Remove2faContextType => {
  const [strategyCode, setStrategyCode] = React.useState<
    MfaCodeStrategy | undefined
  >(undefined);
  const [open, setOpen] = React.useState(false);

  const handleOnOpen = (strategyCode: MfaCodeStrategy) => {
    setStrategyCode(strategyCode);
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
    setStrategyCode(undefined);
  };

  return {
    modal: {
      open,
      onOpen: handleOnOpen,
      onClose: handleOnClose,
    },
    strategyCode,
  };
};

export const useSetupNative2faProvider = (): SetupNative2faContextType => {
  const context = useContext(SetupNative2faContext);
  if (!context) {
    throw new Error("No SetupNative2faContext context was provided.");
  }
  return context;
};

export const useRemove2faProvider = (): Remove2faContextType => {
  const context = useContext(Remove2faContext);
  if (!context) {
    throw new Error("No Remove2faContext context was provided.");
  }
  return context;
};
