import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import styles from "./LoggedOutFrame.module.scss";
export interface LoggedOutFrameProps {}

export const LoggedOutFrame = ({}: LoggedOutFrameProps) => {
  return (
    <Container component="main" maxWidth={false} className={styles.Container}>
      <Outlet />
    </Container>
  );
};
