import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  CreatePaymentMethodInput,
  PaymentMethod,
  UpdatePaymentMethodInput,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import {
  MUTATION_CREATE_PAYMENT_METHOD,
  MUTATION_UPDATE_PAYMENT_METHOD,
  QUERY_PAYMENT_METHOD,
  QUERY_PAYMENT_METHODS,
} from "./constants";

export const useQueryPaymentMethods = () => {
  const { loading, error, data, refetch } = useQuery<{
    paymentMethods: {
      items: Omit<PaymentMethod, "configuration">[];
    };
  }>(QUERY_PAYMENT_METHODS);
  return {
    items: data?.paymentMethods.items,
    loading,
    error,
    refetch,
  };
};

export const useLazyQueryPaymentMethods = () => {
  const [query, { loading, error, data, refetch }] = useLazyQuery<{
    paymentMethods: { items: Omit<PaymentMethod, "configuration"> };
  }>(QUERY_PAYMENT_METHODS);
  return {
    query,
    items: data?.paymentMethods.items,
    loading,
    error,
    refetch,
  };
};

export const useQueryPaymentMethod = () => {
  const { loading, error, data, refetch } = useQuery<{
    paymentMethod: PaymentMethod;
  }>(QUERY_PAYMENT_METHOD);
  return {
    item: data?.paymentMethod,
    loading,
    error,
    refetch,
  };
};

export const useMutationCreatePaymentMethod = () => {
  const [m] = useMutation<{
    createPaymentMethod: PaymentMethod;
  }>(MUTATION_CREATE_PAYMENT_METHOD);
  const createPaymentMethod = useCallback(
    (input: CreatePaymentMethodInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.createPaymentMethod;
      });
    },
    [m]
  );
  return {
    createPaymentMethod,
  };
};

export const useMutationUpdatePaymentMethod = () => {
  const [m] = useMutation<{
    updatePaymentMethod: PaymentMethod;
  }>(MUTATION_UPDATE_PAYMENT_METHOD);
  const updatePaymentMethod = useCallback(
    (input: UpdatePaymentMethodInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updatePaymentMethod;
      });
    },
    [m]
  );
  return {
    updatePaymentMethod,
  };
};
