import { gql } from "@apollo/client";
import {
  AuthenticationMethodDeepNestingFragment,
  MfaMethodDeepNestingFragment,
  RoleDeepNestingFragment,
  UserV2DeepNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const USER_TOKEN_KEY = "ROAM_CLIENT_USER_TOKEN_KEY";
export const USER_KEY = "__ROAM_CLIENT_USER__";

export const loginMutation = gql`
  mutation Login(
    $input: AuthenticationInput!
    $mfa: AuthenticationMfaInput
    $rememberMe: Boolean
  ) {
    authenticate(input: $input, rememberMe: $rememberMe, mfa: $mfa) {
      ... on CurrentUserAndTenant {
        currentUser {
          id
          identifier
          token
          permissions
        }
        tenant {
          identifier
        }
      }
      ... on InvalidCredentialsError {
        message
        errorCode
        authenticationError
      }
      ... on NotVerifiedError {
        message
        errorCode
      }
      ... on MfaRequiredError {
        message
        errorCode
        strategies
      }
      ... on InvalidMfaCodeError {
        message
        errorCode
      }
    }
  }
`;

export const requestMfaCodeMutation = gql`
  mutation MutationRequestMfaCode(
    $input: AuthenticationInput!
    $mfaStrategy: MfaCodeStrategy!
  ) {
    requestMfaCode(input: $input, mfaStrategy: $mfaStrategy) {
      ... on Success {
        success
      }
    }
  }
`;

export const loginWithTaLinkTokenMutation = gql`
  mutation LoginWithTaLinkToken($token: String!) {
    loginWithTaLinkToken(token: $token) {
      ... on AuthenticationResult {
        talinkId
        userId
        token
      }
      ... on InvalidCredentialsError {
        message
        errorCode
        authenticationError
      }
    }
  }
`;

// Reveal all information about the current authenticated user
export const ActiveUserQuery = gql`
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}
  query QueryActiveUser {
    activeUser {
      ...UserV2DeepNesting
    }
  }
`;
