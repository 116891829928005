import { Box, TextField } from "@mui/material";
import { capitalize } from "lodash";
import { useFormContext } from "react-hook-form";
import { FormLayout, Select } from "components";
import { useServerConfigProvider } from "../../../../utilities/server-config";
import { ReceiptFormValues } from "../../utils/receipts/types";

export function ReceiptDetailsForm() {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<ReceiptFormValues>();

  const serverConfig = useServerConfigProvider();

  const receiptTemplateTypeOptions = serverConfig.receiptTemplateTypes.map(
    (t) => ({ label: capitalize(t.id), value: t.id })
  );

  return (
    <Box>
      <FormLayout>
        <TextField
          label="Name"
          fullWidth
          {...register("name")}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
        />
        <TextField
          label="Header"
          fullWidth
          {...register("settings.header")}
          error={Boolean(errors.settings?.header)}
          helperText={errors.settings?.header?.message}
        />
        <TextField
          label="Footer"
          fullWidth
          {...register("settings.footer")}
          error={Boolean(errors.settings?.footer)}
          helperText={errors.settings?.footer?.message}
        />
        <TextField
          label="Settings Name"
          fullWidth
          {...register("settings.name")}
          error={Boolean(errors.settings?.name)}
          helperText={errors.settings?.name?.message}
        />
        <Select
          defaultValue={getValues("defaultTemplateType")}
          label="Template Type"
          {...register("defaultTemplateType")}
          ref={null}
          options={receiptTemplateTypeOptions}
        />
      </FormLayout>
    </Box>
  );
}
