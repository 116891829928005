import {
  Box,
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from "@mui/material";
import { Sizes } from "./constants";
import { getSize } from "./utils";
import styles from "./Avatar.module.scss";

export interface AvatarProps extends MuiAvatarProps {
  size?: Sizes[keyof Sizes];
  initials?: string;
}

/**
 * Standard Mui Avatar with standardised sizing and inset shadow for unambiguous border
 */
export function Avatar({ size = Sizes.SMALL, ...props }: AvatarProps) {
  const initialsMarkup = props.initials ? (
    <Box className={styles.Initials}>{props.initials}</Box>
  ) : null;

  return <Box className={styles.Avatar}>{initialsMarkup}</Box>;
}
