import { onError } from "@apollo/client/link/error";
import { ApolloClient, ApolloLink, from, InMemoryCache } from "@apollo/client";
import { USER_TOKEN_KEY } from "./utilities/authentication/constants";
import { createUploadLink } from "apollo-upload-client";

const errorLink = onError(({ graphQLErrors }) => {
  const e = graphQLErrors && graphQLErrors[0];
  if (e?.message === "error.forbidden") {
    localStorage.removeItem(USER_TOKEN_KEY);
    // TODO: Redirect to login page
    window.location.href = "/login";
  }
});

const httpLinkURI = `${process.env.REACT_APP_API_URL}`;

const httpLink = createUploadLink({ uri: httpLinkURI });

const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem(USER_TOKEN_KEY) || null}`,
    },
  }));

  return forward(operation);
});

const additiveLink = from([errorLink, authLink, httpLink]);

export const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
});
