import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ComplexAction } from "../../../../types";
import { Button } from "../../../Button";

export interface FooterProps {
  primaryAction: ComplexAction;
  secondaryAction?: ComplexAction;
}

const commonButtonProps = {
  fullWidth: true,
} as const;

export function Footer({ primaryAction, secondaryAction }: FooterProps) {
  return (
    <Box
      position="relative"
      boxShadow="0px -2px 1px -1px rgba(0,0,0,0.1),0px -1px 1px 0px rgba(0,0,0,0.07),0px -1px 3px 0px rgba(0,0,0,0.06)"
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 2,
        }}
      >
        {primaryAction && (
          <Button
            {...commonButtonProps}
            loading={primaryAction?.loading}
            onClick={primaryAction?.onAction}
            disabled={primaryAction?.disabled}
            color="primary"
          >
            {primaryAction?.content}
          </Button>
        )}
        {secondaryAction && (
          <Button
            {...commonButtonProps}
            loading={secondaryAction?.loading}
            onClick={secondaryAction?.onAction}
          >
            {secondaryAction?.content}
          </Button>
        )}
      </Stack>
    </Box>
  );
}
