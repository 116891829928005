import { Box, Typography, IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";

export interface HeaderProps {
  title: React.ReactNode;
  onClose(): void;
}

export function Header({ title, onClose }: HeaderProps) {
  let headerMarkup: React.ReactNode = null;

  if (typeof title === "string") {
    headerMarkup = <Typography variant="subtitle1">{title}</Typography>;
  } else {
    headerMarkup = title;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: 2,
        py: 1,
        height: 64,
        zIndex: (theme) => theme.zIndex.modal,
      }}
      boxShadow={1}
    >
      <Box sx={{ flexGrow: 5 }}>{headerMarkup}</Box>
      <IconButton onClick={onClose}>
        <MdClose />
      </IconButton>
    </Box>
  );
}
