import * as yup from "yup";

export const schema = yup.object().shape({
  staffId: yup.string().required("Staff ID is required"),
  username: yup.string().required("Username is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  emailAddress: yup
    .string()
    .email("Please enter a valid email address")
    .nullable(),
});
