import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useAuthenticationProvider } from "../../utilities/authentication/hooks";
import Toolbar from "@mui/material/Toolbar";
import {
  Box,
  Divider,
  IconButton,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useFrame } from "../../utilities/frame";
import { UserAvatar } from "../UserAvatar";
import { Logo } from "../Logo";

export interface TopBarProps {
  title: string;
  children: React.ReactNode | React.ReactNode[] | null;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isMobileView?: boolean;
}

const drawerWidth: number = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) =>
    false === ["isMobileView", "open"].includes(prop as any),
})<AppBarProps>(({ theme, open, isMobileView }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: isMobileView ? "100%" : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const TopBar = ({ title, children }: TopBarProps) => {
  const frame = useFrame();
  const theme = useTheme();
  const { mobileViewActive } = frame;

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const drawerConent = (
    <>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={frame.toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      {children}
    </>
  );

  return (
    <>
      <AppBar
        sx={{
          boxShadow: "none",
          backgroundColor: theme.palette.dynamicBackground?.main,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: theme.palette.border.standard,
        }}
        position="absolute"
        open={frame.drawerOpen}
        isMobileView={mobileViewActive}
      >
        <Toolbar
          sx={{
            pr: "24px",
            backgroundColor: "dynamicBackground",
          }}
        >
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={frame.toggleDrawer}
            sx={[
              !mobileViewActive && {
                marginRight: "36px",
                ...(frame.drawerOpen && { display: "none" }),
              },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Box flexGrow={1}>
            <Logo />
          </Box>
          <UserAvatar />
        </Toolbar>
      </AppBar>
      <MuiDrawer
        container={container}
        variant="temporary"
        open={frame.drawerOpen}
        onClose={frame.toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: mobileViewActive ? "block" : "none",
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawerConent}
      </MuiDrawer>
      <Drawer
        variant={"permanent"}
        open={frame.drawerOpen}
        sx={{
          display: mobileViewActive ? "none" : "block",
        }}
      >
        {drawerConent}
      </Drawer>
    </>
  );
};
