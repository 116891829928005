import { Box, TextField } from "@mui/material";
import { SwitchActiveUserFormValues } from "../../types";
import { useFormContext } from "react-hook-form";

export interface CredentialsFieldsetProps {}

export const CredentialsFieldset = ({}: CredentialsFieldsetProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SwitchActiveUserFormValues>();

  return (
    <Box>
      <TextField
        type="password"
        label="Password"
        required
        fullWidth
        margin="normal"
        {...register("password")}
        error={Boolean(errors.password)}
        helperText={errors.password?.message}
        {...register("password")}
      ></TextField>
    </Box>
  );
};
