import { useFormContext } from "react-hook-form";
import { Remove2faFormValues } from "./types";
import { EnterCredentialsFieldset } from "./components";
import { EnterMfaCodeFieldset } from "./components/EnterMfaCodeFieldset";

export interface Remove2faFormProps {
  onSubmit: (formValues: Remove2faFormValues) => Promise<unknown> | unknown;
}

export const Remove2faForm = ({ onSubmit }: Remove2faFormProps) => {
  const formContext = useFormContext<Remove2faFormValues>();
  const { watch, handleSubmit } = formContext;

  const watchedCodeSent = watch("ui.codeSent");

  if (false === watchedCodeSent) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <EnterCredentialsFieldset />
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EnterMfaCodeFieldset />
    </form>
  );
};
