import { Box, Typography } from "@mui/material";
import { wrappedPadding } from "../../constants";

export interface SectionProps {
  subdued?: boolean;
  children?: React.ReactNode;
}

export function Section({ subdued, children }: SectionProps) {
  return (
    <Box
      sx={{
        ml: -wrappedPadding,
        mr: -wrappedPadding,
        mb: -wrappedPadding,
        p: wrappedPadding,
        backgroundColor: (theme) =>
          subdued ? theme.palette.subduedPaper : undefined,
      }}
    >
      {children}
    </Box>
  );
}
