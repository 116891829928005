import { Box, Typography } from "@mui/material";
import { Stack } from "../../../../../../components";
import { Controller, useFormContext } from "react-hook-form";
import OtpInput from "react18-input-otp";
import styles from "./EnterMfaCodeFieldset.module.scss";
import { Button } from "../../../../../../components/Button";
import { Remove2faFormValues } from "../../types";

export interface EnterMfaCodeFieldsetProps {}

export const EnterMfaCodeFieldset = ({}: EnterMfaCodeFieldsetProps) => {
  const formContext = useFormContext<Remove2faFormValues>();
  const { register, control, formState } = formContext;
  const { errors, isValid, isDirty, isSubmitting } = formState;
  const isDisabled = !isValid || !isDirty || isSubmitting;

  return (
    <Stack spacing={4}>
      <Box>
        <Box mb={2}>
          <Typography variant="h6">Enter MFA Code</Typography>
          <Typography variant="body1">
            Please enter code from your chosen MFA method.
          </Typography>
        </Box>
        <Controller
          control={control}
          name="data.code"
          render={({ field: { onChange, value } }) => {
            return (
              <OtpInput
                inputProps={{ inputMode: "numeric" }}
                inputStyle={styles.Input}
                value={value}
                onChange={onChange}
                numInputs={6}
                isInputNum={true}
              />
            );
          }}
        />
      </Box>
      {errors.root?.serverError && (
        <Typography color="error">{errors.root.serverError.message}</Typography>
      )}
      <Box sx={{ mt: 1, width: "100%" }}>
        <Button
          variant="tile"
          type="submit"
          color="primary"
          size="large"
          sx={{ alignSelf: "flex-end" }}
          disabled={isDisabled}
          fullWidth
        >
          <Typography fontWeight="bold">Remove MFA</Typography>
        </Button>
      </Box>
    </Stack>
  );
};
