import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GlobalSettings,
  UpdateGlobalSettingsInput,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback, useContext, useState } from "react";
import {
  GlobalSettingsQuery,
  GLOBAL_SETTINGS_KEY,
  MUTATION_UPDATE_GLOBAL_SETTINGS,
} from "./constants";
import { GlobalSettingsContext, UseGlobalSettingsContext } from "./context";
import { UseGlobalSettingsResponseType } from "./types";

export const useGlobalSettings = (): UseGlobalSettingsResponseType => {
  const [globalSettings, _setGlobalSettings] = useState<
    GlobalSettings | undefined
  >(undefined);

  const [queryGlobalSettingsFn] = useLazyQuery<{
    globalSettings: GlobalSettings;
  }>(GlobalSettingsQuery);

  const setGlobalSettings = useCallback(
    (settings: GlobalSettings) => {
      _setGlobalSettings(settings);
    },
    [_setGlobalSettings]
  );

  const queryGlobalSettings = useCallback(async () => {
    const cachedGlobalSettings = localStorage.getItem(GLOBAL_SETTINGS_KEY);
    return queryGlobalSettingsFn()
      .then((r) => {
        if (r.data?.globalSettings) {
          localStorage.setItem(
            GLOBAL_SETTINGS_KEY,
            JSON.stringify(r.data.globalSettings)
          );
          setGlobalSettings(r.data.globalSettings);
          return r.data.globalSettings;
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        if (cachedGlobalSettings) {
          setGlobalSettings(JSON.parse(cachedGlobalSettings));
          return JSON.parse(cachedGlobalSettings);
        }
      });
  }, [setGlobalSettings, queryGlobalSettingsFn]);

  return {
    globalSettings,
    setGlobalSettings,
    queryGlobalSettings,
  };
};

export const useGlobalSettingsReponseProvider = () => {
  const context = useContext(UseGlobalSettingsContext);
  if (!context) {
    throw new Error("No UseGlobalSettingsContext context was provided.");
  }
  return context;
};

export const useMutationUpdateGlobalSettings = () => {
  const { setGlobalSettings } = useGlobalSettingsReponseProvider();

  const [m] = useMutation<{
    updateGlobalSettings: GlobalSettings;
  }>(MUTATION_UPDATE_GLOBAL_SETTINGS);

  const updateGlobalSettings = useCallback(
    async (input: UpdateGlobalSettingsInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        setGlobalSettings(d.data.updateGlobalSettings);
        return d.data?.updateGlobalSettings;
      });
    },
    [m]
  );
  return {
    updateGlobalSettings,
  };
};

export const useGlobalSettingsProvider = () => {
  const context = useContext(GlobalSettingsContext);
  if (!context) {
    throw new Error("No GlobalSettingsContext context was provided.");
  }
  return context;
};
