import { gql } from "@apollo/client";
import {
  PaginatedListMetaNoNestingFragment,
  ReceiptListFragment,
  ReceiptNoNestingFragment,
  ReceiptSettingsNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as Yup from "yup";
import { fileUploadSchema } from "../../../../utilities/images";

export const QUERY_RECEIPTS = gql`
  ${ReceiptListFragment}
  ${PaginatedListMetaNoNestingFragment}
  ${ReceiptNoNestingFragment}
  query receipts($options: ReceiptListOptions) {
    receipts(options: $options) {
      ...ReceiptList
    }
  }
`;

export const QUERY_RECEIPT = gql`
  ${ReceiptNoNestingFragment}
  ${ReceiptSettingsNoNestingFragment}
  query receipt($id: ID!) {
    receipt(id: $id) {
      ...ReceiptNoNesting
      settings {
        ...ReceiptSettingsNoNesting
      }
    }
  }
`;

export const MUTATION_CREATE_RECEIPT = gql`
  ${ReceiptNoNestingFragment}
  ${ReceiptSettingsNoNestingFragment}
  mutation createReceipt($input: ReceiptInput!) {
    createReceipt(input: $input) {
      ...ReceiptNoNesting
      settings {
        ...ReceiptSettingsNoNesting
      }
    }
  }
`;

export const MUTATION_UPDATE_RECEIPT = gql`
  ${ReceiptNoNestingFragment}
  ${ReceiptSettingsNoNestingFragment}
  mutation updateReceipt($input: ReceiptInput!) {
    updateReceipt(input: $input) {
      ...ReceiptNoNesting
      settings {
        ...ReceiptSettingsNoNesting
      }
    }
  }
`;

export const defaultReceiptFormValues = {
  name: "",
  enabled: true,
  defaultTemplateType: "a4",
  settings: {
    header: "",
    name: "",
    footer: "",
    displayCustomerDetails: true,
    logo: undefined,
  },
};

export const receiptSettingsSchema = Yup.object({
  header: Yup.string(),
  name: Yup.string(),
  footer: Yup.string(),
  displayCustomerDetails: Yup.boolean(),
  logo: fileUploadSchema,
});

export const receiptFormSchema = Yup.object({
  name: Yup.string().required(),
  enabled: Yup.boolean(),
  defaultTemplateType: Yup.string(),
  settings: receiptSettingsSchema,
});

export const ReceiptFormSections = {
  DETAILS: "Details",
  SETTINGS: "Settings",
} as const;
