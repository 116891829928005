import { Box, Card, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { wrappedPadding } from "../SettingCard/constants";
import {
  DynamicSkeleton,
  DynamicSkeletonType,
  Skeleton,
  Stack,
} from "../../../../components";
import { Children } from "react";

export interface SettingCardSkeletonProps {
  title?: DynamicSkeletonType;
  description?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[] | null;
  wrapped?: boolean;
}

export const SettingCardSkeleton = ({
  title,
  wrapped,
  children,
  description,
}: SettingCardSkeletonProps) => {
  const titleMarkup = (
    <Typography>{title === true ? <Skeleton width={120} /> : title}</Typography>
  );

  const descriptionMarkup = !!description ? (
    <DynamicSkeleton
      content={description}
      typographyProps={{ fontSize: "small", color: "GrayText" }}
      skeletonProps={{ width: 100 }}
    />
  ) : undefined;

  const childrenFormatted =
    Children.count(children) > 1 ? (
      <Stack spacing={3}>{children}</Stack>
    ) : (
      children
    );

  const content = wrapped ? (
    <Card sx={{ p: wrappedPadding }}>{childrenFormatted}</Card>
  ) : (
    childrenFormatted
  );

  return (
    <Grid container>
      <Grid container item xs={4} direction="column">
        <Box sx={{ mr: 10 }}>
          {titleMarkup}
          {descriptionMarkup}
        </Box>
      </Grid>
      <Grid container item xs={8} direction="column">
        {content}
      </Grid>
    </Grid>
  );
};
