import { Product } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";

export interface ProductInventoryDetailsCardProps {
  product: Product;
}

export const ProductInventoryDetailsCard = ({
  product,
}: ProductInventoryDetailsCardProps) => {
  return (
    <CardDetailList
      title="Inventory"
      emptyComponent="No inventory."
      items={product.locationInventoryPricing.map((i) => {
        return {
          key: i.id,
          title: i.location?.name || "-",
          value: i.stockOnHand || "0",
        };
      })}
    ></CardDetailList>
  );
};
