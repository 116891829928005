import { SettingCard } from "../../../SettingCard";

export interface PaymentMethodPayTypeSettingCardProps {
  children?: React.ReactNode;
}

export function PaymentMethodPayTypeSettingCard({
  children,
}: PaymentMethodPayTypeSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Retailer PayType"
      description="Select the PayType the payment will be imported as in Retailer"
    >
      {children}
    </SettingCard>
  );
}
