import { SortOrder } from "@towersystems/roam-common/lib/generated-types";
import { useState } from "react";
import { UnionMap } from "./types";

export const useDynamicSort = <
  T extends Partial<Record<keyof T, SortOrder | undefined>>
>() => {
  const [dynamicSort, setDynamicSort] = useState<
    UnionMap<keyof T, SortOrder> | undefined
  >(undefined);

  const toggleSort = (toggledSort: keyof T) => {
    setDynamicSort((prev) => {
      const isActive = prev && prev[toggledSort];
      const shouldAscend = isActive && prev[toggledSort] === SortOrder.DESC;

      if (shouldAscend) {
        return {
          [toggledSort]: SortOrder.ASC,
        } as UnionMap<keyof T, SortOrder>;
      } else if (isActive) {
        return undefined;
      } else {
        return {
          [toggledSort]: SortOrder.DESC,
        } as UnionMap<keyof T, SortOrder>;
      }
    });
  };

  return {
    toggleSort,
    dynamicSort,
    setDynamicSort,
  };
};
