import { yupResolver } from "@hookform/resolvers/yup";
import { Staff } from "@towersystems/roam-common/lib/generated-types";
import { FormProvider, useForm } from "react-hook-form";
import { MigrateStaffFormValues } from "./types";
import { schema } from "./constants";

export interface MigrateStaffAccountFormProviderProps {
  children?: React.ReactNode;
  staff: Staff;
}
export const MigrateStaffAccountFormProvider = ({
  children,
  staff,
}: MigrateStaffAccountFormProviderProps) => {
  const methods = useForm<MigrateStaffFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: staffToDefaultValues(staff),
    resolver: yupResolver(schema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

function staffToDefaultValues(staff: Staff): MigrateStaffFormValues {
  return {
    staffId: staff.id,
    username: "",
    password: "",
    confirmPassword: "",
    emailAddress: "",
  };
}
