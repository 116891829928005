import { gql } from "@apollo/client";
import { FeaturesListDeepNestingFragment } from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_FEATURES = gql`
  ${FeaturesListDeepNestingFragment}
  query Features {
    features {
      ...FeaturesListDeepNesting
    }
  }
`;
