import { createContext } from "react";

export type SearchContextType = {
  query: string;
  setQuery: (query: string) => void;
  clearQuery: () => void;
};

export const SearchContext = createContext<SearchContextType | undefined>(
  undefined
);
