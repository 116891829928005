import { Box, Typography } from "@mui/material";
import { Button } from "../../../../../../components/Button";
import styles from "./EnterMfaCodeFieldset.module.scss";
import { Controller, useFormContext } from "react-hook-form";
import { LoginFormValues } from "../../../../utils/login";
import OtpInput from "react18-input-otp";
import { Stack } from "../../../../../../components";

export interface EnterMfaCodeFieldsetProps {}

export const EnterMfaCodeFieldset = ({}: EnterMfaCodeFieldsetProps) => {
  const formContext = useFormContext<LoginFormValues>();
  const { control, formState } = formContext;
  const { errors, isValid, isDirty, isSubmitting } = formState;
  const isDisabled = !isValid || !isDirty || isSubmitting;

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h6">Enter MFA Code</Typography>
          <Typography variant="body1">
            Please enter code from your chosen MFA method.
          </Typography>
        </Box>
        <Box className={styles.InputWrapper}>
          <Controller
            control={control}
            name="mfaCode"
            render={({ field: { onChange, value } }) => {
              return (
                <OtpInput
                  inputProps={{ inputMode: "numeric" }}
                  inputStyle={styles.Input}
                  value={value}
                  onChange={onChange}
                  numInputs={6}
                  isInputNum={true}
                  focusStyle={{
                    borderColor: "#F37121",
                    backgroundColor: "#fff",
                  }}
                />
              );
            }}
          />
        </Box>

        {errors.root?.serverError &&
          errors.root.serverError.message !== `MFA_REQUIRED_ERROR` && (
            <Typography color="error">
              {errors.root.serverError.message}
            </Typography>
          )}
        <Box sx={{ mt: 1, width: "100%" }}>
          <Button
            variant="tile"
            type="submit"
            color="primary"
            size="large"
            sx={{ alignSelf: "flex-end" }}
            disabled={isDisabled}
            fullWidth
          >
            <Typography fontWeight="bold">Continue</Typography>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
