import { SettingCard } from "../../../SettingCard";

export interface TerminalSettingsSettingCardProps {
  children?: React.ReactNode;
}

export function TerminalSettingsSettingCard({
  children,
}: TerminalSettingsSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Terminal Settings"
      description="Configure the terminal to your liking."
    >
      {children}
    </SettingCard>
  );
}
