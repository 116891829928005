import { Location } from "@towersystems/roam-common/lib/generated-types";
import { Page } from "components";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useFlashMessages } from "../../../../utilities/flash-messages";
import { usePaginatedList } from "../../../../utilities/paginated-list";
import { useModal } from "../../../../utilities/use-modal";
import { CreateLocationModal, LocationsGrid } from "../../components";
import { QUERY_LOCATIONS_LIST } from "../../utils/locations";
import { Paper } from "@mui/material";

export const LocationsPage = () => {
  const addLocationModal = useModal();
  const navigate = useNavigate();
  const { showMessage } = useFlashMessages();

  const paginatedList = usePaginatedList<Location>({
    query: QUERY_LOCATIONS_LIST as any,
    resultKey: "locations",
  });

  const handleOnSubmitSuccess = useCallback(
    ({ id }: Location) => {
      showMessage({
        message: "Location created successfully",
        severity: "success",
      });
      navigate(`/settings/locations/edit/${id}`);
    },
    [navigate, showMessage]
  );

  const primaryAction = {
    content: "Add Location",
    onAction: addLocationModal.onOpen,
  };

  const items: Location[] = paginatedList.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];

  return (
    <Page
      title="Locations"
      titleDescription="Manage which of your locations are visible on Point of Sale. Locations represent a physical retailer store where stock is held."
      {...{ primaryAction }}
    >
      <Paper variant="outlined">
        <LocationsGrid loading={paginatedList.loading} items={items} />
      </Paper>
      <CreateLocationModal
        onSubmitSuccess={handleOnSubmitSuccess}
        {...addLocationModal}
      />
    </Page>
  );
};
