import { useFormContext } from "react-hook-form";
import { MigrateStaffFormValues } from "./types";
import { Stack } from "../../../../components";
import { AccountIdentityFieldset } from "./components";
import { AccountSecurityFieldset } from "./components/AccountSecurityFieldset/AccountSecurityFieldset";

export interface MigrateStaffAccountFormProps {}

export const MigrateStaffAccountForm = ({}: MigrateStaffAccountFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<MigrateStaffFormValues>();

  return (
    <Stack spacing={5}>
      <AccountIdentityFieldset />
      <AccountSecurityFieldset />
    </Stack>
  );
};
