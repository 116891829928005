import { yupResolver } from "@hookform/resolvers/yup";
import {
  Receipt,
  ReceiptInput,
} from "@towersystems/roam-common/lib/generated-types";
import { Accordion, Sheet, Stack } from "components";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAccordionController } from "utilities/accordion";
import { UseModalReturn } from "../../../../utilities/use-modal";
import {
  defaultReceiptFormValues,
  receiptFormSchema,
  ReceiptFormSections,
} from "../../utils/receipts";
import { useMutationCreateReceipt } from "../../utils/receipts/hooks";
import { ReceiptFormValues } from "../../utils/receipts/types";
import { ReceiptDetailsForm } from "../ReceiptDetailsForm";
import { ReceiptSettingsForm } from "../ReceiptSettingsForm";

export interface CreateReceiptModalProps extends UseModalReturn {
  onSubmitSuccess(receipt: Receipt): void;
}

const defaultAccordionOpenSections = [
  ReceiptFormSections.DETAILS,
  ReceiptFormSections.SETTINGS,
];

export function CreateReceiptSheet({
  onSubmitSuccess,
  ...drawerController
}: CreateReceiptModalProps) {
  const methods = useForm<ReceiptFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultReceiptFormValues,
    resolver: yupResolver(receiptFormSchema),
  });

  const { getAccordionState } = useAccordionController(
    defaultAccordionOpenSections
  );

  const { handleSubmit, formState } = methods;
  const { isValid } = formState;

  const { createReceipt } = useMutationCreateReceipt();

  const handleOnSubmit = useCallback(
    async (formValues: ReceiptFormValues) => {
      try {
        const result = await createReceipt(formValues as ReceiptInput);

        onSubmitSuccess && onSubmitSuccess(result);
      } catch (error) {
        console.error(error);
      }
    },
    [createReceipt]
  );

  const primaryAction = {
    content: "Add New Receipt",
    onAction: handleSubmit(handleOnSubmit),
    disabled: !isValid,
  };

  const secondaryAction = {
    content: "Cancel",
    onAction: drawerController.onClose,
  };

  return (
    <Sheet
      title="Add Receipt"
      {...{ primaryAction, secondaryAction }}
      onTransitionEnd={methods.reset}
      {...drawerController}
    >
      <FormProvider {...methods}>
        <Stack fullWidth spacing={2}>
          <Accordion {...getAccordionState(ReceiptFormSections.DETAILS)}>
            <Accordion.Section>
              <ReceiptDetailsForm />
            </Accordion.Section>
          </Accordion>
          <Accordion {...getAccordionState(ReceiptFormSections.SETTINGS)}>
            <Accordion.Section>
              <ReceiptSettingsForm />
            </Accordion.Section>
          </Accordion>
        </Stack>
      </FormProvider>
    </Sheet>
  );
}
