import { Sale } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";
import { moneyToFormattedString } from "../../../../utilities/money";

export interface SaleTotalsDetailCardProps {
  sale: Sale;
}

export const SaleTotalsDetailCard = ({ sale }: SaleTotalsDetailCardProps) => {
  return (
    <CardDetailList
      title="Totals"
      items={[
        {
          key: "discount",
          title: "Discount",
          value: moneyToFormattedString(sale.totalAdjustments),
        },
        {
          key: "tax",
          title: "Tax",
          value: moneyToFormattedString(sale.totalTax),
        },
        {
          key: "total",
          title: "Total",
          value: moneyToFormattedString(sale.totalPrice),
        },
      ]}
    ></CardDetailList>
  );
};
