import { Customer } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";

export interface CustomerContactInformationDetailsCardProps {
  customer: Customer;
}

export const CustomerContactInformationDetailsCard = ({
  customer,
}: CustomerContactInformationDetailsCardProps) => {
  return (
    <CardDetailList
      title="Contact Information"
      items={[
        {
          key: "emailAddress",
          title: "Email Address",
          value: customer.emailAddress || "",
        },
        {
          key: "phoneNumber",
          title: "Phone Number",
          value: customer.phoneNumber || "",
        },
        {
          key: "mobileNumber",
          title: "Mobile Number",
          value: customer.mobileNumber || "",
        },
      ]}
    ></CardDetailList>
  );
};
