import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Category } from "@towersystems/roam-common/lib/generated-types";
import { useNavigate } from "react-router-dom";
import { ProductImageThumbnail } from "../../../../components/ProductImageThumbnail";
import { toTitleCase } from "@towersystems/roam-common/lib/shared-utils";
import { Badge } from "../../../../components/Badge";
import { ResourceListSkeleton } from "../../../../components/ResourceListSkeleton";

export interface CategoryResourceListProps {
  loading?: boolean;
  items?: Category[];
}

interface HeadCell {
  disablePadding?: boolean;
  id: string;
  label: string;
  numeric?: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Category",
  },
  {
    id: "status",
    numeric: false,
    label: "status",
  },
  {
    id: "numberOfProducts",
    numeric: true,
    disablePadding: true,
    label: "Products",
  },
];

export const CategoryResourceList = ({
  items,
  loading,
}: CategoryResourceListProps) => {
  const navigate = useNavigate();

  return (
    <TableContainer>
      {loading ? (
        <ResourceListSkeleton />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((i) => (
                <TableCell key={i.id}>{i.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((i) => (
              <TableRow
                key={i.id}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    `/departments/view/${i.departmentId}/categories/${i.id}`
                  )
                }
              >
                <TableCell width={40}>
                  <ProductImageThumbnail src={i.bannerImageUrl} />
                </TableCell>
                <TableCell>
                  <Typography fontWeight={"bold"}>
                    {toTitleCase(i.name)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Badge state={i.active ? "active" : "disabled"} />
                </TableCell>

                <TableCell>
                  <Typography>{i.numberOfProducts}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
