import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AccessTokenSession } from "@towersystems/roam-common/lib/generated-types";
import { useDeleteAccessToken } from "../../utils";
import { useCallback } from "react";
import { Skeleton } from "../../../../components";

export interface ApiKeyListProps {
  loading?: boolean;
  afterItemDeleteSuccess?(): void;
  items?: AccessTokenSession[];
}

const NUM_LOADING_PLACEHOLDERS = 3;

export const ApiKeyList = ({
  loading,
  items,
  afterItemDeleteSuccess,
}: ApiKeyListProps) => {
  const { deleteAccessToken } = useDeleteAccessToken();

  const handleOnDeleteAccessToken = useCallback((id: string) => {
    return deleteAccessToken(id)
      .then((i) => {
        afterItemDeleteSuccess && afterItemDeleteSuccess();
        return i;
      })
      .catch();
  }, []);

  const rows = loading
    ? Array.from({ length: NUM_LOADING_PLACEHOLDERS }).map((_, i) => (
        <ApiKeyRowSkeleton key={i} />
      ))
    : items?.map((i) => {
        return (
          <TableRow key={i.id}>
            <TableCell>{i.createdAt}</TableCell>
            <TableCell>{i.token}</TableCell>
            <TableCell>{i.name}</TableCell>
            <TableCell>
              <IconButton
                onClick={() => handleOnDeleteAccessToken(i.id)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 280 }}>Created At</TableCell>
            <TableCell style={{ width: 430 }}>API Key</TableCell>
            <TableCell>Label</TableCell>
            <TableCell style={{ width: 72 }} />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

function ApiKeyRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Typography style={{ width: 150 }}>
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography style={{ width: 200 }}>
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography style={{ width: 40 }}>
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell>
        <Skeleton variant="rectangular" width={24} height={24} />
      </TableCell>
    </TableRow>
  );
}
