import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { ContextualSaveBar, Page, Stack } from "../../../../components";
import { SelfCheckoutLocationSettingsFormProvider } from "../../components/SelfCheckoutLocationSettingsForm/SelfCheckoutLocationSettingsFormProvider";
import React from "react";
import {
  SelfCheckoutSettings,
  SelfCheckoutLocationSettings,
  Terminal,
} from "@towersystems/roam-common/lib/generated-types";
import { Box, Typography } from "@mui/material";
import { SelfCheckoutLocationSettingsForm } from "../../components/SelfCheckoutLocationSettingsForm/SelfCheckoutLocationSettingsForm";
import { useFormContext } from "react-hook-form";
import { SelfCheckoutLocationSettingsFormValues } from "../../components/SelfCheckoutLocationSettingsForm";
import { useMutationSelfCheckoutLocationSettingsUpdate } from "../../utils/self-checkout-settings";
import { SettingCard } from "../../components";
import PlaceTwoToneIcon from "@mui/icons-material/PlaceTwoTone";
import { Button } from "../../../../components/Button";
import OfflineShareTwoToneIcon from "@mui/icons-material/OfflineShareTwoTone";
import QrCodeTwoToneIcon from "@mui/icons-material/QrCodeTwoTone";
import { useModal } from "../../../../utilities/use-modal";
import { SelfCheckoutQRCodeModal } from "../../components/SelfCheckoutQRCodeModal";

export interface EditSelfCheckoutLocationSettingPageProps {}

const Content = ({
  selfCheckoutSettings,
  selfCheckoutLocationSettings,
  terminals,
  storeUrl,
}: EditSelfCheckoutLocationSettingPageProps & {
  selfCheckoutSettings: SelfCheckoutSettings;
  terminals: Terminal[];
  selfCheckoutLocationSettings: SelfCheckoutLocationSettings;
  storeUrl: string;
}) => {
  const {
    formState: { isValid, isSubmitting, isSubmitSuccessful, isDirty, errors },
    handleSubmit,
    reset,
    getValues,
  } = useFormContext<SelfCheckoutLocationSettingsFormValues>();

  const qrCodeModal = useModal();
  const { mutation } = useMutationSelfCheckoutLocationSettingsUpdate();

  const navigate = useNavigate();

  const handleOnSubmit = React.useCallback(
    async (values: SelfCheckoutLocationSettingsFormValues) => {
      return mutation({
        terminalId: values.terminalId,
        locationId: values.locationId,
        enabled: values.enabled,
        onlineCheckoutEnabled: values.onlineCheckoutEnabled,
        offlineCheckoutEnabled: values.offlineCheckoutEnabled,
        payAtCounterHeading: values.payAtCounterHeading,
        payAtCounterSubheading: values.payAtCounterSubheading,
        checkoutSuccessHeading: values.checkoutSuccessHeading,
      })
        .then(async (result) => {})
        .catch((err) => console.log(err));
    },
    []
  );

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      const values = getValues();
      reset(values);
    }
  }, [reset, isSubmitSuccessful]);

  const disabled = !isValid || !isDirty || isSubmitting;

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit(handleOnSubmit),
        disabled: disabled,
      }}
      cancelAction={{ onAction: () => reset() }}
    />
  ) : undefined;

  return (
    <Page
      title="Configure Self Checkout Location"
      titleDescription="Configure self checkout location"
      goBack={{ onAction: () => navigate("/settings/self-checkout") }}
    >
      <Stack spacing={3}>
        <Stack direction="row" style={{ marginBottom: 32 }} spacing={3}>
          <Button
            variant="posTile"
            color="primary"
            onClick={() => {
              qrCodeModal.onOpen();
            }}
            disabled={false}
            startIcon={
              <QrCodeTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
            }
          >
            QR Code
          </Button>
          <Button
            variant="posTile"
            color="primary"
            onClick={() => {
              console.log("storeUrl", storeUrl);
              window.open(storeUrl, "_blank");
            }}
            disabled={false}
            startIcon={
              <OfflineShareTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
            }
          >
            Visit Site
          </Button>
        </Stack>

        <SettingCard
          wrapped
          title="Location"
          description="The location this self checkout is for"
        >
          <Box display="flex" flexDirection={"row"} alignItems={"center"}>
            <Box>
              <Box
                sx={{ mr: 2 }}
                style={{
                  width: 50,
                  height: 50,
                  border: "1px solid var(--mui-palette-border-standard)",
                  borderRadius: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PlaceTwoToneIcon />
              </Box>
            </Box>
            <Box sx={{ flex: 1, flexGrow: 1 }}>
              <Typography>
                {selfCheckoutLocationSettings.location?.name ||
                  "Unknown Location Name"}
              </Typography>
            </Box>
          </Box>
        </SettingCard>
        <SelfCheckoutLocationSettingsForm terminals={terminals} isEdit={true} />
      </Stack>
      {contextualSaveBar}
      <SelfCheckoutQRCodeModal modal={qrCodeModal} url={storeUrl} />
    </Page>
  );
};

export const EditSelfCheckoutLocationSettingPage = (
  props: EditSelfCheckoutLocationSettingPageProps
) => {
  const prefetchData: any = useLoaderData();

  return (
    <React.Suspense fallback={<div>loading</div>}>
      <Await resolve={prefetchData.data}>
        {([
          selfCheckoutSettings,
          selfCheckoutLocationSettings,
          terminals,
          url,
        ]: [
          SelfCheckoutSettings,
          SelfCheckoutLocationSettings,
          Terminal[],
          string
        ]) => {
          return (
            <SelfCheckoutLocationSettingsFormProvider
              defaultValues={{
                enabled: selfCheckoutLocationSettings.enabled,
                onlineCheckoutEnabled:
                  selfCheckoutLocationSettings.onlineCheckoutEnabled,
                offlineCheckoutEnabled:
                  selfCheckoutLocationSettings.offlineCheckoutEnabled,
                payAtCounterHeading:
                  selfCheckoutLocationSettings.payAtCounterHeading,
                payAtCounterSubheading:
                  selfCheckoutLocationSettings.payAtCounterSubheading,
                checkoutSuccessHeading:
                  selfCheckoutLocationSettings.checkoutSuccessHeading,
                locationId: selfCheckoutLocationSettings.locationId,
                terminalId: selfCheckoutLocationSettings.terminalId,
              }}
            >
              <Content
                selfCheckoutLocationSettings={selfCheckoutLocationSettings}
                selfCheckoutSettings={selfCheckoutSettings}
                terminals={terminals}
                storeUrl={url}
                {...props}
              />
            </SelfCheckoutLocationSettingsFormProvider>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
