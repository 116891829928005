export function normalizeSelectedValues<X extends Record<string, any>>(
  defaultValues: X,
  selectedObject?: Record<string, any>
): X {
  const result: any = {};
  Object.entries(defaultValues).forEach(([key, value]) => {
    if (selectedObject) {
      const selectedVal = selectedObject[key];
      if (selectedVal !== undefined && selectedVal !== null) {
        result[key] = selectedVal;
      } else {
        result[key] = value;
      }
    } else {
      result[key] = value;
    }
  });

  return result as X;
}
