import * as Yup from "yup";
import { SelfCheckoutLocationSettingsFormValues } from "./types";

export const defaultFormValues: SelfCheckoutLocationSettingsFormValues = {
  enabled: false,
  onlineCheckoutEnabled: false,
  offlineCheckoutEnabled: false,
  payAtCounterHeading: "",
  payAtCounterSubheading: "",
  checkoutSuccessHeading: "",
  locationId: "",
  terminalId: "",
};

export const schema = Yup.object().shape({
  enabled: Yup.boolean(),
  onlineCheckoutEnabled: Yup.boolean(),
  offlineCheckoutEnabled: Yup.boolean(),
  locationId: Yup.string().required("Location is required"),
  terminalId: Yup.string().required("Terminal is required"),
});
