import {
  Box,
  Container,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, useLocation } from "react-router-dom";
import BackIcon from "@mui/icons-material/ArrowBack";
import KeyIcon from "@mui/icons-material/Key";
import AutoFixHighTwoToneIcon from "@mui/icons-material/AutoFixHighTwoTone";
import { Link } from "react-router-dom";
import { FlashMessagesProvider, Frame, TopBar } from "components";
import { matchedLocation } from "../../utilities/match-navigation";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import ShoppingBasketTwoToneIcon from "@mui/icons-material/ShoppingBasketTwoTone";
import EditLocationTwoToneIcon from "@mui/icons-material/EditLocationTwoTone";
import DevicesOtherTwoToneIcon from "@mui/icons-material/DevicesOtherTwoTone";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";
import { FrameContext } from "../../utilities/frame";
import { useFeaturesProvider } from "../../utilities/features";

export interface SettingsFrameProps {}

export const SettingsFrame = ({}: SettingsFrameProps) => {
  const { pathname } = useLocation();
  const features = useFeaturesProvider();

  return (
    <Frame>
      <FrameContext.Consumer>
        {(maybeContext) => {
          if (!maybeContext) {
            return null;
          }

          const { toggleDrawer, drawerOpen, mobileViewActive } = maybeContext;

          const toggleIfMobile = () => {
            if (mobileViewActive && drawerOpen) {
              toggleDrawer();
            }
          };

          const makeLiProps = (path: string) => ({
            onClick: toggleIfMobile,
            component: Link,
            to: path,
            selected: matchedLocation(
              { url: path, exactMatch: true },
              pathname
            ),
          });

          return (
            <>
              <FlashMessagesProvider>
                <Box sx={{ display: "flex" }}>
                  <TopBar title="Settings">
                    <List component="nav">
                      <ListItemButton
                        component={Link}
                        to="/"
                        onClick={toggleIfMobile}
                      >
                        <ListItemIcon>
                          <BackIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                      </ListItemButton>
                    </List>
                    <Divider />
                    <List component="nav">
                      <ListItemButton {...makeLiProps("/settings")}>
                        <ListItemIcon>
                          <SettingsTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="General" />
                      </ListItemButton>
                      <Divider />
                      <ListSubheader component="div" inset>
                        Point of Sale
                      </ListSubheader>

                      <ListItemButton
                        {...makeLiProps("/settings/payment-methods")}
                      >
                        <ListItemIcon>
                          <PaymentsTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="Payment Methods" />
                      </ListItemButton>

                      <ListItemButton {...makeLiProps("/settings/receipts")}>
                        <ListItemIcon>
                          <ReceiptTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="Receipts" />
                      </ListItemButton>
                      <Divider />

                      {features.selfCheckout && (
                        <>
                          <ListSubheader component="div" inset>
                            Self Checkout
                          </ListSubheader>

                          <ListItemButton
                            component={Link}
                            to="/settings/self-checkout"
                            selected={matchedLocation(
                              {
                                url: "/settings/self-checkout",
                                exactMatch: true,
                              },
                              pathname
                            )}
                            onClick={toggleIfMobile}
                          >
                            <ListItemIcon>
                              <ShoppingBasketTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                            </ListItemIcon>
                            <ListItemText primary="Configuration" />
                          </ListItemButton>
                          <Divider />
                        </>
                      )}

                      <ListSubheader component="div" inset>
                        Retailer
                      </ListSubheader>

                      <ListItemButton {...makeLiProps("/settings/locations")}>
                        <ListItemIcon>
                          <EditLocationTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="Locations" />
                      </ListItemButton>

                      <ListItemButton {...makeLiProps("/settings/terminals")}>
                        <ListItemIcon>
                          <DevicesOtherTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="Terminals" />
                      </ListItemButton>
                      <ListItemButton {...makeLiProps("/settings/staff")}>
                        <ListItemIcon>
                          <GroupTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="Staff" />
                      </ListItemButton>
                      <Divider />
                      <ListSubheader component="div" inset>
                        Integrations
                      </ListSubheader>
                      <ListItemButton {...makeLiProps("/settings/api-keys")}>
                        <ListItemIcon>
                          <KeyIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="API Keys" />
                      </ListItemButton>

                      {features.selfCheckout && (
                        <ListItemButton
                          component={Link}
                          to="/settings/stripe"
                          selected={matchedLocation(
                            { url: "/settings/stripe", exactMatch: true },
                            pathname
                          )}
                          onClick={toggleIfMobile}
                        >
                          <ListItemIcon>
                            <KeyIcon htmlColor="var(--mui-palette-text-primary)" />
                          </ListItemIcon>
                          <ListItemText primary="Stripe" />
                        </ListItemButton>
                      )}
                      <ListItemButton
                        {...makeLiProps("/settings/background-eraser")}
                      >
                        <ListItemIcon>
                          <AutoFixHighTwoToneIcon htmlColor="var(--mui-palette-text-primary)" />
                        </ListItemIcon>
                        <ListItemText primary="Background Eraser" />
                      </ListItemButton>
                    </List>
                  </TopBar>
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      height: "100vh",
                      overflow: "auto",
                      backgroundColor: "background.default",
                    }}
                  >
                    <Toolbar sx={{ background: "transparent" }} />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                      <Outlet />
                    </Container>
                  </Box>
                </Box>
              </FlashMessagesProvider>
            </>
          );
        }}
      </FrameContext.Consumer>
    </Frame>
  );
};
