import { Customer } from "@towersystems/roam-common/lib/generated-types";
import { usePaginatedList } from "../../../../../../utilities/paginated-list";
import { useSearchQueryProvider } from "../../../../../../utilities/search/hooks";
import React from "react";
import { useScheduler } from "../../../../../../utilities/use-scheduler";
import { Box, Pagination } from "@mui/material";
import { QUERY_CUSTOMERS_LIST } from "../../../../utils";
import { CustomerResourceList } from "../../../../components/CustomerResourceList/CustomerResourceList";

export const AllCustomersTabView = () => {
  const search = useSearchQueryProvider();
  const { schedule } = useScheduler(500);

  const paginatedList = usePaginatedList<Customer>({
    query: QUERY_CUSTOMERS_LIST,
    resultKey: "customers",
    variables: {
      sort: { updatedAt: "DESC" },
      filter: { term: { contains: search.query } },
    },
  });

  React.useEffect(() => {
    handleOnTermInputChange();
  }, [search.query]);

  const handleOnTermInputChange = React.useCallback(() => {
    schedule(() =>
      paginatedList.setVariables({
        filter: { term: { contains: search.query } },
      })
    );
  }, [search.query]);

  const handleOnPageChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      paginatedList.setPage(value);
    },
    [paginatedList.setPage]
  );

  const items: Customer[] = paginatedList.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];

  const listMarkup = items ? (
    <CustomerResourceList loading={paginatedList.loading} items={items} />
  ) : undefined;

  const totalPages = paginatedList.maxPage;

  const paginationMarkup = (
    <Box sx={{ p: 2, borderColor: "grey.300" }} borderTop={1}>
      <Pagination
        onChange={handleOnPageChange}
        count={totalPages}
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );

  return (
    <Box>
      {listMarkup}
      {paginationMarkup}
    </Box>
  );
};
