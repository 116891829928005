import { gql } from "@apollo/client";
import {
  GlobalSettingsNoNestingFragment,
  RemovalAiDetailsNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as Yup from "yup";
import { allOrNothing } from "../../../../utilities/yup";

export const QUERY_GLOBAL_SETTINGS = gql`
  ${RemovalAiDetailsNoNestingFragment}
  ${GlobalSettingsNoNestingFragment}
  query globalSettings {
    globalSettings {
      ...GlobalSettingsNoNesting
      removalAiDetails {
        ...RemovalAiDetailsNoNesting
      }
    }
  }
`;

export const MUTATION_UPDATE_GLOBAL_SETTINGS = gql`
  ${RemovalAiDetailsNoNestingFragment}
  ${GlobalSettingsNoNestingFragment}
  mutation updateGlobalSettings($input: UpdateGlobalSettingsInput!) {
    updateGlobalSettings(input: $input) {
      ...GlobalSettingsNoNesting
      removalAiDetails {
        ...RemovalAiDetailsNoNesting
      }
    }
  }
`;

export const defaultGlobalSettingsFormValues = {
  taxPercentage: 10,
  currencyCode: "AUD",
  countryCode: "AU",
  timezone: "Australia/Melbourne",
  cashManagedPaymentMethodId: "",
  removalAiDetails: {
    apiKey: "",
  },
};

export const globalSettingsFormSchema = Yup.object()
  .shape({
    removalAiDetails: allOrNothing,
    taxPercentage: Yup.number()
      .integer()
      .max(100)
      .positive()
      .required("Please enter a tax percentage"),
  })
  .required()
  .noUnknown();
