import { Box } from "@mui/material";

export interface SectionProps {
  children?: React.ReactNode;
}

export function Section({ children }: SectionProps) {
  return (
    <Box
      sx={{
        borderTop: 1,
        borderColor: "border.standard",
      }}
    >
      <Box sx={{ p: 2 }}>{children}</Box>
    </Box>
  );
}
