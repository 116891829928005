import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, MenuItem, Stack } from "@mui/material";
import {
  GlobalSettings,
  PaymentMethod,
} from "@towersystems/roam-common/lib/generated-types";
import { ContextualSaveBar, Select } from "components";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { normalizeSelectedValues } from "../../../../utilities/transforms";
import {
  defaultGlobalSettingsFormValues,
  globalSettingsFormSchema,
} from "../../utils/global-settings";
import { GlobalSettingsFormValues } from "../../utils/global-settings/types";
import { CashManagedSettingCard } from "../CashManagedSettingCard";

export interface GeneralSettingsFormProps {
  onSubmit(input: GlobalSettingsFormValues): Promise<unknown>;
  paymentMethods: PaymentMethod[];
  globalSettings: GlobalSettings;
}

export const GeneralSettingsForm = ({
  globalSettings,
  paymentMethods,
  onSubmit,
}: GeneralSettingsFormProps) => {
  const methods = useForm<GlobalSettingsFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: normalizeSelectedValues(
      defaultGlobalSettingsFormValues,
      globalSettings
    ),
    resolver: yupResolver(globalSettingsFormSchema),
  });

  const { handleSubmit, formState } = methods;
  const { isDirty } = formState;

  useEffect(() => {
    methods.reset();
  }, [globalSettings]);

  const handleOnSubmit = useCallback(
    async (input: GlobalSettingsFormValues) => {
      await onSubmit(input);
      methods.reset(input);
    },
    [onSubmit]
  );

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{ onAction: handleSubmit(handleOnSubmit) }}
      cancelAction={{ onAction: () => methods.reset() }}
    />
  ) : undefined;

  return (
    <>
      {contextualSaveBar}
      <Stack spacing={3} divider={<Divider />}>
        <CashManagedSettingCard>
          <Controller
            control={methods.control}
            name="cashManagedPaymentMethodId"
            render={({ field: { onChange, value } }) => (
              <Select
                label="Select Cash Managed Payment Method"
                fullWidth
                placeholder="Select Cash Managed Payment Method"
                leadingChildren={
                  <MenuItem disabled value="">
                    <em>Select Payment Method</em>
                  </MenuItem>
                }
                value={value}
                onChange={onChange}
                options={paymentMethods
                  .filter((i) => i.payTypeId == "1")
                  .map((i) => {
                    return {
                      value: `${i.id}`,
                      label: i.name || "UNKNOWN",
                    };
                  })}
              ></Select>
            )}
          />
        </CashManagedSettingCard>
      </Stack>
    </>
  );
};
