import { Chip } from "@mui/material";
import styles from "./Badge.module.scss";
import { variationName, classNames } from "@towersystems/roam-common/lib/css";

export type Status =
  | "SAVED"
  | "CLOSED"
  | "CREATED"
  | "CANCELLED"
  | "VOIDED"
  | "LAYBY_CLOSED"
  | "LAYBY_SAVED";

export type State = "active" | "disabled";

export type CustomerAccountType = "cus-account";

export type RetailerState = "retailer-customer" | "non-retailer-customer";

export type SaleSource = "WEB_REGISTER" | "WEB_CHECKOUT";

export interface BadgeProps {
  status?: Status;
  state?: State;
  retailerState?: RetailerState;
  saleSource?: SaleSource;
  customerAccountType?: CustomerAccountType;
}

export const Badge = ({
  status,
  state,
  retailerState,
  saleSource,
  customerAccountType,
}: BadgeProps) => {
  const className = classNames(
    styles.Badge,
    status && styles[variationName("status", status.toLowerCase())],
    state && styles[variationName("state", state.toLowerCase())],
    retailerState &&
      styles[variationName("retailer", retailerState.toLowerCase())]
  );

  return (
    <Chip
      size="small"
      className={className}
      label={
        status || state || retailerState || saleSource || customerAccountType
      }
    ></Chip>
  );
};
