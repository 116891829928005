import { SettingCard } from "../../../SettingCard";

export interface TerminalInformationSettingCardProps {
  children?: React.ReactNode;
}

export function TerminalInformationSettingCard({
  children,
}: TerminalInformationSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Terminal Information"
      description="Name this terminal to make it identifiable to your staff."
    >
      {children}
    </SettingCard>
  );
}
