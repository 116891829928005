import { Stack } from "@mui/material";
import { CardDetailListSkeleton, SkeletonPage } from "../../../../components";

export interface CustomerPageSkeletonProps {}

export function CustomerPageSkeleton(props: CustomerPageSkeletonProps) {
  return (
    <SkeletonPage title goBack badges={1}>
      <Stack spacing={8}>
        <CardDetailListSkeleton
          title="Account Information"
          items={[
            { title: "Retailer Customer Number", value: true },
            { title: "Loyalty Points", value: true },
          ]}
        />
        <CardDetailListSkeleton
          title="Contact Information"
          items={[
            { title: "Email Address", value: true },
            { title: "Phone Number", value: true },
            { title: "Mobile Number", value: true },
          ]}
        />
      </Stack>
    </SkeletonPage>
  );
}
