import { LoggedInApp, LoggedOutApp, PageErrorOccurred } from "components";
import { defer, Navigate, RouteObject } from "react-router-dom";
import { routes as landingRoutes } from "./modules/landing";
import { routes as loginRoutes } from "./modules/login";
import { routes as productsRoutes } from "./modules/products";
import { routes as salesRoutes } from "./modules/sales";
import { routes as customersRoutes } from "./modules/customers";
import { routes as departmentsRoutes } from "./modules/departments";
import { routes as settingsRoutes } from "./modules/settings";
import { routes as meRoutes } from "./modules/me";
import { queryClient } from "./utilities/query-client";
import { QUERY_SERVER_CONFIG } from "./utilities/server-config/constants";
import { theme } from "./utilities/theme";
import { QUERY_FEATURES } from "./utilities/features";

export type RouteObjectWithPermissions = RouteObject & {
  permissions?: string[];
};

export const protectedRoutes: RouteObjectWithPermissions[] = [
  {
    path: "/",
    errorElement: <PageErrorOccurred />,
    element: <LoggedInApp theme={theme} />,
    loader: () => {
      const serverConfig = queryClient({
        query: QUERY_SERVER_CONFIG,
      }).then((res) => res.data.serverConfig);

      const features = queryClient({
        query: QUERY_FEATURES,
      }).then((res) => res.data.features);

      return defer({
        data: Promise.all([serverConfig, features]),
      });
    },
    shouldRevalidate: () => {
      return false;
    },
    children: [
      ...meRoutes,
      ...salesRoutes,
      ...settingsRoutes,
      ...landingRoutes,
      ...productsRoutes,
      ...customersRoutes,
      ...departmentsRoutes,
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

export const publicRoutes: RouteObject[] = [
  {
    path: "/login",
    element: <LoggedOutApp theme={theme} />,
    loader: async () => {
      return [];
    },
    children: [...loginRoutes],
  },
  {
    path: "*",
    element: <Navigate to="/login" />,
  },
];
