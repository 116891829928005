import { Box, Stack, Typography } from "@mui/material";
import {
  CardDetailListSkeleton,
  Skeleton,
  SkeletonPage,
  TileButtonSkeleton,
} from "../../../../components";

export interface ProductPageSkeletonProps {}

export function ProductPageSkeleton(props: ProductPageSkeletonProps) {
  const secondaryTitle = (
    <Box>
      <Typography fontWeight="bold">
        <Skeleton width={48} />
      </Typography>
    </Box>
  );

  return (
    <SkeletonPage
      title
      titleDescription={secondaryTitle}
      badges={1}
      goBack
      adornment={<Skeleton variant="rectangular" width={90} height={90} />}
    >
      <Stack spacing={8}>
        <Stack direction="row" spacing={4}>
          <TileButtonSkeleton />
          <TileButtonSkeleton />
        </Stack>
        <CardDetailListSkeleton title="Inventory" items={[{ title: true }]} />
        <CardDetailListSkeleton
          title="Retail Pricing"
          items={[{ title: true }]}
        />
        <CardDetailListSkeleton
          title="Description"
          items={[
            { title: true, value: true },
            { title: true, value: true },
            { title: true, value: true },
            { title: true, value: true },
          ]}
        />
      </Stack>
    </SkeletonPage>
  );
}
