import { Box } from "@mui/material";
import styles from "./ProductImageThumbnail.module.scss";
import SatelliteIcon from "@mui/icons-material/Satellite";
import { classNames, variationName } from "@towersystems/roam-common/lib/css";
import { AddPhotoAlternate } from "@mui/icons-material";

export interface ProductImageThumbnailProps {
  src?: string;
  quantity?: number;
  size?: "small" | "large";
  variant?: "default" | "upload";
  onClick?: () => void;
}

export const ProductImageThumbnail = ({
  src,
  quantity,
  size = "small",
  variant = "default",
  onClick,
}: ProductImageThumbnailProps) => {
  const numberMarkup =
    quantity !== undefined ? (
      <Box className={styles.QuantityWrapper}>{quantity}</Box>
    ) : undefined;

  const placeholderMarkup = !src ? (
    <Box className={styles.PlaceholderWrapper}>
      {variant == "default" ? <SatelliteIcon /> : <AddPhotoAlternate />}
    </Box>
  ) : undefined;

  const imageMarkup = !placeholderMarkup ? (
    <img src={src} className={styles.ProductImageThumbnail} />
  ) : undefined;

  const className = classNames(
    styles.Wrapper,
    styles[variationName("variant", variant)],
    styles[variationName("size", size)],
    onClick && styles.Clickable
  );

  return (
    <Box className={className} onClick={onClick}>
      {placeholderMarkup}
      {imageMarkup}
      {numberMarkup}
    </Box>
  );
};
