import { useLazyQuery, useMutation } from "@apollo/client";
import {
  SwitchUserResult,
  UserV2,
} from "@towersystems/roam-common/lib/generated-types";
import React from "react";
import { ActiveUserQuery, SwitchActiveUserMutation } from "./constants";
import {
  SwitchActiveUserContextType,
  SwitchUserFn,
  UseActiveUserResponseType,
} from "./types";
import { ActiveUserContext, SwitchActiveUserContext } from "./context";
import { useModal } from "../use-modal";
import { USER_TOKEN_KEY } from "../authentication/constants";

export const useActiveUser = (): UseActiveUserResponseType => {
  const [activeUser, _setActiveUser] = React.useState<UserV2 | undefined>(
    undefined
  );

  const [queryActiveUserFn] = useLazyQuery<{
    activeUser: UserV2;
  }>(ActiveUserQuery);

  const setActiveUser = React.useCallback(
    (activeUser: UserV2) => {
      _setActiveUser(activeUser);
    },
    [_setActiveUser]
  );

  const queryActiveUser = React.useCallback(async () => {
    return queryActiveUserFn()
      .then((r) => {
        if (r.data?.activeUser) {
          setActiveUser(r.data.activeUser);
          return r.data.activeUser;
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        throw new Error();
      });
  }, []);

  return {
    activeUser: activeUser,
    queryActiveUser,
  };
};

export const useActiveUserProvider = () => {
  const context = React.useContext(ActiveUserContext);
  if (!context) {
    throw new Error("No ActiveUserContext context was provided.");
  }
  return context;
};

export const useSwitchActiveUser = (): SwitchActiveUserContextType => {
  const modal = useModal();

  const [m] = useMutation<{ switchUser: SwitchUserResult }>(
    SwitchActiveUserMutation
  );

  const handleSwitchActiveUser: SwitchUserFn = React.useCallback(
    async (input, mfa) => {
      return m({
        variables: {
          input,
          mfa,
        },
      }).then((r) => {
        if (!r.data?.switchUser) {
          throw new Error();
        }
        if (r.data?.switchUser && "token" in r.data?.switchUser) {
          localStorage.setItem(USER_TOKEN_KEY, r.data.switchUser.token);
          window.location.reload();
          return r.data.switchUser;
        }
        return r.data.switchUser;
      });
    },
    []
  );

  return {
    switchUser: handleSwitchActiveUser,
    modal,
  };
};

export const useSwitchActiveUserProvider = (): SwitchActiveUserContextType => {
  const context = React.useContext(SwitchActiveUserContext);
  if (!context) {
    throw new Error("No SwitchActiveUserContextType context was provided.");
  }
  return context;
};
