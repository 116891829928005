import { useLazyQuery } from "@apollo/client";
import { Vendor } from "@towersystems/roam-common/lib/generated-types";
import React from "react";
import { CurrentVendorQuery } from "./constants";
import { UseCurrentVendorResponseType } from "./types";
import { CurrentVendorContext } from "./context";

export const useCurrentVendor = (): UseCurrentVendorResponseType => {
  const [vendor, _setVendor] = React.useState<Vendor | undefined>(undefined);

  const [queryCurrentVendorFn] = useLazyQuery<{
    currentVendor: Vendor;
  }>(CurrentVendorQuery);

  const setCurrentVendor = React.useCallback(
    (vendor: Vendor) => {
      _setVendor(vendor);
    },
    [_setVendor]
  );

  const queryCurrentVendor = React.useCallback(async () => {
    return queryCurrentVendorFn()
      .then((r) => {
        if (r.data?.currentVendor) {
          setCurrentVendor(r.data.currentVendor);
          return r.data.currentVendor;
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        throw new Error();
      });
  }, []);

  return {
    currentVendor: vendor,
    queryCurrentVendor,
  };
};

export const useCurrentVendorProvider = () => {
  const context = React.useContext(CurrentVendorContext);
  if (!context) {
    throw new Error("No CurrentVendorContext context was provided.");
  }
  return context;
};
