import { SettingCard } from "../SettingCard";

export interface RemovalAiSettingsSettingCardProps {
  children?: React.ReactNode;
}

export function RemovalAiSettingsSettingCard({
  children,
}: RemovalAiSettingsSettingCardProps) {
  return (
    <SettingCard wrapped title="Settings">
      {children}
    </SettingCard>
  );
}
