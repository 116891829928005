import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, MenuItem } from "@mui/material";
import {
  PaymentMethod,
  RetailerPayType,
  UpdatePaymentMethodInput,
} from "@towersystems/roam-common/lib/generated-types";
import { ContextualSaveBar, Select, Stack } from "components";
import { editPaymentMethodFormSchema } from "modules/settings/utils/payment-methods";
import { useMutationUpdatePaymentMethod } from "modules/settings/utils/payment-methods/hooks";
import { PaymentMethodFormValues } from "modules/settings/utils/payment-methods/types";
import { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useRevalidator } from "react-router-dom";
import { normalizeSelectedValues } from "utilities/transforms";
import { PaymentMethodDetailsForm } from "../PaymentMethodDetailsForm";
import {
  PaymentMethodInfoSettingCard,
  PaymentMethodPayTypeSettingCard,
  PaymentMethodSettingsSettingCard,
} from "./components";
import { PaymentMethodSettingsForm } from "../PaymentMethodSettingsForm";

export interface EditPaymentMethodFormProps {
  onSubmitSuccess(paymentMethod: PaymentMethod): void;
  paymentMethod: PaymentMethod;
  retailerPayTypes: RetailerPayType[];
}

export function EditPaymentMethodForm({
  onSubmitSuccess,
  paymentMethod,
  retailerPayTypes,
}: EditPaymentMethodFormProps) {
  const methods = useForm<PaymentMethodFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: normalizeSelectedValues(
      { name: "", id: "", retailerPayTypeId: "", enabled: true },
      paymentMethod
    ),
    resolver: yupResolver(editPaymentMethodFormSchema),
  });
  const revalidator = useRevalidator();

  const { handleSubmit, formState } = methods;
  const { isDirty } = formState;

  const { updatePaymentMethod } = useMutationUpdatePaymentMethod();

  const handleOnSubmit = useCallback(
    async (formValues: PaymentMethodFormValues) => {
      try {
        const result = await updatePaymentMethod(
          formValues as UpdatePaymentMethodInput
        );

        onSubmitSuccess && onSubmitSuccess(result);
        methods.reset(formValues); // reset defaults to the updated values
        revalidator.revalidate(); // updates prefetched data
      } catch (error) {
        console.error(error);
      }
    },
    [updatePaymentMethod]
  );

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{ onAction: handleSubmit(handleOnSubmit) }}
      cancelAction={{ onAction: () => methods.reset() }}
    />
  ) : undefined;

  const retailerPayTypeMarkup = (
    <Controller
      control={methods.control}
      name="retailerPayTypeId"
      render={({ field: { onChange, value } }) => (
        <Select
          label="Retailer PayType"
          fullWidth
          placeholder="Select Retailer PayType"
          leadingChildren={
            <MenuItem disabled value="">
              <em>Select PayType</em>
            </MenuItem>
          }
          value={value}
          onChange={onChange}
          options={retailerPayTypes.map((i) => {
            return {
              value: `${i.id}`,
              label: i.payDescription || "UNKNOWN",
            };
          })}
        ></Select>
      )}
    />
  );

  return (
    <FormProvider {...methods}>
      {contextualSaveBar}
      <Stack spacing={3} divider={<Divider />}>
        <PaymentMethodInfoSettingCard>
          <PaymentMethodDetailsForm payTypeDisabled />
        </PaymentMethodInfoSettingCard>
        <PaymentMethodPayTypeSettingCard>
          {retailerPayTypeMarkup}
        </PaymentMethodPayTypeSettingCard>
        <PaymentMethodSettingsSettingCard>
          <PaymentMethodSettingsForm />
        </PaymentMethodSettingsSettingCard>
      </Stack>
    </FormProvider>
  );
}
