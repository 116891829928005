import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import { useAuthenticationProvider } from "../../../../utilities/authentication/hooks";
import { useCallback } from "react";
import { LoginFormValues } from "../../utils/login";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Logo } from "../../../../components/Logo";
import { LoginFieldset } from "./components/LoginFieldset";
import { SelectMfaMethodFieldset } from "./components";
import { EnterMfaCodeFieldset } from "./components/EnterMfaCodeFieldset";

export interface LoginFormProps {}

export const LoginForm = ({}: LoginFormProps) => {
  const [step, setStep] = React.useState<number>(1);
  const [mfaStrategies, setMfaStrategies] = React.useState<string[]>([]);

  const { login } = useAuthenticationProvider();

  const formContext = useFormContext<LoginFormValues>();

  const { handleSubmit, setError, reset, getValues, formState } = formContext;

  const navigate = useNavigate();

  const { errors } = formState;

  const goToStep = useCallback((step: number) => {
    setStep(step);
  }, []);

  const onSubmit = useCallback(
    async (values: LoginFormValues) => {
      if (step === 2) {
        return;
      }
      try {
        const result = await login({
          strategy: values.strategy,
          talinkUsername: values.talinkUsername,
          staffUsername: values.staffUsername,
          password: values.password,
          rememberMe: values.rememberMe,
          mfaCode: values.mfaCode,
          mfaStrategy: values.mfaStrategy,
        });

        if ("errorCode" in result) {
          if (
            result.errorCode === "MFA_REQUIRED_ERROR" &&
            (result as any).strategies
          ) {
            setMfaStrategies((result as any).strategies);
            setStep(2);
            setError("root.serverError", {
              type: "400",
              message: result.errorCode,
            });
            return;
          }

          if (result.errorCode === "INVALID_MFA_CODE_ERROR") {
            setError("root.serverError", {
              type: "400",
              message: result.errorCode,
            });
            return;
          }

          setError("talinkUsername", {
            type: "a",
            message: "Invalid username or password",
          });
          return;
        }
        navigate("/");
      } catch (error) {
        setError("root.serverError", {
          type: "400",
          message: "Something went wrong.",
        });
      }
    },
    [login, reset, navigate]
  );

  React.useEffect(() => {
    if (errors.root?.serverError) {
      const values = getValues();
      reset(values, {
        keepDirty: true,
        keepErrors: true,
        keepIsSubmitted: false,
      });
    }
  }, [errors.root?.serverError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Logo />
        </Box>
        {step === 1 && <LoginFieldset />}
        {step === 2 && (
          <SelectMfaMethodFieldset
            goToStep={goToStep}
            strategies={[...mfaStrategies]}
          />
        )}
        {step === 3 && <EnterMfaCodeFieldset />}
      </Box>
    </form>
  );
};
