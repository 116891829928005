import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppContext } from "../../../../utilities/app";

export interface SettingsPageProps {}

export const SettingsPage = ({}: SettingsPageProps) => {
  const appContext = useAppContext();

  useEffect(() => {
    appContext.setFrameComponent("settings");
    return () => {
      appContext.setFrameComponent("dashboard");
    };
  }, []);

  return <Outlet />;
};
