import { SwipeableDrawerProps } from "@mui/material";

const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

const DRAWER_WIDTH = 720;

export const drawerConfig: Partial<SwipeableDrawerProps> = {
  disableBackdropTransition: !iOS,
  disableDiscovery: iOS,
  anchor: "right",
  PaperProps: {
    sx: {
      width: DRAWER_WIDTH,
    },
  },
} as const;
