import { QUERY_FEATURES } from "./constants";
import { useQuery } from "@apollo/client";
import { FeaturesList } from "@towersystems/roam-common/lib/generated-types";
import { FeaturesContext } from "./context";
import { useContext } from "react";

export const useQueryFeatures = () => {
  const { loading, error, data, refetch } = useQuery<{
    features: FeaturesList;
  }>(QUERY_FEATURES);
  return {
    item: data?.features,
    loading,
    error,
    refetch,
  };
};

export const useFeaturesProvider = () => {
  const context = useContext(FeaturesContext);
  if (!context) {
    throw new Error("No FeaturesContext context was provided.");
  }
  return context;
};
