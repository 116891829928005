import { Customer } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";

export interface CustomerAccountInformationDetailsCardProps {
  customer: Customer;
}

export const CustomerAccountInformationDetailsCard = ({
  customer,
}: CustomerAccountInformationDetailsCardProps) => {
  return (
    <CardDetailList
      title="Account Information"
      items={[
        {
          key: "retailerId",
          title: "Retailer Customer Number",
          value: customer.retailerId || "-",
        },
        {
          key: "isRetailerCusAccount",
          title: "Customer Account Payments",
          value: customer.isRetailerCusAccount ? "Yes" : "No",
        },
        {
          key: "loyaltyPoints",
          title: "Loyalty Points",
          value: customer.loyaltyPoints || "0",
        },
      ]}
    ></CardDetailList>
  );
};
