import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Location } from "@towersystems/roam-common/lib/generated-types";
import { useNavigate } from "react-router-dom";
import { NavigateRightButton } from "../../../../components/NavigateRightButton";
import { Badge } from "../../../../components/Badge";
import { SettingRowSkeleton } from "../../../../components";

export interface LocationsListProps {
  items?: Location[];
  loading?: boolean;
}

const NUM_LOADING_PLACEHOLDERS = 5;

export const LocationsGrid = ({ loading, items }: LocationsListProps) => {
  const navigate = useNavigate();

  const rows = loading
    ? Array.from({ length: NUM_LOADING_PLACEHOLDERS }).map((_, i) => (
        <SettingRowSkeleton key={i} />
      ))
    : items?.map((i) => {
        return (
          <TableRow
            key={i.id}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/settings/locations/edit/${i.id}`)}
          >
            <TableCell width={"100%"}>
              <Typography fontWeight={"bold"}>{i.name}</Typography>
              <Typography variant="subdued">
                {`Retailer Location Key: ${i.retailerLocationKey}`}
              </Typography>
            </TableCell>
            <TableCell />
            <TableCell align="right">
              <Stack direction="row" justifyContent={"flex-end"}>
                <Badge state={i.enabled ? "active" : "disabled"} />
                <NavigateRightButton />
              </Stack>
            </TableCell>
          </TableRow>
        );
      });

  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
