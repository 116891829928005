import { CardDetailList } from "../CardDetailList";
import { formatDateToCalendar } from "@towersystems/roam-common/lib/shared-utils";

export type ResourceType = {
  id: string;
  updatedAt: string;
  createdAt: string;
};

export interface MetaDetailsCardProps<T extends ResourceType> {
  resource: T;
}

export const MetaDetailsCard = <T extends ResourceType>({
  resource,
}: MetaDetailsCardProps<T>) => {
  return (
    <CardDetailList
      title="Meta data"
      items={[
        {
          key: "created",
          title: "Created At",
          value: formatDateToCalendar(resource.createdAt),
        },
        {
          key: "updated",
          title: "Updated At",
          value: formatDateToCalendar(resource.updatedAt),
        },
      ]}
    ></CardDetailList>
  );
};
