import { Box, Button, Modal as BaseModal, Typography } from "@mui/material";
import { useCallback } from "react";
import {
  Action,
  DisableableAction,
  LoadableAction,
} from "@towersystems/roam-common/lib/shared-types";

import { UseModalReturn } from "../../utilities/use-modal";

interface PrimaryAction extends LoadableAction, DisableableAction {}

export interface ModalProps extends Omit<UseModalReturn, "onOpen"> {
  size?: "sm" | "md" | "lg";
  title?: string;
  children?: React.ReactNode;
  primaryAction?: PrimaryAction;
  secondaryAction?: Action;
}

export const Modal = ({
  children,
  size = "md",
  onClose,
  open,
  title,
  primaryAction,
}: ModalProps) => {
  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const primaryActionMarkup = primaryAction ? (
    <Button
      color="primary"
      variant="contained"
      onClick={primaryAction.onAction}
      disabled={primaryAction.disabled}
      fullWidth
    >
      {primaryAction.content}
    </Button>
  ) : undefined;

  const titleMarkup = title ? (
    <Typography
      sx={{ mb: 3 }}
      id="modal-modal-title"
      variant="h6"
      component="h2"
    >
      {title}
    </Typography>
  ) : null;

  const width = size === "sm" ? 400 : size === "md" ? 600 : 800;

  return (
    <BaseModal
      open={open}
      onClose={handleOnClose}
      closeAfterTransition
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: width,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "80%",
          maxWidth: "90%",
          p: 4,
        }}
      >
        {titleMarkup}
        <Box>{children}</Box>
        {primaryAction && (
          <Box sx={{ mt: 3, maxWidth: "100%" }}>{primaryActionMarkup}</Box>
        )}
      </Box>
    </BaseModal>
  );
};
