import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { PaymentMethod } from "@towersystems/roam-common/lib/generated-types";
import { FormLayout } from "components";
import { TerminalFormValues } from "modules/settings/utils/terminals/types";
import { Controller, useFormContext } from "react-hook-form";

export interface TerminalPaymentsFormProps {
  paymentMethods?: PaymentMethod[];
  defaultCashPaymentMethodId?: string;
}

export function TerminalPaymentsForm({
  paymentMethods = [],
  defaultCashPaymentMethodId,
}: TerminalPaymentsFormProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<TerminalFormValues>();

  return (
    <FormLayout>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Payment Methods</FormLabel>
        <FormGroup>
          {paymentMethods
            .filter(
              (i) =>
                i.paytype.internal === false &&
                i.paytype.channels.includes("pos")
            )
            .map((p) => (
              <FormControlLabel
                key={p.id}
                control={
                  <Controller
                    name="paymentMethodIds"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          disabled={p.id === defaultCashPaymentMethodId}
                          checked={
                            p.id == defaultCashPaymentMethodId
                              ? true
                              : field.value.includes(p.id)
                          }
                          value={p.id}
                          onChange={(e) => {
                            if (e.target.checked) {
                              field.onChange([...field.value, p.id]);
                            } else {
                              field.onChange(
                                field.value.filter((id: string) => id !== p.id)
                              );
                            }
                          }}
                        />
                      );
                    }}
                  ></Controller>
                }
                label={p.name}
              />
            ))}
        </FormGroup>
      </FormControl>
    </FormLayout>
  );
}
