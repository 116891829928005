import { AuthContextType, AuthenticationContext } from "../../utilities";

export interface AuthProviderProps {
  authentication: AuthContextType;
  children: React.ReactNode | React.ReactNode[] | null;
}

export const AuthProvider = ({
  children,
  authentication,
}: AuthProviderProps) => {
  return (
    <AuthenticationContext.Provider value={authentication}>
      {children}
    </AuthenticationContext.Provider>
  );
};
