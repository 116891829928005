import { gql } from "@apollo/client";

export const QUERY_ONBOARDING_COMPLETE = gql`
  query onBoardingComplete {
    onBoardingComplete
  }
`;

export const MUTATION_ONBOARD_EXPRESS_ACCOUNT = gql`
  mutation createExpressAccountAndRequestAccountLinkUrl(
    $input: CreateStripeExpressAccountInput
  ) {
    createExpressAccountAndRequestAccountLinkUrl(input: $input)
  }
`;

export const MUTATION_ONBOARD_STANDARD_ACCOUNT = gql`
  mutation createNormalAccountAndRequestAccountLinkUrl {
    createNormalAccountAndRequestAccountLinkUrl
  }
`;

export const MUTATION_GET_LOGIN_LINK = gql`
  mutation createLoginLink {
    createLoginLink
  }
`;

export const MUTATION_UNLINK_STRIPE_ACCOUNT = gql`
  mutation unlinkStripeAccount {
    unlinkStripeAccount {
      success
    }
  }
`;
