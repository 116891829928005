import { Controller, useFormContext } from "react-hook-form";
import { SelfCheckoutLocationSettingsFormValues } from "./types";
import {
  Box,
  Divider,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "../../../../components/Stack";
import { SettingCard } from "../SettingCard";
import {
  Location,
  SelfCheckoutLocationSettings,
  Terminal,
} from "@towersystems/roam-common/lib/generated-types";
import { Select } from "../../../../components";
import React from "react";

export interface SelfCheckoutLocationSettingsFormProps {
  isEdit?: boolean;
  locations?: Location[];
  terminals?: Terminal[];
  selfCheckoutLocationSettings?: SelfCheckoutLocationSettings;
}

export const SelfCheckoutLocationSettingsForm = ({
  isEdit = true,
  locations,
  terminals,
}: SelfCheckoutLocationSettingsFormProps) => {
  const { control, formState, register, watch } =
    useFormContext<SelfCheckoutLocationSettingsFormValues>();

  const { errors } = formState;

  const watchLocationId = watch("locationId");

  const locationOptions = React.useMemo(
    () =>
      locations?.map((l) => ({
        label: l.name || "Unknown Location Name",
        value: l.id,
      })) ?? [],
    [locations]
  );

  const terminalOptions = React.useMemo(
    () =>
      terminals?.map((l) => ({
        label: l.name || "Unknown Terminal Name",
        value: l.id,
      })) ?? [],
    [terminals]
  );

  const locationsMarkup =
    isEdit === false ? (
      <SettingCard
        wrapped
        title="Location"
        description="The location this self checkout is for"
      >
        <Box>
          <Controller
            control={control}
            name="locationId"
            render={({ field: { onChange, value } }) => (
              <Select
                label="Select Location"
                fullWidth
                placeholder="Select Location"
                options={locationOptions}
                leadingChildren={
                  <MenuItem disabled value="">
                    <em>Select Location</em>
                  </MenuItem>
                }
                value={value}
                onChange={onChange}
              ></Select>
            )}
          />
        </Box>
      </SettingCard>
    ) : undefined;

  return (
    <Stack spacing={3} divider={<Divider />}>
      {locationsMarkup}
      {watchLocationId && (
        <SettingCard
          wrapped
          title="Terminal"
          description="Configure which terminal this self checkout is for"
        >
          <Box>
            <Controller
              control={control}
              name="terminalId"
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Select Terminal"
                  fullWidth
                  placeholder="Select Terminal"
                  options={terminalOptions}
                  leadingChildren={
                    <MenuItem disabled value="">
                      <em>Select Terminal</em>
                    </MenuItem>
                  }
                  value={value}
                  onChange={onChange}
                ></Select>
              )}
            />
          </Box>
        </SettingCard>
      )}
      {watchLocationId && (
        <>
          <SettingCard
            wrapped
            title="General"
            description="Configure general self checkout settings"
          >
            <Stack spacing={3}>
              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                >
                  <Box>
                    <Controller
                      name="enabled"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value}
                          color="primary"
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1} paddingTop={1}>
                    <Typography fontWeight={"bold"}>
                      Self checkout Enabled
                    </Typography>
                    <Typography variant="caption">
                      Allow customers to self checkout using the Roam app
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                >
                  <Box>
                    <Controller
                      name="onlineCheckoutEnabled"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value}
                          color="primary"
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1} paddingTop={1}>
                    <Typography fontWeight={"bold"}>
                      Online Checkout Enabled
                    </Typography>
                    <Typography variant="caption">
                      Allow customers to self checkout using stripe on their own
                      devices
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                >
                  <Box>
                    <Controller
                      name="offlineCheckoutEnabled"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value}
                          color="primary"
                        />
                      )}
                    />
                  </Box>
                  <Box flex={1} paddingTop={1}>
                    <Typography fontWeight={"bold"}>
                      Offline Checkout Enabled
                    </Typography>
                    <Typography variant="caption">
                      Allow customers to checkout at the register
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </SettingCard>
          <SettingCard
            wrapped
            title="Customer Messaging"
            description="Configure self checkout messages"
          >
            <Stack spacing={3}>
              <TextField
                label="Pay at Counter Heading"
                fullWidth
                {...register("payAtCounterHeading")}
                error={Boolean(errors.payAtCounterHeading)}
                helperText={errors.payAtCounterHeading?.message}
              />
              <TextField
                label="Pay at Counter Subheading"
                fullWidth
                {...register("payAtCounterSubheading")}
                error={Boolean(errors.payAtCounterSubheading)}
                helperText={errors.payAtCounterSubheading?.message}
              />
              <TextField
                label="Checkout Success Heading"
                fullWidth
                {...register("checkoutSuccessHeading")}
                error={Boolean(errors.checkoutSuccessHeading)}
                helperText={errors.checkoutSuccessHeading?.message}
              />
            </Stack>
          </SettingCard>
        </>
      )}
    </Stack>
  );
};
