import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./NavigateRightButton.module.scss";
import { Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export interface NavigateRightButtonProps {}

export const NavigateRightButton = ({}: NavigateRightButtonProps) => {
  return (
    <Box>
      <ArrowForwardIosIcon className={styles.Icon} />
    </Box>
  );
};
