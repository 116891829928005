import { useSwitchActiveUserProvider } from "../../utilities/active-user";
import { Modal } from "../Modal";
import { SwitchUser } from "./SwitchUser";

export const SwitchUserDialog = () => {
  const { modal, switchUser } = useSwitchActiveUserProvider();
  return (
    <Modal title="Switch User" {...modal}>
      <SwitchUser onSwitchUser={switchUser} />
    </Modal>
  );
};
