import { useTheme } from "@mui/material";
import { Skeleton } from "../Skeleton";

export interface SkeletonBadgeProps {}

export function SkeletonBadge(props: SkeletonBadgeProps) {
  const { spacing } = useTheme();
  return (
    <Skeleton
      variant="rectangular"
      style={{ borderRadius: spacing(2) }}
      width={80}
      height={24}
    />
  );
}
