import { SettingCard } from "../../../SettingCard";

export interface TerminalPaymentsSettingCardProps {
  children?: React.ReactNode;
}

export function TerminalPaymentsSettingCard({
  children,
}: TerminalPaymentsSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="POS Payment Methods"
      description="Configure which payment methods are available on this terminal at POS."
    >
      {children}
    </SettingCard>
  );
}
