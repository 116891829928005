import { gql } from "@apollo/client";

export const MUTATION_DOWNLOAD_RECEIPT = gql`
  mutation downloadReceiptPdfBase64String($saleId: ID!, $receiptId: ID) {
    downloadReceiptPdfBase64String(saleId: $saleId, receiptId: $receiptId) {
      data
      fileName
    }
  }
`;
