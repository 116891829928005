import {
  SelfCheckoutLocationSettings,
  SelfCheckoutSettings,
  UpdateSelfCheckoutLocationSettingsInput,
  UpdateSelfCheckoutSettingsInput,
} from "@towersystems/roam-common/lib/generated-types";
import {
  MUTATION_UPDATE_SELF_CHECKOUT_LOCATION_SETTINGS,
  MUTATION_UPDATE_SELF_CHECKOUT_SETTINGS,
  QUERY_SELF_CHECKOUT_LOCATION_URL,
} from "./constants";
import { useLazyQuery, useMutation } from "@apollo/client";

export const useMutationSelfCheckoutSettingsUpdate = () => {
  const [m] = useMutation<{ updateSelfCheckoutSettings: SelfCheckoutSettings }>(
    MUTATION_UPDATE_SELF_CHECKOUT_SETTINGS
  );

  const mutation = async (input: UpdateSelfCheckoutSettingsInput) => {
    return m({ variables: { input } }).then((d) => {
      if (!d.data) {
        throw new Error();
      }
      return d.data.updateSelfCheckoutSettings;
    });
  };

  return {
    mutation,
  };
};

export const useMutationSelfCheckoutLocationSettingsUpdate = () => {
  const [m] = useMutation<{
    updateSelfCheckoutLocationSettings: SelfCheckoutLocationSettings;
  }>(MUTATION_UPDATE_SELF_CHECKOUT_LOCATION_SETTINGS);

  const mutation = async (input: UpdateSelfCheckoutLocationSettingsInput) => {
    return m({ variables: { input } }).then((d) => {
      if (!d.data) {
        throw new Error();
      }
      return d.data.updateSelfCheckoutLocationSettings;
    });
  };

  return {
    mutation,
  };
};

export const useQuerySelfCheckoutLocationURL = () => {
  const [q] = useLazyQuery<{
    selfCheckoutLocationURL: string;
  }>(QUERY_SELF_CHECKOUT_LOCATION_URL);

  const query = async (locationId: string) => {
    return q({ variables: { locationId } }).then((d) => {
      if (!d.data) {
        throw new Error();
      }
      return d.data.selfCheckoutLocationURL;
    });
  };

  return {
    query,
  };
};
