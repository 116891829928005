import {
  Staff,
  StaffList,
  UserV2,
} from "@towersystems/roam-common/lib/generated-types";
import { QUERY_STAFFS_LIST } from "../../modules/settings/utils/staffs";
import { useQuery } from "@apollo/client";
import { Stack } from "../Stack";
import {
  SwitchUserFn,
  useActiveUserProvider,
} from "../../utilities/active-user";
import React from "react";
import { SwitchActiveUserFormProvider } from "../SwitchActiveUserForm/SwitchActiveUserFormProvider";
import { SwitchUserForm } from "./SwitchUserForm";
import { Box } from "@mui/material";
import { SwitchUserButton } from "./SwitchUserButton";
import { userIsAdmin } from "../../utilities/user-is-admin";

export interface SwitchUserProps {
  onSwitchUser: SwitchUserFn;
}

export const SwitchUser = ({ onSwitchUser }: SwitchUserProps) => {
  const [selectedUser, setSelectedUser] = React.useState<UserV2 | undefined>(
    undefined
  );

  const [signInAsOwnerSelected, setSignInAsOwnerSelected] =
    React.useState<boolean>(false);

  const { activeUser } = useActiveUserProvider();

  const { data } = useQuery<{
    staffs: StaffList;
  }>(QUERY_STAFFS_LIST, {
    variables: {
      limit: 1000,
      filter: {
        enabled: {
          eq: true,
        },
      },
    },
  });

  const resetFormState = React.useCallback(() => {
    setSelectedUser(undefined);
    setSignInAsOwnerSelected(false);
  }, [setSignInAsOwnerSelected, setSelectedUser]);

  const currentUserOwner = userIsAdmin(activeUser);

  const switchCurrentOwnerMarkup = !currentUserOwner ? (
    <SwitchUserButton
      label="Switch to Owner"
      onClick={() => setSignInAsOwnerSelected(true)}
    />
  ) : undefined;

  const items =
    data?.staffs?.items.filter(
      (i: Staff) => i.enabled && i.hasUserAccount && i.userId !== activeUser.id
    ) || [];

  const staffMarkup = items.map((i: Staff) => {
    return (
      <SwitchUserButton
        key={i.id}
        label={i.fullName}
        onClick={() => setSelectedUser(i.user)}
      />
    );
  });

  if (selectedUser || signInAsOwnerSelected) {
    return (
      <SwitchActiveUserFormProvider user={selectedUser}>
        <SwitchUserForm
          user={selectedUser}
          onGoBack={resetFormState}
          onSwitchUser={onSwitchUser}
        />
      </SwitchActiveUserFormProvider>
    );
  }

  const rolesStrings = activeUser.roles.map((role) => role.code);
  const formattedRolesString = `(${rolesStrings.join(", ")})`;

  const currentlyLoggedInAsMarkup = (
    <Box style={{ width: "100%" }}>
      Currently logged in as: {activeUser.identifier} {formattedRolesString}
    </Box>
  );

  return (
    <Stack spacing={1}>
      {currentlyLoggedInAsMarkup}
      {switchCurrentOwnerMarkup}
      {staffMarkup}
    </Stack>
  );
};
