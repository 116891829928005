import { Box, Pagination } from "@mui/material";
import {
  Product,
  ProductSortParameter,
} from "@towersystems/roam-common/lib/generated-types";
import React from "react";
import { usePaginatedList } from "../../../../../../utilities/paginated-list";
import { useSearchQueryProvider } from "../../../../../../utilities/search/hooks";
import { useDynamicSort } from "../../../../../../utilities/sort";
import { useScheduler } from "../../../../../../utilities/use-scheduler";
import { ProductResourceList } from "../../../../components/ProductResourceList";
import { QUERY_PRODUCT_LIST } from "../../../../utils";

export const AllProductsTabView = () => {
  const search = useSearchQueryProvider();
  const { schedule } = useScheduler(500);

  const { dynamicSort, toggleSort } = useDynamicSort<ProductSortParameter>();

  const paginatedList = usePaginatedList<Product>({
    query: QUERY_PRODUCT_LIST as any,
    resultKey: "products",
    variables: {
      sort: { updatedAt: "DESC", ...(dynamicSort || {}) },
      filter: { term: { contains: search.query } },
    },
  });

  React.useEffect(() => {
    handleOnTermInputChange();
  }, [search.query]);

  React.useEffect(() => {
    handleDynamicSortChange();
  }, [dynamicSort]);

  const handleOnTermInputChange = React.useCallback(() => {
    schedule(() =>
      paginatedList.setVariables({
        filter: { term: { contains: search.query } },
      })
    );
  }, [search.query]);

  const handleOnPageChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      paginatedList.setPage(value);
    },
    [paginatedList.setPage]
  );

  const handleDynamicSortChange = React.useCallback(() => {
    paginatedList.setSort({
      updatedAt: "DESC",
      ...(dynamicSort || {}),
    });
  }, [dynamicSort]);

  const items: Product[] = paginatedList.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];

  const listMarkup = items ? (
    <ProductResourceList
      loading={paginatedList.loading}
      items={items}
      dynamicSort={dynamicSort}
      toggleSort={toggleSort}
    />
  ) : undefined;

  const totalPages = paginatedList.maxPage;

  const paginationMarkup = (
    <Box sx={{ p: 2, borderColor: "grey.300" }} borderTop={1}>
      <Pagination
        onChange={handleOnPageChange}
        count={totalPages}
        page={paginatedList.page}
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );

  return (
    <Box>
      {listMarkup}
      {paginationMarkup}
    </Box>
  );
};
