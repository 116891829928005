import { InputAdornment, TextField } from "@mui/material";
import { CurrencyField, FormLayout } from "components";
import { useFormContext } from "react-hook-form";

export interface PricingFormProps {}

export function PricingForm(props: PricingFormProps) {
  const { register } = useFormContext();

  return (
    <FormLayout>
      <FormLayout.Group>
        <CurrencyField
          fullWidth
          label="Cost Ex."
          {...register("costPriceEx")}
        />
        <CurrencyField fullWidth label="Cost Inc." {...register("costPrice")} />
      </FormLayout.Group>
      <FormLayout.Group>
        <CurrencyField
          fullWidth
          label="Retail Price"
          {...register("retailPrice")}
        />
        <TextField
          {...register("grossMargin")}
          fullWidth
          label="Gross Margin"
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <CurrencyField
          fullWidth
          label="Gross Profit"
          {...register("grossProfitDollar")}
        />
        <CurrencyField fullWidth label="RRP" {...register("rrPrice")} />
      </FormLayout.Group>
    </FormLayout>
  );
}
