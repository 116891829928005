import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Customer } from "@towersystems/roam-common/lib/generated-types";
import { toTitleCase } from "@towersystems/roam-common/lib/shared-utils";
import { useNavigate } from "react-router-dom";
import { moneyToFormattedString } from "../../../../utilities/money";
import { Badge } from "../../../../components/Badge";
import { ResourceListSkeleton } from "../../../../components/ResourceListSkeleton";

export interface CustomerResourceListProps {
  items: Customer[];
  loading?: boolean;
}
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  alignment?: "left" | "right" | "center";
}
export const CustomerResourceList = ({
  items,
  loading,
}: CustomerResourceListProps) => {
  const navigate = useNavigate();

  const headCells: readonly HeadCell[] = [
    {
      id: "customer",
      numeric: false,
      disablePadding: true,
      label: "Customer",
    },
    {
      id: "cusAccc",
      numeric: false,
      disablePadding: true,
      label: "Customer Account",
      alignment: "center",
    },
    {
      id: "orders",
      numeric: true,
      label: "Orders",
      disablePadding: false,
    },
    {
      id: "amountSpent",
      numeric: true,
      label: "Amount Spent",
      disablePadding: false,
      alignment: "right",
    },
    {
      id: "invoicedAmount",
      numeric: true,
      label: "Amount Invoiced",
      disablePadding: false,
      alignment: "right",
    },
  ];

  const tableContent = loading ? (
    <ResourceListSkeleton />
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          {headCells.map((i) => (
            <TableCell align={i.alignment} key={i.id}>
              {i.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items?.map((i) => (
          <TableRow
            key={i.id}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/customers/view/${i.id}`)}
          >
            <TableCell width={"40%"}>
              <Typography fontWeight="bold">
                {toTitleCase(`${i.firstName} ${i.lastName}`)}
              </Typography>
              <Typography variant="subdued">{i.emailAddress}</Typography>
            </TableCell>
            <TableCell align="center">
              {i.isRetailerCusAccount ? (
                <Badge customerAccountType="cus-account" />
              ) : undefined}
            </TableCell>
            <TableCell>
              <Typography fontSize={14}>{`${i.numberOfOrders} order${
                i.numberOfOrders == 1 ? "" : "s"
              }`}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{moneyToFormattedString(i.amountSpend)}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>
                {moneyToFormattedString(i.invoicedAmount)}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return <TableContainer>{tableContent}</TableContainer>;
};
