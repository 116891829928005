import { Stack } from "@mui/material";
import { FormLayout, SkeletonPage } from "../../../../components";
import {
  LocationInformationSettingCard,
  LocationSettingsSettingCard,
} from "../EditLocationForm/components";
import { InputSkeleton } from "../InputSkeleton";

export interface LocationPageSkeletonProps {}

export function LocationPageSkeleton(props: LocationPageSkeletonProps) {
  return (
    <SkeletonPage title titleDescription goBack>
      <Stack spacing={3}>
        <LocationInformationSettingCard>
          <FormLayout>
            <InputSkeleton />
            <InputSkeleton />
          </FormLayout>
        </LocationInformationSettingCard>
        <LocationSettingsSettingCard>
          <InputSkeleton />
        </LocationSettingsSettingCard>
      </Stack>
    </SkeletonPage>
  );
}
