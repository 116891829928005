import { gql } from "@apollo/client";
import {
  AuthenticationMethodDeepNestingFragment,
  MfaMethodDeepNestingFragment,
  PaginatedListMetaNoNestingFragment,
  RoleDeepNestingFragment,
  StaffNoNestingFragment,
  UserV2DeepNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as Yup from "yup";

export const QUERY_STAFFS_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${StaffNoNestingFragment}
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}
  query Staffs($options: StaffListOptions) {
    staffs(options: $options) {
      items {
        ...StaffNoNesting
        user {
          ...UserV2DeepNesting
        }
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const QUERY_STAFF = gql`
  ${StaffNoNestingFragment}
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}
  query Staff($id: ID!) {
    staff(id: $id) {
      ...StaffNoNesting
      user {
        ...UserV2DeepNesting
      }
    }
  }
`;

export const MUTATION_UPDATE_STAFF = gql`
  ${StaffNoNestingFragment}
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}

  mutation UpdateStaff($input: UpdateStaffInput!) {
    updateStaff(input: $input) {
      ...StaffNoNesting
      user {
        ...UserV2DeepNesting
      }
    }
  }
`;

export const MUTATION_CREATE_STAFF = gql`
  ${StaffNoNestingFragment}
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}

  mutation CreateStaff($input: CreateStaffInput!) {
    createStaff(input: $input) {
      ...StaffNoNesting
      user {
        ...UserV2DeepNesting
      }
    }
  }
`;

export const MUTATION_CREATE_STAFF_USER_ACCOUNT = gql`
  ${StaffNoNestingFragment}
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}
  mutation CreateStaffUserAccount($input: CreateStaffUserAccountInput!) {
    createStaffUserAccount(input: $input) {
      ...StaffNoNesting
      user {
        ...UserV2DeepNesting
      }
    }
  }
`;

export const MUTATION_UPDATE_STAFF_USER_ACCOUNT = gql`
  ${StaffNoNestingFragment}
  ${UserV2DeepNestingFragment}
  ${AuthenticationMethodDeepNestingFragment}
  ${RoleDeepNestingFragment}
  ${MfaMethodDeepNestingFragment}

  mutation UpdateStaffUserAccount($input: UpdateStaffUserAccountInput!) {
    updateStaffUserAccount(input: $input) {
      ...StaffNoNesting
      user {
        ...UserV2DeepNesting
      }
    }
  }
`;

export const defaultStaffFormValues = {
  fullName: "",
  initials: undefined,
  enabled: true,
};

export const staffFormSchema = Yup.object({
  fullName: Yup.string().required(),
  initials: Yup.string().required(),
  enabled: Yup.boolean().required(),
});

export const StaffFormSections = {
  DETAILS: "Details",
  SETTINGS: "Settings",
} as const;
