import { Typography } from "@mui/material";
import { InputHTMLAttributes } from "react";
import styles from "./StrategyRadioButton.module.scss";

export interface StrategyRadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  description?: string;
}

export const StrategyRadioButton = ({
  title,
  description,
  value,
  ...props
}: StrategyRadioButtonProps) => {
  const { id, ...rest } = props;

  const normalizedId = `${id}-value`;

  return (
    <label htmlFor={normalizedId} className={styles.Wrapper}>
      <input
        {...rest}
        type="radio"
        value={value}
        id={normalizedId}
        className={styles.Radio}
      />
      <div className={styles.Strategy}>
        <Typography fontWeight="bold">{title}</Typography>
        {description && <Typography>{description}</Typography>}
      </div>
    </label>
  );
};
