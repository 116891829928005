import { useFormContext } from "react-hook-form";
import { Remove2faFormValues } from "../../types";
import { Stack } from "../../../../../../components";
import { Box, TextField, Typography } from "@mui/material";
import { Button } from "../../../../../../components/Button";

export interface EnterCredentialsFieldsetProps {}

export const EnterCredentialsFieldset = ({}: EnterCredentialsFieldsetProps) => {
  const formContext = useFormContext<Remove2faFormValues>();

  const { register, formState } = formContext;
  const { errors, isValid, isDirty, isSubmitting } = formState;
  const isDisabled = !isValid || !isDirty || isSubmitting;

  return (
    <Stack spacing={4}>
      <Box>
        <Typography variant="h6">Confirm your password</Typography>
        <Typography>
          A code will be sent to your chosen MFA strategy.
        </Typography>
      </Box>
      <Box>
        <TextField
          type="password"
          label="Password"
          required
          fullWidth
          margin="normal"
          {...register("data.password")}
          {...register("data.password")}
        ></TextField>
      </Box>
      {errors.root?.serverError && (
        <Typography color="error">{errors.root.serverError.message}</Typography>
      )}
      <Box sx={{ mt: 1, width: "100%" }}>
        <Button
          variant="tile"
          type="submit"
          color="primary"
          size="large"
          sx={{ alignSelf: "flex-end" }}
          disabled={isDisabled}
          fullWidth
        >
          <Typography fontWeight="bold">Continue</Typography>
        </Button>
      </Box>
    </Stack>
  );
};
