import { GlobalSettings } from "@towersystems/roam-common/lib/generated-types";
import { createContext } from "react";
import { UseGlobalSettingsResponseType } from "./types";

export const UseGlobalSettingsContext = createContext<
  UseGlobalSettingsResponseType | undefined
>(undefined);

export const GlobalSettingsContext = createContext<GlobalSettings | undefined>(
  undefined
);
