import { SettingCard } from "../../../SettingCard";

export interface PaymentMethodInfoSettingCardProps {
  children?: React.ReactNode;
}

export function PaymentMethodInfoSettingCard({
  children,
}: PaymentMethodInfoSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Payment Method Information"
      description="Name this payment method to make it identifiable to your staff."
    >
      {children}
    </SettingCard>
  );
}
