import { SettingCard } from "../../../SettingCard";

export interface ReceiptInformationSettingCardProps {
  children?: React.ReactNode;
}

export function ReceiptInformationSettingCard({
  children,
}: ReceiptInformationSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Receipt Information"
      description="Customise the information that appears on your receipts."
    >
      {children}
    </SettingCard>
  );
}
