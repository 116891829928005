import { FormProvider, useForm } from "react-hook-form";
import { SelfCheckoutSettingsFormValues } from "./types";
import { defaultFormValues, schema } from "./constants";
import { yupResolver } from "@hookform/resolvers/yup";

export interface SelfCheckoutSettingsFormProviderProps {
  defaultValues?: Partial<SelfCheckoutSettingsFormValues>;
  children?: React.ReactNode;
}

export const SelfCheckoutSettingsFormProvider = ({
  children,
  defaultValues,
}: SelfCheckoutSettingsFormProviderProps) => {
  const normalizedDefaultValues = { ...defaultFormValues, ...defaultValues };

  const methods = useForm<SelfCheckoutSettingsFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: normalizedDefaultValues,
    resolver: yupResolver(schema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
