import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_DEPARTMENT } from "./constants";
import {
  Department,
  UpdateDepartmentInput,
} from "@towersystems/roam-common/lib/generated-types";
import React from "react";

export const useMutationUpdateDepartment = () => {
  const [m] = useMutation<{
    updateDepartment: Department;
  }>(MUTATION_UPDATE_DEPARTMENT);
  const mutation = React.useCallback(
    async (input: UpdateDepartmentInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updateDepartment;
      });
    },
    [m]
  );
  return {
    mutation,
  };
};
