import { Stack } from "@mui/material";
import { FormLayout, SkeletonPage } from "../../../../components";
import {
  TerminalInformationSettingCard,
  TerminalInvoiceConfigurationSettingCard,
  TerminalPaymentsSettingCard,
  TerminalSettingsSettingCard,
} from "../EditTerminalForm/components";
import { InputSkeleton } from "../InputSkeleton";

export interface TerminalPageSkeletonProps {}

export function TerminalPageSkeleton(props: TerminalPageSkeletonProps) {
  return (
    <SkeletonPage title titleDescription goBack>
      <Stack spacing={5}>
        <TerminalInformationSettingCard>
          <InputSkeleton />
        </TerminalInformationSettingCard>
        <TerminalInvoiceConfigurationSettingCard>
          <FormLayout>
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
          </FormLayout>
        </TerminalInvoiceConfigurationSettingCard>
        <TerminalPaymentsSettingCard>
          <InputSkeleton />
        </TerminalPaymentsSettingCard>
        <TerminalSettingsSettingCard>
          <InputSkeleton />
        </TerminalSettingsSettingCard>
      </Stack>
    </SkeletonPage>
  );
}
