import { useEffect, useState } from "react";
import { useIsMounted } from "../use-is-mounted";
import { UseAwaitReturn, Success, Defined } from "./types";

export function useAwait<T>(
  asyncFunc: Promise<T>,
  deps: any[] = []
): UseAwaitReturn<T> {
  const isMounted = useIsMounted();

  const [value, setValue] = useState<UseAwaitReturn<T>>({
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      isLoading: true,
      isError: false,
    }));
    asyncFunc
      .then((data) => {
        if (data && isMounted.current) {
          const success: Success<T> = {
            data: data as Defined<T>,
            isLoading: false,
            isError: false,
          };
          setValue(success);
        }
      })
      .catch((e) => {
        if (isMounted.current)
          setValue((prev) => ({
            ...prev,
            isLoading: false,
            isError: true,
            errorMsg: e,
          }));
      });
  }, deps);

  return value;
}
