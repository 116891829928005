import { useState, useCallback, useMemo } from "react";

export function useListToggle<T>(defaultListItems: T[] = []) {
  const [listItems, setListItems] = useState<T[]>(defaultListItems);

  const toggle = useCallback(
    (toggleItem: T) => {
      if (listItems.includes(toggleItem)) {
        setListItems((prev) => prev.filter((li) => li !== toggleItem));
      } else {
        setListItems((prev) => [...prev, toggleItem]);
      }
    },
    [listItems]
  );

  return {
    listItems,
    setListItems,
    toggle,
  };
}

export type UseAccordionControllerReturn = {
  getAccordionState: (section: string) => {
    title: string;
    expanded: boolean;
    defaultExpanded: boolean;
    onChange(): void;
  };
};

export function useAccordionController(
  defaultOpenSections: string[] = []
): UseAccordionControllerReturn {
  const { listItems: openAccordians, toggle } =
    useListToggle(defaultOpenSections);

  const getAccordionState = useCallback(
    (section: string) => {
      const expanded = openAccordians.includes(section);
      return {
        title: section,
        expanded,
        defaultExpanded: defaultOpenSections.includes(section),
        onChange() {
          toggle(section);
        },
      };
    },
    [openAccordians]
  );

  return {
    getAccordionState,
  };
}
