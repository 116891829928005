import { UseAwaitReturn } from "../../modules/settings/utils/use-await";

export type SuspendAwaitProps<T> = {
  children?: (data: T[]) => React.ReactNode;
  resolve: UseAwaitReturn<T>;
  fallback?: React.ReactNode;
};

/**
 * Similar to Suspense / Await combination but operates on declarative UseAwaitReturn state.
 * Useful in the following scenarios:
 * 1) Passing UseAwaitReturn<T> is preferred over Promise<T> for props eg. load data in a parent component and Render as you Fetch in child component.
 */
export function SuspendAwait<T>({
  children,
  resolve,
  fallback,
}: SuspendAwaitProps<T>) {
  if (resolve.isLoading) {
    return <>{fallback}</>;
  } else if (resolve.isError) {
    throw resolve.errorMsg;
  } else {
    return <>{children?.(resolve.data as T[])}</>;
  }
}
