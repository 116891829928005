import { yupResolver } from "@hookform/resolvers/yup";
import { Staff } from "@towersystems/roam-common/lib/generated-types";
import { FormProvider, useForm } from "react-hook-form";
import { UpdateStaffAccountFormValues } from "./types";
import { schema } from "./constants";

export interface UpdateStaffAccountFormProviderProps {
  children?: React.ReactNode;
  staff: Staff;
}
export const UpdateStaffAccountFormProvider = ({
  children,
  staff,
}: UpdateStaffAccountFormProviderProps) => {
  const methods = useForm<UpdateStaffAccountFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: staffToDefaultValues(staff),
    resolver: yupResolver(schema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

function staffToDefaultValues(staff: Staff): UpdateStaffAccountFormValues {
  return {
    staffId: staff.id,
    username: staff.username ?? "",
    fullName: staff.fullName ?? "",
    initials: staff.initials ?? "",
    emailAddress: staff.emailAddress ?? "",
    password: "",
    confirmPassword: "",
    enabled: staff.enabled ?? false,
  };
}
