import { Page, Stack } from "../../../../components";
import {
  QUERY_COUNT_ITEMS_SOLD_ANALYTICS,
  QUERY_COUNT_TOTAL_SALES_ANALYTICS,
  QUERY_SUM_REVENUE_TOTAL_SALES_ANALYTICS,
} from "../../../../utilities/analytics";
import { useGlobalSettingsProvider } from "../../../../utilities/global-settings/hooks";
import moment from "moment";
import { AnalyticTile } from "../../components/AnalyticTile";
import { ReactNode, useMemo } from "react";
import { moneyToFormattedString } from "../../../../utilities/money";
import { Top10ProductsTable } from "../../components/Top10ProductsTable";
import { Card, Grid } from "@mui/material";
import { useFrame } from "../../../../utilities/frame";
import { PermissionVisabilityHidden } from "../../../../components/PermissionVisabilityHidden";

export interface LandingPageProps {}

export const LandingPage = ({}: LandingPageProps) => {
  const globalSettings = useGlobalSettingsProvider();
  const title = `Hello, ${globalSettings.businessName}`;
  const subtitle = `Your progress so far this week`;

  const { mobileViewActive } = useFrame();

  const startComepletedDate = useMemo(() => {
    return moment().startOf("week");
  }, []);

  const endCompletedDate = useMemo(() => {
    return moment().endOf("week");
  }, []);

  return (
    <Page title={title} titleDescription={subtitle}>
      <Stack spacing={10}>
        <PermissionVisabilityHidden permissions={["ViewAnalytics"]}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <AnalyticTile
                title={"Total Revenue"}
                query={QUERY_SUM_REVENUE_TOTAL_SALES_ANALYTICS}
                options={{
                  variables: {
                    options: {
                      startComepletedDate,
                      endCompletedDate,
                      state: ["CLOSED"],
                    },
                  },
                }}
                formatter={function (data: any): ReactNode {
                  return moneyToFormattedString(data);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AnalyticTile
                title={"Total Sales"}
                query={QUERY_COUNT_TOTAL_SALES_ANALYTICS}
                options={{
                  variables: {
                    options: {
                      startComepletedDate,
                      endCompletedDate,
                      state: ["CLOSED"],
                    },
                  },
                }}
                formatter={function (data: any): ReactNode {
                  return data;
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AnalyticTile
                title={"Total Items Sold"}
                query={QUERY_COUNT_ITEMS_SOLD_ANALYTICS}
                options={{
                  variables: {
                    options: {
                      startComepletedDate,
                      endCompletedDate,
                      state: ["CLOSED"],
                    },
                  },
                }}
                formatter={function (data: any): ReactNode {
                  return data;
                }}
              />
            </Grid>
          </Grid>
          <Card>
            <Top10ProductsTable />
          </Card>
        </PermissionVisabilityHidden>
      </Stack>
    </Page>
  );
};
