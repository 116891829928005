import * as Yup from "yup";

export const allOrNothing = Yup.object()
  .nullable()
  .transform((details: Record<string, unknown>) => {
    const allEmpty = Object.values(details).every(
      (val) => val === "" || val === null || val === undefined
    );
    return allEmpty ? null : details;
  });
