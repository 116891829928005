import { useQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AnalyticProductMetaDataItem } from "@towersystems/roam-common/lib/generated-types";
import { toTitleCase } from "@towersystems/roam-common/lib/shared-utils";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../../../../components";
import { ProductImageThumbnail } from "../../../../components/ProductImageThumbnail";
import { QUERY_TOP_10_PRODUCTS_BY_SOLD_ANALYTICS } from "../../../../utilities/analytics";
import styles from "./ResultRow.module.scss";

export interface Top10ProductsTableProps {}

export const Top10ProductsTable = ({}: Top10ProductsTableProps) => {
  const navigate = useNavigate();

  const { data, loading } = useQuery<{
    getTop10ProductsBySoldAnaltics: AnalyticProductMetaDataItem[];
  }>(QUERY_TOP_10_PRODUCTS_BY_SOLD_ANALYTICS, {
    variables: {
      options: {
        startComepletedDate: moment().startOf("week"),
        endCompletedDate: moment().endOf("week"),
        state: ["CLOSED"],
      },
    },
  });

  const results = data ? Object.values(data)[0] : [];

  const resultsMarkup = loading
    ? [...Array(10)].map((_, i) => <ResultRowSkeleton key={i} />)
    : results.map((i) => {
        return (
          <TableRow
            key={i.id}
            onClick={() => navigate(`/products/view/${i.id}`)}
          >
            <TableCell width={40}>
              <ProductImageThumbnail src={i.imageUrl} />
            </TableCell>
            <TableCell>
              <Typography variant="h6" fontWeight="normal">
                {toTitleCase(i.name)}
              </Typography>
              <Typography variant="subdued">{i.sku}</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6" fontWeight="normal">
                {i.value}
              </Typography>
            </TableCell>
          </TableRow>
        );
      });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Top 10 Products</TableCell>
            <TableCell align="right">Units Sold</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{resultsMarkup}</TableBody>
      </Table>
    </TableContainer>
  );
};

function ResultRowSkeleton() {
  return (
    <TableRow>
      <TableCell width={72}>
        <Skeleton variant="rectangular" width={40} height={40} />
      </TableCell>
      <TableCell>
        <Typography variant="h6" fontWeight="normal">
          <Skeleton width={250} style={{ maxWidth: "40%" }} />
        </Typography>
        <Typography variant="subdued">
          <Skeleton width={200} style={{ maxWidth: "30%" }} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography
          variant="h6"
          fontWeight="normal"
          className={styles.ResultRowValue}
        >
          <Skeleton width={40} />
        </Typography>
      </TableCell>
    </TableRow>
  );
}
