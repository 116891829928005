import { useMutation } from "@apollo/client";
import {
  MfaMethod,
  Staff,
  Success,
  UserV2,
} from "@towersystems/roam-common/lib/generated-types";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  ConfirmationPrompt,
  Stack,
  VerifiedMfaListItem,
} from "../../../../components";
import { useActiveUserProvider } from "../../../../utilities/active-user";
import { useFlashMessages } from "../../../../utilities/flash-messages";
import { userIsAdmin } from "../../../../utilities/user-is-admin";
import {
  AccountIdentityFieldset,
  AccountSecurityFieldset,
} from "../MigrateStaffAccountForm/components";
import { SettingCard } from "../SettingCard";
import { StaffDetailsForm } from "../StaffDetailsForm";
import { StaffSettingsForm } from "../StaffSettingsForm";
import { REMOVE_2FA_STRATEGY_FOR_STAFF } from "./constants";
import { UpdateStaffAccountFormValues } from "./types";

export interface UpdateStaffAccountFormProps {
  staff: Staff;
}

export const UpdateStaffAccountForm = ({
  staff,
}: UpdateStaffAccountFormProps) => {
  const {
    formState: { errors },
  } = useFormContext<UpdateStaffAccountFormValues>();

  const targetUser = staff.user;

  return (
    <Stack spacing={5}>
      <AccountIdentityFieldset />
      <SettingCard
        title="Staff Information"
        wrapped
        description="Information about the staff"
      >
        <StaffDetailsForm />
      </SettingCard>
      <AccountSecurityFieldset
        additionalContent={
          targetUser ? <MfaAdditionalContent user={targetUser} /> : undefined
        }
      />
      <SettingCard title="Settings" description="Settings for staff" wrapped>
        <StaffSettingsForm />
      </SettingCard>
    </Stack>
  );
};

interface MfaAdditionalContentProps {
  user: UserV2;
}

type Remove2faModalState = {
  open: boolean;
  mfaMethod?: MfaMethod;
  loading?: boolean;
};

function MfaAdditionalContent({ user }: MfaAdditionalContentProps) {
  const [removedMfaMethods, setRemovedMfaMethods] = useState<Set<string>>(
    new Set()
  );

  const [modalState, setModalState] = useState<Remove2faModalState>({
    open: false,
    mfaMethod: undefined,
    loading: false,
  });

  const { showMessage } = useFlashMessages();

  const { activeUser } = useActiveUserProvider();

  const [remove2faStrategyForStaff] = useMutation<{
    remove2faStrategyForStaff: Success;
  }>(REMOVE_2FA_STRATEGY_FOR_STAFF);

  function handleRemovePress(mfaMethod: MfaMethod) {
    setModalState({ open: true, mfaMethod, loading: false });
  }

  function handleConfirm() {
    if (!modalState.mfaMethod) {
      reset();
      return;
    }

    setModalState((prev) => ({ ...prev, loading: true }));

    remove2faStrategyForStaff({
      variables: {
        userId: user.id,
        strategy: modalState.mfaMethod.strategy,
      },
    })
      .then((res) => {
        if (res.data?.remove2faStrategyForStaff.success) {
          handleConfirmSuccess(modalState.mfaMethod);
        } else {
          handleConfirmError();
        }
      })
      .catch((e) => {
        handleConfirmError(e);
      });
  }

  function handleConfirmSuccess(mfaMethod?: MfaMethod) {
    if (mfaMethod && !removedMfaMethods.has(mfaMethod.id)) {
      setRemovedMfaMethods((prev) => new Set(prev).add(mfaMethod.id));
    }
    reset();
  }

  function handleConfirmError(e?: any) {
    showMessage({
      message: e?.message ?? "Something went wrong",
      severity: "error",
    });
  }

  function handleCancel() {
    reset();
  }

  function reset() {
    setModalState({ open: false });
  }

  const verifiedMfaMethods = user.mfaMethods.filter(
    (i) => i.verified && !removedMfaMethods.has(i.id)
  );

  if (!userIsAdmin(activeUser) || !verifiedMfaMethods.length) {
    return null;
  }

  return (
    <>
      <Stack direction={"column"} spacing={3}>
        {verifiedMfaMethods.map((mfaMethod) => {
          return (
            <VerifiedMfaListItem
              key={mfaMethod.id}
              onClick={() => handleRemovePress(mfaMethod)}
              mfaMethod={mfaMethod}
            />
          );
        })}
      </Stack>
      <ConfirmationPrompt
        visible={modalState.open}
        cancel={handleCancel}
        title="Are you sure you want to remove 2FA?"
        description="Removing a staff member's 2FA will make their account less secure."
        confirm={{
          content: "Remove 2FA",
          destructive: true,
          onAction: handleConfirm,
        }}
      />
    </>
  );
}
