import { useMutation } from "@apollo/client";
import {
  MUTATION_GET_LOGIN_LINK,
  MUTATION_ONBOARD_EXPRESS_ACCOUNT,
  MUTATION_ONBOARD_STANDARD_ACCOUNT,
  MUTATION_UNLINK_STRIPE_ACCOUNT,
} from "./constants";
import {
  CreateStripeExpressAccountInput,
  Success,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";

export const useMutationOnBoardExpressAccount = () => {
  const [m] = useMutation<{
    createExpressAccountAndRequestAccountLinkUrl?: string;
  }>(MUTATION_ONBOARD_EXPRESS_ACCOUNT);
  const onboardExpressAccount = useCallback(
    async (input?: CreateStripeExpressAccountInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.createExpressAccountAndRequestAccountLinkUrl;
      });
    },
    [m]
  );
  return {
    onboardExpressAccount,
  };
};

export const useMutationGetLoginLink = () => {
  const [m] = useMutation<{
    createLoginLink?: string;
  }>(MUTATION_GET_LOGIN_LINK);
  const getLoginLink = useCallback(async () => {
    return m().then((d) => {
      if (!d.data) {
        throw new Error();
      }
      return d.data?.createLoginLink;
    });
  }, [m]);
  return {
    getLoginLink,
  };
};

export const useMutationOnBoardStandardAccount = () => {
  const [m] = useMutation<{
    createNormalAccountAndRequestAccountLinkUrl?: string;
  }>(MUTATION_ONBOARD_STANDARD_ACCOUNT);
  const onboardStandardAccount = useCallback(async () => {
    return m().then((d) => {
      if (!d.data) {
        throw new Error();
      }
      return d.data?.createNormalAccountAndRequestAccountLinkUrl;
    });
  }, [m]);
  return {
    onboardStandardAccount,
  };
};

export const useMutationUnlinkStripeAccount = () => {
  const [m] = useMutation<{
    unlinkStripeAccount: Success;
  }>(MUTATION_UNLINK_STRIPE_ACCOUNT);

  const unlinkStripeAccount = useCallback(async () => {
    return m().then((d) => {
      if (!d.data) {
        throw new Error();
      }
      return d.data?.unlinkStripeAccount;
    });
  }, [m]);

  return {
    unlinkStripeAccount,
  };
};
