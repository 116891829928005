import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Chip,
  useTheme,
} from "@mui/material";
import {
  Product,
  ProductSortParameter,
  SortOrder,
} from "@towersystems/roam-common/lib/generated-types";
import { toTitleCase } from "@towersystems/roam-common/lib/shared-utils";
import { useNavigate } from "react-router-dom";
import { ProductImageThumbnail } from "../../../../components/ProductImageThumbnail";
import { headCells } from "./constants";
import { Badge, HeadCell, Skeleton } from "../../../../components";
import { UnionMap } from "../../../../utilities/sort";
import { isNil } from "lodash";

export interface ProductResourceListProps {
  loading?: boolean;
  items?: Product[];
  dynamicSort?: UnionMap<keyof ProductSortParameter, SortOrder>;
  toggleSort?: (key: keyof ProductSortParameter) => void;
}

const NUM_LOADING_PLACEHOLDERS = 15;

export const ProductResourceList = ({
  loading,
  items,
  dynamicSort,
  toggleSort,
}: ProductResourceListProps) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const rowsMarkup = loading
    ? Array.from({ length: NUM_LOADING_PLACEHOLDERS }).map((_, i) => (
        <ProductRowSkeleton key={i} />
      ))
    : items?.map((i) => {
        const showDisplayName = !!i.displayName;

        return (
          <TableRow
            key={i.id}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/products/view/${i.id}`)}
          >
            <TableCell width={40}>
              <ProductImageThumbnail src={i.image?.path} />
            </TableCell>
            <TableCell>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <Typography
                  fontWeight={"bold"}
                  style={
                    showDisplayName
                      ? { paddingRight: theme.spacing(1) }
                      : undefined
                  }
                >
                  {toTitleCase(i.name)}
                </Typography>
                {showDisplayName ? (
                  <Typography variant="subdued">{`(${i.displayName})`}</Typography>
                ) : undefined}
              </div>
              <Typography style={{ display: "block" }} variant="subdued">
                {toTitleCase(i.stockNumber)}
              </Typography>
            </TableCell>
            <TableCell>
              {!isNil(i.stockCode) ? (
                <Typography variant="subdued">{i.stockCode}</Typography>
              ) : undefined}
            </TableCell>
            <TableCell>
              <Badge state={i.active ? "active" : "disabled"} />
            </TableCell>
            <TableCell>
              {i.hasVariants ? (
                <Chip label={`${i.totalVariants} variants`} size="small"></Chip>
              ) : undefined}
            </TableCell>
            <TableCell>
              <Typography
                fontSize={14}
                color={i.totalInventory <= 0 ? "destructive.main" : ""}
              >{`${i.totalInventory} in stock`}</Typography>
            </TableCell>
          </TableRow>
        );
      });

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 1050 }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <HeadCell {...{ headCell, dynamicSort, toggleSort }} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{rowsMarkup}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

function ProductRowSkeleton() {
  const imageSizeStyle = {
    height: "40px",
    width: "40px",
    lineHeight: 0,
    boxSizing: "content-box",
    paddingTop: 0,
    paddingBottom: 0,
  } as const;

  return (
    <TableRow>
      <TableCell style={imageSizeStyle}>
        <Skeleton style={{ display: "block" }} height="100%" width="100%" />
      </TableCell>
      <TableCell>
        <Typography>
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subdued">
          <Skeleton style={{ width: 45 }} />
        </Typography>
      </TableCell>
      <TableCell>
        <Skeleton
          style={{
            width: 40,
            height: 20,
          }}
        ></Skeleton>
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography fontSize={14}>
          <Skeleton />
        </Typography>
      </TableCell>
    </TableRow>
  );
}
