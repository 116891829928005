import { gql } from "@apollo/client";
import { GlobalSettingsNoNestingFragment } from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const GLOBAL_SETTINGS_KEY = "__ROAM_CLIENT_GLOBAL_SETTINGS_KEY__";

export const GlobalSettingsQuery = gql`
  ${GlobalSettingsNoNestingFragment}
  query globalSettings {
    globalSettings {
      ...GlobalSettingsNoNesting
    }
  }
`;

export const MUTATION_UPDATE_GLOBAL_SETTINGS = gql`
  ${GlobalSettingsNoNestingFragment}
  mutation updateGlobalSettings($input: UpdateGlobalSettingsInput!) {
    updateGlobalSettings(input: $input) {
      ...GlobalSettingsNoNesting
    }
  }
`;
