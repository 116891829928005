import { Sale } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";
import { moneyToFormattedString } from "../../../../utilities/money";

export interface SalePaymentsDetailCardProps {
  sale: Sale;
}

export const SalePaymentsDetailCard = ({
  sale,
}: SalePaymentsDetailCardProps) => {
  return (
    <CardDetailList
      title="Payments"
      emptyComponent="No payments made."
      items={sale.payments.map((p) => {
        return {
          key: p.id,
          title: p.name,
          value: moneyToFormattedString(p.amount),
        };
      })}
    ></CardDetailList>
  );
};
