import { Divider, Typography } from "@mui/material";
import { ComplexAction } from "@towersystems/roam-common/lib/shared-types";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { Stack } from "../Stack";

type ConfirmationPromptAction =
  | (() => void)
  | (ComplexAction & { onAction(): void });

const CONFIRMATION_LABEL = "Confirm";
const CANCEL_LABEL = "Cancel";

export interface ConfirmationPromptProps {
  visible: boolean;
  cancel: ConfirmationPromptAction;
  confirm: ConfirmationPromptAction;
  title: string;
  description: string;
}

export const ConfirmationPrompt = ({
  visible,
  cancel,
  confirm,
  title,
  description,
}: ConfirmationPromptProps) => {
  function getActionLabel(
    confirmationPromptAction: ConfirmationPromptAction,
    fallback: string
  ) {
    if (typeof confirmationPromptAction !== "function") {
      return confirmationPromptAction.content ?? fallback;
    }

    return fallback;
  }

  function getButtonProps(confirmationPromptAction: ConfirmationPromptAction) {
    if (typeof confirmationPromptAction !== "function") {
      return {
        onClick: confirmationPromptAction.onAction,
        disabled: confirmationPromptAction.disabled,
        children: confirmationPromptAction.content,
      };
    } else {
      return {
        onClick: confirmationPromptAction,
      };
    }
  }

  const confirmButtonProps = getButtonProps(confirm);
  const cancelButtonProps = getButtonProps(cancel);

  return (
    <Modal title={title} open={visible} onClose={cancelButtonProps.onClick}>
      <Stack spacing={3}>
        <section>
          <Typography>{description}</Typography>
        </section>
        <Divider />
        <Stack direction="row" spacing={2}>
          <Button
            {...confirmButtonProps}
            fullWidth
            variant="tile"
            color="primary"
          >
            {getActionLabel(confirm, CONFIRMATION_LABEL)}
          </Button>
          <Button {...cancelButtonProps} fullWidth variant="tile">
            {getActionLabel(cancel, CANCEL_LABEL)}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
