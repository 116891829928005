import React from "react";
import { useActiveUser, ActiveUserContext } from "../../utilities/active-user";

export interface ActiveUserControllerProps {
  children?: React.ReactNode;
}

export const ActiveUserController = ({
  children,
}: ActiveUserControllerProps) => {
  const { activeUser, queryActiveUser } = useActiveUser();

  React.useEffect(() => {
    queryActiveUser().catch((err) => console.log(err));
  }, []);

  if (!activeUser) {
    return null;
  }

  return (
    <ActiveUserContext.Provider value={{ activeUser }}>
      {children}
    </ActiveUserContext.Provider>
  );
};
