import { gql } from "@apollo/client";
import {
  PaymentMethodNoNestingFragment,
  PayTypeFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as Yup from "yup";

export const QUERY_PAYMENT_METHODS = gql`
  ${PaymentMethodNoNestingFragment}
  ${PayTypeFragment}
  query paymentMethods {
    paymentMethods(options: { limit: 1000 }) {
      items {
        ...PaymentMethodNoNesting
        paytype {
          ...PayType
        }
      }
    }
  }
`;

export const QUERY_PAYMENT_METHOD = gql`
  ${PaymentMethodNoNestingFragment}
  ${PayTypeFragment}
  query paymentMethod($id: ID!) {
    paymentMethod(id: $id) {
      ...PaymentMethodNoNesting
      paytype {
        ...PayType
      }
    }
  }
`;

export const MUTATION_CREATE_PAYMENT_METHOD = gql`
  ${PaymentMethodNoNestingFragment}
  ${PayTypeFragment}
  mutation createPaymentMethod($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      ...PaymentMethodNoNesting
      paytype {
        ...PayType
      }
    }
  }
`;

export const MUTATION_UPDATE_PAYMENT_METHOD = gql`
  ${PaymentMethodNoNestingFragment}
  ${PayTypeFragment}
  mutation updatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      ...PaymentMethodNoNesting
      paytype {
        ...PayType
      }
    }
  }
`;

export const defaultPaymentMethodFormValues = {
  name: "",
  payTypeId: undefined,
  enabled: true,
};

export const paymentMethodFormSchema = Yup.object({
  name: Yup.string().required(),
  enabled: Yup.boolean(),
  payTypeId: Yup.string().required(),
  retailerPayType: Yup.string(),
});

export const editPaymentMethodFormSchema = Yup.object({
  id: Yup.string().required(),
  name: Yup.string().required(),
  enabled: Yup.boolean(),
  retailerPayType: Yup.string(),
});

export const PaymentMethodFormSections = {
  DETAILS: "Details",
  SETTINGS: "Settings",
} as const;
