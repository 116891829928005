import { gql } from "@apollo/client";
import {
  CategoryNoNestingFragment,
  PaginatedListMetaNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_CATEGORY = gql`
  ${CategoryNoNestingFragment}
  query QueryCategory($id: ID!) {
    category(id: $id) {
      ...CategoryNoNesting
    }
  }
`;

export const QUERY_CATEGORY_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${CategoryNoNestingFragment}
  query QueryCategoryList($options: CategoryListOptions) {
    categories(options: $options) {
      items {
        ...CategoryNoNesting
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const MUTATION_UPDATE_CATEGORY = gql`
  ${CategoryNoNestingFragment}
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      ...CategoryNoNesting
    }
  }
`;
