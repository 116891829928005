import { Box, InputAdornment, Paper, TextField } from "@mui/material";
import { Page } from "components";
import SearchIcon from "@mui/icons-material/Search";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useSearch } from "../../../../utilities/search/hooks";
import { SearchContext } from "../../../../utilities/search/context";
import {
  AllSalesTabView,
  PosSalesTabView,
  SelfCheckoutSalesTabView,
} from "./components";

export const SaleListPage = () => {
  const search = useSearch();

  const toolbarMarkup = (
    <Box>
      <TextField
        value={search.query}
        onChange={(e) => search.setQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search sales"
        fullWidth
        className="CardSearchInput"
        variant="standard"
      ></TextField>
    </Box>
  );

  return (
    <SearchContext.Provider value={search}>
      <Page title="Sales" titleDescription="Manage your sales.">
        <Paper variant="outlined">
          {toolbarMarkup}
          <Tabs>
            <TabList>
              <Tab>All Sales</Tab>
              <Tab>POS Sales</Tab>
              <Tab>Self Checkout Sales</Tab>
            </TabList>
            <TabPanel>
              <AllSalesTabView />
            </TabPanel>
            <TabPanel>
              <PosSalesTabView />
            </TabPanel>
            <TabPanel>
              <SelfCheckoutSalesTabView />
            </TabPanel>
          </Tabs>
        </Paper>
      </Page>
    </SearchContext.Provider>
  );
};
