import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { PaymentMethod } from "@towersystems/roam-common/lib/generated-types";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../../../components/Badge";
import { NavigateRightButton } from "../../../../components/NavigateRightButton";
import { PayTypeToIcon } from "../../../../components/PayTypeToIcon/PayTypeToIcon";
import { SettingRowSkeleton } from "../../../../components/SettingRowSkeleton";

export interface PaymentMethodListProps {
  loading?: boolean;
  items?: Omit<PaymentMethod, "configuration">[];
}

const NUM_LOADING_PLACEHOLDERS = 5;

export const PaymentMethodList = ({
  loading,
  items,
}: PaymentMethodListProps) => {
  const navigate = useNavigate();

  const rows: any[] = loading
    ? Array.from({ length: NUM_LOADING_PLACEHOLDERS }).map((_, i) => (
        <SettingRowSkeleton key={i} />
      ))
    : items
        ?.filter((i) => {
          return i.paytype.internal === false;
        })
        .map((i) => {
          return (
            <TableRow
              key={i.id}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/settings/payment-methods/edit/${i.id}`)}
            >
              <TableCell padding="checkbox">
                <div style={{ paddingLeft: 10 }}>
                  <PayTypeToIcon payType={i.paytype.id} />
                </div>
              </TableCell>
              <TableCell width={"100%"}>
                <Typography fontWeight={"bold"}>{i.name}</Typography>
                <Typography variant="subdued">{i.paytype.name}</Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
                <Stack direction="row" justifyContent={"flex-end"}>
                  <Badge state={i.enabled ? "active" : "disabled"} />
                  <NavigateRightButton />
                </Stack>
              </TableCell>
            </TableRow>
          );
        }) || [];

  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
