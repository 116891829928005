import { Box } from "@mui/material";
import { MfaMethod } from "@towersystems/roam-common/lib/generated-types";
import SecurityUpdateGoodTwoToneIcon from "@mui/icons-material/SecurityUpdateGoodTwoTone";
import MailLockTwoToneIcon from "@mui/icons-material/MailLockTwoTone";
import LockClockTwoToneIcon from "@mui/icons-material/LockClockTwoTone";

export interface MfaStrategyIconProps {
  mfaMethod: MfaMethod;
}

export const MfaStrategyIcon = ({ mfaMethod }: MfaStrategyIconProps) => {
  let icon;

  switch (mfaMethod.strategy) {
    case "EMAIL":
      icon = <MailLockTwoToneIcon />;
      break;
    case "SMS":
      icon = <SecurityUpdateGoodTwoToneIcon />;
      break;
    default:
      icon = <LockClockTwoToneIcon />;
  }

  return (
    <Box>
      <Box
        sx={{ mr: 2 }}
        style={{
          width: 50,
          height: 50,
          border: "1px solid var(--mui-palette-border-standard)",
          borderRadius: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </Box>
    </Box>
  );
};
