import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Skeleton } from "../Skeleton";
import React from "react";

export interface SkeletonHeaderProps {
  title?: boolean | string;
  titleDescription?: boolean | React.ReactNode;
  primaryAction?: boolean;
  goBack?: boolean;
  badges?: number;
  adornment?: boolean | React.ReactNode;
}

export function SkeletonHeader({
  title,
  titleDescription,
  primaryAction,
  goBack,
  badges,
  adornment,
}: SkeletonHeaderProps) {
  const { spacing } = useTheme();

  const goBackMarkup = goBack ? (
    <Box style={{ width: 48 }}>
      <Skeleton />
    </Box>
  ) : undefined;

  const badgesMarkup = !!badges ? (
    <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
      {Array.from({ length: badges }).map((_, i) => (
        <Skeleton
          variant="rectangular"
          style={{ borderRadius: spacing(2) }}
          key={i}
          width={80}
          height={24}
        />
      ))}
    </Stack>
  ) : undefined;

  const bodyMarkup = !!titleDescription ? (
    titleDescription === true ? (
      <Typography variant="body1" style={{ width: 250 }}>
        <Skeleton />
      </Typography>
    ) : (
      <Box>{titleDescription}</Box>
    )
  ) : undefined;

  const adormentMarkup = !!adornment ? (
    <Box marginRight={4}>
      {adornment === true ? (
        <Box width="80px">
          <Skeleton />
        </Box>
      ) : (
        adornment
      )}
    </Box>
  ) : undefined;

  const primaryActionMarkup = primaryAction ? (
    <Box
      style={{
        width: 150,
        height: 60,
      }}
    >
      <Skeleton />
    </Box>
  ) : undefined;

  return (
    <Box style={{ display: "flex" }} flexDirection={"row"}>
      {adormentMarkup}
      <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography fontWeight={"bold"} variant="h1">
            {typeof title === "string" ? (
              title
            ) : (
              <Skeleton width={title ? 200 : 0} />
            )}
          </Typography>
          <Stack direction={"column"} spacing={1}>
            {bodyMarkup}
            {badgesMarkup}
            {goBackMarkup}
          </Stack>
        </Box>
        <Box>{primaryActionMarkup}</Box>
      </Box>
    </Box>
  );
}
