import { gql } from "@apollo/client";
import {
  CustomerAddressDeepNestingFragment,
  CustomerDeepNestingFragment,
  CustomerListDeepNestingFragment,
  PaginatedListMetaDeepNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_CUSTOMERS_LIST = gql`
  ${CustomerDeepNestingFragment}
  ${PaginatedListMetaDeepNestingFragment}
  ${CustomerAddressDeepNestingFragment}
  ${CustomerListDeepNestingFragment}
  query customers($options: CustomerListOptions) {
    customers(options: $options) {
      ...CustomerListDeepNesting
    }
  }
`;

export const QUERY_CUSTOMER = gql`
  ${CustomerDeepNestingFragment}
  ${CustomerAddressDeepNestingFragment}
  query customer($id: ID!) {
    customer(id: $id) {
      ...CustomerDeepNesting
    }
  }
`;

export const MUTATION_CREATE_CUSTOMER = gql`
  ${CustomerAddressDeepNestingFragment}
  ${CustomerDeepNestingFragment}
  mutation createCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      ...CustomerDeepNesting
    }
  }
`;

export const MUTATION_UPDATE_CUSTOMER = gql`
  ${CustomerAddressDeepNestingFragment}
  ${CustomerDeepNestingFragment}
  mutation updateCustomer($input: CustomerInput!) {
    updateCustomer(input: $input) {
      ...CustomerDeepNesting
    }
  }
`;
