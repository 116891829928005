import { Box, Typography } from "@mui/material";
import { StrategyRadioButton } from "../../../../modules/login/components/LoginForm/components";
import { Stack } from "../../../Stack";
import { Controller, useFormContext } from "react-hook-form";
import { SwitchActiveUserFormValues } from "../../types";
import { strategiesMap } from "../../../../modules/login/utils/login";

export interface SelectMfaMethodFieldsetProps {}

export const SelectMfaMethodFieldset = ({}: SelectMfaMethodFieldsetProps) => {
  const formContext = useFormContext<SwitchActiveUserFormValues>();
  const { watch, control } = formContext;
  const strategies = watch("ui.strategies");

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h6">
            Two-Factor Authentication Required
          </Typography>
          <Typography>
            Please select a two-factor authentication method.
          </Typography>
        </Box>
        <Box>
          <Controller
            control={control}
            name="mfaStrategy"
            render={({ field: { onChange } }) => {
              return (
                <Stack spacing={2} direction={"column"}>
                  {strategies.map((s) => {
                    const strategyData = strategiesMap[s];
                    if (!strategyData) {
                      return null;
                    }
                    return (
                      <StrategyRadioButton
                        {...strategyData}
                        name="mfaStrategy"
                        onChange={onChange}
                        key={s}
                        value={s}
                        id={s}
                      />
                    );
                  })}
                </Stack>
              );
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};
