import { Box, Drawer, useTheme } from "@mui/material";
import { PropsWithChildren, useCallback } from "react";
import { Footer, FooterProps, Header, HeaderProps } from "./components";
import { drawerConfig } from "./constants";

type Props = Partial<HeaderProps> &
  FooterProps & {
    onTransitionEnd?(): void;
    open: boolean;
    onOpen(): void;
    onClose(): void;
    onTransitionEnd?(): void;
  };

export type SheetProps = PropsWithChildren<Props>;

export function Sheet({
  title,
  primaryAction,
  secondaryAction,
  children,
  onClose,
  onTransitionEnd,
  ...props
}: SheetProps) {
  const theme = useTheme();

  const isFooterVisible = primaryAction || secondaryAction;

  const footerMarkup = isFooterVisible ? (
    <Footer {...{ primaryAction, secondaryAction }} />
  ) : null;

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      onTransitionEnd && onTransitionEnd();
    }, theme.transitions.duration.leavingScreen);
  }, [onClose, onTransitionEnd, theme.transitions.duration.leavingScreen]);

  const headerMarkup = title ? (
    <Header {...{ title }} onClose={handleClose} />
  ) : null;

  return (
    <Drawer
      sx={{
        zIndex: (theme) => theme.zIndex.modal,
      }}
      {...drawerConfig}
      {...props}
      onClose={handleClose}
    >
      {headerMarkup}
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexGrow: 1,
          overflowX: "hidden",
          backgroundColor: "dynamicBackground",
        }}
      >
        {children}
      </Box>
      {footerMarkup}
    </Drawer>
  );
}
