import { yupResolver } from "@hookform/resolvers/yup";
import { Divider } from "@mui/material";
import { Staff } from "@towersystems/roam-common/lib/generated-types";
import { ContextualSaveBar, Stack } from "components";
import {
  defaultStaffFormValues,
  staffFormSchema,
} from "modules/settings/utils/staffs";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRevalidator } from "react-router-dom";
import { useFlashMessages } from "utilities/flash-messages";
import { normalizeSelectedValues } from "utilities/transforms";
import { useMutationUpdateStaff } from "../../utils/staffs/hooks";
import { StaffFormValues } from "../../utils/staffs/types";
import { SettingCard } from "../SettingCard";
import { StaffDetailsForm } from "../StaffDetailsForm";
import { StaffSettingsForm } from "../StaffSettingsForm";

export interface EditStaffFormProps {
  staff: Staff;
}

export function EditStaffForm({ staff }: EditStaffFormProps) {
  const { showMessage } = useFlashMessages();
  const methods = useForm<StaffFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: normalizeSelectedValues(defaultStaffFormValues, staff),
    resolver: yupResolver(staffFormSchema),
  });

  const { handleSubmit, formState } = methods;
  const { isDirty } = formState;

  const { updateStaff } = useMutationUpdateStaff();

  const revalidator = useRevalidator();

  const handleOnSubmit = useCallback(
    async (formValues: StaffFormValues) => {
      try {
        await updateStaff({ ...formValues, id: staff.id });
        showMessage({
          message: "Staff updated successfully",
          severity: "success",
        });
        methods.reset(formValues); // reset defaults to the updated values
        revalidator.revalidate(); // updates prefetched data
      } catch (error) {
        console.error(error);
      }
    },
    [updateStaff]
  );

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{ onAction: handleSubmit(handleOnSubmit) }}
      cancelAction={{ onAction: () => methods.reset() }}
    />
  ) : undefined;

  return (
    <FormProvider {...methods}>
      {contextualSaveBar}
      <Stack spacing={3} divider={<Divider />}>
        <SettingCard
          title="Staff Information"
          wrapped
          description="Information about the staff"
        >
          <StaffDetailsForm />
        </SettingCard>
        <SettingCard title="Settings" description="Settings for staff" wrapped>
          <StaffSettingsForm />
        </SettingCard>
      </Stack>
    </FormProvider>
  );
}
