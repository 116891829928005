import { Snackbar, Alert } from "@mui/material";
import {
  DEFAULT_SEVERITY,
  DURATION,
  FlashMessageProps,
} from "../../../../utilities/flash-messages";

export function FlashMessage({
  keyProp,
  open,
  severity = DEFAULT_SEVERITY,
  message,
  onClose,
  onExited,
}: FlashMessageProps) {
  const anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  } as const;

  return (
    <Snackbar
      key={keyProp}
      open={open}
      autoHideDuration={DURATION}
      anchorOrigin={anchorOrigin}
      TransitionProps={{ onExited }}
      onClose={onClose}
    >
      <Alert onClose={onClose} {...{ severity }} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
