import { Stack } from "@mui/material";
import { FormLayout, SkeletonPage } from "../../../../components";
import { InputSkeleton } from "../InputSkeleton";
import { ReceiptInformationSettingCard } from "../ReceiptForm/components";
import { ReceiptSettingsSettingCard } from "../ReceiptForm/components/ReceiptSettingsSettingCard";

export interface ReceiptPageSkeletonProps {}

export function ReceiptPageSkeleton(props: ReceiptPageSkeletonProps) {
  return (
    <SkeletonPage title titleDescription goBack>
      <Stack spacing={3}>
        <ReceiptInformationSettingCard>
          <FormLayout>
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
          </FormLayout>
        </ReceiptInformationSettingCard>
        <ReceiptSettingsSettingCard>
          <InputSkeleton />
          <InputSkeleton />
        </ReceiptSettingsSettingCard>
      </Stack>
    </SkeletonPage>
  );
}
