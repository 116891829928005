import * as yup from "yup";

export const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const schema = yup.object().shape({
  oldPassword: yup.string().required("Current password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .min(6, "New password must be at least 6 characters long"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});
