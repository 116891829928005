import { gql } from "@apollo/client";

export const Setup2faStrategyMutation = gql`
  mutation Setup2faStrategyMutation($input: Setup2faStrategyInput!) {
    setup2faStrategy(input: $input) {
      ... on Success {
        success
      }
      ... on InvalidMfaStrategyError {
        message
        errorCode
      }
      ... on InvalidMfaIdentifierError {
        message
        errorCode
      }
      ... on MfaAlreadySetupError {
        message
        errorCode
      }
    }
  }
`;

export const Verify2faStrategyMutation = gql`
  mutation Verify2faStrategyMutation(
    $credentials: AuthenticationInput!
    $strategy: MfaCodeStrategy!
    $code: String!
  ) {
    verify2faStrategy(
      credentials: $credentials
      strategy: $strategy
      code: $code
    ) {
      ... on Success {
        success
      }
      ... on InvalidMfaStrategyError {
        message
        errorCode
      }
      ... on MfaAlreadySetupError {
        message
        errorCode
      }
      ... on InvalidMfaCodeError {
        message
        errorCode
      }
      ... on InvalidCredentialsError {
        message
        errorCode
      }
    }
  }
`;
export const Remove2faStrategyMutation = gql`
  mutation Remove2faStrategyMutation(
    $strategy: MfaCodeStrategy!
    $code: String!
  ) {
    remove2faStrategy(strategy: $strategy, code: $code) {
      ... on Success {
        success
      }
      ... on InvalidMfaStrategyError {
        message
        errorCode
      }
      ... on InvalidMfaCodeError {
        message
        errorCode
      }
    }
  }
`;
