import React from "react";
import { ActiveUserContextType, SwitchActiveUserContextType } from "./types";

export const ActiveUserContext = React.createContext<
  ActiveUserContextType | undefined
>(undefined);

export const SwitchActiveUserContext = React.createContext<
  SwitchActiveUserContextType | undefined
>(undefined);
