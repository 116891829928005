import { createContext } from "react";
import { Frames } from "./types";

export interface AppContextType {
  frameComponent?: Frames;
  toggleColorMode(): void;
  setFrameComponent(frame: Frames): void;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);
