import {
  SwitchActiveUserContext,
  useSwitchActiveUser,
} from "../../utilities/active-user";
import { SwitchUserDialog } from "./SwitchUserDialog";

export interface SwitchUserProviderProps {
  children?: React.ReactNode | React.ReactNode[] | null;
}

export const SwitchUserProvider = ({ children }: SwitchUserProviderProps) => {
  const switchUserContext = useSwitchActiveUser();
  return (
    <SwitchActiveUserContext.Provider value={switchUserContext}>
      {children}
      <SwitchUserDialog />
    </SwitchActiveUserContext.Provider>
  );
};
