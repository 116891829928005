import { SelfCheckoutSettingsFormValues } from "./types";
import * as Yup from "yup";

export const defaultFormValues: SelfCheckoutSettingsFormValues = {
  enabled: false,
  paymentMethodId: "",
};

export const schema = Yup.object().shape({
  enabled: Yup.boolean(),
});
