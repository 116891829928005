import { gql } from "@apollo/client";
import {
  LocationListFragment,
  LocationNoNestingFragment,
  PaginatedListMetaNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as Yup from "yup";

export const QUERY_LOCATIONS_LIST = gql`
  ${LocationListFragment}
  ${PaginatedListMetaNoNestingFragment}
  ${LocationNoNestingFragment}
  query locations($options: LocationListOptions) {
    locations(options: $options) {
      ...LocationList
    }
  }
`;

export const QUERY_LOCATION = gql`
  ${LocationNoNestingFragment}
  query location($id: ID!) {
    location(id: $id) {
      ...LocationNoNesting
    }
  }
`;

export const MUTATION_LOCATION_METHOD = gql`
  ${LocationNoNestingFragment}
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ...LocationNoNesting
    }
  }
`;

export const MUTATION_UPDATE_LOCATION = gql`
  ${LocationNoNestingFragment}
  mutation updateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      ...LocationNoNesting
    }
  }
`;

export const defaultLocationFormValues = {
  retailerLocationKey: "",
  name: "",
  enabled: undefined,
};

export const locationFormSchema = Yup.object({
  retailerLocationKey: Yup.string(),
  name: Yup.string().required(),
  enabled: Yup.boolean().required(),
});
