import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { StaffPageSkeleton } from "../../components";
import { Staff } from "@towersystems/roam-common/lib/generated-types";
import { ContextualSaveBar, Page } from "../../../../components";
import { MigrateStaffAccountFormProvider } from "../../components/MigrateStaffAccountForm/MigrateStaffAccountFormProvider";
import { MigrateStaffAccountForm } from "../../components/MigrateStaffAccountForm";
import { MigrateStaffFormValues } from "../../components/MigrateStaffAccountForm/types";
import { useFormContext } from "react-hook-form";
import { useMutationMigrateStaffUserAccount } from "../../utils/staffs/hooks";

export interface UpgradeStaffAccountPageProps {}

interface UpgradeStaffAcountProps {
  staff: Staff;
}

const UpgradeStaffAcount = ({ staff }: UpgradeStaffAcountProps) => {
  const { handleSubmit, reset, setError, formState } =
    useFormContext<MigrateStaffFormValues>();

  const { isDirty } = formState;
  const navigate = useNavigate();
  const { migrateStaffUserAccount } = useMutationMigrateStaffUserAccount();

  const goBackURL = `/settings/staffs/edit/${staff.id}`;

  React.useEffect(() => {
    if (staff.hasUserAccount) {
      navigate(goBackURL);
    }
  }, []);

  const handleOnSubmit = React.useCallback(
    async (formValues: MigrateStaffFormValues) => {
      return migrateStaffUserAccount({
        staffId: formValues.staffId,
        username: formValues.username,
        password: formValues.password,
        emailAddress: formValues.emailAddress,
      })
        .then((staff) => {
          if (staff) {
            navigate(goBackURL);
          }
        })
        .catch((err) => {
          if (err.message === "error.username-already-exists") {
            setError("username", {
              message: "Username already exists",
            });
          }
        });
    },
    []
  );

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{ onAction: handleSubmit(handleOnSubmit) }}
      cancelAction={{ onAction: () => reset() }}
    />
  ) : undefined;

  return (
    <Page
      title="Migrate to Staff Account"
      titleDescription="Upgrade account for staff to be able to signin."
      goBack={{
        onAction: () => navigate(`/settings/staff/edit/${staff.id}`),
      }}
    >
      {contextualSaveBar}
      <MigrateStaffAccountForm />
    </Page>
  );
};

export const UpgradeStaffAccountPage = ({}: UpgradeStaffAccountPageProps) => {
  const { data }: any = useLoaderData();
  return (
    <React.Suspense fallback={<StaffPageSkeleton />}>
      <Await resolve={data}>
        {([staff]: [Staff]) => {
          return (
            <MigrateStaffAccountFormProvider staff={staff}>
              <UpgradeStaffAcount staff={staff} />
            </MigrateStaffAccountFormProvider>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
