import { Box, Typography } from "@mui/material";
import { SwitchActiveUserForm } from "../SwitchActiveUserForm";
import {
  MfaCodeStrategy,
  UserV2,
} from "@towersystems/roam-common/lib/generated-types";
import { SwitchActiveUserFormValues } from "../SwitchActiveUserForm/types";
import { useFormContext } from "react-hook-form";
import React from "react";
import { SwitchUserFn } from "../../utilities/active-user";
import { Button } from "../Button";
import { Stack } from "../Stack";
import { useAuthenticationProvider } from "../../utilities/authentication/hooks";
import { useCurrentVendorProvider } from "../../utilities/vendor/hooks";

export interface SwitchUserFormProps {
  onGoBack: () => void;
  user?: UserV2;
  onSwitchUser: SwitchUserFn;
}

export const SwitchUserForm = ({
  onGoBack,
  user,
  onSwitchUser,
}: SwitchUserFormProps) => {
  const { requestMfaCode } = useAuthenticationProvider();
  const { currentVendor } = useCurrentVendorProvider();
  const { handleSubmit, setValue, setError, formState } =
    useFormContext<SwitchActiveUserFormValues>();

  const { isValid, isSubmitting } = formState;

  const handleOnSubmit = React.useCallback(
    async (formValues: SwitchActiveUserFormValues) => {
      let strategy = formValues.strategy;
      let input;

      if (strategy === "native") {
        input = {
          native: {
            talinkUsername: currentVendor.talinkUsername,
            password: formValues.password,
            username: formValues.identifier,
          },
        };
      } else if (strategy === "talink") {
        input = {
          talink: {
            talinkUsername: currentVendor?.talinkUsername,
            password: formValues.password,
          },
        };
      } else {
        return;
      }

      if (formValues.ui.mfaRequired) {
        if (false === formValues.ui.mfaCodeSent) {
          return await requestMfaCode(
            {
              strategy: formValues.strategy,
              talinkUsername: currentVendor.talinkUsername,
              staffUsername: formValues.identifier,
              password: formValues.password,
              mfaCode: formValues.mfaCode,
              mfaStrategy: formValues.mfaStrategy,
            },
            formValues.mfaStrategy as MfaCodeStrategy
          )
            .then(() => {
              setValue("ui.mfaCodeSent", true);
            })
            .catch((err) => console.log(err));
        }
      }

      const mfaInput = formValues.ui.mfaRequired
        ? {
            mfaCode: formValues.mfaCode,
            strategy: formValues.mfaStrategy,
          }
        : undefined;

      await onSwitchUser(input, mfaInput)
        .then((result) => {
          if ("errorCode" in result) {
            if (
              result.errorCode === "MFA_REQUIRED_ERROR" &&
              (result as any).strategies
            ) {
              setValue("ui.strategies", (result as any).strategies);
              setValue("ui.mfaRequired", true);
              setError("root.serverError", {
                type: "400",
                message: result.errorCode,
              });
              return;
            }

            if (result.errorCode === "INVALID_MFA_CODE_ERROR") {
              setError("root.serverError", {
                type: "400",
                message: result.errorCode,
              });
              return;
            }

            setError("password", {
              message: "Invalid Password",
            });
          }
          return result;
        })
        .catch((err) => console.log(err));
    },
    []
  );

  const signingIntoMarkup = user ? (
    <p>You are signing into: {user.identifier}</p>
  ) : (
    <p>You are signing into: Owner</p>
  );

  const disabled = !isValid || isSubmitting;

  return (
    <Box>
      <Button onClick={onGoBack}>Go Back</Button>
      {signingIntoMarkup}
      <Stack direction="column" spacing={2}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <SwitchActiveUserForm />
        </form>
        <Button
          variant="tile"
          type="submit"
          color="primary"
          size="large"
          sx={{ alignSelf: "flex-end" }}
          onClick={handleSubmit(handleOnSubmit)}
          disabled={disabled}
          fullWidth
        >
          <Typography fontWeight="bold">Continue</Typography>
        </Button>
      </Stack>
    </Box>
  );
};
