import React from "react";
import {
  USER_TOKEN_KEY,
  loginMutation,
} from "../../utilities/authentication/constants";
import { useMutation } from "@apollo/client";
import { AuthenticationResultV2 } from "@towersystems/roam-common/lib/generated-types";

export interface TalinkLoginHighjackProps {
  children?: React.ReactNode;
}

export const TalinkLoginHighjack = ({ children }: TalinkLoginHighjackProps) => {
  const search = window.location.search;
  const params = React.useMemo(() => new URLSearchParams(search), []);
  const talinkToken = params.get("talink_token");
  const [loading, setLoading] = React.useState(Boolean(talinkToken));

  const [loginFn] = useMutation<{ authenticate: AuthenticationResultV2 }>(
    loginMutation
  );

  const login = React.useCallback(async (token: string) => {
    return loginFn({
      variables: {
        input: {
          ["talink_token"]: {
            token,
          },
        },
      },
    }).then((r) => {
      if (r.data?.authenticate) {
        if ("currentUser" in r.data?.authenticate) {
          const { token } = r.data.authenticate.currentUser;
          localStorage.setItem(USER_TOKEN_KEY, token);
          window.location.href = "/";
          return;
        }
      }
    });
  }, []);

  React.useEffect(() => {
    const existingToken = localStorage.getItem(USER_TOKEN_KEY);
    if (existingToken || !talinkToken) {
      setLoading(false);
      return;
    }

    login(talinkToken)
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return null;
  }

  return <>{children}</>;
};
