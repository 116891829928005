import { useState, useCallback } from "react";

/**
 * exports return type to simplify hooks with useModal
 */
export type UseModalReturn = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

/**
 * Returns a stateful value, and a set of memoized functions to toggle it,
 * set it to true and set it to false
 */
export function useModal(initialState = false): UseModalReturn {
  const [open, setState] = useState(initialState);

  return {
    open,
    onOpen: useCallback(() => setState(true), []),
    onClose: useCallback(() => setState(false), []),
  };
}
