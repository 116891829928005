import {
  Container,
  styled,
  Typography,
  Toolbar,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { ContextualSaveBarProps, useFrame } from "../../../../utilities/frame";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "#000",
  display: "flex",
  flexDirection: "row",
  zIndex: 1202,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const ContextualSaveBar = ({
  message,
  saveAction,
  cancelAction,
}: ContextualSaveBarProps) => {
  const frame = useFrame();

  const cancelActionMarkup = cancelAction ? (
    <Button sx={{ ml: 2 }} variant="outlined" onClick={cancelAction?.onAction}>
      {cancelAction?.content || "Cancel"}
    </Button>
  ) : undefined;

  const saveActionMarkup = saveAction ? (
    <Button color="primary" variant="contained" onClick={saveAction?.onAction}>
      {saveAction?.content || "Save"}
    </Button>
  ) : undefined;

  const messageMarkup = (
    <Typography
      component="h1"
      variant="h6"
      color="inherit"
      noWrap
      sx={{ flexGrow: 1 }}
    >
      {message ? message : "Unsaved changes"}
    </Typography>
  );
  return (
    <AppBar position="absolute" open={frame.drawerOpen}>
      <Box>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={frame.toggleDrawer}
            sx={{
              ...(frame.drawerOpen && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Box>

      <Container maxWidth="lg" sx={{}}>
        <Toolbar disableGutters sx={{ display: "flex", alignItems: "center" }}>
          {messageMarkup}
          {saveActionMarkup}
          {cancelActionMarkup}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
