export const valueAsMoney = (value: string) => {
  const result = Math.round(parseFloat(value) * 100) / 100;
  return !isFinite(result) || isNaN(result) ? "0.00" : result.toFixed(2);
};
export const valueTo2Decimals = valueAsMoney;

export const moneyToFormattedString = (
  value?: string | number,
  prefix = "$ ",
  errorText = "-"
): string => {
  value = value || value == 0 || value == "0" ? value?.toString() : "";

  const result = parseFloat(value);
  if (isNaN(result)) {
    return errorText;
  }
  return `${prefix}${valueAsMoney(value)}`;
};
