import { Box, Divider } from "@mui/material";
import { Header, HeaderProps } from "./components";
import styles from "./Page.module.scss";
import { classNames } from "@towersystems/roam-common/lib/css";

export interface PageProps extends HeaderProps {
  /** The contents of the page */
  children?: React.ReactNode;
  /** The document title of the page */
  documentTitle?: string;
  divider?: boolean;
  pageClassName?: string;
}

export const Page = ({
  documentTitle,
  children,
  divider = true,
  pageClassName,
  ...headerProps
}: PageProps) => {
  const header = <Header {...headerProps} />;
  return (
    <Box
      paddingBottom={6}
      paddingTop={3}
      className={classNames(styles.Page, pageClassName)}
    >
      {header}
      {divider && <Divider sx={{ mt: 3, mb: 3 }} />}
      <Box className={styles.Body}>{children}</Box>
    </Box>
  );
};
