import { useMutation } from "@apollo/client";
import {
  CreateProductInput,
  Product,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import { MUTATION_CREATE_PRODUCT } from "./constants";
import { minBy } from "lodash";

export const useMutationCreateProduct = () => {
  const [m] = useMutation<{
    createProduct: Product;
  }>(MUTATION_CREATE_PRODUCT);
  const createProduct = useCallback(
    (input: CreateProductInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.createProduct;
      });
    },
    [m]
  );
  return {
    createProduct,
  };
};

export const lowestPrice = (product: Product): number => {
  const variants = product.variants || [];

  const allLocationInventoryPricing = [
    ...product.locationInventoryPricing,
    ...variants.flatMap((v) => v.locationInventoryPricing),
  ];

  return minBy(allLocationInventoryPricing.map((i) => i.retailPrice)) || 0;
};
