import { useFormContext } from "react-hook-form";
import { SetupNative2faFormValues } from "../../types";
import { Box, TextField, Typography } from "@mui/material";
import { Stack } from "../../../../../../components";
import { mfaStrategyInfoMap } from "../../constants";
import { Button } from "../../../../../../components/Button";

export const EnterMfaIdentifierFieldset = () => {
  const formContext = useFormContext<SetupNative2faFormValues>();
  const { register, watch, formState } = formContext;
  const { errors, isValid, isDirty, isSubmitting } = formState;
  const isDisabled = !isValid || !isDirty || isSubmitting;

  const strategy = watch("data.strategy");
  const strategyData = mfaStrategyInfoMap[strategy];

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h6">Enter details</Typography>
          <Typography variant="body1">
            {strategyData?.identifierInputDescription}.
          </Typography>
        </Box>
        <Box>
          <TextField
            error={Boolean(errors?.data?.identifier?.message)}
            helperText={errors?.data?.identifier?.message}
            fullWidth
            {...register("data.identifier")}
          />
        </Box>
        {errors.root?.serverError &&
          errors.root.serverError.message !== `MFA_REQUIRED_ERROR` && (
            <Typography color="error">
              {errors.root.serverError.message}
            </Typography>
          )}
        <Box sx={{ mt: 1, width: "100%" }}>
          <Button
            variant="tile"
            type="submit"
            color="primary"
            size="large"
            sx={{ alignSelf: "flex-end" }}
            disabled={isDisabled}
            fullWidth
          >
            <Typography fontWeight="bold">Send Code</Typography>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
