import { Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { GlobalSettings } from "@towersystems/roam-common/lib/generated-types";
import { Controller, useForm } from "react-hook-form";
import {
  ContextualSaveBar,
  FormLayout,
  Select,
  Stack,
} from "../../../../components";
import { normalizeSelectedValues } from "../../../../utilities/transforms";
import { RemovalAiSettingCard } from "../RemovalAiSettingCard";
import { RemovalAiSettingsSettingCard } from "../RemovalAiSettingsSettingCard";
import { BackgroundEraserFormValues } from "./types";
import { SettingCard } from "../SettingCard";

export interface BackgroundEraserFormProps {
  backgroundEraserFormValues?: BackgroundEraserFormValues;
  onSubmit: (
    input: BackgroundEraserFormValues
  ) => Promise<
    | Pick<GlobalSettings, "automaticBackgroundRemoval" | "removalAiDetails">
    | undefined
  >;
}

const defaultFormValues = {
  aiApiKey: "",
  bgApiKey: "",
  automaticBackgroundRemoval: false,
  bgRemovalStrategy: "RemovalBgStrategy",
};

export function BackgroundEraserForm({
  backgroundEraserFormValues,
  onSubmit,
}: BackgroundEraserFormProps) {
  const methods = useForm<BackgroundEraserFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: normalizeSelectedValues(
      defaultFormValues,
      backgroundEraserFormValues
    ),
  });

  const { control, formState, register, handleSubmit, watch } = methods;
  const { errors, isDirty } = formState;

  const watchStrategy = watch("bgRemovalStrategy", "RemovalBgStrategy");

  function handleLocalSubmit(input: BackgroundEraserFormValues) {
    onSubmit(input)
      .then((res) => {
        methods.reset(res);
      })
      .catch(() => {});
  }

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{ onAction: handleSubmit(handleLocalSubmit) }}
      cancelAction={{ onAction: () => methods.reset() }}
    />
  ) : undefined;

  return (
    <>
      {contextualSaveBar}
      <Stack spacing={3}>
        <SettingCard
          wrapped
          title="Removal Strategy"
          description={
            "Select the strategy you would like to use to remove the background from your product images."
          }
        >
          <Controller
            control={methods.control}
            name="bgRemovalStrategy"
            render={({ field: { onChange, value } }) => (
              <Select
                label="Select Cash Managed Payment Method"
                fullWidth
                placeholder="Select Cash Managed Payment Method"
                leadingChildren={
                  <MenuItem disabled value="">
                    <em>Select Strategy</em>
                  </MenuItem>
                }
                value={value}
                onChange={onChange}
                options={[
                  {
                    label: "Removal AI",
                    value: "RemovalAiStrategy",
                  },
                  {
                    label: "Remove.bg",
                    value: "RemovalBgStrategy",
                  },
                ]}
              ></Select>
            )}
          />
        </SettingCard>
        {watchStrategy == "RemovalBgStrategy" ? (
          <RemovalAiSettingCard strategy="RemovalBgStrategy">
            <TextField
              label="BG API Key"
              fullWidth
              {...register("bgApiKey", { shouldUnregister: true })}
            />
          </RemovalAiSettingCard>
        ) : (
          <RemovalAiSettingCard strategy="RemovalAiStrategy">
            <TextField
              label="AI API Key"
              fullWidth
              {...register("aiApiKey", { shouldUnregister: true })}
            />
          </RemovalAiSettingCard>
        )}
        <RemovalAiSettingsSettingCard>
          <FormLayout>
            <FormControlLabel
              control={
                <Controller
                  name="automaticBackgroundRemoval"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      color="primary"
                    />
                  )}
                />
              }
              label="Automatic Background Removal"
              labelPlacement="end"
            />
          </FormLayout>
        </RemovalAiSettingsSettingCard>
      </Stack>
    </>
  );
}
