import { SettingCard } from "../SettingCard";

export interface CashManagedSettingCardProps {
  children?: React.ReactNode;
}

export function CashManagedSettingCard({
  children,
}: CashManagedSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Cash Managed Payment Method"
      description="This is the payment method that will be used for cash managed transactions."
    >
      {children}
    </SettingCard>
  );
}
