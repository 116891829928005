import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { Page } from "components";
import { EditLocationForm, LocationPageSkeleton } from "../../components";

export const EditLocationPage = () => {
  const navigate = useNavigate();
  const { data }: any = useLoaderData();

  return (
    <React.Suspense fallback={<LocationPageSkeleton />}>
      <Await resolve={data}>
        {(location) => {
          return (
            <Page
              title={location.name}
              titleDescription="Edit Location"
              goBack={{ onAction: () => navigate("/settings/locations") }}
            >
              <EditLocationForm {...{ location }} />
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
