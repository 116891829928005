import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, Paper, TextField } from "@mui/material";
import { Page } from "components";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { SearchContext } from "../../../../utilities/search/context";
import { useSearch } from "../../../../utilities/search/hooks";
import { useModal } from "../../../../utilities/use-modal";
import { CreateProductSheet } from "../../components";
import { AllProductsTabView } from "./components";
import { InactiveProductsTabView } from "./components/InactiveProductsTabView";

export const ProductListPage = () => {
  const search = useSearch();

  const modal = useModal();

  const toolbarMarkup = (
    <Box>
      <TextField
        value={search.query}
        onChange={(e) => search.setQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search products"
        fullWidth
        className="CardSearchInput"
        variant="standard"
      ></TextField>
    </Box>
  );

  return (
    <SearchContext.Provider value={search}>
      <Page title="Products" titleDescription="Manage your products.">
        <Paper variant="outlined">
          {toolbarMarkup}
          <Tabs>
            <TabList>
              <Tab>All Products</Tab>
              <Tab>Inactive Products</Tab>
            </TabList>
            <TabPanel>
              <AllProductsTabView />
            </TabPanel>
            <TabPanel>
              <InactiveProductsTabView />
            </TabPanel>
          </Tabs>
        </Paper>
        <CreateProductSheet {...modal} onSubmitSuccess={() => {}} />
      </Page>
    </SearchContext.Provider>
  );
};
