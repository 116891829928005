import { gql } from "@apollo/client";

export const QUERY_SUM_REVENUE_TOTAL_SALES_ANALYTICS = gql`
  query getSumRevenueTotalSalesAnalytics($options: QuerySalesAnalyticsOptions) {
    getSumRevenueTotalSalesAnalytics(options: $options)
  }
`;

export const QUERY_COUNT_TOTAL_SALES_ANALYTICS = gql`
  query getCountTotalSalesAnalytics($options: QuerySalesAnalyticsOptions) {
    getCountTotalSalesAnalytics(options: $options)
  }
`;

export const QUERY_COUNT_ITEMS_SOLD_ANALYTICS = gql`
  query getCountItemsSoldAnalytics($options: QuerySalesAnalyticsOptions) {
    getCountItemsSoldAnalytics(options: $options)
  }
`;

export const QUERY_TOP_10_PRODUCTS_BY_SOLD_ANALYTICS = gql`
  query getTop10ProductsBySoldAnalytics($options: QuerySalesAnalyticsOptions) {
    getTop10ProductsBySoldAnalytics(options: $options) {
      name
      sku
      id
      value
      imageUrl
    }
  }
`;
