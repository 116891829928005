import { createContext } from "react";
import { Remove2faContextType, SetupNative2faContextType } from "./types";

export const SetupNative2faContext = createContext<
  SetupNative2faContextType | undefined
>(undefined);

export const Remove2faContext = createContext<Remove2faContextType | undefined>(
  undefined
);
