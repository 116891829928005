import { Product } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";
import { moneyToFormattedString } from "../../../../utilities/money";

export interface ProductPricingDetailsCardProps {
  product: Product;
}

export const ProductPricingDetailsCard = ({
  product,
}: ProductPricingDetailsCardProps) => {
  return (
    <CardDetailList
      title="Retail Pricing"
      emptyComponent="No Pricing."
      items={product.locationInventoryPricing.map((i) => {
        return {
          key: i.id,
          title: i.location?.name || "-",
          value: moneyToFormattedString(i.retailPrice || "0"),
        };
      })}
    ></CardDetailList>
  );
};
