import { TextField } from "@mui/material";
import { ContextualSaveBar, Stack } from "../../../../components";
import { useFormContext } from "react-hook-form";
import { UpdatePasswordFormValues } from "./types";

export interface UpdatePasswordFormProps {
  onSubmit: (values: UpdatePasswordFormValues) => unknown | Promise<unknown>;
}

export const UpdatePasswordForm = ({ onSubmit }: UpdatePasswordFormProps) => {
  const { register, reset, handleSubmit, formState } =
    useFormContext<UpdatePasswordFormValues>();

  const { errors, isDirty, isValid, isSubmitting } = formState;
  const disabled = !isValid || !isDirty || isSubmitting;

  console.log(isValid, errors);
  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit(onSubmit),
        disabled: disabled,
      }}
      cancelAction={{ onAction: () => reset() }}
    />
  ) : undefined;

  return (
    <>
      <Stack spacing={2}>
        <TextField
          type="password"
          label="Old Password"
          fullWidth
          {...register("oldPassword")}
          error={Boolean(errors.oldPassword)}
          helperText={errors.oldPassword?.message}
        />
        <TextField
          type="password"
          label="New Password"
          fullWidth
          {...register("newPassword")}
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword?.message}
        />

        <TextField
          type="password"
          label="Confirm New Password"
          fullWidth
          {...register("confirmPassword")}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message}
        />
      </Stack>
      {contextualSaveBar}
    </>
  );
};
