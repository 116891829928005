import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Staff } from "@towersystems/roam-common/lib/generated-types";
import { Badge } from "../../../../components/Badge";
import { NavigateRightButton } from "../../../../components/NavigateRightButton";
import { SettingRowSkeleton } from "../../../../components";

export interface StaffsListProps {
  items?: Staff[];
  loading?: boolean;
}

const NUM_LOADING_PLACEHOLDERS = 5;

export const StaffsList = ({ loading, items }: StaffsListProps) => {
  const navigate = useNavigate();

  const rows = loading
    ? Array.from({ length: NUM_LOADING_PLACEHOLDERS }).map((_, i) => (
        <SettingRowSkeleton key={i} />
      ))
    : items?.map((i) => {
        const rolesStrings = i.user?.roles.map((role) => role.code);

        const formattedRolesString = rolesStrings
          ? `${rolesStrings.join(", ")}`
          : undefined;

        return (
          <TableRow
            key={i.id}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/settings/staff/edit/${i.id}`)}
          >
            <TableCell width={"100%"}>
              <Typography fontWeight="bold">
                {i.user ? i.username : i.initials}
              </Typography>
              <Typography variant="subdued">{i.fullName}</Typography>
            </TableCell>
            <TableCell>
              {formattedRolesString && (
                <Typography whiteSpace="nowrap">
                  {formattedRolesString}
                </Typography>
              )}
            </TableCell>
            <TableCell />
            <TableCell align="right">
              <Stack direction="row" justifyContent={"flex-end"}>
                <Badge state={i.enabled ? "active" : "disabled"} />
                <NavigateRightButton />
              </Stack>
            </TableCell>
          </TableRow>
        );
      });

  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
