import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Terminal } from "@towersystems/roam-common/lib/generated-types";
import { useNavigate } from "react-router-dom";
import { Stack } from "../../../../components/Stack";
import { Badge } from "../../../../components/Badge";
import { NavigateRightButton } from "../../../../components/NavigateRightButton";
import { SettingRowSkeleton } from "../../../../components";

export interface TerminalsListProps {
  items?: Terminal[];
  loading?: boolean;
}

const NUM_LOADING_PLACEHOLDERS = 5;

export const TerminalsList = ({ loading, items }: TerminalsListProps) => {
  const navigate = useNavigate();

  const rows = loading
    ? Array.from({ length: NUM_LOADING_PLACEHOLDERS }).map((_, i) => (
        <SettingRowSkeleton key={i} />
      ))
    : items?.map((i) => {
        const terminalClosed =
          Boolean(i.terminalCloseTime) || !i.openTerminalClosureId;
        const terminalOpen = !terminalClosed;

        const closedMarkup = terminalClosed ? (
          i.terminalCloseTime ? (
            <Typography variant="subdued">
              Closed at {new Date(i.terminalCloseTime).toLocaleString()}
            </Typography>
          ) : undefined
        ) : terminalOpen ? (
          <Typography variant="subdued">{`Opened ${new Date(
            i.terminalOpenTime
          ).toLocaleString()}`}</Typography>
        ) : null;

        return (
          <TableRow
            key={i.id}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/settings/terminals/edit/${i.id}`)}
          >
            <TableCell width={"100%"}>
              <Typography fontWeight="bold">{i.name}</Typography>
              {closedMarkup}
            </TableCell>
            <TableCell />

            <TableCell align="right">
              <Stack direction="row" justifyContent={"flex-end"}>
                <Badge state={i.enabled ? "active" : "disabled"} />
                <NavigateRightButton />
              </Stack>
            </TableCell>
          </TableRow>
        );
      });

  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
