import { useFormContext } from "react-hook-form";
import { MigrateStaffFormValues } from "../../types";
import { SettingCard } from "../../../SettingCard";
import { FormLayout } from "../../../../../../components";
import { TextField } from "@mui/material";

export interface AccountIdentityFieldsetProps {
  passwordfieldName?: string;
}

export const AccountIdentityFieldset = ({}: AccountIdentityFieldsetProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<MigrateStaffFormValues>();
  return (
    <SettingCard
      wrapped
      title="Account Identity"
      description="Account information for this staff member."
    >
      <FormLayout>
        <TextField
          label="Username"
          required
          fullWidth
          {...register("username")}
          error={Boolean(errors.username)}
          helperText={errors.username?.message}
        />
        <TextField
          label="Email Address"
          fullWidth
          {...register("emailAddress")}
          error={Boolean(errors.emailAddress)}
          helperText={errors.emailAddress?.message}
        />
      </FormLayout>
    </SettingCard>
  );
};
