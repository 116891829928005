import { gql } from "@apollo/client";
import {
  TerminalListFragment,
  TerminalNoNestingFragment,
  PaginatedListMetaNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as Yup from "yup";

export const QUERY_TERMINALS_LIST = gql`
  ${TerminalListFragment}
  ${PaginatedListMetaNoNestingFragment}
  ${TerminalNoNestingFragment}
  query terminals($options: TerminalListOptions) {
    terminals(options: $options) {
      ...TerminalList
    }
  }
`;

export const QUERY_TERMINAL = gql`
  ${TerminalNoNestingFragment}
  query terminal($id: ID!) {
    terminal(id: $id) {
      ...TerminalNoNesting
    }
  }
`;

export const MUTATION_UPDATE_TERMINAL = gql`
  ${TerminalNoNestingFragment}
  mutation updateTerminal($input: UpdateTerminalInput!) {
    updateTerminal(input: $input) {
      ...TerminalNoNesting
    }
  }
`;

export const MUTATION_CREATE_TERMINAL = gql`
  ${TerminalNoNestingFragment}
  mutation createTerminal($input: CreateTerminalInput!) {
    createTerminal(input: $input) {
      ...TerminalNoNesting
    }
  }
`;

export const defaultTerminalFormValues = {
  id: "",
  name: "",
  invoiceSequence: 1,
  saleSufix: "",
  salePrefix: "",
  enabled: false,
  paymentMethodIds: [],
};

export const terminalFormSchema = Yup.object({
  name: Yup.string().required(),
  invoiceSequence: Yup.number().required().positive(),
});

export const TerminalFormSections = {
  DETAILS: "Terminal Information",
  INVOICE: "Invoice Configuration",
  PAYMENTS: "Payment Methods Configuration",
  SETTINGS: "Terminal Settings",
} as const;
