import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
  styled,
} from "@mui/material";

const StyledButton = styled(BaseButton, {
  shouldForwardProp: (prop) => !["compact"].includes(prop as any),
})<
  BaseButtonProps & {
    bgColor?: BaseButtonProps["color"];
    compact?: boolean;
    loading?: boolean;
  }
>(({ variant, theme, color, bgColor = "secondary", size, compact }) => {
  const isText = variant === "text";
  return {
    //@ts-ignore
    color: theme.palette[color]?.main || theme.palette.text.secondary,
    //@ts-ignore
    background: isText
      ? "transparent"
      : //@ts-ignore
        theme.palette[bgColor]?.main || bgColor,
    paddingLeft: isText ? 5 : 20,
    paddingRight: isText ? 5 : 20,
    minWidth: compact ? 0 : 150,
    textTransform: "none",
    minHeight: size === "large" ? 60 : 36,
  };
});

export const Button = StyledButton;
