import { QueryHookOptions, useQuery } from "@apollo/client";
import { ReactNode } from "react";
import styles from "./AnalyticTile.module.scss";
import { Box, Typography } from "@mui/material";
import { Skeleton } from "../../../../components";

export interface AnalyticTileProps {
  title: string;
  query: any;
  options: QueryHookOptions<any>;
  formatter: (data: any) => ReactNode;
}

export const AnalyticTile = ({
  title,
  formatter,
  options,
  query,
}: AnalyticTileProps) => {
  const { data, loading } = useQuery(query, {
    ...options,
  });

  const result = data ? Object.values(data)[0] : undefined;

  const formattedResultMarkup =
    result !== undefined ? <span>{formatter(result)}</span> : undefined;

  const titleMarkup = (
    <Box
      flex={1}
      display={"flex"}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        {loading ? (
          <Skeleton width="50%" />
        ) : (
          <Typography fontSize="large">{title}</Typography>
        )}
      </Box>
      <Box>
        {loading ? (
          <Skeleton width="35%" />
        ) : (
          <Typography fontSize="x-large">{formattedResultMarkup}</Typography>
        )}
      </Box>
    </Box>
  );

  return <Box className={styles.AnalyticTile}>{titleMarkup}</Box>;
};
