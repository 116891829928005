// tslint:disable
// THIS FILE HAS BEEN AUTO-GENERATED BY "graphql-fragments-plugin"
// DO NOT EDIT THIS FILE DIRECTLY

export const AccessTokenSessionNoNestingFragment = `fragment AccessTokenSessionNoNesting on AccessTokenSession {
  id
  name
  userId
  token
  expires
  invalidated
  createdAt
  updatedAt
  deletedAt
}
`

export const AnalyticProductMetaDataItemNoNestingFragment = `fragment AnalyticProductMetaDataItemNoNesting on AnalyticProductMetaDataItem {
  name
  sku
  id
  value
  imageUrl
}
`

export const AuthenticationMethodNoNestingFragment = `fragment AuthenticationMethodNoNesting on AuthenticationMethod {
  id
  createdAt
  updatedAt
  strategy
}
`

export const AuthenticationResultNoNestingFragment = `fragment AuthenticationResultNoNesting on AuthenticationResult {
  talinkId
  userId
  token
}
`

export const BackgroundRemoverInsufficientCreditsErrorNoNestingFragment = `fragment BackgroundRemoverInsufficientCreditsErrorNoNesting on BackgroundRemoverInsufficientCreditsError {
  errorCode
  message
}
`

export const BackgroundRemoverNotConfiguredErrorNoNestingFragment = `fragment BackgroundRemoverNotConfiguredErrorNoNesting on BackgroundRemoverNotConfiguredError {
  errorCode
  message
}
`

export const CashMovementNoNestingFragment = `fragment CashMovementNoNesting on CashMovement {
  id
  createdAt
  updatedAt
  openTerminalClosureId
  amount
  userId
  userIdentifier
  type
  note
  date
}
`

export const CategoryNoNestingFragment = `fragment CategoryNoNesting on Category {
  id
  description
  name
  bannerImageUrl
  active
  departmentId
  numberOfProducts
  createdAt
  updatedAt
  deletedAt
}
`

export const ConfigArgNoNestingFragment = `fragment ConfigArgNoNesting on ConfigArg {
  name
  value
}
`

export const ConfigArgDefinitionNoNestingFragment = `fragment ConfigArgDefinitionNoNesting on ConfigArgDefinition {
  name
  type
  list
  required
  defaultValue
  label
  description
}
`

export const ConfigurableOperationNoNestingFragment = `fragment ConfigurableOperationNoNesting on ConfigurableOperation {
  code
}
`

export const ConfigurableOperationDefinitionNoNestingFragment = `fragment ConfigurableOperationDefinitionNoNesting on ConfigurableOperationDefinition {
  code
  description
}
`

export const CurrencyTypeNoNestingFragment = `fragment CurrencyTypeNoNesting on CurrencyType {
  code
  symbol
}
`

export const CurrentUserV2NoNestingFragment = `fragment CurrentUserV2NoNesting on CurrentUserV2 {
  id
  identifier
  token
  permissions
  restrictedTerminalIds
}
`

export const CustomerNoNestingFragment = `fragment CustomerNoNesting on Customer {
  id
  firstName
  lastName
  gender
  emailAddress
  mobileNumber
  phoneNumber
  retailerId
  isRetailerCusAccount
  amountSpend
  invoicedAmount
  numberOfOrders
  loyaltyPoints
  createdAt
  updatedAt
  deletedAt
}
`

export const CustomerAddressNoNestingFragment = `fragment CustomerAddressNoNesting on CustomerAddress {
  id
  isPrimary
  address1
  address2
  postcode
  city
  state
  country
  createdAt
  updatedAt
  deletedAt
}
`

export const DeletedNoNestingFragment = `fragment DeletedNoNesting on Deleted {
  result
}
`

export const DeletionResponseNoNestingFragment = `fragment DeletionResponseNoNesting on DeletionResponse {
  result
  reason
}
`

export const DepartmentNoNestingFragment = `fragment DepartmentNoNesting on Department {
  id
  description
  name
  bannerImageUrl
  numberOfCategories
  numberOfProducts
  active
  createdAt
  updatedAt
  deletedAt
}
`

export const DownloadReceiptPdfBase64StringResponseNoNestingFragment = `fragment DownloadReceiptPdfBase64StringResponseNoNesting on DownloadReceiptPdfBase64StringResponse {
  data
  fileName
}
`

export const DownloadStocktakeCsvBase64StringResponseNoNestingFragment = `fragment DownloadStocktakeCsvBase64StringResponseNoNesting on DownloadStocktakeCsvBase64StringResponse {
  data
  fileName
}
`

export const EmailAddressConflictErrorNoNestingFragment = `fragment EmailAddressConflictErrorNoNesting on EmailAddressConflictError {
  errorCode
  message
}
`

export const FeaturesListNoNestingFragment = `fragment FeaturesListNoNesting on FeaturesList {
  utilities
  pointOfSale
  selfCheckout
  stocktake
}
`

export const FilterValueMetaNoNestingFragment = `fragment FilterValueMetaNoNesting on FilterValueMeta {
  type
  value
  label
}
`

export const GlobalSettingsNoNestingFragment = `fragment GlobalSettingsNoNesting on GlobalSettings {
  cashManagedPaymentMethodId
  taxPercentage
  countryCode
  timezone
  businessName
  address1
  address2
  email
  phone
  website
  postcode
  abn
  talinkId
  productSurchargeId
  laybyEnabled
  defaultLaybyDepositPercentage
  minLaybySpend
  defaultTerminalClosureFloat
  automaticOpenTerminalClosure
  showExpectedClosureCounts
  bgRemovalStrategy
  automaticBackgroundRemoval
  stocktakeSendToAddress
  automaticallyEmailStockTake
  automaticallyEmailTerminalClosureSummary
  terminalClosureSendToAddress
}
`

export const InsufficientStockErrorNoNestingFragment = `fragment InsufficientStockErrorNoNesting on InsufficientStockError {
  errorCode
  message
  quantityAvailable
}
`

export const InvalidCredentialsErrorNoNestingFragment = `fragment InvalidCredentialsErrorNoNesting on InvalidCredentialsError {
  errorCode
  message
  authenticationError
}
`

export const InvalidLinklyCredentialsErrorNoNestingFragment = `fragment InvalidLinklyCredentialsErrorNoNesting on InvalidLinklyCredentialsError {
  errorCode
  message
}
`

export const InvalidMfaCodeErrorNoNestingFragment = `fragment InvalidMfaCodeErrorNoNesting on InvalidMfaCodeError {
  errorCode
  message
}
`

export const InvalidMfaIdentifierErrorNoNestingFragment = `fragment InvalidMfaIdentifierErrorNoNesting on InvalidMfaIdentifierError {
  errorCode
  message
}
`

export const InvalidMfaStrategyErrorNoNestingFragment = `fragment InvalidMfaStrategyErrorNoNesting on InvalidMfaStrategyError {
  errorCode
  message
}
`

export const InventoryItemNoNestingFragment = `fragment InventoryItemNoNesting on InventoryItem {
  id
  tracked
  productId
  createdAt
  updatedAt
  deletedAt
}
`

export const InventoryLevelNoNestingFragment = `fragment InventoryLevelNoNesting on InventoryLevel {
  id
  locationId
  onHand
  unitsPerPack
  inventoryItemId
  createdAt
  updatedAt
  deletedAt
}
`

export const InventoryLevelMovementNoNestingFragment = `fragment InventoryLevelMovementNoNesting on InventoryLevelMovement {
  id
  inventoryLevelId
  quantity
  adjustment
  userId
  createdAt
  updatedAt
  deletedAt
}
`

export const InventoryMovementNoNestingFragment = `fragment InventoryMovementNoNesting on InventoryMovement {
  id
  locationId
  userId
  targetLocationId
  name
  inventoryMovementDate
  referenceNo
  dueAt
  receivedAt
  sentAt
  dispatchedAt
  cancelledAt
  completedAt
  status
  isFullStocktake
  createdAt
  updatedAt
  deletedAt
}
`

export const InventoryMovementFilterNoNestingFragment = `fragment InventoryMovementFilterNoNesting on InventoryMovementFilter {
  type
  value
}
`

export const InventoryMovementItemNoNestingFragment = `fragment InventoryMovementItemNoNesting on InventoryMovementItem {
  id
  inventoryMovementId
  productId
  stockCode
  counted
  initialExpected
  expected
  cost
  status
  manual
  createdAt
  updatedAt
  deletedAt
}
`

export const InventoryMovementItemSummaryTotalsNoNestingFragment = `fragment InventoryMovementItemSummaryTotalsNoNesting on InventoryMovementItemSummaryTotals {
  inventoryMovementItemId
  stockNumber
  received
  counted
  price
  productId
  productName
  totalCost
}
`

export const InventoryMovementSummaryTotalNoNestingFragment = `fragment InventoryMovementSummaryTotalNoNesting on InventoryMovementSummaryTotal {
  cost
  received
  counted
}
`

export const InventoryMovementTotalsNoNestingFragment = `fragment InventoryMovementTotalsNoNesting on InventoryMovementTotals {
  totalCountGain
  totalCostGain
  totalCountLoss
  totalCostLoss
}
`

export const LayoutNoNestingFragment = `fragment LayoutNoNesting on Layout {
  id
  terminalId
  createdAt
  updatedAt
  deletedAt
}
`

export const LayoutNodeNoNestingFragment = `fragment LayoutNodeNoNesting on LayoutNode {
  id
  label
  color
  layoutId
  productId
  showImage
  type
  position
  action
  createdAt
  updatedAt
  deletedAt
}
`

export const LayoutNodeFilterNoNestingFragment = `fragment LayoutNodeFilterNoNesting on LayoutNodeFilter {
  type
  values
}
`

export const LayoutNodeLinkNoNestingFragment = `fragment LayoutNodeLinkNoNesting on LayoutNodeLink {
  url
  newTab
}
`

export const LinklyConfigurationNoNestingFragment = `fragment LinklyConfigurationNoNesting on LinklyConfiguration {
  id
  paymentMethodId
  receiptAutoPrint
  createdAt
  updatedAt
  deletedAt
}
`

export const LinklyErrorNoNestingFragment = `fragment LinklyErrorNoNesting on LinklyError {
  errorCode
  message
}
`

export const LinklyPairedPinpadNoNestingFragment = `fragment LinklyPairedPinpadNoNesting on LinklyPairedPinpad {
  id
  linklyConfigurationId
  name
  secret
  createdAt
  updatedAt
  deletedAt
}
`

export const LocationNoNestingFragment = `fragment LocationNoNesting on Location {
  id
  retailerLocationKey
  name
  businessName
  enabled
  createdAt
  updatedAt
}
`

export const MfaAlreadySetupErrorNoNestingFragment = `fragment MfaAlreadySetupErrorNoNesting on MfaAlreadySetupError {
  errorCode
  message
}
`

export const MfaMethodNoNestingFragment = `fragment MfaMethodNoNesting on MfaMethod {
  id
  createdAt
  updatedAt
  strategy
  identifier
  verified
}
`

export const MfaRequiredErrorNoNestingFragment = `fragment MfaRequiredErrorNoNesting on MfaRequiredError {
  errorCode
  message
  strategies
}
`

export const MimeTypeErrorNoNestingFragment = `fragment MimeTypeErrorNoNesting on MimeTypeError {
  errorCode
  message
  fileName
  mimeType
}
`

export const MissingPasswordErrorNoNestingFragment = `fragment MissingPasswordErrorNoNesting on MissingPasswordError {
  errorCode
  message
}
`

export const MovementItemProductSummaryNoNestingFragment = `fragment MovementItemProductSummaryNoNesting on MovementItemProductSummary {
  productName
  productId
  stockNumber
  stockCode
  onHand
}
`

export const NativeAuthStrategyErrorNoNestingFragment = `fragment NativeAuthStrategyErrorNoNesting on NativeAuthStrategyError {
  errorCode
  message
}
`

export const NegativeQuantityErrorNoNestingFragment = `fragment NegativeQuantityErrorNoNesting on NegativeQuantityError {
  errorCode
  message
}
`

export const NotVerifiedErrorNoNestingFragment = `fragment NotVerifiedErrorNoNesting on NotVerifiedError {
  errorCode
  message
}
`

export const PaginatedListMetaNoNestingFragment = `fragment PaginatedListMetaNoNesting on PaginatedListMeta {
  itemCount
  totalItems
  currentPage
}
`

export const PasswordAlreadySetErrorNoNestingFragment = `fragment PasswordAlreadySetErrorNoNesting on PasswordAlreadySetError {
  errorCode
  message
}
`

export const PasswordValidationErrorNoNestingFragment = `fragment PasswordValidationErrorNoNesting on PasswordValidationError {
  errorCode
  message
  validationErrorMessage
}
`

export const PayTypeNoNestingFragment = `fragment PayTypeNoNesting on PayType {
  id
  name
  subtext
  description
  logo
  internal
  platforms
  channels
  onlineOnly
  configurationUrl
  defaultGatewayUrl
  version
  gateway
  gatewayEditable
  available
  restricted
  createdAt
  updatedAt
  deletedAt
}
`

export const PayTypeGatewayConfigNoNestingFragment = `fragment PayTypeGatewayConfigNoNesting on PayTypeGatewayConfig {
  url
}
`

export const PayTypeRoundingConfigNoNestingFragment = `fragment PayTypeRoundingConfigNoNesting on PayTypeRoundingConfig {
  rounding
}
`

export const PaymentNoNestingFragment = `fragment PaymentNoNesting on Payment {
  id
  amount
  name
  label
  paymentDate
  paymentMethodId
  paymentTypeId
  transactionId
  state
  errorMessage
  metadata
}
`

export const PaymentMethodNoNestingFragment = `fragment PaymentMethodNoNesting on PaymentMethod {
  id
  name
  payTypeId
  retailerPayCode
  retailerPayTypeId
  enabled
  createdAt
  updatedAt
  deletedAt
}
`

export const ProductNoNestingFragment = `fragment ProductNoNesting on Product {
  id
  name
  taxable
  active
  description
  stockCode
  stockNumber
  variantParentId
  totalInventory
  hasVariants
  displayName
  totalVariants
  categoryId
  departmentId
  discountable
  internal
  adjustable
  price
  priceExcludingTax
  loyaltyPointEarnValue
  loyaltyPointRedeemValue
  serialNumberRequired
  createdAt
  updatedAt
  deletedAt
}
`

export const ProductIdResultNoNestingFragment = `fragment ProductIdResultNoNesting on ProductIdResult {
  productId
}
`

export const ProductImageNoNestingFragment = `fragment ProductImageNoNesting on ProductImage {
  id
  path
  name
  width
  height
  mineType
  position
  productId
  createdAt
  updatedAt
  deletedAt
}
`

export const ProductLocationInventoryPricingNoNestingFragment = `fragment ProductLocationInventoryPricingNoNesting on ProductLocationInventoryPricing {
  id
  productId
  locationId
  stockOnHand
  costPrice
  retailPrice
  rrPrice
  taxRatePercent
}
`

export const ProductOptionNoNestingFragment = `fragment ProductOptionNoNesting on ProductOption {
  id
  name
  position
  values
  createdAt
  updatedAt
}
`

export const ReceiptNoNestingFragment = `fragment ReceiptNoNesting on Receipt {
  id
  name
  defaultTemplateType
  enabled
  createdAt
  updatedAt
  deletedAt
}
`

export const ReceiptSettingsNoNestingFragment = `fragment ReceiptSettingsNoNesting on ReceiptSettings {
  header
  name
  footer
  displayCustomerDetails
  logoURL
}
`

export const ReceiptTemplateTypeNoNestingFragment = `fragment ReceiptTemplateTypeNoNesting on ReceiptTemplateType {
  id
  html
  css
  version
  createdAt
  updatedAt
  deletedAt
}
`

export const RemovalAiDetailsNoNestingFragment = `fragment RemovalAiDetailsNoNesting on RemovalAiDetails {
  apiKey
}
`

export const RemovalBgDetailsNoNestingFragment = `fragment RemovalBgDetailsNoNesting on RemovalBgDetails {
  apiKey
}
`

export const RemoveBackgroundBase64ResultNoNestingFragment = `fragment RemoveBackgroundBase64ResultNoNesting on RemoveBackgroundBase64Result {
  base64
}
`

export const RetailerExportCustomerNoNestingFragment = `fragment RetailerExportCustomerNoNesting on RetailerExportCustomer {
  FirstName
  Surname
  Email
  Phone
}
`

export const RetailerExportPaymentNoNestingFragment = `fragment RetailerExportPaymentNoNesting on RetailerExportPayment {
  PayCode
  Amount
  Tendered
}
`

export const RetailerExportSaleNoNestingFragment = `fragment RetailerExportSaleNoNesting on RetailerExportSale {
  SaleType
  SaleId
  OrderNumber
  SaleDate
  SaleStaff
  SaleTime
  SaleAmount
  SaleDiscount
  SaleTax
  SaleDeposit
  LocationKey
  SaleTerm
  CustID
  SaleCust
  SaleRound
  FreightCharge
  FreightTax
}
`

export const RetailerExportSaleItemNoNestingFragment = `fragment RetailerExportSaleItemNoNesting on RetailerExportSaleItem {
  TxnStock
  TxnItem
  TxnQty
  TxnUnitAmount
  TxnUnitDiscount
  TxnUnitTax
  TxnUnitCost
  TxnExtendedAmount
  TxnExtendedDiscount
  TxnExtendedTax
  TxnTotal
  TxnTotalCost
  TxnDiscountType
  SerialNumber
  TotalPointsEarned
  TotalPointsUsed
}
`

export const RetailerPayTypeNoNestingFragment = `fragment RetailerPayTypeNoNesting on RetailerPayType {
  id
  payCode
  payDescription
  payType
  payOver
  createdAt
  updatedAt
  deletedAt
}
`

export const RoleNoNestingFragment = `fragment RoleNoNesting on Role {
  id
  createdAt
  updatedAt
  code
  permissions
}
`

export const SaleNoNestingFragment = `fragment SaleNoNesting on Sale {
  id
  code
  receiptConfirmed
  receiptConfirmationUrl
  active
  terminalId
  locationId
  customerId
  staffId
  source
  receiptId
  state
  invoiceNumber
  invoiceSequence
  isReturn
  returnForId
  note
  totalCost
  totalTax
  totalPrice
  totalAdjustments
  totalAdjustmentPercentSaving
  balance
  saleDate
  metadata
  completedAt
  createdAt
  updatedAt
  deletedAt
}
`

export const SaleAddressNoNestingFragment = `fragment SaleAddressNoNesting on SaleAddress {
  fullName
  company
  street1
  street2
  city
  state
  postCode
  country
  countryCode
  phoneNumber
}
`

export const SaleAdjustmentNoNestingFragment = `fragment SaleAdjustmentNoNesting on SaleAdjustment {
  id
  type
  amount
  metadata
  unitLoyaltyPointsUsed
  createdAt
  updatedAt
}
`

export const SaleHistoryEntryNoNestingFragment = `fragment SaleHistoryEntryNoNesting on SaleHistoryEntry {
  id
  type
  userId
  createdAt
  updatedAt
  deletedAt
}
`

export const SaleLineItemNoNestingFragment = `fragment SaleLineItemNoNesting on SaleLineItem {
  id
  saleId
  productId
  stockNumber
  stockCode
  serialNumber
  basePrice
  basePriceInclTax
  price
  priceInclTax
  cost
  tax
  totalPriceInclusiveTax
  quantity
  totalBasePrice
  taxRatePercent
  totalBasePriceInclTax
  totalPrice
  totalCost
  totalTax
  totalAdjustments
  loyaltyPointEarned
  metadata
  createdAt
  updatedAt
  deletedAt
}
`

export const SaleLineItemEntryDataNoNestingFragment = `fragment SaleLineItemEntryDataNoNesting on SaleLineItemEntryData {
  productName
  stockNumber
  stockCode
  serialNumber
  basePrice
  basePriceInclTax
  price
  priceInclTax
  cost
  tax
  totalPriceInclusiveTax
  quantity
  totalBasePrice
  taxRatePercent
  totalBasePriceInclTax
  totalPrice
  totalCost
  totalTax
  totalAdjustments
  loyaltyPointEarned
  metadata
}
`

export const SaleModifiedCustomerAddedEntryDataNoNestingFragment = `fragment SaleModifiedCustomerAddedEntryDataNoNesting on SaleModifiedCustomerAddedEntryData {
  customerId
  emailAddress
}
`

export const SaleModifiedEntryDataNoNestingFragment = `fragment SaleModifiedEntryDataNoNesting on SaleModifiedEntryData {
  input
  oldEntity
}
`

export const SaleNoteEntryDataNoNestingFragment = `fragment SaleNoteEntryDataNoNesting on SaleNoteEntryData {
  note
}
`

export const SaleStateTransitionErrorNoNestingFragment = `fragment SaleStateTransitionErrorNoNesting on SaleStateTransitionError {
  errorCode
  message
  transitionError
  fromState
  toState
}
`

export const SaleStatusTransitionDataNoNestingFragment = `fragment SaleStatusTransitionDataNoNesting on SaleStatusTransitionData {
  from
  to
}
`

export const SearchResultItemNoNestingFragment = `fragment SearchResultItemNoNesting on SearchResultItem {
  id
  type
}
`

export const SelectedOptionNoNestingFragment = `fragment SelectedOptionNoNesting on SelectedOption {
  name
  value
}
`

export const SelfCheckoutLocationSettingsNoNestingFragment = `fragment SelfCheckoutLocationSettingsNoNesting on SelfCheckoutLocationSettings {
  locationId
  enabled
  terminalId
  onlineCheckoutEnabled
  offlineCheckoutEnabled
  payAtCounterHeading
  payAtCounterSubheading
  checkoutSuccessHeading
}
`

export const SelfCheckoutSettingsNoNestingFragment = `fragment SelfCheckoutSettingsNoNesting on SelfCheckoutSettings {
  enabled
  logoURL
  paymentMethodId
}
`

export const StaffNoNestingFragment = `fragment StaffNoNesting on Staff {
  id
  hasUserAccount
  userId
  username
  terminalIds
  emailAddress
  fullName
  initials
  enabled
  createdAt
  updatedAt
  deletedAt
}
`

export const StockNumberNotFoundErrorNoNestingFragment = `fragment StockNumberNotFoundErrorNoNesting on StockNumberNotFoundError {
  errorCode
  message
}
`

export const StocktakeItemNoNestingFragment = `fragment StocktakeItemNoNesting on StocktakeItem {
  id
  stocktakeId
  product
  stockCode
  counted
  initialExpected
  expected
  cost
  status
  manual
  createdAt
  updatedAt
  deletedAt
}
`

export const SuccessNoNestingFragment = `fragment SuccessNoNesting on Success {
  success
}
`

export const TenantNoNestingFragment = `fragment TenantNoNesting on Tenant {
  identifier
}
`

export const TerminalNoNestingFragment = `fragment TerminalNoNesting on Terminal {
  id
  name
  invoiceSequence
  openTerminalClosureId
  terminalOpenTime
  terminalCloseTime
  saleSufix
  salePrefix
  enabled
  paymentMethodIds
  defaultReceiptTemplateId
  printReceiptOnCheckout
  createdAt
  updatedAt
  deletedAt
}
`

export const TerminalClosureNoNestingFragment = `fragment TerminalClosureNoNesting on TerminalClosure {
  id
  terminalId
  terminalName
  terminalOpenDate
  terminalCloseDate
  isClosed
  estimatedCashBalance
  createdAt
  updatedAt
  deletedAt
}
`

export const TerminalClosurePaymentMethodSummaryNoNestingFragment = `fragment TerminalClosurePaymentMethodSummaryNoNesting on TerminalClosurePaymentMethodSummary {
  paymentMethodId
  payTypeId
  paymentTypeName
  total
  expected
  difference
}
`

export const TerminalClosurePaymentTotalNoNestingFragment = `fragment TerminalClosurePaymentTotalNoNesting on TerminalClosurePaymentTotal {
  paymentMethodId
  counted
}
`

export const TerminalClosurePaymentsSummaryNoNestingFragment = `fragment TerminalClosurePaymentsSummaryNoNesting on TerminalClosurePaymentsSummary {
  terminalOpenTime
  terminalId
  terminalClosureId
}
`

export const TerminalClosureSalesSummaryNoNestingFragment = `fragment TerminalClosureSalesSummaryNoNesting on TerminalClosureSalesSummary {
  totalSales
  totalInvoices
  totalLaybys
  totalPayments
  totalTaxes
  totalDiscounts
  totalRefunds
}
`

export const UserNoNestingFragment = `fragment UserNoNesting on User {
  id
  username
  emailAddress
  mobileNumber
  displayName
  numberAvailableTerminals
  userAccountType
  stripeAccountId
  stripeOnboardingComplete
}
`

export const UserV2NoNestingFragment = `fragment UserV2NoNesting on UserV2 {
  id
  createdAt
  updatedAt
  identifier
  enabled2fa
  verified
  lastLogin
}
`

export const VendorNoNestingFragment = `fragment VendorNoNesting on Vendor {
  id
  active
  talinkId
  talinkUsername
  numberAvailableTerminals
  userAccountType
  stripeOnboardingComplete
}
`


export const AccessTokenSessionFragment = `fragment AccessTokenSession on AccessTokenSession {
  id
  name
  userId
  token
  expires
  invalidated
  createdAt
  updatedAt
  deletedAt
}

`

export const AnalyticProductMetaDataItemFragment = `fragment AnalyticProductMetaDataItem on AnalyticProductMetaDataItem {
  name
  sku
  id
  value
  imageUrl
}

`

export const AuthenticationMethodFragment = `fragment AuthenticationMethod on AuthenticationMethod {
  id
  createdAt
  updatedAt
  strategy
}

`

export const AuthenticationResultFragment = `fragment AuthenticationResult on AuthenticationResult {
  talinkId
  userId
  token
}

`

export const BackgroundRemoverInsufficientCreditsErrorFragment = `fragment BackgroundRemoverInsufficientCreditsError on BackgroundRemoverInsufficientCreditsError {
  errorCode
  message
}

`

export const BackgroundRemoverNotConfiguredErrorFragment = `fragment BackgroundRemoverNotConfiguredError on BackgroundRemoverNotConfiguredError {
  errorCode
  message
}

`

export const CashMovementFragment = `fragment CashMovement on CashMovement {
  id
  createdAt
  updatedAt
  openTerminalClosureId
  amount
  userId
  userIdentifier
  type
  note
  date
}

`

export const CategoryFragment = `fragment Category on Category {
  id
  description
  name
  bannerImageUrl
  active
  departmentId
  numberOfProducts
  createdAt
  updatedAt
  deletedAt
}

`

export const CategoryListFragment = `fragment CategoryList on CategoryList {
  items {
    ...CategoryNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ConfigArgFragment = `fragment ConfigArg on ConfigArg {
  name
  value
}

`

export const ConfigArgDefinitionFragment = `fragment ConfigArgDefinition on ConfigArgDefinition {
  name
  type
  list
  required
  defaultValue
  label
  description
}

`

export const ConfigurableOperationFragment = `fragment ConfigurableOperation on ConfigurableOperation {
  code
  args {
    ...ConfigArgNoNesting
  }
}

`

export const ConfigurableOperationDefinitionFragment = `fragment ConfigurableOperationDefinition on ConfigurableOperationDefinition {
  code
  args {
    ...ConfigArgDefinitionNoNesting
  }
  description
}

`

export const CurrencyTypeFragment = `fragment CurrencyType on CurrencyType {
  code
  symbol
}

`

export const CurrentUserAndTenantFragment = `fragment CurrentUserAndTenant on CurrentUserAndTenant {
  currentUser {
    ...CurrentUserV2NoNesting
  }
  tenant {
    ...TenantNoNesting
  }
}

`

export const CurrentUserV2Fragment = `fragment CurrentUserV2 on CurrentUserV2 {
  id
  identifier
  token
  permissions
  restrictedTerminalIds
}

`

export const CustomerFragment = `fragment Customer on Customer {
  id
  firstName
  lastName
  gender
  emailAddress
  mobileNumber
  phoneNumber
  retailerId
  isRetailerCusAccount
  amountSpend
  invoicedAmount
  numberOfOrders
  addresses {
    ...CustomerAddressNoNesting
  }
  loyaltyPoints
  createdAt
  updatedAt
  deletedAt
}

`

export const CustomerAddressFragment = `fragment CustomerAddress on CustomerAddress {
  id
  isPrimary
  address1
  address2
  postcode
  city
  state
  country
  createdAt
  updatedAt
  deletedAt
}

`

export const CustomerListFragment = `fragment CustomerList on CustomerList {
  items {
    ...CustomerNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const DeletedFragment = `fragment Deleted on Deleted {
  result
}

`

export const DeletionResponseFragment = `fragment DeletionResponse on DeletionResponse {
  result
  reason
}

`

export const DepartmentFragment = `fragment Department on Department {
  id
  description
  name
  bannerImageUrl
  numberOfCategories
  numberOfProducts
  active
  createdAt
  updatedAt
  deletedAt
}

`

export const DepartmentListFragment = `fragment DepartmentList on DepartmentList {
  items {
    ...DepartmentNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const DownloadReceiptPdfBase64StringResponseFragment = `fragment DownloadReceiptPdfBase64StringResponse on DownloadReceiptPdfBase64StringResponse {
  data
  fileName
}

`

export const DownloadStocktakeCsvBase64StringResponseFragment = `fragment DownloadStocktakeCsvBase64StringResponse on DownloadStocktakeCsvBase64StringResponse {
  data
  fileName
}

`

export const EmailAddressConflictErrorFragment = `fragment EmailAddressConflictError on EmailAddressConflictError {
  errorCode
  message
}

`

export const FeaturesListFragment = `fragment FeaturesList on FeaturesList {
  utilities
  pointOfSale
  selfCheckout
  stocktake
}

`

export const FilterValueMetaFragment = `fragment FilterValueMeta on FilterValueMeta {
  type
  value
  label
}

`

export const GlobalSettingsFragment = `fragment GlobalSettings on GlobalSettings {
  cashManagedPaymentMethodId
  taxPercentage
  countryCode
  currency {
    ...CurrencyTypeNoNesting
  }
  timezone
  businessName
  address1
  address2
  email
  phone
  website
  postcode
  abn
  talinkId
  productSurchargeId
  laybyEnabled
  defaultLaybyDepositPercentage
  minLaybySpend
  defaultTerminalClosureFloat
  automaticOpenTerminalClosure
  showExpectedClosureCounts
  removalAiDetails {
    ...RemovalAiDetailsNoNesting
  }
  removalBgDetails {
    ...RemovalBgDetailsNoNesting
  }
  bgRemovalStrategy
  automaticBackgroundRemoval
  stocktakeSendToAddress
  automaticallyEmailStockTake
  automaticallyEmailTerminalClosureSummary
  terminalClosureSendToAddress
}

`

export const InsufficientStockErrorFragment = `fragment InsufficientStockError on InsufficientStockError {
  errorCode
  message
  quantityAvailable
  sale {
    ...SaleNoNesting
  }
}

`

export const InvalidCredentialsErrorFragment = `fragment InvalidCredentialsError on InvalidCredentialsError {
  errorCode
  message
  authenticationError
}

`

export const InvalidLinklyCredentialsErrorFragment = `fragment InvalidLinklyCredentialsError on InvalidLinklyCredentialsError {
  errorCode
  message
}

`

export const InvalidMfaCodeErrorFragment = `fragment InvalidMfaCodeError on InvalidMfaCodeError {
  errorCode
  message
}

`

export const InvalidMfaIdentifierErrorFragment = `fragment InvalidMfaIdentifierError on InvalidMfaIdentifierError {
  errorCode
  message
}

`

export const InvalidMfaStrategyErrorFragment = `fragment InvalidMfaStrategyError on InvalidMfaStrategyError {
  errorCode
  message
}

`

export const InventoryItemFragment = `fragment InventoryItem on InventoryItem {
  id
  tracked
  inventoryLevels {
    ...InventoryLevelNoNesting
  }
  productId
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryItemListFragment = `fragment InventoryItemList on InventoryItemList {
  items {
    ...InventoryItemNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryLevelFragment = `fragment InventoryLevel on InventoryLevel {
  id
  locationId
  onHand
  unitsPerPack
  inventoryItemId
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryLevelListFragment = `fragment InventoryLevelList on InventoryLevelList {
  items {
    ...InventoryLevelNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryLevelMovementFragment = `fragment InventoryLevelMovement on InventoryLevelMovement {
  id
  inventoryLevelId
  quantity
  adjustment
  userId
  user {
    ...UserNoNesting
  }
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryLevelMovementListFragment = `fragment InventoryLevelMovementList on InventoryLevelMovementList {
  items {
    ...InventoryLevelMovementNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryMovementFragment = `fragment InventoryMovement on InventoryMovement {
  id
  locationId
  location {
    ...LocationNoNesting
  }
  userId
  user {
    ...UserNoNesting
  }
  targetLocationId
  name
  inventoryMovementDate
  referenceNo
  dueAt
  receivedAt
  sentAt
  dispatchedAt
  cancelledAt
  completedAt
  status
  filters {
    ...InventoryMovementFilterNoNesting
  }
  totals {
    ...InventoryMovementTotalsNoNesting
  }
  isFullStocktake
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryMovementFilterFragment = `fragment InventoryMovementFilter on InventoryMovementFilter {
  type
  value
}

`

export const InventoryMovementItemFragment = `fragment InventoryMovementItem on InventoryMovementItem {
  id
  inventoryMovementId
  productId
  stockCode
  counted
  initialExpected
  expected
  cost
  status
  manual
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryMovementItemListFragment = `fragment InventoryMovementItemList on InventoryMovementItemList {
  items {
    ...InventoryMovementItemNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryMovementItemSummaryTotalsFragment = `fragment InventoryMovementItemSummaryTotals on InventoryMovementItemSummaryTotals {
  inventoryMovementItemId
  stockNumber
  received
  counted
  price
  productId
  productName
  totalCost
}

`

export const InventoryMovementListFragment = `fragment InventoryMovementList on InventoryMovementList {
  items {
    ...InventoryMovementNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const InventoryMovementSummaryTotalFragment = `fragment InventoryMovementSummaryTotal on InventoryMovementSummaryTotal {
  cost
  received
  counted
}

`

export const InventoryMovementSummaryTotalsFragment = `fragment InventoryMovementSummaryTotals on InventoryMovementSummaryTotals {
  totals {
    ...InventoryMovementSummaryTotalNoNesting
  }
  items {
    ...InventoryMovementItemSummaryTotalsNoNesting
  }
}

`

export const InventoryMovementTotalsFragment = `fragment InventoryMovementTotals on InventoryMovementTotals {
  totalCountGain
  totalCostGain
  totalCountLoss
  totalCostLoss
}

`

export const LayoutFragment = `fragment Layout on Layout {
  id
  nodes {
    ...LayoutNodeNoNesting
  }
  terminalId
  createdAt
  updatedAt
  deletedAt
}

`

export const LayoutListFragment = `fragment LayoutList on LayoutList {
  items {
    ...LayoutNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const LayoutNodeFragment = `fragment LayoutNode on LayoutNode {
  id
  label
  color
  layoutId
  productId
  showImage
  type
  position
  action
  filter {
    ...LayoutNodeFilterNoNesting
  }
  link {
    ...LayoutNodeLinkNoNesting
  }
  createdAt
  updatedAt
  deletedAt
}

`

export const LayoutNodeFilterFragment = `fragment LayoutNodeFilter on LayoutNodeFilter {
  type
  values
}

`

export const LayoutNodeLinkFragment = `fragment LayoutNodeLink on LayoutNodeLink {
  url
  newTab
}

`

export const LayoutNodeListFragment = `fragment LayoutNodeList on LayoutNodeList {
  items {
    ...LayoutNodeNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const LinklyConfigurationFragment = `fragment LinklyConfiguration on LinklyConfiguration {
  id
  paymentMethodId
  receiptAutoPrint
  pairedPinpads {
    ...LinklyPairedPinpadNoNesting
  }
  createdAt
  updatedAt
  deletedAt
}

`

export const LinklyErrorFragment = `fragment LinklyError on LinklyError {
  errorCode
  message
}

`

export const LinklyPairedPinpadFragment = `fragment LinklyPairedPinpad on LinklyPairedPinpad {
  id
  linklyConfigurationId
  name
  secret
  createdAt
  updatedAt
  deletedAt
}

`

export const LocationFragment = `fragment Location on Location {
  id
  retailerLocationKey
  name
  businessName
  enabled
  createdAt
  updatedAt
}

`

export const LocationListFragment = `fragment LocationList on LocationList {
  items {
    ...LocationNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const MfaAlreadySetupErrorFragment = `fragment MfaAlreadySetupError on MfaAlreadySetupError {
  errorCode
  message
}

`

export const MfaMethodFragment = `fragment MfaMethod on MfaMethod {
  id
  createdAt
  updatedAt
  strategy
  identifier
  verified
}

`

export const MfaRequiredErrorFragment = `fragment MfaRequiredError on MfaRequiredError {
  errorCode
  message
  strategies
}

`

export const MimeTypeErrorFragment = `fragment MimeTypeError on MimeTypeError {
  errorCode
  message
  fileName
  mimeType
}

`

export const MissingPasswordErrorFragment = `fragment MissingPasswordError on MissingPasswordError {
  errorCode
  message
}

`

export const MovementItemProductSummaryFragment = `fragment MovementItemProductSummary on MovementItemProductSummary {
  productName
  productId
  stockNumber
  stockCode
  onHand
}

`

export const NativeAuthStrategyErrorFragment = `fragment NativeAuthStrategyError on NativeAuthStrategyError {
  errorCode
  message
}

`

export const NegativeQuantityErrorFragment = `fragment NegativeQuantityError on NegativeQuantityError {
  errorCode
  message
}

`

export const NotVerifiedErrorFragment = `fragment NotVerifiedError on NotVerifiedError {
  errorCode
  message
}

`

export const PaginatedListMetaFragment = `fragment PaginatedListMeta on PaginatedListMeta {
  itemCount
  totalItems
  currentPage
}

`

export const PasswordAlreadySetErrorFragment = `fragment PasswordAlreadySetError on PasswordAlreadySetError {
  errorCode
  message
}

`

export const PasswordValidationErrorFragment = `fragment PasswordValidationError on PasswordValidationError {
  errorCode
  message
  validationErrorMessage
}

`

export const PayTypeFragment = `fragment PayType on PayType {
  id
  name
  subtext
  description
  logo
  internal
  platforms
  channels
  onlineOnly
  configurationUrl
  defaultGatewayUrl
  version
  gateway
  gatewayEditable
  available
  restricted
  createdAt
  updatedAt
  deletedAt
}

`

export const PayTypeGatewayConfigFragment = `fragment PayTypeGatewayConfig on PayTypeGatewayConfig {
  url
}

`

export const PayTypeRoundingConfigFragment = `fragment PayTypeRoundingConfig on PayTypeRoundingConfig {
  rounding
}

`

export const PaymentFragment = `fragment Payment on Payment {
  id
  amount
  name
  label
  paymentDate
  paymentMethodId
  paymentTypeId
  transactionId
  state
  errorMessage
  metadata
}

`

export const PaymentMethodFragment = `fragment PaymentMethod on PaymentMethod {
  id
  name
  payTypeId
  paytype {
    ...PayTypeNoNesting
  }
  retailerPayCode
  retailerPayTypeId
  enabled
  createdAt
  updatedAt
  deletedAt
}

`

export const PaymentMethodListFragment = `fragment PaymentMethodList on PaymentMethodList {
  items {
    ...PaymentMethodNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ProductFragment = `fragment Product on Product {
  id
  name
  taxable
  active
  description
  stockCode
  stockNumber
  variantParentId
  locationInventoryPricing {
    ...ProductLocationInventoryPricingNoNesting
  }
  totalInventory
  hasVariants
  displayName
  totalVariants
  categoryId
  departmentId
  category {
    ...CategoryNoNesting
  }
  department {
    ...DepartmentNoNesting
  }
  variants {
    ...ProductNoNesting
  }
  discountable
  options {
    ...ProductOptionNoNesting
  }
  image {
    ...ProductImageNoNesting
  }
  images {
    ...ProductImageNoNesting
  }
  selectedOptions {
    ...SelectedOptionNoNesting
  }
  internal
  adjustable
  price
  priceExcludingTax
  loyaltyPointEarnValue
  loyaltyPointRedeemValue
  serialNumberRequired
  createdAt
  updatedAt
  deletedAt
}

`

export const ProductIdResultFragment = `fragment ProductIdResult on ProductIdResult {
  productId
}

`

export const ProductImageFragment = `fragment ProductImage on ProductImage {
  id
  path
  name
  width
  height
  mineType
  position
  productId
  createdAt
  updatedAt
  deletedAt
}

`

export const ProductListFragment = `fragment ProductList on ProductList {
  items {
    ...ProductNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ProductLocationInventoryPricingFragment = `fragment ProductLocationInventoryPricing on ProductLocationInventoryPricing {
  id
  productId
  locationId
  location {
    ...LocationNoNesting
  }
  stockOnHand
  costPrice
  retailPrice
  rrPrice
  taxRatePercent
}

`

export const ProductOptionFragment = `fragment ProductOption on ProductOption {
  id
  name
  position
  values
  createdAt
  updatedAt
}

`

export const ReceiptFragment = `fragment Receipt on Receipt {
  id
  name
  defaultTemplateType
  settings {
    ...ReceiptSettingsNoNesting
  }
  enabled
  createdAt
  updatedAt
  deletedAt
}

`

export const ReceiptListFragment = `fragment ReceiptList on ReceiptList {
  items {
    ...ReceiptNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const ReceiptSettingsFragment = `fragment ReceiptSettings on ReceiptSettings {
  header
  name
  footer
  displayCustomerDetails
  logoURL
}

`

export const ReceiptTemplateTypeFragment = `fragment ReceiptTemplateType on ReceiptTemplateType {
  id
  html
  css
  version
  createdAt
  updatedAt
  deletedAt
}

`

export const RemovalAiDetailsFragment = `fragment RemovalAiDetails on RemovalAiDetails {
  apiKey
}

`

export const RemovalBgDetailsFragment = `fragment RemovalBgDetails on RemovalBgDetails {
  apiKey
}

`

export const RemoveBackgroundBase64ResultFragment = `fragment RemoveBackgroundBase64Result on RemoveBackgroundBase64Result {
  base64
}

`

export const RetailerExportCustomerFragment = `fragment RetailerExportCustomer on RetailerExportCustomer {
  FirstName
  Surname
  Email
  Phone
}

`

export const RetailerExportPaymentFragment = `fragment RetailerExportPayment on RetailerExportPayment {
  PayCode
  Amount
  Tendered
}

`

export const RetailerExportSaleFragment = `fragment RetailerExportSale on RetailerExportSale {
  SaleType
  SaleId
  OrderNumber
  SaleDate
  SaleStaff
  SaleTime
  SaleAmount
  SaleDiscount
  SaleTax
  SaleDeposit
  LocationKey
  SaleTerm
  CustID
  SaleCust
  SaleRound
  Payments {
    ...RetailerExportPaymentNoNesting
  }
  SaleItems {
    ...RetailerExportSaleItemNoNesting
  }
  Customer {
    ...RetailerExportCustomerNoNesting
  }
  FreightCharge
  FreightTax
}

`

export const RetailerExportSaleItemFragment = `fragment RetailerExportSaleItem on RetailerExportSaleItem {
  TxnStock
  TxnItem
  TxnQty
  TxnUnitAmount
  TxnUnitDiscount
  TxnUnitTax
  TxnUnitCost
  TxnExtendedAmount
  TxnExtendedDiscount
  TxnExtendedTax
  TxnTotal
  TxnTotalCost
  TxnDiscountType
  SerialNumber
  TotalPointsEarned
  TotalPointsUsed
}

`

export const RetailerPayTypeFragment = `fragment RetailerPayType on RetailerPayType {
  id
  payCode
  payDescription
  payType
  payOver
  createdAt
  updatedAt
  deletedAt
}

`

export const RetailerPayTypeListFragment = `fragment RetailerPayTypeList on RetailerPayTypeList {
  items {
    ...RetailerPayTypeNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const RoleFragment = `fragment Role on Role {
  id
  createdAt
  updatedAt
  code
  permissions
}

`

export const SaleFragment = `fragment Sale on Sale {
  id
  code
  receiptConfirmed
  receiptConfirmationUrl
  active
  terminalId
  terminal {
    ...TerminalNoNesting
  }
  locationId
  location {
    ...LocationNoNesting
  }
  customerId
  customer {
    ...CustomerNoNesting
  }
  staffId
  staff {
    ...StaffNoNesting
  }
  source
  receiptId
  state
  invoiceNumber
  invoiceSequence
  isReturn
  returnForId
  note
  totalCost
  totalTax
  totalPrice
  totalAdjustments
  totalAdjustmentPercentSaving
  balance
  lineItems {
    ...SaleLineItemNoNesting
  }
  historyEntries {
    ...SaleHistoryEntryNoNesting
  }
  payments {
    ...PaymentNoNesting
  }
  saleDate
  metadata
  completedAt
  createdAt
  updatedAt
  deletedAt
}

`

export const SaleAddressFragment = `fragment SaleAddress on SaleAddress {
  fullName
  company
  street1
  street2
  city
  state
  postCode
  country
  countryCode
  phoneNumber
}

`

export const SaleAdjustmentFragment = `fragment SaleAdjustment on SaleAdjustment {
  id
  type
  amount
  metadata
  unitLoyaltyPointsUsed
  createdAt
  updatedAt
}

`

export const SaleHistoryEntryFragment = `fragment SaleHistoryEntry on SaleHistoryEntry {
  id
  type
  userId
  user {
    ...UserNoNesting
  }
  createdAt
  updatedAt
  deletedAt
}

`

export const SaleLineItemFragment = `fragment SaleLineItem on SaleLineItem {
  id
  saleId
  productId
  product {
    ...ProductNoNesting
  }
  stockNumber
  stockCode
  serialNumber
  basePrice
  basePriceInclTax
  adjustments {
    ...SaleAdjustmentNoNesting
  }
  price
  priceInclTax
  cost
  tax
  totalPriceInclusiveTax
  quantity
  totalBasePrice
  taxRatePercent
  totalBasePriceInclTax
  shippingAddress {
    ...SaleAddressNoNesting
  }
  billingAddress {
    ...SaleAddressNoNesting
  }
  totalPrice
  totalCost
  totalTax
  totalAdjustments
  loyaltyPointEarned
  metadata
  createdAt
  updatedAt
  deletedAt
}

`

export const SaleLineItemEntryDataFragment = `fragment SaleLineItemEntryData on SaleLineItemEntryData {
  productName
  stockNumber
  stockCode
  serialNumber
  basePrice
  basePriceInclTax
  adjustments {
    ...SaleAdjustmentNoNesting
  }
  price
  priceInclTax
  cost
  tax
  totalPriceInclusiveTax
  quantity
  totalBasePrice
  taxRatePercent
  totalBasePriceInclTax
  totalPrice
  totalCost
  totalTax
  totalAdjustments
  loyaltyPointEarned
  metadata
}

`

export const SaleListFragment = `fragment SaleList on SaleList {
  items {
    ...SaleNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const SaleModifiedCustomerAddedEntryDataFragment = `fragment SaleModifiedCustomerAddedEntryData on SaleModifiedCustomerAddedEntryData {
  customerId
  emailAddress
}

`

export const SaleModifiedEntryDataFragment = `fragment SaleModifiedEntryData on SaleModifiedEntryData {
  input
  oldEntity
}

`

export const SaleNoteEntryDataFragment = `fragment SaleNoteEntryData on SaleNoteEntryData {
  note
}

`

export const SaleStateTransitionErrorFragment = `fragment SaleStateTransitionError on SaleStateTransitionError {
  errorCode
  message
  transitionError
  fromState
  toState
}

`

export const SaleStatusTransitionDataFragment = `fragment SaleStatusTransitionData on SaleStatusTransitionData {
  from
  to
}

`

export const SearchResponseFragment = `fragment SearchResponse on SearchResponse {
  items {
    ...SearchResultItemNoNesting
  }
}

`

export const SearchResultItemFragment = `fragment SearchResultItem on SearchResultItem {
  id
  type
}

`

export const SelectedOptionFragment = `fragment SelectedOption on SelectedOption {
  name
  value
}

`

export const SelfCheckoutLocationSettingsFragment = `fragment SelfCheckoutLocationSettings on SelfCheckoutLocationSettings {
  locationId
  location {
    ...LocationNoNesting
  }
  enabled
  terminalId
  terminal {
    ...TerminalNoNesting
  }
  onlineCheckoutEnabled
  offlineCheckoutEnabled
  payAtCounterHeading
  payAtCounterSubheading
  checkoutSuccessHeading
}

`

export const SelfCheckoutSettingsFragment = `fragment SelfCheckoutSettings on SelfCheckoutSettings {
  enabled
  locationSettings {
    ...SelfCheckoutLocationSettingsNoNesting
  }
  logoURL
  paymentMethod {
    ...PaymentMethodNoNesting
  }
  paymentMethodId
}

`

export const ServerConfigFragment = `fragment ServerConfig on ServerConfig {
  receiptTemplateTypes {
    ...ReceiptTemplateTypeNoNesting
  }
  availablePayTypes {
    ...PayTypeNoNesting
  }
  internalPayTypes {
    ...PayTypeNoNesting
  }
}

`

export const StaffFragment = `fragment Staff on Staff {
  id
  hasUserAccount
  userId
  user {
    ...UserV2NoNesting
  }
  username
  terminalIds
  terminals {
    ...TerminalNoNesting
  }
  emailAddress
  fullName
  initials
  enabled
  createdAt
  updatedAt
  deletedAt
}

`

export const StaffListFragment = `fragment StaffList on StaffList {
  items {
    ...StaffNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const StockNumberNotFoundErrorFragment = `fragment StockNumberNotFoundError on StockNumberNotFoundError {
  errorCode
  message
}

`

export const StocktakeItemFragment = `fragment StocktakeItem on StocktakeItem {
  id
  stocktakeId
  product
  stockCode
  counted
  initialExpected
  expected
  cost
  status
  manual
  createdAt
  updatedAt
  deletedAt
}

`

export const StocktakeItemListFragment = `fragment StocktakeItemList on StocktakeItemList {
  items {
    ...StocktakeItemNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const SuccessFragment = `fragment Success on Success {
  success
}

`

export const SwapLayoutNodesResultFragment = `fragment SwapLayoutNodesResult on SwapLayoutNodesResult {
  firstNode {
    ...LayoutNodeNoNesting
  }
  secondNode {
    ...LayoutNodeNoNesting
  }
}

`

export const TenantFragment = `fragment Tenant on Tenant {
  identifier
}

`

export const TerminalFragment = `fragment Terminal on Terminal {
  id
  name
  invoiceSequence
  openTerminalClosure {
    ...TerminalClosureNoNesting
  }
  openTerminalClosureId
  terminalOpenTime
  terminalCloseTime
  saleSufix
  salePrefix
  enabled
  paymentMethodIds
  defaultReceiptTemplateId
  printReceiptOnCheckout
  createdAt
  updatedAt
  deletedAt
}

`

export const TerminalClosureFragment = `fragment TerminalClosure on TerminalClosure {
  id
  terminalId
  terminalName
  terminalOpenDate
  terminalCloseDate
  isClosed
  payments {
    ...TerminalClosurePaymentTotalNoNesting
  }
  cashMovements {
    ...CashMovementNoNesting
  }
  saleSummary {
    ...TerminalClosureSalesSummaryNoNesting
  }
  countedPaymentsSummary {
    ...TerminalClosurePaymentMethodSummaryNoNesting
  }
  estimatedCashBalance
  createdAt
  updatedAt
  deletedAt
}

`

export const TerminalClosureListFragment = `fragment TerminalClosureList on TerminalClosureList {
  items {
    ...TerminalClosureNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const TerminalClosurePaymentMethodSummaryFragment = `fragment TerminalClosurePaymentMethodSummary on TerminalClosurePaymentMethodSummary {
  paymentMethodId
  payTypeId
  paymentTypeName
  total
  expected
  difference
}

`

export const TerminalClosurePaymentTotalFragment = `fragment TerminalClosurePaymentTotal on TerminalClosurePaymentTotal {
  paymentMethodId
  counted
}

`

export const TerminalClosurePaymentsSummaryFragment = `fragment TerminalClosurePaymentsSummary on TerminalClosurePaymentsSummary {
  terminalOpenTime
  terminalId
  terminalClosureId
  payments {
    ...TerminalClosurePaymentMethodSummaryNoNesting
  }
}

`

export const TerminalClosureSalesSummaryFragment = `fragment TerminalClosureSalesSummary on TerminalClosureSalesSummary {
  totalSales
  totalInvoices
  totalLaybys
  totalPayments
  totalTaxes
  totalDiscounts
  totalRefunds
}

`

export const TerminalListFragment = `fragment TerminalList on TerminalList {
  items {
    ...TerminalNoNesting
  }
  meta {
    ...PaginatedListMetaNoNesting
  }
}

`

export const UserFragment = `fragment User on User {
  id
  username
  emailAddress
  mobileNumber
  displayName
  numberAvailableTerminals
  userAccountType
  stripeAccountId
  stripeOnboardingComplete
}

`

export const UserV2Fragment = `fragment UserV2 on UserV2 {
  id
  createdAt
  updatedAt
  identifier
  enabled2fa
  verified
  roles {
    ...RoleNoNesting
  }
  lastLogin
  authenticationMethods {
    ...AuthenticationMethodNoNesting
  }
  mfaMethods {
    ...MfaMethodNoNesting
  }
}

`

export const VendorFragment = `fragment Vendor on Vendor {
  id
  active
  talinkId
  talinkUsername
  numberAvailableTerminals
  userAccountType
  stripeOnboardingComplete
}

`


export const AccessTokenSessionDeepNestingFragment = `fragment AccessTokenSessionDeepNesting on AccessTokenSession {
  id
  name
  userId
  token
  expires
  invalidated
  createdAt
  updatedAt
  deletedAt
}

`

export const AnalyticProductMetaDataItemDeepNestingFragment = `fragment AnalyticProductMetaDataItemDeepNesting on AnalyticProductMetaDataItem {
  name
  sku
  id
  value
  imageUrl
}

`

export const AuthenticationMethodDeepNestingFragment = `fragment AuthenticationMethodDeepNesting on AuthenticationMethod {
  id
  createdAt
  updatedAt
  strategy
}

`

export const AuthenticationResultDeepNestingFragment = `fragment AuthenticationResultDeepNesting on AuthenticationResult {
  talinkId
  userId
  token
}

`

export const BackgroundRemoverInsufficientCreditsErrorDeepNestingFragment = `fragment BackgroundRemoverInsufficientCreditsErrorDeepNesting on BackgroundRemoverInsufficientCreditsError {
  errorCode
  message
}

`

export const BackgroundRemoverNotConfiguredErrorDeepNestingFragment = `fragment BackgroundRemoverNotConfiguredErrorDeepNesting on BackgroundRemoverNotConfiguredError {
  errorCode
  message
}

`

export const CashMovementDeepNestingFragment = `fragment CashMovementDeepNesting on CashMovement {
  id
  createdAt
  updatedAt
  openTerminalClosureId
  amount
  userId
  userIdentifier
  type
  note
  date
}

`

export const CategoryDeepNestingFragment = `fragment CategoryDeepNesting on Category {
  id
  description
  name
  bannerImageUrl
  active
  departmentId
  numberOfProducts
  createdAt
  updatedAt
  deletedAt
}

`

export const CategoryListDeepNestingFragment = `fragment CategoryListDeepNesting on CategoryList {
  items {
    ...CategoryDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ConfigArgDeepNestingFragment = `fragment ConfigArgDeepNesting on ConfigArg {
  name
  value
}

`

export const ConfigArgDefinitionDeepNestingFragment = `fragment ConfigArgDefinitionDeepNesting on ConfigArgDefinition {
  name
  type
  list
  required
  defaultValue
  label
  description
}

`

export const ConfigurableOperationDeepNestingFragment = `fragment ConfigurableOperationDeepNesting on ConfigurableOperation {
  code
  args {
    ...ConfigArgDeepNesting
  }
}

`

export const ConfigurableOperationDefinitionDeepNestingFragment = `fragment ConfigurableOperationDefinitionDeepNesting on ConfigurableOperationDefinition {
  code
  args {
    ...ConfigArgDefinitionDeepNesting
  }
  description
}

`

export const CurrencyTypeDeepNestingFragment = `fragment CurrencyTypeDeepNesting on CurrencyType {
  code
  symbol
}

`

export const CurrentUserAndTenantDeepNestingFragment = `fragment CurrentUserAndTenantDeepNesting on CurrentUserAndTenant {
  currentUser {
    ...CurrentUserV2DeepNesting
  }
  tenant {
    ...TenantDeepNesting
  }
}

`

export const CurrentUserV2DeepNestingFragment = `fragment CurrentUserV2DeepNesting on CurrentUserV2 {
  id
  identifier
  token
  permissions
  restrictedTerminalIds
}

`

export const CustomerDeepNestingFragment = `fragment CustomerDeepNesting on Customer {
  id
  firstName
  lastName
  gender
  emailAddress
  mobileNumber
  phoneNumber
  retailerId
  isRetailerCusAccount
  amountSpend
  invoicedAmount
  numberOfOrders
  addresses {
    ...CustomerAddressDeepNesting
  }
  loyaltyPoints
  createdAt
  updatedAt
  deletedAt
}

`

export const CustomerAddressDeepNestingFragment = `fragment CustomerAddressDeepNesting on CustomerAddress {
  id
  isPrimary
  address1
  address2
  postcode
  city
  state
  country
  createdAt
  updatedAt
  deletedAt
}

`

export const CustomerListDeepNestingFragment = `fragment CustomerListDeepNesting on CustomerList {
  items {
    ...CustomerDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const DeletedDeepNestingFragment = `fragment DeletedDeepNesting on Deleted {
  result
}

`

export const DeletionResponseDeepNestingFragment = `fragment DeletionResponseDeepNesting on DeletionResponse {
  result
  reason
}

`

export const DepartmentDeepNestingFragment = `fragment DepartmentDeepNesting on Department {
  id
  description
  name
  bannerImageUrl
  numberOfCategories
  numberOfProducts
  active
  createdAt
  updatedAt
  deletedAt
}

`

export const DepartmentListDeepNestingFragment = `fragment DepartmentListDeepNesting on DepartmentList {
  items {
    ...DepartmentDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const DownloadReceiptPdfBase64StringResponseDeepNestingFragment = `fragment DownloadReceiptPdfBase64StringResponseDeepNesting on DownloadReceiptPdfBase64StringResponse {
  data
  fileName
}

`

export const DownloadStocktakeCsvBase64StringResponseDeepNestingFragment = `fragment DownloadStocktakeCsvBase64StringResponseDeepNesting on DownloadStocktakeCsvBase64StringResponse {
  data
  fileName
}

`

export const EmailAddressConflictErrorDeepNestingFragment = `fragment EmailAddressConflictErrorDeepNesting on EmailAddressConflictError {
  errorCode
  message
}

`

export const FeaturesListDeepNestingFragment = `fragment FeaturesListDeepNesting on FeaturesList {
  utilities
  pointOfSale
  selfCheckout
  stocktake
}

`

export const FilterValueMetaDeepNestingFragment = `fragment FilterValueMetaDeepNesting on FilterValueMeta {
  type
  value
  label
}

`

export const GlobalSettingsDeepNestingFragment = `fragment GlobalSettingsDeepNesting on GlobalSettings {
  cashManagedPaymentMethodId
  taxPercentage
  countryCode
  currency {
    ...CurrencyTypeDeepNesting
  }
  timezone
  businessName
  address1
  address2
  email
  phone
  website
  postcode
  abn
  talinkId
  productSurchargeId
  laybyEnabled
  defaultLaybyDepositPercentage
  minLaybySpend
  defaultTerminalClosureFloat
  automaticOpenTerminalClosure
  showExpectedClosureCounts
  removalAiDetails {
    ...RemovalAiDetailsDeepNesting
  }
  removalBgDetails {
    ...RemovalBgDetailsDeepNesting
  }
  bgRemovalStrategy
  automaticBackgroundRemoval
  stocktakeSendToAddress
  automaticallyEmailStockTake
  automaticallyEmailTerminalClosureSummary
  terminalClosureSendToAddress
}

`

export const InsufficientStockErrorDeepNestingFragment = `fragment InsufficientStockErrorDeepNesting on InsufficientStockError {
  errorCode
  message
  quantityAvailable
  sale {
    ...SaleDeepNesting
  }
}

`

export const InvalidCredentialsErrorDeepNestingFragment = `fragment InvalidCredentialsErrorDeepNesting on InvalidCredentialsError {
  errorCode
  message
  authenticationError
}

`

export const InvalidLinklyCredentialsErrorDeepNestingFragment = `fragment InvalidLinklyCredentialsErrorDeepNesting on InvalidLinklyCredentialsError {
  errorCode
  message
}

`

export const InvalidMfaCodeErrorDeepNestingFragment = `fragment InvalidMfaCodeErrorDeepNesting on InvalidMfaCodeError {
  errorCode
  message
}

`

export const InvalidMfaIdentifierErrorDeepNestingFragment = `fragment InvalidMfaIdentifierErrorDeepNesting on InvalidMfaIdentifierError {
  errorCode
  message
}

`

export const InvalidMfaStrategyErrorDeepNestingFragment = `fragment InvalidMfaStrategyErrorDeepNesting on InvalidMfaStrategyError {
  errorCode
  message
}

`

export const InventoryItemDeepNestingFragment = `fragment InventoryItemDeepNesting on InventoryItem {
  id
  tracked
  inventoryLevels {
    ...InventoryLevelDeepNesting
  }
  productId
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryItemListDeepNestingFragment = `fragment InventoryItemListDeepNesting on InventoryItemList {
  items {
    ...InventoryItemDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryLevelDeepNestingFragment = `fragment InventoryLevelDeepNesting on InventoryLevel {
  id
  locationId
  onHand
  unitsPerPack
  inventoryItemId
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryLevelListDeepNestingFragment = `fragment InventoryLevelListDeepNesting on InventoryLevelList {
  items {
    ...InventoryLevelDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryLevelMovementDeepNestingFragment = `fragment InventoryLevelMovementDeepNesting on InventoryLevelMovement {
  id
  inventoryLevelId
  quantity
  adjustment
  userId
  user {
    ...UserDeepNesting
  }
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryLevelMovementListDeepNestingFragment = `fragment InventoryLevelMovementListDeepNesting on InventoryLevelMovementList {
  items {
    ...InventoryLevelMovementDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryMovementDeepNestingFragment = `fragment InventoryMovementDeepNesting on InventoryMovement {
  id
  locationId
  location {
    ...LocationDeepNesting
  }
  userId
  user {
    ...UserDeepNesting
  }
  targetLocationId
  name
  inventoryMovementDate
  referenceNo
  dueAt
  receivedAt
  sentAt
  dispatchedAt
  cancelledAt
  completedAt
  status
  filters {
    ...InventoryMovementFilterDeepNesting
  }
  totals {
    ...InventoryMovementTotalsDeepNesting
  }
  isFullStocktake
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryMovementFilterDeepNestingFragment = `fragment InventoryMovementFilterDeepNesting on InventoryMovementFilter {
  type
  value
}

`

export const InventoryMovementItemDeepNestingFragment = `fragment InventoryMovementItemDeepNesting on InventoryMovementItem {
  id
  inventoryMovementId
  productId
  stockCode
  counted
  initialExpected
  expected
  cost
  status
  manual
  createdAt
  updatedAt
  deletedAt
}

`

export const InventoryMovementItemListDeepNestingFragment = `fragment InventoryMovementItemListDeepNesting on InventoryMovementItemList {
  items {
    ...InventoryMovementItemDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryMovementItemSummaryTotalsDeepNestingFragment = `fragment InventoryMovementItemSummaryTotalsDeepNesting on InventoryMovementItemSummaryTotals {
  inventoryMovementItemId
  stockNumber
  received
  counted
  price
  productId
  productName
  totalCost
}

`

export const InventoryMovementListDeepNestingFragment = `fragment InventoryMovementListDeepNesting on InventoryMovementList {
  items {
    ...InventoryMovementDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const InventoryMovementSummaryTotalDeepNestingFragment = `fragment InventoryMovementSummaryTotalDeepNesting on InventoryMovementSummaryTotal {
  cost
  received
  counted
}

`

export const InventoryMovementSummaryTotalsDeepNestingFragment = `fragment InventoryMovementSummaryTotalsDeepNesting on InventoryMovementSummaryTotals {
  totals {
    ...InventoryMovementSummaryTotalDeepNesting
  }
  items {
    ...InventoryMovementItemSummaryTotalsDeepNesting
  }
}

`

export const InventoryMovementTotalsDeepNestingFragment = `fragment InventoryMovementTotalsDeepNesting on InventoryMovementTotals {
  totalCountGain
  totalCostGain
  totalCountLoss
  totalCostLoss
}

`

export const LayoutDeepNestingFragment = `fragment LayoutDeepNesting on Layout {
  id
  nodes {
    ...LayoutNodeDeepNesting
  }
  terminalId
  createdAt
  updatedAt
  deletedAt
}

`

export const LayoutListDeepNestingFragment = `fragment LayoutListDeepNesting on LayoutList {
  items {
    ...LayoutDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const LayoutNodeDeepNestingFragment = `fragment LayoutNodeDeepNesting on LayoutNode {
  id
  label
  color
  layoutId
  productId
  showImage
  type
  position
  action
  filter {
    ...LayoutNodeFilterDeepNesting
  }
  link {
    ...LayoutNodeLinkDeepNesting
  }
  createdAt
  updatedAt
  deletedAt
}

`

export const LayoutNodeFilterDeepNestingFragment = `fragment LayoutNodeFilterDeepNesting on LayoutNodeFilter {
  type
  values
}

`

export const LayoutNodeLinkDeepNestingFragment = `fragment LayoutNodeLinkDeepNesting on LayoutNodeLink {
  url
  newTab
}

`

export const LayoutNodeListDeepNestingFragment = `fragment LayoutNodeListDeepNesting on LayoutNodeList {
  items {
    ...LayoutNodeDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const LinklyConfigurationDeepNestingFragment = `fragment LinklyConfigurationDeepNesting on LinklyConfiguration {
  id
  paymentMethodId
  receiptAutoPrint
  pairedPinpads {
    ...LinklyPairedPinpadDeepNesting
  }
  createdAt
  updatedAt
  deletedAt
}

`

export const LinklyErrorDeepNestingFragment = `fragment LinklyErrorDeepNesting on LinklyError {
  errorCode
  message
}

`

export const LinklyPairedPinpadDeepNestingFragment = `fragment LinklyPairedPinpadDeepNesting on LinklyPairedPinpad {
  id
  linklyConfigurationId
  name
  secret
  createdAt
  updatedAt
  deletedAt
}

`

export const LocationDeepNestingFragment = `fragment LocationDeepNesting on Location {
  id
  retailerLocationKey
  name
  businessName
  enabled
  createdAt
  updatedAt
}

`

export const LocationListDeepNestingFragment = `fragment LocationListDeepNesting on LocationList {
  items {
    ...LocationDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const MfaAlreadySetupErrorDeepNestingFragment = `fragment MfaAlreadySetupErrorDeepNesting on MfaAlreadySetupError {
  errorCode
  message
}

`

export const MfaMethodDeepNestingFragment = `fragment MfaMethodDeepNesting on MfaMethod {
  id
  createdAt
  updatedAt
  strategy
  identifier
  verified
}

`

export const MfaRequiredErrorDeepNestingFragment = `fragment MfaRequiredErrorDeepNesting on MfaRequiredError {
  errorCode
  message
  strategies
}

`

export const MimeTypeErrorDeepNestingFragment = `fragment MimeTypeErrorDeepNesting on MimeTypeError {
  errorCode
  message
  fileName
  mimeType
}

`

export const MissingPasswordErrorDeepNestingFragment = `fragment MissingPasswordErrorDeepNesting on MissingPasswordError {
  errorCode
  message
}

`

export const MovementItemProductSummaryDeepNestingFragment = `fragment MovementItemProductSummaryDeepNesting on MovementItemProductSummary {
  productName
  productId
  stockNumber
  stockCode
  onHand
}

`

export const NativeAuthStrategyErrorDeepNestingFragment = `fragment NativeAuthStrategyErrorDeepNesting on NativeAuthStrategyError {
  errorCode
  message
}

`

export const NegativeQuantityErrorDeepNestingFragment = `fragment NegativeQuantityErrorDeepNesting on NegativeQuantityError {
  errorCode
  message
}

`

export const NotVerifiedErrorDeepNestingFragment = `fragment NotVerifiedErrorDeepNesting on NotVerifiedError {
  errorCode
  message
}

`

export const PaginatedListMetaDeepNestingFragment = `fragment PaginatedListMetaDeepNesting on PaginatedListMeta {
  itemCount
  totalItems
  currentPage
}

`

export const PasswordAlreadySetErrorDeepNestingFragment = `fragment PasswordAlreadySetErrorDeepNesting on PasswordAlreadySetError {
  errorCode
  message
}

`

export const PasswordValidationErrorDeepNestingFragment = `fragment PasswordValidationErrorDeepNesting on PasswordValidationError {
  errorCode
  message
  validationErrorMessage
}

`

export const PayTypeDeepNestingFragment = `fragment PayTypeDeepNesting on PayType {
  id
  name
  subtext
  description
  logo
  internal
  platforms
  channels
  onlineOnly
  configurationUrl
  defaultGatewayUrl
  version
  gateway
  gatewayEditable
  available
  restricted
  createdAt
  updatedAt
  deletedAt
}

`

export const PayTypeGatewayConfigDeepNestingFragment = `fragment PayTypeGatewayConfigDeepNesting on PayTypeGatewayConfig {
  url
}

`

export const PayTypeRoundingConfigDeepNestingFragment = `fragment PayTypeRoundingConfigDeepNesting on PayTypeRoundingConfig {
  rounding
}

`

export const PaymentDeepNestingFragment = `fragment PaymentDeepNesting on Payment {
  id
  amount
  name
  label
  paymentDate
  paymentMethodId
  paymentTypeId
  transactionId
  state
  errorMessage
  metadata
}

`

export const PaymentMethodDeepNestingFragment = `fragment PaymentMethodDeepNesting on PaymentMethod {
  id
  name
  payTypeId
  paytype {
    ...PayTypeDeepNesting
  }
  retailerPayCode
  retailerPayTypeId
  enabled
  createdAt
  updatedAt
  deletedAt
}

`

export const PaymentMethodListDeepNestingFragment = `fragment PaymentMethodListDeepNesting on PaymentMethodList {
  items {
    ...PaymentMethodDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ProductDeepNestingFragment = `fragment ProductDeepNesting on Product {
  id
  name
  taxable
  active
  description
  stockCode
  stockNumber
  variantParentId
  locationInventoryPricing {
    ...ProductLocationInventoryPricingDeepNesting
  }
  totalInventory
  hasVariants
  displayName
  totalVariants
  categoryId
  departmentId
  category {
    ...CategoryDeepNesting
  }
  department {
    ...DepartmentDeepNesting
  }
  variants {
    ...ProductDeepNesting
  }
  discountable
  options {
    ...ProductOptionDeepNesting
  }
  image {
    ...ProductImageDeepNesting
  }
  images {
    ...ProductImageDeepNesting
  }
  selectedOptions {
    ...SelectedOptionDeepNesting
  }
  internal
  adjustable
  price
  priceExcludingTax
  loyaltyPointEarnValue
  loyaltyPointRedeemValue
  serialNumberRequired
  createdAt
  updatedAt
  deletedAt
}

`

export const ProductIdResultDeepNestingFragment = `fragment ProductIdResultDeepNesting on ProductIdResult {
  productId
}

`

export const ProductImageDeepNestingFragment = `fragment ProductImageDeepNesting on ProductImage {
  id
  path
  name
  width
  height
  mineType
  position
  productId
  createdAt
  updatedAt
  deletedAt
}

`

export const ProductListDeepNestingFragment = `fragment ProductListDeepNesting on ProductList {
  items {
    ...ProductDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ProductLocationInventoryPricingDeepNestingFragment = `fragment ProductLocationInventoryPricingDeepNesting on ProductLocationInventoryPricing {
  id
  productId
  locationId
  location {
    ...LocationDeepNesting
  }
  stockOnHand
  costPrice
  retailPrice
  rrPrice
  taxRatePercent
}

`

export const ProductOptionDeepNestingFragment = `fragment ProductOptionDeepNesting on ProductOption {
  id
  name
  position
  values
  createdAt
  updatedAt
}

`

export const ReceiptDeepNestingFragment = `fragment ReceiptDeepNesting on Receipt {
  id
  name
  defaultTemplateType
  settings {
    ...ReceiptSettingsDeepNesting
  }
  enabled
  createdAt
  updatedAt
  deletedAt
}

`

export const ReceiptListDeepNestingFragment = `fragment ReceiptListDeepNesting on ReceiptList {
  items {
    ...ReceiptDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const ReceiptSettingsDeepNestingFragment = `fragment ReceiptSettingsDeepNesting on ReceiptSettings {
  header
  name
  footer
  displayCustomerDetails
  logoURL
}

`

export const ReceiptTemplateTypeDeepNestingFragment = `fragment ReceiptTemplateTypeDeepNesting on ReceiptTemplateType {
  id
  html
  css
  version
  createdAt
  updatedAt
  deletedAt
}

`

export const RemovalAiDetailsDeepNestingFragment = `fragment RemovalAiDetailsDeepNesting on RemovalAiDetails {
  apiKey
}

`

export const RemovalBgDetailsDeepNestingFragment = `fragment RemovalBgDetailsDeepNesting on RemovalBgDetails {
  apiKey
}

`

export const RemoveBackgroundBase64ResultDeepNestingFragment = `fragment RemoveBackgroundBase64ResultDeepNesting on RemoveBackgroundBase64Result {
  base64
}

`

export const RetailerExportCustomerDeepNestingFragment = `fragment RetailerExportCustomerDeepNesting on RetailerExportCustomer {
  FirstName
  Surname
  Email
  Phone
}

`

export const RetailerExportPaymentDeepNestingFragment = `fragment RetailerExportPaymentDeepNesting on RetailerExportPayment {
  PayCode
  Amount
  Tendered
}

`

export const RetailerExportSaleDeepNestingFragment = `fragment RetailerExportSaleDeepNesting on RetailerExportSale {
  SaleType
  SaleId
  OrderNumber
  SaleDate
  SaleStaff
  SaleTime
  SaleAmount
  SaleDiscount
  SaleTax
  SaleDeposit
  LocationKey
  SaleTerm
  CustID
  SaleCust
  SaleRound
  Payments {
    ...RetailerExportPaymentDeepNesting
  }
  SaleItems {
    ...RetailerExportSaleItemDeepNesting
  }
  Customer {
    ...RetailerExportCustomerDeepNesting
  }
  FreightCharge
  FreightTax
}

`

export const RetailerExportSaleItemDeepNestingFragment = `fragment RetailerExportSaleItemDeepNesting on RetailerExportSaleItem {
  TxnStock
  TxnItem
  TxnQty
  TxnUnitAmount
  TxnUnitDiscount
  TxnUnitTax
  TxnUnitCost
  TxnExtendedAmount
  TxnExtendedDiscount
  TxnExtendedTax
  TxnTotal
  TxnTotalCost
  TxnDiscountType
  SerialNumber
  TotalPointsEarned
  TotalPointsUsed
}

`

export const RetailerPayTypeDeepNestingFragment = `fragment RetailerPayTypeDeepNesting on RetailerPayType {
  id
  payCode
  payDescription
  payType
  payOver
  createdAt
  updatedAt
  deletedAt
}

`

export const RetailerPayTypeListDeepNestingFragment = `fragment RetailerPayTypeListDeepNesting on RetailerPayTypeList {
  items {
    ...RetailerPayTypeDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const RoleDeepNestingFragment = `fragment RoleDeepNesting on Role {
  id
  createdAt
  updatedAt
  code
  permissions
}

`

export const SaleDeepNestingFragment = `fragment SaleDeepNesting on Sale {
  id
  code
  receiptConfirmed
  receiptConfirmationUrl
  active
  terminalId
  terminal {
    ...TerminalDeepNesting
  }
  locationId
  location {
    ...LocationDeepNesting
  }
  customerId
  customer {
    ...CustomerDeepNesting
  }
  staffId
  staff {
    ...StaffDeepNesting
  }
  source
  receiptId
  state
  invoiceNumber
  invoiceSequence
  isReturn
  returnForId
  note
  totalCost
  totalTax
  totalPrice
  totalAdjustments
  totalAdjustmentPercentSaving
  balance
  lineItems {
    ...SaleLineItemDeepNesting
  }
  historyEntries {
    ...SaleHistoryEntryDeepNesting
  }
  payments {
    ...PaymentDeepNesting
  }
  saleDate
  metadata
  completedAt
  createdAt
  updatedAt
  deletedAt
}

`

export const SaleAddressDeepNestingFragment = `fragment SaleAddressDeepNesting on SaleAddress {
  fullName
  company
  street1
  street2
  city
  state
  postCode
  country
  countryCode
  phoneNumber
}

`

export const SaleAdjustmentDeepNestingFragment = `fragment SaleAdjustmentDeepNesting on SaleAdjustment {
  id
  type
  amount
  metadata
  unitLoyaltyPointsUsed
  createdAt
  updatedAt
}

`

export const SaleHistoryEntryDeepNestingFragment = `fragment SaleHistoryEntryDeepNesting on SaleHistoryEntry {
  id
  type
  userId
  user {
    ...UserDeepNesting
  }
  createdAt
  updatedAt
  deletedAt
}

`

export const SaleLineItemDeepNestingFragment = `fragment SaleLineItemDeepNesting on SaleLineItem {
  id
  saleId
  productId
  product {
    ...ProductDeepNesting
  }
  stockNumber
  stockCode
  serialNumber
  basePrice
  basePriceInclTax
  adjustments {
    ...SaleAdjustmentDeepNesting
  }
  price
  priceInclTax
  cost
  tax
  totalPriceInclusiveTax
  quantity
  totalBasePrice
  taxRatePercent
  totalBasePriceInclTax
  shippingAddress {
    ...SaleAddressDeepNesting
  }
  billingAddress {
    ...SaleAddressDeepNesting
  }
  totalPrice
  totalCost
  totalTax
  totalAdjustments
  loyaltyPointEarned
  metadata
  createdAt
  updatedAt
  deletedAt
}

`

export const SaleLineItemEntryDataDeepNestingFragment = `fragment SaleLineItemEntryDataDeepNesting on SaleLineItemEntryData {
  productName
  stockNumber
  stockCode
  serialNumber
  basePrice
  basePriceInclTax
  adjustments {
    ...SaleAdjustmentDeepNesting
  }
  price
  priceInclTax
  cost
  tax
  totalPriceInclusiveTax
  quantity
  totalBasePrice
  taxRatePercent
  totalBasePriceInclTax
  totalPrice
  totalCost
  totalTax
  totalAdjustments
  loyaltyPointEarned
  metadata
}

`

export const SaleListDeepNestingFragment = `fragment SaleListDeepNesting on SaleList {
  items {
    ...SaleDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const SaleModifiedCustomerAddedEntryDataDeepNestingFragment = `fragment SaleModifiedCustomerAddedEntryDataDeepNesting on SaleModifiedCustomerAddedEntryData {
  customerId
  emailAddress
}

`

export const SaleModifiedEntryDataDeepNestingFragment = `fragment SaleModifiedEntryDataDeepNesting on SaleModifiedEntryData {
  input
  oldEntity
}

`

export const SaleNoteEntryDataDeepNestingFragment = `fragment SaleNoteEntryDataDeepNesting on SaleNoteEntryData {
  note
}

`

export const SaleStateTransitionErrorDeepNestingFragment = `fragment SaleStateTransitionErrorDeepNesting on SaleStateTransitionError {
  errorCode
  message
  transitionError
  fromState
  toState
}

`

export const SaleStatusTransitionDataDeepNestingFragment = `fragment SaleStatusTransitionDataDeepNesting on SaleStatusTransitionData {
  from
  to
}

`

export const SearchResponseDeepNestingFragment = `fragment SearchResponseDeepNesting on SearchResponse {
  items {
    ...SearchResultItemDeepNesting
  }
}

`

export const SearchResultItemDeepNestingFragment = `fragment SearchResultItemDeepNesting on SearchResultItem {
  id
  type
}

`

export const SelectedOptionDeepNestingFragment = `fragment SelectedOptionDeepNesting on SelectedOption {
  name
  value
}

`

export const SelfCheckoutLocationSettingsDeepNestingFragment = `fragment SelfCheckoutLocationSettingsDeepNesting on SelfCheckoutLocationSettings {
  locationId
  location {
    ...LocationDeepNesting
  }
  enabled
  terminalId
  terminal {
    ...TerminalDeepNesting
  }
  onlineCheckoutEnabled
  offlineCheckoutEnabled
  payAtCounterHeading
  payAtCounterSubheading
  checkoutSuccessHeading
}

`

export const SelfCheckoutSettingsDeepNestingFragment = `fragment SelfCheckoutSettingsDeepNesting on SelfCheckoutSettings {
  enabled
  locationSettings {
    ...SelfCheckoutLocationSettingsDeepNesting
  }
  logoURL
  paymentMethod {
    ...PaymentMethodDeepNesting
  }
  paymentMethodId
}

`

export const ServerConfigDeepNestingFragment = `fragment ServerConfigDeepNesting on ServerConfig {
  receiptTemplateTypes {
    ...ReceiptTemplateTypeDeepNesting
  }
  availablePayTypes {
    ...PayTypeDeepNesting
  }
  internalPayTypes {
    ...PayTypeDeepNesting
  }
}

`

export const StaffDeepNestingFragment = `fragment StaffDeepNesting on Staff {
  id
  hasUserAccount
  userId
  user {
    ...UserV2DeepNesting
  }
  username
  terminalIds
  terminals {
    ...TerminalDeepNesting
  }
  emailAddress
  fullName
  initials
  enabled
  createdAt
  updatedAt
  deletedAt
}

`

export const StaffListDeepNestingFragment = `fragment StaffListDeepNesting on StaffList {
  items {
    ...StaffDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const StockNumberNotFoundErrorDeepNestingFragment = `fragment StockNumberNotFoundErrorDeepNesting on StockNumberNotFoundError {
  errorCode
  message
}

`

export const StocktakeItemDeepNestingFragment = `fragment StocktakeItemDeepNesting on StocktakeItem {
  id
  stocktakeId
  product
  stockCode
  counted
  initialExpected
  expected
  cost
  status
  manual
  createdAt
  updatedAt
  deletedAt
}

`

export const StocktakeItemListDeepNestingFragment = `fragment StocktakeItemListDeepNesting on StocktakeItemList {
  items {
    ...StocktakeItemDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const SuccessDeepNestingFragment = `fragment SuccessDeepNesting on Success {
  success
}

`

export const SwapLayoutNodesResultDeepNestingFragment = `fragment SwapLayoutNodesResultDeepNesting on SwapLayoutNodesResult {
  firstNode {
    ...LayoutNodeDeepNesting
  }
  secondNode {
    ...LayoutNodeDeepNesting
  }
}

`

export const TenantDeepNestingFragment = `fragment TenantDeepNesting on Tenant {
  identifier
}

`

export const TerminalDeepNestingFragment = `fragment TerminalDeepNesting on Terminal {
  id
  name
  invoiceSequence
  openTerminalClosure {
    ...TerminalClosureDeepNesting
  }
  openTerminalClosureId
  terminalOpenTime
  terminalCloseTime
  saleSufix
  salePrefix
  enabled
  paymentMethodIds
  defaultReceiptTemplateId
  printReceiptOnCheckout
  createdAt
  updatedAt
  deletedAt
}

`

export const TerminalClosureDeepNestingFragment = `fragment TerminalClosureDeepNesting on TerminalClosure {
  id
  terminalId
  terminalName
  terminalOpenDate
  terminalCloseDate
  isClosed
  payments {
    ...TerminalClosurePaymentTotalDeepNesting
  }
  cashMovements {
    ...CashMovementDeepNesting
  }
  saleSummary {
    ...TerminalClosureSalesSummaryDeepNesting
  }
  countedPaymentsSummary {
    ...TerminalClosurePaymentMethodSummaryDeepNesting
  }
  estimatedCashBalance
  createdAt
  updatedAt
  deletedAt
}

`

export const TerminalClosureListDeepNestingFragment = `fragment TerminalClosureListDeepNesting on TerminalClosureList {
  items {
    ...TerminalClosureDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const TerminalClosurePaymentMethodSummaryDeepNestingFragment = `fragment TerminalClosurePaymentMethodSummaryDeepNesting on TerminalClosurePaymentMethodSummary {
  paymentMethodId
  payTypeId
  paymentTypeName
  total
  expected
  difference
}

`

export const TerminalClosurePaymentTotalDeepNestingFragment = `fragment TerminalClosurePaymentTotalDeepNesting on TerminalClosurePaymentTotal {
  paymentMethodId
  counted
}

`

export const TerminalClosurePaymentsSummaryDeepNestingFragment = `fragment TerminalClosurePaymentsSummaryDeepNesting on TerminalClosurePaymentsSummary {
  terminalOpenTime
  terminalId
  terminalClosureId
  payments {
    ...TerminalClosurePaymentMethodSummaryDeepNesting
  }
}

`

export const TerminalClosureSalesSummaryDeepNestingFragment = `fragment TerminalClosureSalesSummaryDeepNesting on TerminalClosureSalesSummary {
  totalSales
  totalInvoices
  totalLaybys
  totalPayments
  totalTaxes
  totalDiscounts
  totalRefunds
}

`

export const TerminalListDeepNestingFragment = `fragment TerminalListDeepNesting on TerminalList {
  items {
    ...TerminalDeepNesting
  }
  meta {
    ...PaginatedListMetaDeepNesting
  }
}

`

export const UserDeepNestingFragment = `fragment UserDeepNesting on User {
  id
  username
  emailAddress
  mobileNumber
  displayName
  numberAvailableTerminals
  userAccountType
  stripeAccountId
  stripeOnboardingComplete
}

`

export const UserV2DeepNestingFragment = `fragment UserV2DeepNesting on UserV2 {
  id
  createdAt
  updatedAt
  identifier
  enabled2fa
  verified
  roles {
    ...RoleDeepNesting
  }
  lastLogin
  authenticationMethods {
    ...AuthenticationMethodDeepNesting
  }
  mfaMethods {
    ...MfaMethodDeepNesting
  }
}

`

export const VendorDeepNestingFragment = `fragment VendorDeepNesting on Vendor {
  id
  active
  talinkId
  talinkUsername
  numberAvailableTerminals
  userAccountType
  stripeOnboardingComplete
}

`

