import { Product } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";
import { moneyToFormattedString } from "../../../../utilities/money";

export interface ProductVariantDetailsCardProps {
  product: Product;
}

export const ProductVariantDetailsCard = ({
  product,
}: ProductVariantDetailsCardProps) => {
  if (product.hasVariants === false) {
    return null;
  }
  const price = product?.locationInventoryPricing[0]?.retailPrice || 0;

  return (
    <CardDetailList
      title="Variants"
      emptyComponent="No Variants."
      items={[
        {
          key: product.id,
          title: product.displayName || "-",
          subTitle: product.stockNumber || "-",
          value: moneyToFormattedString(price),
        },
        ...(product.variants?.map((p) => {
          const price = p?.locationInventoryPricing[0]?.retailPrice || 0;

          return {
            key: p.id,
            title: p.displayName || "-",
            subTitle: p.stockNumber || "-",
            value: moneyToFormattedString(price),
          };
        }) || []),
      ]}
    ></CardDetailList>
  );
};
