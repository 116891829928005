import { FontAwesome, Ionicons } from "@expo/vector-icons";
import PaymentIcon from "@mui/icons-material/Payment";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

export interface PayTypeToIconProps {
  payType: string;
  size?: number;
  color?: string;
}
export const PayTypeToIcon = ({
  payType,
  size = 24,
  color,
}: PayTypeToIconProps) => {
  switch (payType) {
    case "1":
    case "2":
    case "25":
      return <LocalAtmIcon />;
    case "15":
    case "10":
      return <PaymentIcon />;
    default:
      return <LocalAtmIcon />;
  }
};
