import { Stack } from "@mui/material";
import { SkeletonPage } from "../../../../components";
import {
  PaymentMethodInfoSettingCard,
  PaymentMethodPayTypeSettingCard,
} from "../EditPaymentMethodForm/components";
import { InputSkeleton } from "../InputSkeleton";

export interface PaymentMethodPageSkeletonProps {}

export function PaymentMethodPageSkeleton(
  props: PaymentMethodPageSkeletonProps
) {
  return (
    <SkeletonPage title titleDescription goBack>
      <Stack spacing={5}>
        <PaymentMethodInfoSettingCard>
          <InputSkeleton />
        </PaymentMethodInfoSettingCard>
        <PaymentMethodPayTypeSettingCard>
          <InputSkeleton />
        </PaymentMethodPayTypeSettingCard>
      </Stack>
    </SkeletonPage>
  );
}
