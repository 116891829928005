import { TextField } from "@mui/material";
import { FormLayout } from "components";
import { LocationFormValues } from "modules/settings/utils/locations/types";
import { useFormContext } from "react-hook-form";

export interface LocationDetailsFormProps {}

export function LocationDetailsForm(props: LocationDetailsFormProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<LocationFormValues>();

  return (
    <FormLayout>
      <TextField
        label="Name"
        fullWidth
        {...register("name")}
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
      />
      <TextField
        label="Retailer Location Key"
        fullWidth
        {...register("retailerLocationKey")}
        error={Boolean(errors.retailerLocationKey)}
        helperText={errors.retailerLocationKey?.message}
      />
    </FormLayout>
  );
}
