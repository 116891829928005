import { useQuery } from "@apollo/client";
import { PaginatedList } from "@towersystems/roam-common/lib/shared-types";
import { isNull, omit } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PaginatedListOptions, PaginatedListResponse } from "./types";

const DEFAULT_PAGINATION_LIMIT = 30;
const DEFAULT_PAGE = 1;
const CONTROLLED_FILTER_KEYS = ["limit", "page", "sort"];

export function usePaginatedList<T>({
  query,
  variables,
  resultKey,
  fixedVariables,
}: PaginatedListOptions<T>): PaginatedListResponse<T> {
  const [pageParams, setPageParams] = useSearchParams();
  const pageNumber = pageParams.get("page");
  const [limit, setLimit] = useState<number>(DEFAULT_PAGINATION_LIMIT);
  const [page, setPage] = useState<number>(
    pageNumber ? parseInt(pageNumber) : DEFAULT_PAGE
  );
  const [sort, setSort] = useState<{ [key: string]: any } | undefined>(
    variables?.sort
  );

  useEffect(() => {
    if (!pageNumber) {
      pageParams.set("page", DEFAULT_PAGE.toString());
      setPageParams(pageParams, { replace: true });
    }
  }, [pageParams, setPageParams, pageNumber]);

  const [variablesState, setVariables] = useState<
    { [key: string]: any } | undefined
  >(variables);

  const { data, loading, called, error, refetch, previousData, ...r } =
    useQuery<{
      [key: string]: PaginatedList<T>;
    }>(query, {
      notifyOnNetworkStatusChange: true,
      variables: {
        limit,
        page,
        sort,
        options: {
          limit,
          page,
          sort,
          ...omit<any, any>(variablesState, CONTROLLED_FILTER_KEYS),
        },
        ...omit<any, any>(variablesState, CONTROLLED_FILTER_KEYS),
        ...fixedVariables,
      },
    });

  const setVariable = (key: string, value: any) => {
    setVariables((prev) => ({ ...prev, [key]: value }));
    setPage(1);
  };

  const updateSort = (newSort?: { [key: string]: any }) => {
    setSort(newSort);
    setPage(1);
  };

  const result = data && data[resultKey];
  const previousResult = previousData && previousData[resultKey];

  const loaded = called && !loading;

  const maxPage = useMemo(() => {
    if (!called || (called && error)) return 1;

    if (!result) {
      if (previousData) {
        return Math.ceil(previousData[resultKey].meta.totalItems / limit);
      }
      return 1;
    }
    return Math.ceil(result.meta.totalItems / limit);
  }, [called, result, error]);

  const next = () => {
    if (!loaded || error || page + 1 > maxPage) return;
    const newPage: number = page + 1;
    pageParams.set("page", newPage.toString());
    setPageParams(pageParams, { replace: true });
    setPage(newPage);
  };

  const back = () => {
    if (!loaded || error || page == 1) return;
    const newPage: number = page - 1;
    pageParams.set("page", newPage.toString());
    setPageParams(pageParams, { replace: true });
    setPage(newPage);
  };

  const numAppliedFilters = useMemo(
    () => getNumAppliedFilters(variablesState || {}),
    [variablesState]
  );

  return {
    result,
    previousResult,
    loaded,
    called,
    page,
    maxPage,
    limit,
    loading,
    error,
    variablesState,
    numAppliedFilters,
    setLimit,
    setPage,
    setVariables,
    setVariable,
    setSort: updateSort,
    next,
    back,
    refetch,
  };
}

export function getItems<T>(paginatedList: PaginatedListResponse<T>) {
  const items = paginatedList?.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];
  return items;
}

export function getNumAppliedFilters(variablesState: {
  [key: string]: any;
}): any {
  return Object.entries(variablesState || {}).reduce(
    (acc, [member, values]) => {
      if (CONTROLLED_FILTER_KEYS.includes(member) || member === "term") {
        return acc;
      } else if (Array.isArray(values)) {
        return acc + (values?.length || 0);
      } else if (!isNull(values) && typeof values === "object") {
        return acc + getNumAppliedFilters(values);
      } else {
        return acc;
      }
    },
    0
  );
}
