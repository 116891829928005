import React, {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { useComboboxListbox } from "../../utilities/combo-box";
import { Key } from "../../utilities/keys";
import { useToggle } from "../../utilities/use-toggle";
import { KeyPressListener } from "../KeyPressListener";
import { Option } from "./components";

import { List as MuiList } from "@mui/material";
import { NavigableOption } from "../../utilities/list";
import {
  ListboxContext,
  WithinListboxContext,
} from "../../utilities/list/context";

const LISTBOX_OPTION_SELECTOR = "[data-listbox-option]";
const LISTBOX_OPTION_VALUE_ATTRIBUTE = "data-listbox-option-value";
const DATA_ATTRIBUTE = "data-focused";

export interface ListBoxProps {
  children?: React.ReactNode;
  enableKeyboardControl?: boolean;
  onSelect?(value: string): void;
}

export type ArrowKeys = "up" | "down";

export const List = ({
  children,
  enableKeyboardControl,
  onSelect,
}: ListBoxProps) => {
  const {
    value: keyboardEventsEnabled,
    setTrue: enableKeyboardEvents,
    setFalse: disableKeyboardEvents,
  } = useToggle(Boolean(enableKeyboardControl));
  const listBoxClassName = "list-box";
  const listId = useId();
  const listboxRef = useRef<HTMLUListElement>(null);
  const [loading, setLoading] = useState<string>();
  const {
    setActiveOptionId,
    setListboxId,
    listboxId,
    textFieldLabelId,
    onOptionSelected,
    onKeyToBottom,
    textFieldFocused,
  } = useComboboxListbox();

  const [currentActiveOption, setCurrentActiveOption] =
    useState<NavigableOption>();

  useEffect(() => {
    if (!currentActiveOption || !setActiveOptionId) return;
    setActiveOptionId(currentActiveOption.domId);
  }, [currentActiveOption, setActiveOptionId]);

  const inCombobox = Boolean(setActiveOptionId);

  useEffect(() => {
    if (setListboxId && !listboxId) {
      setListboxId(listId);
    }
  }, [setListboxId, listboxId, listId]);

  useEffect(() => {
    if (enableKeyboardControl && !keyboardEventsEnabled) {
      enableKeyboardEvents();
    }
  }, [enableKeyboardControl, keyboardEventsEnabled, enableKeyboardEvents]);

  const handleChangeActiveOption = useCallback(
    (nextOption?: NavigableOption) => {
      setCurrentActiveOption((currentActiveOption) => {
        if (currentActiveOption) {
          currentActiveOption.element.removeAttribute(DATA_ATTRIBUTE);
        }
        if (nextOption) {
          nextOption.element.setAttribute(DATA_ATTRIBUTE, "true");
          return nextOption;
        } else {
          return undefined;
        }
      });
    },
    []
  );

  function handleFocus() {
    if (enableKeyboardControl) return;
    enableKeyboardEvents();
  }

  function handleBlur(event: React.FocusEvent) {
    event.stopPropagation();
    if (keyboardEventsEnabled) {
      handleChangeActiveOption();
    }
    if (enableKeyboardControl) return;
    disableKeyboardEvents();
  }

  const onOptionSelect = useCallback(
    (option: NavigableOption) => {
      handleChangeActiveOption(option);

      if (onOptionSelected) {
        onOptionSelected();
      }
      if (onSelect) onSelect(option.value);
    },
    [handleChangeActiveOption, onSelect, onOptionSelected]
  );

  const listboxContext = useMemo(
    () => ({
      onOptionSelect,
      setLoading,
    }),
    [onOptionSelect]
  );

  function findNextValidOption(type: ArrowKeys) {
    const isUp = type === "up";
    const navItems = getNavigableOptions();

    // @note added any to make it compile
    let nextElement: HTMLElement | null | undefined | any =
      currentActiveOption?.element;
    let count = -1;

    while (count++ < navItems.length) {
      let nextIndex;
      if (nextElement) {
        const currentId = nextElement?.id;
        const currentIndex = navItems.findIndex(
          (currentNavItem: any) => currentNavItem.id === currentId
        );

        let increment = isUp ? -1 : 1;
        if (currentIndex === 0 && isUp) {
          increment = navItems.length - 1;
        } else if (currentIndex === navItems.length - 1 && !isUp) {
          increment = -(navItems.length - 1);
        }
        nextIndex = currentIndex + increment;
        nextElement = navItems[nextIndex];
      } else {
        nextIndex = isUp ? navItems.length - 1 : 0;
        nextElement = navItems[nextIndex];
      }

      if (nextElement?.getAttribute("aria-disabled") === "true") continue;

      if (nextIndex === navItems.length - 1 && onKeyToBottom) {
        onKeyToBottom();
      }
      return nextElement;
    }

    return null;
  }

  function handleArrow(type: ArrowKeys, evt: KeyboardEvent) {
    evt.preventDefault();
    const nextValidElement = findNextValidOption(type);
    if (!nextValidElement) return;

    const nextOption = {
      domId: nextValidElement.id,
      value:
        nextValidElement.getAttribute(LISTBOX_OPTION_VALUE_ATTRIBUTE) || "",
      element: nextValidElement,
      disabled: nextValidElement.getAttribute("aria-disabled") === "true",
    };

    handleChangeActiveOption(nextOption);
  }

  function handleDownArrow(evt: KeyboardEvent) {
    handleArrow("down", evt);
  }

  function handleUpArrow(evt: KeyboardEvent) {
    handleArrow("up", evt);
  }

  function handleEnter(evt: KeyboardEvent) {
    if (currentActiveOption) {
      onOptionSelect(currentActiveOption);
    }
  }

  const listeners =
    keyboardEventsEnabled || textFieldFocused ? (
      <>
        <KeyPressListener
          keyEvent="keydown"
          keyCode={Key.DownArrow}
          handler={handleDownArrow}
        />
        <KeyPressListener
          keyEvent="keydown"
          keyCode={Key.UpArrow}
          handler={handleUpArrow}
        />
        <KeyPressListener
          keyEvent="keydown"
          keyCode={Key.Enter}
          handler={handleEnter}
        />
      </>
    ) : null;

  return (
    <>
      {listeners}
      <ListboxContext.Provider value={listboxContext}>
        <WithinListboxContext.Provider value>
          <MuiList
            className={listBoxClassName}
            id={listId}
            role="listbox"
            onFocus={inCombobox ? undefined : handleFocus}
            onBlur={inCombobox ? undefined : handleBlur}
            ref={listboxRef}
          >
            {children}
          </MuiList>
        </WithinListboxContext.Provider>
      </ListboxContext.Provider>
    </>
  );

  function getNavigableOptions() {
    return [
      ...new Set(
        listboxRef.current?.querySelectorAll<HTMLElement>(
          LISTBOX_OPTION_SELECTOR
        ) as any
      ),
    ];
  }
};

List.Option = Option;
