import { QUERY_SERVER_CONFIG } from "./constants";
import { useQuery } from "@apollo/client";
import { ServerConfig } from "@towersystems/roam-common/lib/generated-types";
import { ServerConfigContext } from "./context";
import { useContext } from "react";

export const useQueryGlobalServerConfig = () => {
  const { loading, error, data, refetch } = useQuery<{
    serverConfig: ServerConfig;
  }>(QUERY_SERVER_CONFIG);
  return {
    item: data?.serverConfig,
    loading,
    error,
    refetch,
  };
};

export const useServerConfigProvider = () => {
  const context = useContext(ServerConfigContext);
  if (!context) {
    throw new Error("No ServerConfigContext context was provided.");
  }
  return context;
};
