import { SettingCard } from "../../../SettingCard";

export interface TerminalInvoiceConfigurationSettingCardProps {
  children?: React.ReactNode;
}

export function TerminalInvoiceConfigurationSettingCard({
  children,
}: TerminalInvoiceConfigurationSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Invoice Configuration"
      description="Configure the terminal to your liking."
    >
      {children}
    </SettingCard>
  );
}
