import { useCallback, useMemo, useState } from "react";
import { ContextualSaveBarProps, FrameContext } from "../../utilities/frame";
import { ContextualSaveBar } from "./components";
import React from "react";

export interface FrameProps {
  children?: React.ReactNode;
}

const MOBILE_VIEW_THRESHOLD = 1040;

function getWindowSize() {
  const { innerWidth } = window;
  return innerWidth;
}

export const Frame = ({ children }: FrameProps) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [windowWidth, setWindowSize] = useState(getWindowSize());

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  const [contextualSaveBar, pSetContextualSaveBar] =
    useState<ContextualSaveBarProps | null>(null);

  const [showContextualSaveBar, setShowContextualSaveBar] = useState(false);

  const setContextualSaveBar = useCallback((props: ContextualSaveBarProps) => {
    pSetContextualSaveBar({ ...props });
    setShowContextualSaveBar(true);
  }, []);

  const removeContextualSaveBar = useCallback(() => {
    pSetContextualSaveBar(null);
    setShowContextualSaveBar(false);
  }, []);

  const contextualSaveBarMarkup = showContextualSaveBar ? (
    <ContextualSaveBar {...contextualSaveBar} />
  ) : undefined;

  const context = useMemo(
    () => ({
      drawerOpen,
      toggleDrawer,
      removeContextualSaveBar,
      setContextualSaveBar,
      windowWidth: windowWidth,
      mobileViewActive: windowWidth < MOBILE_VIEW_THRESHOLD,
    }),
    [
      removeContextualSaveBar,
      windowWidth,
      setContextualSaveBar,
      drawerOpen,
      toggleDrawer,
    ]
  );

  return (
    <FrameContext.Provider value={context}>
      {children}
      {contextualSaveBarMarkup}
    </FrameContext.Provider>
  );
};
