import { Page, Stack } from "components";
import React, { useCallback } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { GeneralSettingsForm } from "../../components/GeneralSettingsForm";
import { useMutationUpdateGlobalSettings } from "../../utils/global-settings";
import { GlobalSettingsFormValues } from "../../utils/global-settings/types";
import { GeneralSettingsPageSkeleton, SettingCard } from "../../components";
import { Box, Divider, Typography } from "@mui/material";

export const GeneralSettingsPage = () => {
  const prefetchData: any = useLoaderData();
  const { updateGlobalSettings } = useMutationUpdateGlobalSettings();

  const handleOnSubmit = useCallback(
    async (input: GlobalSettingsFormValues) => {
      const result = await updateGlobalSettings(input);
      return result;
    },
    []
  );

  return (
    <React.Suspense fallback={<GeneralSettingsPageSkeleton />}>
      <Await resolve={prefetchData.data}>
        {(data) => {
          return (
            <Page title="General Settings">
              <Stack spacing={3} divider={<Divider />}>
                <SettingCard wrapped title="Account details">
                  <Box flex={1}>
                    <Typography variant="caption">TALink ID</Typography>
                    <Typography fontWeight={"bold"}>
                      {data[0].talinkId}
                    </Typography>
                  </Box>
                </SettingCard>

                <SettingCard wrapped title="Business details">
                  <Stack direction={"column"} spacing={3}>
                    <Box flex={1}>
                      <Typography variant="caption">Business Name</Typography>
                      <Typography fontWeight={"bold"}>
                        {data[0].businessName}
                      </Typography>
                    </Box>
                    <Box flex={1}>
                      <Typography variant="caption">Country</Typography>
                      <Typography fontWeight={"bold"}>
                        {data[0].countryCode}
                      </Typography>
                    </Box>
                    <Box flex={1}>
                      <Typography variant="caption">Timezone</Typography>
                      <Typography fontWeight={"bold"}>
                        {data[0].timezone}
                      </Typography>
                    </Box>
                  </Stack>
                </SettingCard>
                <GeneralSettingsForm
                  paymentMethods={data[1]}
                  globalSettings={data[0]}
                  onSubmit={handleOnSubmit}
                />
              </Stack>
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
