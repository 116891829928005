import { useRef, useEffect, useCallback } from "react";
import { Key } from "../../utilities/keys";

export interface KeyPressListenerProps {
  keyCode: Key;
  handler(event: KeyboardEvent): void;
  keyEvent?: KeyEvent;
}

type KeyEvent = "keydown" | "keyup";

export function KeyPressListener({
  keyCode,
  handler,
  keyEvent = "keyup",
}: KeyPressListenerProps) {
  const tracked = useRef({ handler, keyCode });

  useEffect(() => {
    tracked.current = { handler, keyCode };
  }, [handler, keyCode]);

  const handleKeyEvent = useCallback((event: KeyboardEvent) => {
    const { handler, keyCode } = tracked.current;
    if (event.keyCode === keyCode) {
      handler(event);
    }
  }, []);

  useEffect(() => {
    document.addEventListener(keyEvent, handleKeyEvent);
    return () => {
      document.removeEventListener(keyEvent, handleKeyEvent);
    };
  }, [keyEvent, handleKeyEvent]);

  return null;
}
