import * as yup from "yup";
import { startCase as _startCase } from "lodash";

// This is a polyfill workaround for Yup not supporting global label formatting
// changes default error message to be more readable

const formatError = yup.ValidationError.formatError;

yup.ValidationError.formatError = function (message, params) {
  if (!params.label) {
    params.label = _startCase(String(params.path));
  }

  return formatError.call(this, message, params);
};

export * from "yup";
