import { MfaCodeStrategy } from "@towersystems/roam-common/lib/generated-types";
import * as yup from "yup";

export const schema = yup.object().shape({
  ui: yup.object().shape({
    codeSent: yup.boolean(),
    strategies: yup.array().of(yup.string()),
  }),

  data: yup.object().shape({
    code: yup.string().test("valid-code", "Invalid code", function (value) {
      //@ts-ignore
      const [_, rootSchema] = this.from;
      if (true === rootSchema.value.ui.codeSent) {
        return Boolean(value && value?.length >= 1);
      }
      return true;
    }),
    strategy: yup
      .string()
      .oneOf(
        [MfaCodeStrategy.SMS, MfaCodeStrategy.EMAIL],
        "Strategy must be one of the defined strategies"
      ),
    password: yup
      .string()
      .test("password-required", "Password required", function (value) {
        //@ts-ignore
        const [_, rootSchema] = this.from;
        if (true === rootSchema.value.ui.codeSent) {
          return Boolean(value && value?.length >= 1);
        }
        return true;
      }),
  }),
});
