// tslint:disable
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Decimal: any;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccessTokenSession = EntityNode & {
  __typename?: 'AccessTokenSession';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  token: Scalars['String'];
  expires: Scalars['DateTime'];
  invalidated: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddItemToInventoryMovementInput = {
  productId: Scalars['ID'];
  counted?: Maybe<Scalars['Float']>;
  /** Manually transition inventory movement item to proccessed */
  status?: Maybe<InventoryMovementItemStatus>;
};

export type AddItemToStocktakeInput = {
  productId: Scalars['ID'];
  counted?: Maybe<Scalars['Float']>;
  /** Manually transition inventory movement item to proccessed */
  status?: Maybe<StocktakeItemStatus>;
};

export type AdjustStockItemInput = {
  productId: Scalars['ID'];
  adjustBy: Scalars['Int'];
};

export type AnalyticProductMetaDataItem = {
  __typename?: 'AnalyticProductMetaDataItem';
  name: Scalars['String'];
  sku: Scalars['String'];
  id: Scalars['ID'];
  value: Scalars['Float'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type AuthenticationInput = {
  native?: Maybe<TalinkStaffAuthInputType>;
  talink_token?: Maybe<TalinkTokenAuthInputType>;
  talink?: Maybe<TalinkAuthInputType>;
};

export type AuthenticationMethod = EntityNode & {
  __typename?: 'AuthenticationMethod';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  strategy: Scalars['String'];
};

export type AuthenticationMfaInput = {
  mfaCode: Scalars['String'];
  strategy: MfaCodeStrategy;
};

export type AuthenticationResult = {
  __typename?: 'AuthenticationResult';
  talinkId: Scalars['ID'];
  userId: Scalars['ID'];
  token: Scalars['String'];
};

export type AuthenticationResultV2 = CurrentUserAndTenant | InvalidCredentialsError | InvalidMfaCodeError | MfaRequiredError | NotVerifiedError;

export type BackgroundRemoverInsufficientCreditsError = ErrorResult & {
  __typename?: 'BackgroundRemoverInsufficientCreditsError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type BackgroundRemoverNotConfiguredError = ErrorResult & {
  __typename?: 'BackgroundRemoverNotConfiguredError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Base64ImageUploadInput = {
  base64: Scalars['String'];
  imageName: Scalars['String'];
};

export enum BgRemovalStrategy {
  RemovalAiStrategy = 'RemovalAiStrategy',
  RemovalBgStrategy = 'RemovalBgStrategy'
}

/** Operators for filtering on a list of Boolean fields */
export type BooleanListOperators = {
  inList: Array<Maybe<Scalars['Boolean']>>;
};

export type BooleanOperators = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type CashMovement = EntityNode & {
  __typename?: 'CashMovement';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  openTerminalClosureId: Scalars['ID'];
  amount: Scalars['Decimal'];
  userId?: Maybe<Scalars['ID']>;
  userIdentifier?: Maybe<Scalars['String']>;
  type: CashMovementType;
  note?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
};

export type CashMovementInput = {
  /** Optional, edit only */
  id?: Maybe<Scalars['ID']>;
  /** Open terminal id to add a cash movement for */
  openTerminalClosureId: Scalars['ID'];
  /** The amount of cash being added */
  amount: Scalars['Float'];
  /** The type of cash movement */
  type: CashMovementType;
  /** Optional note for reason of movement */
  note?: Maybe<Scalars['String']>;
  /** The date time of the movement. default to current time */
  date?: Maybe<Scalars['DateTime']>;
};

export enum CashMovementType {
  /** Cash in */
  CASH_IN = 'CASH_IN',
  /** Petty cash in */
  PETTY_CASH_IN = 'PETTY_CASH_IN',
  /** Cash out */
  CASH_OUT = 'CASH_OUT',
  /** Pretty cash out */
  PETTY_CASH_OUT = 'PETTY_CASH_OUT',
  /** Opening float */
  OPEN_FLOAT = 'OPEN_FLOAT',
  /** The closing float */
  CLOSING_FLOAT = 'CLOSING_FLOAT'
}

export type Category = EntityNode & {
  __typename?: 'Category';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  bannerImageUrl?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  departmentId?: Maybe<Scalars['String']>;
  numberOfProducts: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryFilterParameter = {
  id?: Maybe<IdOperators>;
  description?: Maybe<StringOperators>;
  name?: Maybe<StringOperators>;
  bannerImageUrl?: Maybe<StringOperators>;
  active?: Maybe<BooleanOperators>;
  departmentId?: Maybe<StringOperators>;
  numberOfProducts?: Maybe<NumberOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type CategoryInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['ID']>;
};

export type CategoryList = PaginatedList & {
  __typename?: 'CategoryList';
  items: Array<Category>;
  meta: PaginatedListMeta;
};

export type CategoryListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<CategorySortParameter>;
  filter?: Maybe<CategoryFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type CategorySortParameter = {
  id?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  bannerImageUrl?: Maybe<SortOrder>;
  departmentId?: Maybe<SortOrder>;
  numberOfProducts?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type CloseTerminalInput = {
  terminalId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  /** Value will be used as the opening float next time opened */
  nextOpeningFloat?: Maybe<Scalars['Decimal']>;
  payments: Array<TerminalClosurePaymentTotalInput>;
};

export type ConfigArg = {
  __typename?: 'ConfigArg';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigArgDefinition = {
  __typename?: 'ConfigArgDefinition';
  name: Scalars['String'];
  type: Scalars['String'];
  list: Scalars['Boolean'];
  required: Scalars['Boolean'];
  defaultValue?: Maybe<Scalars['JSON']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ConfigurableOperation = {
  __typename?: 'ConfigurableOperation';
  code: Scalars['String'];
  args: Array<ConfigArg>;
};

export type ConfigurableOperationDefinition = {
  __typename?: 'ConfigurableOperationDefinition';
  code: Scalars['String'];
  args: Array<ConfigArgDefinition>;
  description: Scalars['String'];
};

export enum ContactPreference {
  /** Customer will receive Email notifications */
  EMAIL = 'EMAIL',
  /** Customer will receive SMS notifications */
  SMS = 'SMS',
  /** Customer will receive both SMS and Email notifications */
  BOTH = 'BOTH',
  /** Customer will not be contacted */
  NONE = 'NONE',
  /** Customer has not yet specified contact preference */
  UNSET = 'UNSET'
}

export type CountStockItemInput = {
  productId: Scalars['ID'];
  counted: Scalars['Float'];
};

export type CreateAddressInput = {
  fullName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  departmentId: Scalars['ID'];
  bannerImage?: Maybe<Scalars['Upload']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type CreateDepartmentInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['Upload']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type CreateImageResult = ProductImage | MimeTypeError;

export type CreateLocationInput = {
  name: Scalars['String'];
  retailerLocationKey: Scalars['String'];
};

export type CreatePaymentMethodInput = {
  name: Scalars['String'];
  payTypeId: Scalars['String'];
  /** @deprecated use retailerPayTypeId */
  retailerPayCode?: Maybe<Scalars['String']>;
  retailerPayTypeId?: Maybe<Scalars['ID']>;
  configuration?: Maybe<PaymentMethodConfigurationInput>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CreateProductImageBase64Input = {
  base64: Scalars['String'];
  imageName: Scalars['String'];
  sendRetailer?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
};

export type CreateProductImageInput = {
  productId: Scalars['ID'];
  sendRetailer?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  file: Scalars['Upload'];
};

export type CreateProductInput = {
  stockCode?: Maybe<Scalars['String']>;
  stockNumber: Scalars['String'];
  taxable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['ID']>;
  department?: Maybe<Scalars['String']>;
  variantParentId?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  locationInventoryPricing?: Maybe<Array<InventoryPricingInput>>;
  options?: Maybe<Array<Scalars['String']>>;
  selectedOptions?: Maybe<Array<SelectedOptionInput>>;
  loyaltyPointEarnValue?: Maybe<Scalars['Int']>;
  loyaltyPointRedeemValue?: Maybe<Scalars['Int']>;
  serialNumberRequired?: Maybe<Scalars['Boolean']>;
};

export type CreateRetailerPayTypeInput = {
  payCode: Scalars['String'];
  payDescription?: Maybe<Scalars['String']>;
  payType?: Maybe<Scalars['String']>;
  /** defaults to false */
  payOver?: Maybe<Scalars['Boolean']>;
};

export type CreateSaleInput = {
  /** Valid id of location to assign sale to */
  locationId: Scalars['ID'];
  /** Optional invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Sets the invoice sequence */
  invoiceSequence?: Maybe<Scalars['Int']>;
  /** Valid id of terminal to assign sale to */
  terminalId: Scalars['ID'];
  /** Valid id of the customer accociated with the sale */
  customerId?: Maybe<Scalars['ID']>;
  /** Valid id of the retailer staff who created this sale. */
  staffId?: Maybe<Scalars['ID']>;
  /** optional, send a receipt to customer */
  receiptTo?: Maybe<Scalars['String']>;
  /** optional, the the receipt template to use when sending email */
  receiptId?: Maybe<Scalars['ID']>;
  /** Time of sale, by default current time will be assigned */
  saleDate?: Maybe<Scalars['DateTime']>;
  /** One of: OPEN, SAVED, CLOSED, LAYBY, LAYBY_CLOSED, VOIDED */
  state: Scalars['String'];
  /** Optional note to add to the sale */
  note?: Maybe<Scalars['String']>;
  /** Optional: Store any metadata relevent to the sale */
  metadata?: Maybe<Scalars['JSON']>;
  lineItems: Array<SaleLineItemInput>;
  /** An arra yof payments */
  payments: Array<RegisterSalePaymentInput>;
};

export type CreateStaffInput = {
  /** Must be unique */
  initials: Scalars['String'];
  fullName: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CreateStaffUserAccountInput = {
  staffId: Scalars['ID'];
  /** unique identifier for the user account */
  username: Scalars['String'];
  /** Optional, required for 2fa */
  emailAddress?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export type CreateStripeExpressAccountInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type CreateTerminalInput = {
  name: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  invoiceSequence?: Maybe<Scalars['Int']>;
  defaultReceiptTemplateId?: Maybe<Scalars['ID']>;
  paymentMethodIds?: Maybe<Array<Scalars['ID']>>;
  salePrefix?: Maybe<Scalars['String']>;
  saleSufix?: Maybe<Scalars['String']>;
};


export type CurrencyInput = {
  code: Scalars['Currency'];
  symbol: Scalars['String'];
};

export type CurrencyType = {
  __typename?: 'CurrencyType';
  code: Scalars['Currency'];
  symbol?: Maybe<Scalars['String']>;
};

export type CurrentUserAndTenant = {
  __typename?: 'CurrentUserAndTenant';
  currentUser: CurrentUserV2;
  tenant: Tenant;
};

export type CurrentUserV2 = {
  __typename?: 'CurrentUserV2';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  token: Scalars['String'];
  permissions: Array<Permission>;
  restrictedTerminalIds: Array<Scalars['ID']>;
};

export type Customer = EntityNode & {
  __typename?: 'Customer';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: Maybe<Gender>;
  emailAddress?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['String']>;
  isRetailerCusAccount: Scalars['Boolean'];
  amountSpend: Scalars['Float'];
  invoicedAmount: Scalars['Float'];
  numberOfOrders: Scalars['Int'];
  addresses?: Maybe<Array<CustomerAddress>>;
  loyaltyPoints: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerAddress = EntityNode & {
  __typename?: 'CustomerAddress';
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerAddressInput = {
  id?: Maybe<Scalars['ID']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type CustomerAddressToAddInput = {
  isPrimary?: Maybe<Scalars['Boolean']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  country: Scalars['String'];
};

export type CustomerFilterParameter = {
  id?: Maybe<IdOperators>;
  firstName?: Maybe<StringOperators>;
  lastName?: Maybe<StringOperators>;
  gender?: Maybe<StringOperators>;
  emailAddress?: Maybe<StringOperators>;
  mobileNumber?: Maybe<StringOperators>;
  phoneNumber?: Maybe<StringOperators>;
  retailerId?: Maybe<StringOperators>;
  isRetailerCusAccount?: Maybe<BooleanOperators>;
  amountSpend?: Maybe<NumberOperators>;
  invoicedAmount?: Maybe<NumberOperators>;
  numberOfOrders?: Maybe<NumberOperators>;
  loyaltyPoints?: Maybe<NumberOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
  term?: Maybe<StringOperators>;
};

export type CustomerInput = {
  id?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  addressesToAdd?: Maybe<Array<Maybe<CustomerAddressToAddInput>>>;
  addressesToRemove?: Maybe<Array<Scalars['ID']>>;
  contactPreference?: Maybe<ContactPreference>;
  loyaltyPoints?: Maybe<Scalars['Int']>;
  retailerId?: Maybe<Scalars['String']>;
  isRetailerCusAccount?: Maybe<Scalars['Boolean']>;
};

export type CustomerList = PaginatedList & {
  __typename?: 'CustomerList';
  items: Array<Customer>;
  meta: PaginatedListMeta;
};

export type CustomerListExtendedFilterOptions = {
  fullName?: Maybe<StringOperators>;
};

export type CustomerListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<CustomerSortParameter>;
  filter?: Maybe<CustomerFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
  extendedFilter?: Maybe<CustomerListExtendedFilterOptions>;
};

export type CustomerSortParameter = {
  id?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  emailAddress?: Maybe<SortOrder>;
  mobileNumber?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  retailerId?: Maybe<SortOrder>;
  amountSpend?: Maybe<SortOrder>;
  invoicedAmount?: Maybe<SortOrder>;
  numberOfOrders?: Maybe<SortOrder>;
  loyaltyPoints?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

/** Operators for filtering on a list of Date fields */
export type DateListOperators = {
  inList: Array<Maybe<Scalars['DateTime']>>;
};

export type DateOperators = {
  eq?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['DateTime']>;
  after?: Maybe<Scalars['DateTime']>;
  between?: Maybe<DateRange>;
};

export type DateRange = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};



export type Deleted = {
  __typename?: 'Deleted';
  result?: Maybe<DeletionResult>;
};

export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  result: DeletionResult;
  reason?: Maybe<Scalars['String']>;
};

export enum DeletionResult {
  /** The entity was successfully deleted */
  DELETED = 'DELETED',
  /** Deletion did not take place, reason given in message */
  NOT_DELETED = 'NOT_DELETED'
}

export type Department = EntityNode & {
  __typename?: 'Department';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  bannerImageUrl?: Maybe<Scalars['String']>;
  numberOfCategories: Scalars['Int'];
  numberOfProducts: Scalars['Int'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DepartmentFilterParameter = {
  id?: Maybe<IdOperators>;
  description?: Maybe<StringOperators>;
  name?: Maybe<StringOperators>;
  bannerImageUrl?: Maybe<StringOperators>;
  numberOfCategories?: Maybe<NumberOperators>;
  numberOfProducts?: Maybe<NumberOperators>;
  active?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type DepartmentInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type DepartmentList = PaginatedList & {
  __typename?: 'DepartmentList';
  items: Array<Department>;
  meta: PaginatedListMeta;
};

export type DepartmentListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<DepartmentSortParameter>;
  filter?: Maybe<DepartmentFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type DepartmentSortParameter = {
  id?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  bannerImageUrl?: Maybe<SortOrder>;
  numberOfCategories?: Maybe<SortOrder>;
  numberOfProducts?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type DownloadReceiptPdfBase64StringResponse = {
  __typename?: 'DownloadReceiptPdfBase64StringResponse';
  data: Scalars['String'];
  fileName: Scalars['String'];
};

export type DownloadStocktakeCsvBase64StringResponse = {
  __typename?: 'DownloadStocktakeCsvBase64StringResponse';
  data: Scalars['String'];
  fileName: Scalars['String'];
};

/** Returned when attempting to create a Customer with an email address already registered to an existing User. */
export type EmailAddressConflictError = ErrorResult & {
  __typename?: 'EmailAddressConflictError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type EmailClosureSummaryInput = {
  closureId: Scalars['ID'];
  to: Scalars['String'];
};

export type EntityNode = {
  id: Scalars['ID'];
};

export enum ErrorCode {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  INVALID_LINKLY_CREDENTIALS_ERROR = 'INVALID_LINKLY_CREDENTIALS_ERROR',
  LINKLY_ERROR = 'LINKLY_ERROR',
  MIME_TYPE_ERROR = 'MIME_TYPE_ERROR',
  BACKGROUND_REMOVER_NOT_CONFIGURED_ERROR = 'BACKGROUND_REMOVER_NOT_CONFIGURED_ERROR',
  BACKGROUND_REMOVER_INSUFFICIENT_CREDITS_ERROR = 'BACKGROUND_REMOVER_INSUFFICIENT_CREDITS_ERROR',
  INVALID_CREDENTIALS_ERROR = 'INVALID_CREDENTIALS_ERROR',
  MFA_REQUIRED_ERROR = 'MFA_REQUIRED_ERROR',
  INVALID_MFA_CODE_ERROR = 'INVALID_MFA_CODE_ERROR',
  INVALID_MFA_STRATEGY_ERROR = 'INVALID_MFA_STRATEGY_ERROR',
  MFA_ALREADY_SETUP_ERROR = 'MFA_ALREADY_SETUP_ERROR',
  INVALID_MFA_IDENTIFIER_ERROR = 'INVALID_MFA_IDENTIFIER_ERROR',
  NATIVE_AUTH_STRATEGY_ERROR = 'NATIVE_AUTH_STRATEGY_ERROR',
  EMAIL_ADDRESS_CONFLICT_ERROR = 'EMAIL_ADDRESS_CONFLICT_ERROR',
  MISSING_PASSWORD_ERROR = 'MISSING_PASSWORD_ERROR',
  PASSWORD_VALIDATION_ERROR = 'PASSWORD_VALIDATION_ERROR',
  NOT_VERIFIED_ERROR = 'NOT_VERIFIED_ERROR',
  PASSWORD_ALREADY_SET_ERROR = 'PASSWORD_ALREADY_SET_ERROR',
  SALE_STATE_TRANSITION_ERROR = 'SALE_STATE_TRANSITION_ERROR',
  NEGATIVE_QUANTITY_ERROR = 'NEGATIVE_QUANTITY_ERROR',
  INSUFFICIENT_STOCK_ERROR = 'INSUFFICIENT_STOCK_ERROR',
  STOCK_NUMBER_NOT_FOUND_ERROR = 'STOCK_NUMBER_NOT_FOUND_ERROR'
}

export type ErrorResult = {
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type ExportRetailerSaleOptions = {
  /** The date to export sales after */
  after?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export enum ExportSaleType {
  /** WEBLINK */
  WEBLINK = 'WEBLINK'
}

export type FeaturesList = {
  __typename?: 'FeaturesList';
  utilities: Scalars['Boolean'];
  pointOfSale: Scalars['Boolean'];
  selfCheckout: Scalars['Boolean'];
  stocktake: Scalars['Boolean'];
};

export type FilterValueMeta = {
  __typename?: 'FilterValueMeta';
  type: FilterValueMetaType;
  value: Scalars['String'];
  label: Scalars['String'];
};

export type FilterValueMetaInput = {
  type: FilterValueMetaType;
  /** max 100 at a time to stop overload server */
  value: Array<Scalars['String']>;
};

export enum FilterValueMetaType {
  DEPARTMENT_ID = 'DEPARTMENT_ID',
  CATEGORY_ID = 'CATEGORY_ID'
}

export enum Gender {
  /** male */
  MALE = 'MALE',
  /** female */
  FEMALE = 'FEMALE',
  /** other */
  OTHER = 'OTHER',
  /** unknown */
  UNKNOWN = 'UNKNOWN'
}

export type GenerateAccessTokenInput = {
  name: Scalars['String'];
};

export type GlobalSettings = {
  __typename?: 'GlobalSettings';
  cashManagedPaymentMethodId?: Maybe<Scalars['ID']>;
  taxPercentage: Scalars['Int'];
  countryCode: Scalars['String'];
  currency: CurrencyType;
  timezone: Scalars['String'];
  businessName?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  abn?: Maybe<Scalars['String']>;
  talinkId: Scalars['String'];
  productSurchargeId: Scalars['ID'];
  laybyEnabled: Scalars['Boolean'];
  defaultLaybyDepositPercentage: Scalars['Int'];
  minLaybySpend: Scalars['Float'];
  defaultTerminalClosureFloat: Scalars['Float'];
  automaticOpenTerminalClosure: Scalars['Boolean'];
  showExpectedClosureCounts: Scalars['Boolean'];
  removalAiDetails?: Maybe<RemovalAiDetails>;
  removalBgDetails?: Maybe<RemovalBgDetails>;
  bgRemovalStrategy?: Maybe<Scalars['String']>;
  automaticBackgroundRemoval: Scalars['Boolean'];
  stocktakeSendToAddress?: Maybe<Scalars['String']>;
  automaticallyEmailStockTake: Scalars['Boolean'];
  automaticallyEmailTerminalClosureSummary: Scalars['Boolean'];
  terminalClosureSendToAddress?: Maybe<Scalars['String']>;
};


/** Operators for filtering on a list of ID fields */
export type IdListOperators = {
  inList: Array<Maybe<Scalars['ID']>>;
};

/** Operators for filtering on an ID field */
export type IdOperators = {
  eq?: Maybe<Scalars['String']>;
  notEq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

/** Returned when attempting to set a SaleItem quantity greater than the available stock. */
export type InsufficientStockError = ErrorResult & {
  __typename?: 'InsufficientStockError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  quantityAvailable: Scalars['Int'];
  sale: Sale;
};

export type InvalidCredentialsError = ErrorResult & {
  __typename?: 'InvalidCredentialsError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  authenticationError: Scalars['String'];
};

/** The username, password, or paircode was invalid. */
export type InvalidLinklyCredentialsError = ErrorResult & {
  __typename?: 'InvalidLinklyCredentialsError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Retured if MFA code is invalid for authentication strategy */
export type InvalidMfaCodeError = ErrorResult & {
  __typename?: 'InvalidMfaCodeError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type InvalidMfaIdentifierError = ErrorResult & {
  __typename?: 'InvalidMfaIdentifierError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type InvalidMfaStrategyError = ErrorResult & {
  __typename?: 'InvalidMfaStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type InventoryAdjustQuantityInput = {
  inventoryLevelId: Scalars['ID'];
  available: Scalars['Int'];
  unitsPerPack?: Maybe<Scalars['Int']>;
};

export type InventoryAdjustQuantityInputV2 = {
  available: Scalars['Int'];
  inventoryItemId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type InventoryItem = EntityNode & {
  __typename?: 'InventoryItem';
  id: Scalars['ID'];
  tracked: Scalars['Boolean'];
  inventoryLevels: Array<InventoryLevel>;
  productId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryItemFilterParameter = {
  id?: Maybe<IdOperators>;
  tracked?: Maybe<BooleanOperators>;
  productId?: Maybe<IdOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type InventoryItemInput = {
  tracked?: Maybe<Scalars['Boolean']>;
};

export type InventoryItemList = PaginatedList & {
  __typename?: 'InventoryItemList';
  items: Array<InventoryItem>;
  meta: PaginatedListMeta;
};

export type InventoryItemListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<InventoryItemSortParameter>;
  filter?: Maybe<InventoryItemFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type InventoryItemSortParameter = {
  id?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type InventoryLevel = EntityNode & {
  __typename?: 'InventoryLevel';
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  onHand: Scalars['Int'];
  unitsPerPack: Scalars['Int'];
  inventoryItemId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryLevelFilterParameter = {
  id?: Maybe<IdOperators>;
  locationId?: Maybe<IdOperators>;
  onHand?: Maybe<NumberOperators>;
  unitsPerPack?: Maybe<NumberOperators>;
  inventoryItemId?: Maybe<IdOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type InventoryLevelInput = {
  availableQuantity: Scalars['Int'];
  locationId: Scalars['ID'];
  unitsPerPack?: Maybe<Scalars['Int']>;
};

export type InventoryLevelList = PaginatedList & {
  __typename?: 'InventoryLevelList';
  items: Array<InventoryLevel>;
  meta: PaginatedListMeta;
};

export type InventoryLevelListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<InventoryLevelSortParameter>;
  filter?: Maybe<InventoryLevelFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type InventoryLevelMovement = EntityNode & {
  __typename?: 'InventoryLevelMovement';
  id: Scalars['ID'];
  inventoryLevelId?: Maybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  adjustment: Scalars['Int'];
  userId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryLevelMovementFilterParameter = {
  id?: Maybe<IdOperators>;
  inventoryLevelId?: Maybe<IdOperators>;
  quantity?: Maybe<NumberOperators>;
  adjustment?: Maybe<NumberOperators>;
  userId?: Maybe<IdOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
  productVariantId?: Maybe<IdOperators>;
  productId?: Maybe<IdOperators>;
  locationId?: Maybe<IdOperators>;
};

export type InventoryLevelMovementList = PaginatedList & {
  __typename?: 'InventoryLevelMovementList';
  items: Array<InventoryLevelMovement>;
  meta: PaginatedListMeta;
};

export type InventoryLevelMovementListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<InventoryLevelMovementSortParameter>;
  filter?: Maybe<InventoryLevelMovementFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type InventoryLevelMovementSortParameter = {
  id?: Maybe<SortOrder>;
  inventoryLevelId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  adjustment?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type InventoryLevelSortParameter = {
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  onHand?: Maybe<SortOrder>;
  unitsPerPack?: Maybe<SortOrder>;
  inventoryItemId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type InventoryMovement = EntityNode & {
  __typename?: 'InventoryMovement';
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  location?: Maybe<Location>;
  userId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  targetLocationId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  inventoryMovementDate: Scalars['DateTime'];
  referenceNo?: Maybe<Scalars['String']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  receivedAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  dispatchedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  status: InventoryMovementStatus;
  filters?: Maybe<Array<InventoryMovementFilter>>;
  totals?: Maybe<InventoryMovementTotals>;
  isFullStocktake: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryMovementFilter = {
  __typename?: 'InventoryMovementFilter';
  type: InventoryMovementFilterType;
  value: Scalars['String'];
};

export type InventoryMovementFilterInput = {
  type: InventoryMovementFilterType;
  value: Scalars['String'];
};

export type InventoryMovementFilterParameter = {
  id?: Maybe<IdOperators>;
  locationId?: Maybe<IdOperators>;
  userId?: Maybe<IdOperators>;
  targetLocationId?: Maybe<IdOperators>;
  name?: Maybe<StringOperators>;
  inventoryMovementDate?: Maybe<DateOperators>;
  referenceNo?: Maybe<StringOperators>;
  dueAt?: Maybe<DateOperators>;
  receivedAt?: Maybe<DateOperators>;
  sentAt?: Maybe<DateOperators>;
  dispatchedAt?: Maybe<DateOperators>;
  cancelledAt?: Maybe<DateOperators>;
  completedAt?: Maybe<DateOperators>;
  status?: Maybe<StringOperators>;
  isFullStocktake?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export enum InventoryMovementFilterType {
  PRODUCT = 'PRODUCT',
  PRODUCT_VARIANT = 'PRODUCT_VARIANT',
  CATEGORY = 'CATEGORY',
  DEPARTMENT = 'DEPARTMENT'
}

export type InventoryMovementItem = EntityNode & {
  __typename?: 'InventoryMovementItem';
  id: Scalars['ID'];
  inventoryMovementId: Scalars['ID'];
  productId: Scalars['ID'];
  stockCode?: Maybe<Scalars['String']>;
  counted?: Maybe<Scalars['Int']>;
  initialExpected?: Maybe<Scalars['Int']>;
  expected?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Float']>;
  status?: Maybe<InventoryMovementItemStatus>;
  manual: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type InventoryMovementItemFilterParameter = {
  id?: Maybe<IdOperators>;
  inventoryMovementId?: Maybe<IdOperators>;
  productId?: Maybe<IdOperators>;
  stockCode?: Maybe<StringOperators>;
  counted?: Maybe<NumberOperators>;
  initialExpected?: Maybe<NumberOperators>;
  expected?: Maybe<NumberOperators>;
  cost?: Maybe<NumberOperators>;
  status?: Maybe<StringOperators>;
  manual?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type InventoryMovementItemList = PaginatedList & {
  __typename?: 'InventoryMovementItemList';
  items: Array<InventoryMovementItem>;
  meta: PaginatedListMeta;
};

export type InventoryMovementItemListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<InventoryMovementItemSortParameter>;
  filter?: Maybe<InventoryMovementItemFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type InventoryMovementItemSortParameter = {
  id?: Maybe<SortOrder>;
  inventoryMovementId?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  stockCode?: Maybe<SortOrder>;
  counted?: Maybe<SortOrder>;
  initialExpected?: Maybe<SortOrder>;
  expected?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export enum InventoryMovementItemStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS'
}

export type InventoryMovementItemSummaryTotals = {
  __typename?: 'InventoryMovementItemSummaryTotals';
  inventoryMovementItemId: Scalars['ID'];
  stockNumber?: Maybe<Scalars['String']>;
  received: Scalars['Int'];
  counted: Scalars['Int'];
  price: Scalars['Float'];
  productId?: Maybe<Scalars['ID']>;
  productName?: Maybe<Scalars['String']>;
  totalCost: Scalars['Float'];
};

export type InventoryMovementList = PaginatedList & {
  __typename?: 'InventoryMovementList';
  items: Array<InventoryMovement>;
  meta: PaginatedListMeta;
};

export type InventoryMovementListExtendedFilterOptions = {
  productId?: Maybe<IdListOperators>;
};

export type InventoryMovementListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<InventoryMovementSortParameter>;
  filter?: Maybe<InventoryMovementFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
  extendedFilter?: Maybe<InventoryMovementListExtendedFilterOptions>;
};

export type InventoryMovementSortParameter = {
  id?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  targetLocationId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  inventoryMovementDate?: Maybe<SortOrder>;
  referenceNo?: Maybe<SortOrder>;
  dueAt?: Maybe<SortOrder>;
  receivedAt?: Maybe<SortOrder>;
  sentAt?: Maybe<SortOrder>;
  dispatchedAt?: Maybe<SortOrder>;
  cancelledAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export enum InventoryMovementStatus {
  /** RESERVED FOR INITIAL TRANSITION STATE. DO NOT SEND TO SERVER */
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
  STOCKTAKE_PLANNED = 'STOCKTAKE_PLANNED',
  STOCKTAKE_UNDERWAY = 'STOCKTAKE_UNDERWAY',
  STOCKTAKE_UNDERWAY_PROCESSING = 'STOCKTAKE_UNDERWAY_PROCESSING',
  STOCKTAKE_UNDERWAY_PROCESSING_ERROR = 'STOCKTAKE_UNDERWAY_PROCESSING_ERROR',
  STOCKTAKE_UNDERWAY_PROCESSED = 'STOCKTAKE_UNDERWAY_PROCESSED',
  STOCKTAKE_COMPLETE = 'STOCKTAKE_COMPLETE'
}

export type InventoryMovementSummaryTotal = {
  __typename?: 'InventoryMovementSummaryTotal';
  cost: Scalars['Float'];
  received: Scalars['Int'];
  counted: Scalars['Int'];
};

export type InventoryMovementSummaryTotals = {
  __typename?: 'InventoryMovementSummaryTotals';
  totals: InventoryMovementSummaryTotal;
  items: Array<InventoryMovementItemSummaryTotals>;
};

export type InventoryMovementTotals = {
  __typename?: 'InventoryMovementTotals';
  totalCountGain?: Maybe<Scalars['Float']>;
  totalCostGain?: Maybe<Scalars['Float']>;
  totalCountLoss?: Maybe<Scalars['Float']>;
  totalCostLoss?: Maybe<Scalars['Float']>;
};

export type InventoryPricingInput = {
  /** Required when updating */
  id?: Maybe<Scalars['ID']>;
  /** Required when creating only */
  retailerLocationKey?: Maybe<Scalars['String']>;
  stockOnHand?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  retailPrice?: Maybe<Scalars['Float']>;
  rrPrice?: Maybe<Scalars['Float']>;
  taxRatePercent?: Maybe<Scalars['Int']>;
};


export type Layout = EntityNode & {
  __typename?: 'Layout';
  id: Scalars['ID'];
  nodes: Array<LayoutNode>;
  terminalId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type LayoutFilterParameter = {
  id?: Maybe<IdOperators>;
  terminalId?: Maybe<IdOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type LayoutInput = {
  id?: Maybe<Scalars['ID']>;
  /** ID of terminal to associate with */
  terminalId?: Maybe<Scalars['ID']>;
};

export type LayoutList = PaginatedList & {
  __typename?: 'LayoutList';
  items: Array<Layout>;
  meta: PaginatedListMeta;
};

export type LayoutListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<LayoutSortParameter>;
  filter?: Maybe<LayoutFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type LayoutNode = EntityNode & {
  __typename?: 'LayoutNode';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['HexColorCode']>;
  layoutId: Scalars['ID'];
  productId?: Maybe<Scalars['ID']>;
  showImage?: Maybe<Scalars['Boolean']>;
  type?: Maybe<LayoutNodeType>;
  position: Scalars['Int'];
  action?: Maybe<LayoutNodeActionType>;
  filter?: Maybe<LayoutNodeFilter>;
  link?: Maybe<LayoutNodeLink>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum LayoutNodeActionType {
  /** OPEN DISCOUNT SALE MODAL */
  DISCOUNT_SALE = 'DISCOUNT_SALE',
  /** OPEN SWITCH SALE MODAL */
  SWITCH_SALE = 'SWITCH_SALE',
  /** OPEN PARK SALE MODAL */
  PARK_SALE = 'PARK_SALE',
  /** OPEN CANCEL SALE MODAL */
  CANCEL_SALE = 'CANCEL_SALE',
  /** OPEN ACTION TO ADD OR REPLACE CUSTOMER */
  ADD_CUSTOMER = 'ADD_CUSTOMER',
  /** ADD NOTE TO SALE */
  ADD_NOTE_TO_SALE = 'ADD_NOTE_TO_SALE',
  /** APPLY_CUSTOMER_LOYALTY_POINTS */
  APPLY_CUSTOMER_LOYALTY_POINTS = 'APPLY_CUSTOMER_LOYALTY_POINTS',
  /** SALES HISTORY */
  SALES_HISTORY = 'SALES_HISTORY',
  /** CUSTOMER ACCOUNT PAYMENT */
  CUSTOMER_ACCOUNT_PAYMENT = 'CUSTOMER_ACCOUNT_PAYMENT'
}

export type LayoutNodeFilter = {
  __typename?: 'LayoutNodeFilter';
  type: LayoutNodeFilterType;
  values: Array<Scalars['String']>;
};

export type LayoutNodeFilterInput = {
  values: Array<Scalars['String']>;
  type?: Maybe<LayoutNodeFilterType>;
};

export type LayoutNodeFilterParameter = {
  id?: Maybe<IdOperators>;
  label?: Maybe<StringOperators>;
  layoutId?: Maybe<IdOperators>;
  productId?: Maybe<IdOperators>;
  showImage?: Maybe<BooleanOperators>;
  type?: Maybe<StringOperators>;
  position?: Maybe<NumberOperators>;
  action?: Maybe<StringOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export enum LayoutNodeFilterType {
  DEPARTMENT = 'DEPARTMENT',
  CATEGORY = 'CATEGORY'
}

export type LayoutNodeInput = {
  id?: Maybe<Scalars['ID']>;
  /** manually set the lable */
  label?: Maybe<Scalars['String']>;
  /** Background color of node */
  color?: Maybe<Scalars['HexColorCode']>;
  /** required when creating */
  layoutId?: Maybe<Scalars['ID']>;
  /** required when type PRODUCT */
  productId?: Maybe<Scalars['ID']>;
  /** required when type PRODUCT */
  position?: Maybe<Scalars['Int']>;
  /** manually show image */
  showImage?: Maybe<Scalars['Boolean']>;
  /** required when creating. cant be changed once saved */
  type?: Maybe<LayoutNodeType>;
  /** required when type is ACTION */
  action?: Maybe<LayoutNodeActionType>;
  /** required when action is CUSTOM_URL */
  link?: Maybe<LayoutNodeLinkInput>;
  /** filter for products */
  filter?: Maybe<LayoutNodeFilterInput>;
};

export type LayoutNodeLink = {
  __typename?: 'LayoutNodeLink';
  url: Scalars['String'];
  newTab: Scalars['Boolean'];
};

export type LayoutNodeLinkInput = {
  url: Scalars['String'];
  newTab: Scalars['Boolean'];
};

export type LayoutNodeList = PaginatedList & {
  __typename?: 'LayoutNodeList';
  items: Array<LayoutNode>;
  meta: PaginatedListMeta;
};

export type LayoutNodeListExtendedFilterOptions = {
  value?: Maybe<StringOperators>;
};

export type LayoutNodeListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<LayoutNodeSortParameter>;
  filter?: Maybe<LayoutNodeFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
  extendedFilter?: Maybe<LayoutNodeListExtendedFilterOptions>;
};

export type LayoutNodeSortParameter = {
  id?: Maybe<SortOrder>;
  label?: Maybe<SortOrder>;
  layoutId?: Maybe<SortOrder>;
  productId?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export enum LayoutNodeType {
  /** PRODUCT */
  PRODUCT = 'PRODUCT',
  /** ACTION */
  ACTION = 'ACTION',
  /** FILTER */
  FILTER = 'FILTER'
}

export type LayoutSortParameter = {
  id?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type LinklyConfiguration = {
  __typename?: 'LinklyConfiguration';
  id: Scalars['ID'];
  paymentMethodId: Scalars['String'];
  receiptAutoPrint: ReceiptAutoPrintType;
  pairedPinpads: Array<LinklyPairedPinpad>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type LinklyConfigurationInput = {
  receiptAutoPrint?: Maybe<ReceiptAutoPrintType>;
};

/** The POS should allow the operator to try the request again. */
export type LinklyError = ErrorResult & {
  __typename?: 'LinklyError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type LinklyPairPinpadInput = {
  name: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
  pairCode: Scalars['String'];
};

export type LinklyPairedPinpad = {
  __typename?: 'LinklyPairedPinpad';
  id: Scalars['ID'];
  linklyConfigurationId: Scalars['ID'];
  name: Scalars['String'];
  secret: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type Location = EntityNode & {
  __typename?: 'Location';
  id: Scalars['ID'];
  retailerLocationKey: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LocationFilterParameter = {
  id?: Maybe<IdOperators>;
  retailerLocationKey?: Maybe<StringOperators>;
  name?: Maybe<StringOperators>;
  businessName?: Maybe<StringOperators>;
  enabled?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
};

export type LocationList = PaginatedList & {
  __typename?: 'LocationList';
  items: Array<Location>;
  meta: PaginatedListMeta;
};

export type LocationListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<LocationSortParameter>;
  filter?: Maybe<LocationFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type LocationSortParameter = {
  id?: Maybe<SortOrder>;
  retailerLocationKey?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  businessName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR'
}

export type MfaAlreadySetupError = ErrorResult & {
  __typename?: 'MfaAlreadySetupError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export enum MfaCodeStrategy {
  /** The code is sent to the user via email */
  EMAIL = 'EMAIL',
  /** The code is sent to the user via SMS */
  SMS = 'SMS'
}

export type MfaMethod = EntityNode & {
  __typename?: 'MfaMethod';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  strategy: Scalars['String'];
  identifier?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

/** Valid AUTH, but MFA code is required for this user for authentication strategy */
export type MfaRequiredError = ErrorResult & {
  __typename?: 'MfaRequiredError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  /**
   * The obscured MFA strategy that is required for this user.
   * Can be used to determine which MFA strategy to use to prompt the user.
   */
  strategies?: Maybe<Array<MfaCodeStrategy>>;
};

export type MimeTypeError = ErrorResult & {
  __typename?: 'MimeTypeError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};

/** Returned when attempting to register or verify a customer account without a password, when one is required. */
export type MissingPasswordError = ErrorResult & {
  __typename?: 'MissingPasswordError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type MovementItemProductSummary = {
  __typename?: 'MovementItemProductSummary';
  productName?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  stockNumber?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  onHand?: Maybe<Scalars['Int']>;
};

export type MovementItemProductSummaryOptions = {
  productId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Authenticates the user (store owner, or staff) using a named authentication strategy */
  authenticate: AuthenticationResultV2;
  /**
   * Once authenticated, can switch to a different user (staff).
   * MFA is still required if switching to owner (or admin) account.
   */
  switchUser: SwitchUserResult;
  /** Login with a valid talink token */
  loginWithTaLinkToken: NativeAuthenticationResult;
  /**
   * Request a new MFA code to be sent to the user's preferred MFA method.
   * Credentials are required, as the user is not authenticated yet as
   * no sesion is granted untill the MFA code is validated.
   */
  requestMfaCode: Success;
  /** Create a new Category */
  createCategory: Category;
  /** Update an existing Category */
  updateCategory: Category;
  /** Delete a Category */
  deleteCategory: DeletionResponse;
  /** Create a new Customer */
  createCustomer: Customer;
  /** Update an existing Customer */
  updateCustomer: Customer;
  /** Delete a Product */
  deleteCustomer: DeletionResponse;
  /** Create a new Department */
  createDepartment: Department;
  /** Update an existing Department */
  updateDepartment: Department;
  /** Delete a Department */
  deleteDepartment: DeletionResponse;
  exportSale: Success;
  updateGlobalSettings: GlobalSettings;
  /** Add or update an exisitng inventory movement item */
  addItemToInventoryMovement: InventoryMovementItem;
  /** Bulk add or update (up to 250) inventory movement item */
  bulkAddItemToInventoryMovement: Success;
  /** Removes the specific prodct variant from the inventory movement */
  removeItemFromInventoryMovement: DeletionResponse;
  /** Adjust the inventory */
  inventoryAdjustQuantity: InventoryLevel;
  applyDefaultLayoutConfiguration: Layout;
  createLayout: Layout;
  updateLayout: Layout;
  deleteLayout: DeletionResponse;
  swapLayoutNodes: SwapLayoutNodesResult;
  shiftLayoutNodes: Array<LayoutNode>;
  createLayoutNode: LayoutNode;
  updateLayoutNode: LayoutNode;
  deleteLayoutNode: DeletionResponse;
  /** Create a new Linkly configuration for a given (linkly) payment method ID. */
  pairPinpad: PairLinklyPinpadResult;
  updateLinklyConfiguration: LinklyConfiguration;
  /** Remove a Linkly configuration for a given (linkly) payment method ID. */
  removePinpad: Success;
  createLocation: Location;
  updateLocation: Location;
  syncTaLinkLocations: Array<Location>;
  /** Create a new payment method */
  createPaymentMethod: PaymentMethod;
  /** Update an existing payment method */
  updatePaymentMethod: PaymentMethod;
  /** Delete an existing payment method */
  deletePaymentMethod: Deleted;
  /** Upload (and replace ) a product image */
  createProductImage: CreateImageResult;
  deleteProductImage: DeletionResponse;
  createProductImageBase64: CreateImageResult;
  removeBackgroundBase64: NativeRemoveBackgroundBase64Result;
  /** Preview the result of removing the background from an image */
  previewRemoveBackgroundBase64: NativeRemoveBackgroundBase64Result;
  /** Update the position of a product image */
  updateProductImagePosition: Array<ProductImage>;
  /** Create a new Product */
  createProduct: Product;
  /** Update a existing product */
  updateProduct: Product;
  /** soft delete a product */
  softDeleteProduct: DeletionResponse;
  createReceipt: Receipt;
  updateReceipt: Receipt;
  deleteReceipt: Deleted;
  sendReceipt: Success;
  downloadReceiptPdfBase64String: DownloadReceiptPdfBase64StringResponse;
  createRetailerPayType: RetailerPayType;
  updateRetailerPayType: RetailerPayType;
  deleteRetailerPayType: RetailerPayType;
  uploadImageToRetailerBySKU: Success;
  uploadImagesToRetailerByImageId: Success;
  /** Create a new sale from a terminal */
  createSale: Sale;
  /** Create a new sale from a terminal */
  updateSale: Sale;
  /** Clone a sale, and return the new sale */
  initiateReturnSale: Sale;
  /** Void a sale */
  voidSale: Sale;
  /** Cancel a sale */
  cancelSale: Sale;
  transferWebSale: Sale;
  saleTransferBase64String: Scalars['String'];
  updateSelfCheckoutSettings: SelfCheckoutSettings;
  updateSelfCheckoutLocationSettings: SelfCheckoutLocationSettings;
  deleteSelfCheckoutLocationSettings: Success;
  /** Generate a long lasting access token  */
  generateAccessToken: AccessTokenSession;
  /** Delete */
  deleteAccessToken: Deleted;
  /** Update access token */
  updateAccessToken: AccessTokenSession;
  createStaff: Staff;
  updateStaff: Staff;
  /** Create a user account for a staff member. The user account will be able to login with their own credentials. */
  createStaffUserAccount: Staff;
  /** Update user account for a staff member */
  updateStaffUserAccount: Staff;
  /** Delete a user account for a staff member. The staff member will no longer be able to login with their own credentials.  */
  deleteStaffUserAccount: Success;
  /** Add or update an exisitng inventory movement item */
  addItemToStocktake: StocktakeItem;
  /** Bulk add or update (up to 250) inventory movement item */
  bulkAddItemToStocktake: Success;
  /** Removes the specific prodct variant from the inventory movement */
  removeItemFromStocktake: DeletionResponse;
  /** Create a new stocktake in STOCKTAKE_PLANNED */
  createStocktake: InventoryMovement;
  /** Create a new stocktake in STOCKTAKE_UNDERWAY AND then begin transition to STOCKTAKE_UNDERWAY => STOCKTAKE_UNDERWAY_PROCESSED */
  createStocktakeUnderway: InventoryMovement;
  /** Update the existing details of a Stocktake at any point before CANCELLED or COMPLETE */
  updateStocktakeDetails: InventoryMovement;
  /** Transition stocktake to STOCKTAKE_UNDERWAY then automatically begin transition to STOCKTAKE_UNDERWAY_PROCESSED */
  stocktakeUnderway: InventoryMovement;
  /** Transition stocktake to COMPLETE */
  completeStocktake: InventoryMovement;
  /** Transition stocktake to STOCKTAKE_CANCELLED */
  cancelStocktake: InventoryMovement;
  /** Create or update a stock count for this stocktake */
  countStockItem: InventoryMovementItem;
  /** Create or update a stock count for this stocktake */
  adjustStockItem: InventoryMovementItem;
  /** Immedietly stocktake item. Can no longer be modified once done */
  quickStocktakeStockItem: InventoryMovementItem;
  /** Count item and immediately stock take it */
  countStockItemAndQuickStocktake: InventoryMovementItem;
  downloadStocktakeCsvBase64String: DownloadStocktakeCsvBase64StringResponse;
  sendStocktake: Success;
  /** Remove item from stocktake */
  removeStocktakeItem: DeletionResponse;
  createExpressAccountAndRequestAccountLinkUrl?: Maybe<Scalars['String']>;
  createNormalAccountAndRequestAccountLinkUrl?: Maybe<Scalars['String']>;
  createLoginLink?: Maybe<Scalars['String']>;
  unlinkStripeAccount: Success;
  /** Open a single terminal */
  openTerminal: TerminalClosure;
  /** Close a single terminal */
  closeTerminal: TerminalClosure;
  /** Close terminals linked together by a Drawer */
  closeTerminals: Array<Maybe<TerminalClosure>>;
  /** Create a cash movement for a terminal */
  createCashMovement: CashMovement;
  /** Send a summary of a terminal closure via email */
  emailClosureSummary: Success;
  updateTerminal: Terminal;
  createTerminal: Terminal;
  /** Update the current authenticated users details. (Only available to owner rn) */
  updateMe: UserV2;
  /**
   * Request to setup 2fa for the current authenticated user
   * using the given strategy
   *
   * Depending on the strategy, this may send an email, or sms for native 2fa
   */
  setup2faStrategy: Setup2faStrategyResult;
  /**
   * Verify the given 2fa strategy and code.
   * 2fa will not be enabled until this is called.
   */
  verify2faStrategy: Verify2faStrategyResult;
  /**
   * Remove the given 2fa strategy from the current user.
   * A code will be sent to the strategy to confirm the removal
   * by calling requestMfaCode with the user's credentials
   */
  remove2faStrategy: Remove2faStrategyResult;
  /** Remove the given 2fa strategy from the given user. (Only available to owner) */
  remove2faStrategyForStaff: Success;
};


export type MutationAuthenticateArgs = {
  input: AuthenticationInput;
  rememberMe?: Maybe<Scalars['Boolean']>;
  mfa?: Maybe<AuthenticationMfaInput>;
};


export type MutationSwitchUserArgs = {
  input: AuthenticationInput;
  mfa?: Maybe<AuthenticationMfaInput>;
};


export type MutationLoginWithTaLinkTokenArgs = {
  token: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type MutationRequestMfaCodeArgs = {
  input: AuthenticationInput;
  mfaStrategy: MfaCodeStrategy;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationDeleteDepartmentArgs = {
  id: Scalars['ID'];
};


export type MutationExportSaleArgs = {
  saleId: Scalars['ID'];
  exportType: ExportSaleType;
};


export type MutationUpdateGlobalSettingsArgs = {
  input: UpdateGlobalSettingsInput;
};


export type MutationAddItemToInventoryMovementArgs = {
  inventoryMovementId: Scalars['ID'];
  input: AddItemToInventoryMovementInput;
};


export type MutationBulkAddItemToInventoryMovementArgs = {
  inventoryMovementId: Scalars['ID'];
  input: Array<Maybe<AddItemToInventoryMovementInput>>;
};


export type MutationRemoveItemFromInventoryMovementArgs = {
  inventoryMovementId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationInventoryAdjustQuantityArgs = {
  input: InventoryAdjustQuantityInput;
};


export type MutationApplyDefaultLayoutConfigurationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationCreateLayoutArgs = {
  input: LayoutInput;
};


export type MutationUpdateLayoutArgs = {
  input: LayoutInput;
};


export type MutationDeleteLayoutArgs = {
  id: Scalars['ID'];
};


export type MutationSwapLayoutNodesArgs = {
  input: SwapLayoutNodesInput;
};


export type MutationShiftLayoutNodesArgs = {
  input: ShiftLayoutNodesInput;
};


export type MutationCreateLayoutNodeArgs = {
  input: LayoutNodeInput;
};


export type MutationUpdateLayoutNodeArgs = {
  input: LayoutNodeInput;
};


export type MutationDeleteLayoutNodeArgs = {
  id: Scalars['ID'];
};


export type MutationPairPinpadArgs = {
  paymentMethodId: Scalars['ID'];
  input?: Maybe<LinklyPairPinpadInput>;
};


export type MutationUpdateLinklyConfigurationArgs = {
  paymentMethodId: Scalars['ID'];
  input: LinklyConfigurationInput;
};


export type MutationRemovePinpadArgs = {
  pinpadId: Scalars['ID'];
};


export type MutationCreateLocationArgs = {
  input?: Maybe<CreateLocationInput>;
};


export type MutationUpdateLocationArgs = {
  input?: Maybe<UpdateLocationInput>;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationDeletePaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationCreateProductImageArgs = {
  input: CreateProductImageInput;
};


export type MutationDeleteProductImageArgs = {
  imageId: Scalars['ID'];
};


export type MutationCreateProductImageBase64Args = {
  productId: Scalars['ID'];
  input: CreateProductImageBase64Input;
};


export type MutationRemoveBackgroundBase64Args = {
  base64: Scalars['String'];
};


export type MutationPreviewRemoveBackgroundBase64Args = {
  base64: Scalars['String'];
};


export type MutationUpdateProductImagePositionArgs = {
  input: UpdateProductImageInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationSoftDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationCreateReceiptArgs = {
  input: ReceiptInput;
};


export type MutationUpdateReceiptArgs = {
  input: ReceiptInput;
};


export type MutationDeleteReceiptArgs = {
  id: Scalars['ID'];
};


export type MutationSendReceiptArgs = {
  input?: Maybe<SendReceiptInput>;
};


export type MutationDownloadReceiptPdfBase64StringArgs = {
  saleId: Scalars['ID'];
  receiptId?: Maybe<Scalars['ID']>;
};


export type MutationCreateRetailerPayTypeArgs = {
  input: CreateRetailerPayTypeInput;
};


export type MutationUpdateRetailerPayTypeArgs = {
  input: UpdateRetailerPayTypeInput;
};


export type MutationDeleteRetailerPayTypeArgs = {
  id: Scalars['ID'];
};


export type MutationUploadImageToRetailerBySkuArgs = {
  input: UploadImageToRetailerBySkuInput;
};


export type MutationUploadImagesToRetailerByImageIdArgs = {
  productId: Scalars['ID'];
  imageIds: Array<Scalars['ID']>;
};


export type MutationCreateSaleArgs = {
  input: CreateSaleInput;
};


export type MutationUpdateSaleArgs = {
  input: UpdateSaleInput;
};


export type MutationInitiateReturnSaleArgs = {
  id: Scalars['ID'];
};


export type MutationVoidSaleArgs = {
  id: Scalars['ID'];
};


export type MutationCancelSaleArgs = {
  id: Scalars['ID'];
};


export type MutationTransferWebSaleArgs = {
  saleId: Scalars['ID'];
};


export type MutationSaleTransferBase64StringArgs = {
  saleId: Scalars['ID'];
};


export type MutationUpdateSelfCheckoutSettingsArgs = {
  input: UpdateSelfCheckoutSettingsInput;
};


export type MutationUpdateSelfCheckoutLocationSettingsArgs = {
  input: UpdateSelfCheckoutLocationSettingsInput;
};


export type MutationDeleteSelfCheckoutLocationSettingsArgs = {
  locationId: Scalars['ID'];
};


export type MutationGenerateAccessTokenArgs = {
  input: GenerateAccessTokenInput;
};


export type MutationDeleteAccessTokenArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAccessTokenArgs = {
  input: UpdateAccessTokenInput;
};


export type MutationCreateStaffArgs = {
  input: CreateStaffInput;
};


export type MutationUpdateStaffArgs = {
  input: UpdateStaffInput;
};


export type MutationCreateStaffUserAccountArgs = {
  input: CreateStaffUserAccountInput;
};


export type MutationUpdateStaffUserAccountArgs = {
  input: UpdateStaffUserAccountInput;
};


export type MutationDeleteStaffUserAccountArgs = {
  staffId: Scalars['ID'];
};


export type MutationAddItemToStocktakeArgs = {
  stocktakeId: Scalars['ID'];
  input: AddItemToStocktakeInput;
};


export type MutationBulkAddItemToStocktakeArgs = {
  stocktakeId: Scalars['ID'];
  input: Array<Maybe<AddItemToStocktakeInput>>;
};


export type MutationRemoveItemFromStocktakeArgs = {
  stocktakeId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationCreateStocktakeArgs = {
  input: StocktakeCreateInput;
};


export type MutationCreateStocktakeUnderwayArgs = {
  input: StocktakeCreateInput;
};


export type MutationUpdateStocktakeDetailsArgs = {
  stocktakeId: Scalars['ID'];
  input: StocktakeDetailsUpdateInput;
};


export type MutationStocktakeUnderwayArgs = {
  stocktakeId: Scalars['ID'];
};


export type MutationCompleteStocktakeArgs = {
  stocktakeId: Scalars['ID'];
};


export type MutationCancelStocktakeArgs = {
  stocktakeId: Scalars['ID'];
};


export type MutationCountStockItemArgs = {
  stocktakeId: Scalars['ID'];
  input: CountStockItemInput;
};


export type MutationAdjustStockItemArgs = {
  stocktakeId: Scalars['ID'];
  input: AdjustStockItemInput;
};


export type MutationQuickStocktakeStockItemArgs = {
  stocktakeId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationCountStockItemAndQuickStocktakeArgs = {
  stocktakeId: Scalars['ID'];
  input: CountStockItemInput;
};


export type MutationDownloadStocktakeCsvBase64StringArgs = {
  stocktakeId: Scalars['ID'];
};


export type MutationSendStocktakeArgs = {
  input?: Maybe<SendStocktakeInput>;
};


export type MutationRemoveStocktakeItemArgs = {
  stocktakeId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type MutationCreateExpressAccountAndRequestAccountLinkUrlArgs = {
  input?: Maybe<CreateStripeExpressAccountInput>;
};


export type MutationOpenTerminalArgs = {
  input: OpenTerminalInput;
};


export type MutationCloseTerminalArgs = {
  input: CloseTerminalInput;
};


export type MutationCloseTerminalsArgs = {
  input: Array<Maybe<CloseTerminalInput>>;
};


export type MutationCreateCashMovementArgs = {
  input: CashMovementInput;
};


export type MutationEmailClosureSummaryArgs = {
  input: EmailClosureSummaryInput;
};


export type MutationUpdateTerminalArgs = {
  input?: Maybe<UpdateTerminalInput>;
};


export type MutationCreateTerminalArgs = {
  input?: Maybe<CreateTerminalInput>;
};


export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};


export type MutationSetup2faStrategyArgs = {
  input: Setup2faStrategyInput;
};


export type MutationVerify2faStrategyArgs = {
  credentials: AuthenticationInput;
  strategy: MfaCodeStrategy;
  code: Scalars['String'];
};


export type MutationRemove2faStrategyArgs = {
  code: Scalars['String'];
  strategy: MfaCodeStrategy;
};


export type MutationRemove2faStrategyForStaffArgs = {
  userId: Scalars['ID'];
  strategy: MfaCodeStrategy;
};

export type NativeAuthStrategyError = ErrorResult & {
  __typename?: 'NativeAuthStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type NativeAuthenticationResult = AuthenticationResult | InvalidCredentialsError | NativeAuthStrategyError;

export type NativeRemoveBackgroundBase64Result = RemoveBackgroundBase64Result | BackgroundRemoverNotConfiguredError | BackgroundRemoverInsufficientCreditsError;

/** Returned when attempting to set a SaleItem quantity to a negative value. */
export type NegativeQuantityError = ErrorResult & {
  __typename?: 'NegativeQuantityError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/**
 * Returned if `authOptions.requireVerification` is set to `true`
 * and an unverified user attempts to authenticate.
 */
export type NotVerifiedError = ErrorResult & {
  __typename?: 'NotVerifiedError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Operators for filtering on a list of Number fields */
export type NumberListOperators = {
  inList: Array<Maybe<Scalars['Float']>>;
};

export type NumberOperators = {
  eq?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  between?: Maybe<NumberRange>;
};

export type NumberRange = {
  start: Scalars['Float'];
  end: Scalars['Float'];
};

export type OpenTerminalInput = {
  terminalId: Scalars['ID'];
  /** Optional open float amount, will override any other default float values */
  openFloatAmount?: Maybe<Scalars['Decimal']>;
};

export type PaginatedList = {
  items: Array<EntityNode>;
  meta: PaginatedListMeta;
};

export type PaginatedListMeta = PaginatedListMetaInterface & {
  __typename?: 'PaginatedListMeta';
  itemCount?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type PaginatedListMetaInterface = {
  itemCount?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
};

export type PairLinklyPinpadResult = LinklyPairedPinpad | InvalidLinklyCredentialsError | LinklyError;

/** Returned when attempting to verify a customer account with a password, when a password has already been set. */
export type PasswordAlreadySetError = ErrorResult & {
  __typename?: 'PasswordAlreadySetError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when attempting to register or verify a customer account where the given password fails password validation. */
export type PasswordValidationError = ErrorResult & {
  __typename?: 'PasswordValidationError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  validationErrorMessage: Scalars['String'];
};

export type PayType = {
  __typename?: 'PayType';
  id: Scalars['String'];
  name: Scalars['String'];
  subtext?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  internal: Scalars['Boolean'];
  platforms: Array<Scalars['String']>;
  channels: Array<Scalars['String']>;
  onlineOnly: Scalars['Boolean'];
  configurationUrl?: Maybe<Scalars['String']>;
  defaultGatewayUrl?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
  gateway: Scalars['Boolean'];
  gatewayEditable: Scalars['Boolean'];
  available: Scalars['Boolean'];
  /** Can only have 1 of this type */
  restricted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PayTypeGatewayConfig = {
  __typename?: 'PayTypeGatewayConfig';
  url: Scalars['String'];
};

export type PayTypeListFilterOptions = {
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayTypeRoundingConfig = {
  __typename?: 'PayTypeRoundingConfig';
  rounding: Scalars['Decimal'];
};

export type Payment = EntityNode & {
  __typename?: 'Payment';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  name: Scalars['String'];
  label: Scalars['String'];
  paymentDate: Scalars['DateTime'];
  paymentMethodId: Scalars['ID'];
  paymentTypeId: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
};

export type PaymentMethod = EntityNode & {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  payTypeId: Scalars['String'];
  paytype: PayType;
  configuration?: Maybe<PaymentMethodConfiguration>;
  /** Deprecated, (but backwards compatible) use retailer pay code Id */
  retailerPayCode?: Maybe<Scalars['String']>;
  retailerPayTypeId?: Maybe<Scalars['ID']>;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodConfiguration = PayTypeGatewayConfig | PayTypeRoundingConfig;

export type PaymentMethodConfigurationInput = {
  url?: Maybe<Scalars['String']>;
  rounding?: Maybe<Scalars['Decimal']>;
};

export type PaymentMethodFilterParameter = {
  id?: Maybe<IdOperators>;
  name?: Maybe<StringOperators>;
  payTypeId?: Maybe<StringOperators>;
  retailerPayCode?: Maybe<StringOperators>;
  retailerPayTypeId?: Maybe<IdOperators>;
  enabled?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type PaymentMethodList = PaginatedList & {
  __typename?: 'PaymentMethodList';
  items: Array<PaymentMethod>;
  meta: PaginatedListMeta;
};

export type PaymentMethodListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<PaymentMethodSortParameter>;
  filter?: Maybe<PaymentMethodFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type PaymentMethodSortParameter = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  payTypeId?: Maybe<SortOrder>;
  retailerPayCode?: Maybe<SortOrder>;
  retailerPayTypeId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export enum Permission {
  /** SuperPlatformAdmin has access to private operations. They are an employee of the Platform, not a Store */
  SuperPlatformAdmin = 'SuperPlatformAdmin',
  /** Alias for Administrator. Authenticated means that the user is logged in as a Administrator */
  AuthenticatedAccess = 'AuthenticatedAccess',
  /** Authenticated means that the user is logged in as a (Store) Administrator */
  AdministratorAccess = 'AdministratorAccess',
  /** StaffAuthenticatedAccess means that the user is logged in as a (Store) Staff Member */
  StaffAuthenticatedAccess = 'StaffAuthenticatedAccess',
  /** Public means any unauthenticated user may perform the operation */
  PublicAccess = 'PublicAccess',
  /** Public means any unauthenticated user may perform the operation */
  CustomerAuthenticatedAccess = 'CustomerAuthenticatedAccess',
  /** Owner means the user owns this entity, e.g. a Customer's own Order */
  Owner = 'Owner',
  /** Grants permission to Manage settings */
  ManageSettings = 'ManageSettings',
  /** Grants permission to Manage Products */
  ManageProducts = 'ManageProducts',
  /** Grants permission to Manage Customers */
  ManageCustomers = 'ManageCustomers',
  /** Grants permission to Manage Sales */
  ManageSales = 'ManageSales',
  /** Grants permission to Manage Departments */
  ManageDepartments = 'ManageDepartments',
  /** Grants permission to view Analytics */
  ReadAnalytics = 'ReadAnalytics',
  /** Grants access to Point of Sale App */
  PointOfSale = 'PointOfSale',
  /** Grants access to Stock take App */
  Stocktake = 'Stocktake',
  /** Grants access to Utilties App */
  Utilities = 'Utilities'
}

export type Product = EntityNode & {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  taxable: Scalars['Boolean'];
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  stockNumber: Scalars['String'];
  variantParentId?: Maybe<Scalars['ID']>;
  locationInventoryPricing: Array<ProductLocationInventoryPricing>;
  /** Total salable inventory accross all locations */
  totalInventory: Scalars['Float'];
  hasVariants: Scalars['Boolean'];
  /** Product name & Variant name combined */
  displayName: Scalars['String'];
  /** Number of variants (excluding self) */
  totalVariants: Scalars['Int'];
  categoryId?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  department?: Maybe<Department>;
  variants?: Maybe<Array<Product>>;
  discountable: Scalars['Boolean'];
  options: Array<ProductOption>;
  /** Returns the default image for the product */
  image?: Maybe<ProductImage>;
  images: Array<ProductImage>;
  selectedOptions: Array<SelectedOption>;
  internal: Scalars['Boolean'];
  adjustable: Scalars['Boolean'];
  /** Determined based on location context. null if not applicable */
  price?: Maybe<Scalars['Float']>;
  /** Determined based on location context. null if not applicable */
  priceExcludingTax?: Maybe<Scalars['Float']>;
  loyaltyPointEarnValue: Scalars['Int'];
  loyaltyPointRedeemValue: Scalars['Int'];
  serialNumberRequired: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductFilterParameter = {
  id?: Maybe<IdOperators>;
  name?: Maybe<StringOperators>;
  taxable?: Maybe<BooleanOperators>;
  active?: Maybe<BooleanOperators>;
  description?: Maybe<StringOperators>;
  stockCode?: Maybe<StringOperators>;
  stockNumber?: Maybe<StringOperators>;
  variantParentId?: Maybe<IdOperators>;
  totalInventory?: Maybe<NumberOperators>;
  hasVariants?: Maybe<BooleanOperators>;
  displayName?: Maybe<StringOperators>;
  totalVariants?: Maybe<NumberOperators>;
  categoryId?: Maybe<StringOperators>;
  departmentId?: Maybe<StringOperators>;
  discountable?: Maybe<BooleanOperators>;
  internal?: Maybe<BooleanOperators>;
  adjustable?: Maybe<BooleanOperators>;
  price?: Maybe<NumberOperators>;
  priceExcludingTax?: Maybe<NumberOperators>;
  loyaltyPointEarnValue?: Maybe<NumberOperators>;
  loyaltyPointRedeemValue?: Maybe<NumberOperators>;
  serialNumberRequired?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
  term?: Maybe<StringOperators>;
};

export type ProductIdByStockNumberResult = ProductIdResult | StockNumberNotFoundError;

export type ProductIdResult = {
  __typename?: 'ProductIdResult';
  productId: Scalars['String'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  id: Scalars['ID'];
  path: Scalars['String'];
  name: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  mineType?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  productId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductList = PaginatedList & {
  __typename?: 'ProductList';
  items: Array<Product>;
  meta: PaginatedListMeta;
};

export type ProductListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductSortParameter>;
  filter?: Maybe<ProductFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type ProductLocationInventoryPricing = {
  __typename?: 'ProductLocationInventoryPricing';
  id: Scalars['ID'];
  productId: Scalars['ID'];
  locationId: Scalars['ID'];
  location?: Maybe<Location>;
  stockOnHand: Scalars['Float'];
  costPrice: Scalars['Float'];
  retailPrice: Scalars['Float'];
  rrPrice: Scalars['Float'];
  taxRatePercent: Scalars['Int'];
};

export type ProductOption = {
  __typename?: 'ProductOption';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  values: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ProductSortParameter = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  stockCode?: Maybe<SortOrder>;
  stockNumber?: Maybe<SortOrder>;
  variantParentId?: Maybe<SortOrder>;
  totalInventory?: Maybe<SortOrder>;
  displayName?: Maybe<SortOrder>;
  totalVariants?: Maybe<SortOrder>;
  categoryId?: Maybe<SortOrder>;
  departmentId?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  priceExcludingTax?: Maybe<SortOrder>;
  loyaltyPointEarnValue?: Maybe<SortOrder>;
  loyaltyPointRedeemValue?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

/** These will all get replaced by CubeJS if more complicated queries are needed */
export type Query = {
  __typename?: 'Query';
  me?: Maybe<CurrentUserAndTenant>;
  /**
   * Return full details of the current user,
   * including the user's roles and permissions.
   */
  activeUser?: Maybe<UserV2>;
  /** Find a category by id */
  category?: Maybe<Category>;
  /** List Categories */
  categories: CategoryList;
  /** Find a product by id */
  customer?: Maybe<Customer>;
  /** List Customers */
  customers: CustomerList;
  /** Find a department by id */
  department?: Maybe<Department>;
  /** List Departments */
  departments: DepartmentList;
  exportRawSaleJSON: Scalars['JSON'];
  /** Export *CLOSED* sales, formatted for retailer */
  exportRetailerSales: Array<RetailerExportSale>;
  /** Export a single *CLOSED* sale, formatted for retailer */
  exportRetailerSale: RetailerExportSale;
  features?: Maybe<FeaturesList>;
  globalSettings: GlobalSettings;
  serverConfig: ServerConfig;
  /** List all inventory movements */
  inventoryLevelMovements: InventoryLevelMovementList;
  /** List inventory movement items */
  inventoryMovementItems: InventoryMovementItemList;
  /** Get summary of items. Not available to stocktake. */
  inventoryMovementItemsMeta: Array<Maybe<MovementItemProductSummary>>;
  /** Get product summary details */
  movementItemProductSummary: MovementItemProductSummary;
  /** List inventory items */
  inventoryItems: InventoryItemList;
  /** List inventory levels */
  inventoryLevels: InventoryLevelList;
  /** Find a layout by id */
  layout: Layout;
  /** List all layouts */
  layouts: LayoutList;
  /** list all nodes */
  layoutNodes: LayoutNodeList;
  /** get layout by terminal id */
  layoutByTerminalId: Layout;
  /**
   * Get the Linkly configuration for a given (linkly) payment method ID.
   * Will generate a new configuration if one does not already exist.
   */
  linklyConfiguration: LinklyConfiguration;
  locations: LocationList;
  location: Location;
  /** Find a payment method by id */
  paymentMethod: PaymentMethod;
  /** List all payment methods */
  paymentMethods: PaymentMethodList;
  payTypes: Array<PayType>;
  productImages: Array<ProductImage>;
  /** List all products */
  products: ProductList;
  /** Get a product */
  product: Product;
  /** Get product by stock number */
  productByStockNumber: Product;
  productIdByStockNumber: ProductIdByStockNumberResult;
  /** Get all variants of a product */
  productVariants: Array<Product>;
  receipt: Receipt;
  receipts?: Maybe<ReceiptList>;
  receiptTemplateTypes: Array<ReceiptTemplateType>;
  retailerPayType: RetailerPayType;
  retailerPayTypes: RetailerPayTypeList;
  getSumRevenueTotalSalesAnalytics: Scalars['Float'];
  getCountTotalSalesAnalytics: Scalars['Int'];
  getCountItemsSoldAnalytics: Scalars['Float'];
  getTop10ProductsBySoldAnalytics: Array<AnalyticProductMetaDataItem>;
  /** List all changes for a Sale */
  saleHistory: Array<SaleHistoryEntry>;
  sale: Sale;
  sales?: Maybe<SaleList>;
  /** Search entity type based on the criteria set by the `SearchInput` */
  search: SearchResponse;
  selfCheckoutLocationURL: Scalars['String'];
  selfCheckoutSettings: SelfCheckoutSettings;
  selfCheckoutLocationSettings: SelfCheckoutLocationSettings;
  /** Return all access tokens */
  accessTokens: Array<AccessTokenSession>;
  staff: Staff;
  staffs: StaffList;
  /** List inventory movement items */
  stocktakeItems: StocktakeItemList;
  /** Get summary of items. Not available to stocktake. */
  stocktakeItemsMeta: Array<Maybe<MovementItemProductSummary>>;
  /** Get product summary details */
  stocktakeItemProductSummary: MovementItemProductSummary;
  /** List inventory movements */
  stocktakes: InventoryMovementList;
  /** List a single inventory movement */
  stocktake: InventoryMovement;
  /** Estimate number of stock that will be selected from filters */
  estimateNoMatchingStockFromStocktakeFilters: Scalars['Int'];
  onBoardingComplete: Scalars['Boolean'];
  /** Find a terminal closure by id */
  terminalClosure: TerminalClosure;
  /** List all terminal closures */
  terminalClosures: TerminalClosureList;
  /** A summary of the payments made during a closure */
  terminalClosurePaymentsSummary: TerminalClosurePaymentsSummary;
  /** A list of all cash movements made during a closure */
  terminalClosureCashMovements: Array<CashMovement>;
  /** A summary of sales data made during a closure period */
  terminalClosureSalesSummary: TerminalClosureSalesSummary;
  /** An estimate of current closure float including payments and cash movements */
  estimateFloat: Scalars['Decimal'];
  terminals: TerminalList;
  terminal: Terminal;
  testConnection: Success;
  /** max 100 at a time to stop overload server */
  undocumentedFilterMetadata: Array<FilterValueMeta>;
  /**
   * Return the current Vendor. Vendor is an alias for the User (v1)
   * object
   */
  currentVendor: Vendor;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCategoriesArgs = {
  options?: Maybe<CategoryListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryCustomersArgs = {
  options?: Maybe<CustomerListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryDepartmentArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryDepartmentsArgs = {
  options?: Maybe<DepartmentListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryExportRawSaleJsonArgs = {
  saleId: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryExportRetailerSalesArgs = {
  options?: Maybe<ExportRetailerSaleOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryExportRetailerSaleArgs = {
  saleId: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryLevelMovementsArgs = {
  options?: Maybe<InventoryLevelMovementListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryMovementItemsArgs = {
  inventoryMovementId: Scalars['ID'];
  options?: Maybe<InventoryMovementItemListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryMovementItemsMetaArgs = {
  inventoryMovementId: Scalars['ID'];
  inventoryMovementItemIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryMovementItemProductSummaryArgs = {
  options: MovementItemProductSummaryOptions;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryItemsArgs = {
  options?: Maybe<InventoryItemListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryInventoryLevelsArgs = {
  options?: Maybe<InventoryLevelListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLayoutArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLayoutsArgs = {
  options?: Maybe<LayoutListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLayoutNodesArgs = {
  options?: Maybe<LayoutNodeListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLayoutByTerminalIdArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLinklyConfigurationArgs = {
  paymentMethodId: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLocationsArgs = {
  options?: Maybe<LocationListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryLocationArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryPaymentMethodArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryPaymentMethodsArgs = {
  options?: Maybe<PaymentMethodListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryPayTypesArgs = {
  filter?: Maybe<PayTypeListFilterOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductImagesArgs = {
  productId: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductsArgs = {
  options?: Maybe<ProductListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductByStockNumberArgs = {
  stockNumber: Scalars['String'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductIdByStockNumberArgs = {
  stockNumber: Scalars['String'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryProductVariantsArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryReceiptArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryReceiptsArgs = {
  options?: Maybe<ReceiptListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryReceiptTemplateTypesArgs = {
  filters?: Maybe<ReceiptTemplatesListFilterOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryRetailerPayTypeArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryRetailerPayTypesArgs = {
  options?: Maybe<RetailerPayTypeListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetSumRevenueTotalSalesAnalyticsArgs = {
  options?: Maybe<QuerySalesAnalyticsOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetCountTotalSalesAnalyticsArgs = {
  options?: Maybe<QuerySalesAnalyticsOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetCountItemsSoldAnalyticsArgs = {
  options?: Maybe<QuerySalesAnalyticsOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryGetTop10ProductsBySoldAnalyticsArgs = {
  options?: Maybe<QuerySalesAnalyticsOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySaleHistoryArgs = {
  saleId: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySaleArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySalesArgs = {
  options?: Maybe<SaleListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySearchArgs = {
  input: SearchInput;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySelfCheckoutLocationUrlArgs = {
  locationId: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QuerySelfCheckoutLocationSettingsArgs = {
  locationId: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStaffArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStaffsArgs = {
  options?: Maybe<StaffListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakeItemsArgs = {
  stocktakeId: Scalars['ID'];
  options?: Maybe<StocktakeItemListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakeItemsMetaArgs = {
  stocktakeId: Scalars['ID'];
  stocktakeItemIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakeItemProductSummaryArgs = {
  options: MovementItemProductSummaryOptions;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakesArgs = {
  options?: Maybe<InventoryMovementListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryStocktakeArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryEstimateNoMatchingStockFromStocktakeFiltersArgs = {
  filters: Array<InventoryMovementFilterInput>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosureArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosuresArgs = {
  options?: Maybe<TerminalClosureListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosurePaymentsSummaryArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosureCashMovementsArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalClosureSalesSummaryArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryEstimateFloatArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalsArgs = {
  options?: Maybe<TerminalListOptions>;
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryTerminalArgs = {
  id: Scalars['ID'];
};


/** These will all get replaced by CubeJS if more complicated queries are needed */
export type QueryUndocumentedFilterMetadataArgs = {
  filters: Array<FilterValueMetaInput>;
};

export type QuerySalesAnalyticsOptions = {
  startComepletedDate?: Maybe<Scalars['String']>;
  endCompletedDate?: Maybe<Scalars['String']>;
  /** The state of the order */
  state?: Maybe<Array<Scalars['String']>>;
};

export type Receipt = EntityNode & {
  __typename?: 'Receipt';
  id: Scalars['ID'];
  name: Scalars['String'];
  defaultTemplateType: Scalars['String'];
  settings: ReceiptSettings;
  enabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum ReceiptAutoPrintType {
  POS = 'POS',
  PinPad = 'PinPad',
  Both = 'Both'
}

export type ReceiptFilterParameter = {
  id?: Maybe<IdOperators>;
  name?: Maybe<StringOperators>;
  defaultTemplateType?: Maybe<StringOperators>;
  enabled?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type ReceiptInput = {
  /** Update only field */
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  defaultTemplateType?: Maybe<Scalars['String']>;
  settings?: Maybe<ReceiptSettingsInput>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ReceiptList = PaginatedList & {
  __typename?: 'ReceiptList';
  items: Array<Receipt>;
  meta: PaginatedListMeta;
};

export type ReceiptListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<ReceiptSortParameter>;
  filter?: Maybe<ReceiptFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type ReceiptSettings = {
  __typename?: 'ReceiptSettings';
  header: Scalars['String'];
  name: Scalars['String'];
  footer: Scalars['String'];
  displayCustomerDetails: Scalars['Boolean'];
  logoURL?: Maybe<Scalars['String']>;
};

export type ReceiptSettingsInput = {
  name?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  displayCustomerDetails?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['Upload']>;
};

export type ReceiptSortParameter = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  defaultTemplateType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type ReceiptTemplateType = {
  __typename?: 'ReceiptTemplateType';
  id: Scalars['String'];
  html: Scalars['String'];
  css: Scalars['String'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ReceiptTemplatesListFilterOptions = {
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RegisterSalePaymentInput = {
  /** Required for edit */
  id?: Maybe<Scalars['ID']>;
  /** The sale the payment was made, the payment can also be accepted in a different terminal the sale originated from */
  terminalId?: Maybe<Scalars['ID']>;
  /** The skytill payment method id */
  paymentMethodId: Scalars['ID'];
  /** The date the payment was time, by default current time will be assigned */
  paymentDate?: Maybe<Scalars['DateTime']>;
  /** Optional, will default to `Settled` if not provided */
  state?: Maybe<Scalars['String']>;
  /** Payment amount */
  amount: Scalars['Float'];
  /** A optional unique id for the transaction, provided by the payment provider */
  transactionId?: Maybe<Scalars['String']>;
  /** Store any metadata relevent to the payment */
  metadata?: Maybe<Scalars['JSON']>;
};

export type RemovalAiDetails = {
  __typename?: 'RemovalAiDetails';
  apiKey?: Maybe<Scalars['String']>;
};

export type RemovalAiDetailsInput = {
  apiKey: Scalars['String'];
};

export type RemovalBgDetails = {
  __typename?: 'RemovalBgDetails';
  apiKey?: Maybe<Scalars['String']>;
};

export type RemovalBgDetailsInput = {
  apiKey: Scalars['String'];
};

export type Remove2faStrategyResult = Success | InvalidMfaStrategyError | InvalidMfaCodeError;

export type RemoveBackgroundBase64Result = {
  __typename?: 'RemoveBackgroundBase64Result';
  base64: Scalars['String'];
};

export type RetailerExportCustomer = {
  __typename?: 'RetailerExportCustomer';
  FirstName: Scalars['String'];
  Surname?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Phone?: Maybe<Scalars['String']>;
};

export type RetailerExportPayment = {
  __typename?: 'RetailerExportPayment';
  PayCode?: Maybe<Scalars['String']>;
  Amount: Scalars['Float'];
  Tendered: Scalars['Float'];
};

export type RetailerExportSale = {
  __typename?: 'RetailerExportSale';
  SaleType: Scalars['String'];
  SaleId: Scalars['String'];
  OrderNumber: Scalars['String'];
  SaleDate: Scalars['String'];
  SaleStaff?: Maybe<Scalars['String']>;
  SaleTime: Scalars['String'];
  SaleAmount: Scalars['Float'];
  SaleDiscount: Scalars['Float'];
  SaleTax: Scalars['Float'];
  SaleDeposit?: Maybe<Scalars['Float']>;
  LocationKey: Scalars['Int'];
  SaleTerm: Scalars['String'];
  CustID?: Maybe<Scalars['String']>;
  SaleCust?: Maybe<Scalars['Int']>;
  SaleRound?: Maybe<Scalars['Float']>;
  Payments: Array<RetailerExportPayment>;
  SaleItems: Array<RetailerExportSaleItem>;
  Customer?: Maybe<RetailerExportCustomer>;
  FreightCharge: Scalars['Float'];
  FreightTax: Scalars['Float'];
};

export type RetailerExportSaleItem = {
  __typename?: 'RetailerExportSaleItem';
  TxnStock: Scalars['String'];
  TxnItem: Scalars['Int'];
  TxnQty: Scalars['Float'];
  TxnUnitAmount: Scalars['Float'];
  TxnUnitDiscount: Scalars['Float'];
  TxnUnitTax: Scalars['Float'];
  TxnUnitCost: Scalars['Float'];
  TxnExtendedAmount: Scalars['Float'];
  TxnExtendedDiscount: Scalars['Float'];
  TxnExtendedTax: Scalars['Float'];
  TxnTotal: Scalars['Float'];
  TxnTotalCost: Scalars['Float'];
  TxnDiscountType: Scalars['Int'];
  SerialNumber?: Maybe<Scalars['String']>;
  TotalPointsEarned: Scalars['Int'];
  TotalPointsUsed: Scalars['Int'];
};

export type RetailerPayType = EntityNode & {
  __typename?: 'RetailerPayType';
  id: Scalars['ID'];
  payCode: Scalars['String'];
  payDescription?: Maybe<Scalars['String']>;
  payType: Scalars['String'];
  payOver?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type RetailerPayTypeFilterParameter = {
  id?: Maybe<IdOperators>;
  payCode?: Maybe<StringOperators>;
  payDescription?: Maybe<StringOperators>;
  payType?: Maybe<StringOperators>;
  payOver?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type RetailerPayTypeList = PaginatedList & {
  __typename?: 'RetailerPayTypeList';
  items: Array<RetailerPayType>;
  meta: PaginatedListMeta;
};

export type RetailerPayTypeListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<RetailerPayTypeSortParameter>;
  filter?: Maybe<RetailerPayTypeFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type RetailerPayTypeSortParameter = {
  id?: Maybe<SortOrder>;
  payCode?: Maybe<SortOrder>;
  payDescription?: Maybe<SortOrder>;
  payType?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type Role = EntityNode & {
  __typename?: 'Role';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  code: Scalars['String'];
  permissions: Array<Permission>;
};

export type Sale = EntityNode & {
  __typename?: 'Sale';
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  receiptConfirmed: Scalars['Boolean'];
  receiptConfirmationUrl?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  terminalId: Scalars['ID'];
  terminal?: Maybe<Terminal>;
  locationId: Scalars['ID'];
  location?: Maybe<Location>;
  customerId?: Maybe<Scalars['ID']>;
  customer?: Maybe<Customer>;
  staffId?: Maybe<Scalars['ID']>;
  staff?: Maybe<Staff>;
  source: SaleSource;
  receiptId?: Maybe<Scalars['ID']>;
  state: Scalars['String'];
  invoiceNumber: Scalars['String'];
  invoiceSequence?: Maybe<Scalars['Int']>;
  isReturn: Scalars['Boolean'];
  returnForId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  totalCost: Scalars['Float'];
  totalTax: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalAdjustments: Scalars['Float'];
  totalAdjustmentPercentSaving: Scalars['Float'];
  balance: Scalars['Float'];
  lineItems: Array<SaleLineItem>;
  historyEntries?: Maybe<Array<SaleHistoryEntry>>;
  payments: Array<Payment>;
  saleDate: Scalars['DateTime'];
  metadata?: Maybe<Scalars['JSON']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type SaleAddress = {
  __typename?: 'SaleAddress';
  fullName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SaleAdjustment = EntityNode & {
  __typename?: 'SaleAdjustment';
  id: Scalars['ID'];
  type: SaleAdjustmentType;
  amount: Scalars['Float'];
  metadata?: Maybe<Scalars['JSON']>;
  unitLoyaltyPointsUsed: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SaleAdjustmentInput = {
  /** Required for edit */
  id?: Maybe<Scalars['ID']>;
  /** The type of adjustment */
  type: SaleAdjustmentType;
  /** The date the adjustment was made, by defaut current time will be assigned */
  adjustedDate?: Maybe<Scalars['DateTime']>;
  /** Payment amount */
  amount: Scalars['Float'];
  /** Store any metadata relevent to the adjustment */
  metadata?: Maybe<Scalars['JSON']>;
  /** The unit amount of loyalty points used for this adjustmentm, only applicable if type is LOYALTY_POINTS */
  unitLoyaltyPointsUsed?: Maybe<Scalars['Int']>;
};

export enum SaleAdjustmentType {
  /** The discount was applied to the entire sale and this is the distributed portion */
  SALE_DISCOUNT = 'SALE_DISCOUNT',
  /** A manual discount was applied to this sale item */
  MANUAL_DISCOUNT = 'MANUAL_DISCOUNT',
  /** A discount was applied to this sale item because it was part of a loyalty program */
  LOYALTY_DISCOUNT = 'LOYALTY_DISCOUNT',
  /** A discount was applied to this sale item because it was part of a catalogue */
  CATALOGUE_DISCOUNT = 'CATALOGUE_DISCOUNT'
}

export type SaleFilterParameter = {
  id?: Maybe<IdOperators>;
  code?: Maybe<StringOperators>;
  receiptConfirmed?: Maybe<BooleanOperators>;
  receiptConfirmationUrl?: Maybe<StringOperators>;
  active?: Maybe<BooleanOperators>;
  terminalId?: Maybe<IdOperators>;
  locationId?: Maybe<IdOperators>;
  customerId?: Maybe<IdOperators>;
  staffId?: Maybe<IdOperators>;
  source?: Maybe<StringOperators>;
  receiptId?: Maybe<IdOperators>;
  state?: Maybe<StringOperators>;
  invoiceNumber?: Maybe<StringOperators>;
  invoiceSequence?: Maybe<NumberOperators>;
  isReturn?: Maybe<BooleanOperators>;
  returnForId?: Maybe<IdOperators>;
  note?: Maybe<StringOperators>;
  totalCost?: Maybe<NumberOperators>;
  totalTax?: Maybe<NumberOperators>;
  totalPrice?: Maybe<NumberOperators>;
  totalAdjustments?: Maybe<NumberOperators>;
  totalAdjustmentPercentSaving?: Maybe<NumberOperators>;
  balance?: Maybe<NumberOperators>;
  saleDate?: Maybe<DateOperators>;
  completedAt?: Maybe<DateOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
  term?: Maybe<StringOperators>;
};

export type SaleHistoryEntry = EntityNode & {
  __typename?: 'SaleHistoryEntry';
  id: Scalars['ID'];
  type: SaleHistoryEntryType;
  userId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  data?: Maybe<SaleHistoryEntryData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type SaleHistoryEntryData = SaleNoteEntryData | SaleModifiedEntryData | SaleModifiedCustomerAddedEntryData | SaleLineItemEntryData | SaleStatusTransitionData;

export enum SaleHistoryEntryType {
  /** Sale was voided */
  VOID_SALE = 'VOID_SALE',
  /** Sale state transitioned */
  SALE_STATUS_TRANSITION = 'SALE_STATUS_TRANSITION',
  /** Sale modified */
  SALE_MODIFIED = 'SALE_MODIFIED',
  /** Sale customer added */
  SALE_CUSTOMER_ADDED = 'SALE_CUSTOMER_ADDED',
  /** Sale customer removed */
  SALE_CUSTOMER_REMOVED = 'SALE_CUSTOMER_REMOVED',
  /** Sale note changed */
  SALE_NOTE_MODIFIED = 'SALE_NOTE_MODIFIED',
  /** Sale line item deleted */
  SALE_LINE_ITEM_DELETED = 'SALE_LINE_ITEM_DELETED',
  /** Sale line item created */
  SALE_LINE_ITEM_CREATED = 'SALE_LINE_ITEM_CREATED',
  /** Sale line item modified */
  SALE_LINE_ITEM_MODIFIED = 'SALE_LINE_ITEM_MODIFIED'
}

export type SaleLineItem = EntityNode & {
  __typename?: 'SaleLineItem';
  id: Scalars['ID'];
  saleId: Scalars['ID'];
  productId: Scalars['ID'];
  product?: Maybe<Product>;
  stockNumber?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  basePrice: Scalars['Float'];
  basePriceInclTax: Scalars['Float'];
  adjustments: Array<SaleAdjustment>;
  price: Scalars['Float'];
  priceInclTax: Scalars['Float'];
  cost: Scalars['Float'];
  tax: Scalars['Float'];
  totalPriceInclusiveTax: Scalars['Float'];
  quantity: Scalars['Float'];
  totalBasePrice: Scalars['Float'];
  taxRatePercent: Scalars['Int'];
  totalBasePriceInclTax: Scalars['Float'];
  shippingAddress?: Maybe<SaleAddress>;
  billingAddress?: Maybe<SaleAddress>;
  totalPrice: Scalars['Float'];
  totalCost: Scalars['Float'];
  totalTax: Scalars['Float'];
  totalAdjustments: Scalars['Float'];
  loyaltyPointEarned: Scalars['Int'];
  metadata?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type SaleLineItemEntryData = {
  __typename?: 'SaleLineItemEntryData';
  productName?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  basePrice: Scalars['Float'];
  basePriceInclTax: Scalars['Float'];
  adjustments: Array<SaleAdjustment>;
  price: Scalars['Float'];
  priceInclTax: Scalars['Float'];
  cost: Scalars['Float'];
  tax: Scalars['Float'];
  totalPriceInclusiveTax: Scalars['Float'];
  quantity: Scalars['Float'];
  totalBasePrice: Scalars['Float'];
  taxRatePercent: Scalars['Int'];
  totalBasePriceInclTax: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalCost: Scalars['Float'];
  totalTax: Scalars['Float'];
  totalAdjustments: Scalars['Float'];
  loyaltyPointEarned: Scalars['Int'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type SaleLineItemInput = {
  id?: Maybe<Scalars['ID']>;
  productId: Scalars['ID'];
  /** Optional stockNumber of product. Default stockNumber will be used if non provided */
  stockNumber?: Maybe<Scalars['String']>;
  /** Optional stockCode of product. Default stockCode sku will be used if non provided */
  stockCode?: Maybe<Scalars['String']>;
  /** Optional serialNumber of product. */
  serialNumber?: Maybe<Scalars['String']>;
  /** Product quantity */
  quantity: Scalars['Float'];
  /** Unit base price, tax exclusive, before adjustments  */
  basePrice: Scalars['Float'];
  /** Unit base price, tax inclusive, before adjustments  */
  basePriceInclTax: Scalars['Float'];
  /** Unit price, tax exclusive. after adjustments */
  price: Scalars['Float'];
  /** Unit price, tax exclusive. after adjustments */
  priceInclTax: Scalars['Float'];
  /** Cost price used for margin calculations */
  cost?: Maybe<Scalars['Float']>;
  /** Tax value */
  tax: Scalars['Float'];
  /** The tax rate used to calculate the tax value. defaults to 0 */
  taxRatePercent?: Maybe<Scalars['Int']>;
  /** Order of the sale line item */
  sequence?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['JSON']>;
  loyaltyPointEarned?: Maybe<Scalars['Int']>;
  /** All adjustments made to the sale item that affected the unit price */
  adjustments: Array<SaleAdjustmentInput>;
};

export type SaleList = PaginatedList & {
  __typename?: 'SaleList';
  items: Array<Sale>;
  meta: PaginatedListMeta;
};

export type SaleListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<SaleSortParameter>;
  filter?: Maybe<SaleFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type SaleModifiedCustomerAddedEntryData = {
  __typename?: 'SaleModifiedCustomerAddedEntryData';
  customerId: Scalars['ID'];
  emailAddress: Scalars['String'];
};

export type SaleModifiedEntryData = {
  __typename?: 'SaleModifiedEntryData';
  input: Scalars['JSON'];
  oldEntity?: Maybe<Scalars['JSON']>;
};

export type SaleNoteEntryData = {
  __typename?: 'SaleNoteEntryData';
  note: Scalars['String'];
};

export type SaleSortParameter = {
  id?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
  receiptConfirmationUrl?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  locationId?: Maybe<SortOrder>;
  customerId?: Maybe<SortOrder>;
  staffId?: Maybe<SortOrder>;
  receiptId?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  invoiceNumber?: Maybe<SortOrder>;
  invoiceSequence?: Maybe<SortOrder>;
  returnForId?: Maybe<SortOrder>;
  note?: Maybe<SortOrder>;
  totalCost?: Maybe<SortOrder>;
  totalTax?: Maybe<SortOrder>;
  totalPrice?: Maybe<SortOrder>;
  totalAdjustments?: Maybe<SortOrder>;
  totalAdjustmentPercentSaving?: Maybe<SortOrder>;
  balance?: Maybe<SortOrder>;
  saleDate?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export enum SaleSource {
  /** A sale that was created via the POS */
  WEB_REGISTER = 'WEB_REGISTER',
  /** A sale that was created via the web checkout */
  WEB_CHECKOUT = 'WEB_CHECKOUT'
}

/** Returned if there is an error in transitioning the Salle state */
export type SaleStateTransitionError = ErrorResult & {
  __typename?: 'SaleStateTransitionError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  transitionError: Scalars['String'];
  fromState: Scalars['String'];
  toState: Scalars['String'];
};

export type SaleStatusTransitionData = {
  __typename?: 'SaleStatusTransitionData';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type SearchInput = {
  term?: Maybe<Scalars['String']>;
  type: SuggestType;
  /** defaults to false */
  activeOnly?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  items?: Maybe<Array<SearchResultItem>>;
};

export type SearchResultItem = {
  __typename?: 'SearchResultItem';
  id: Scalars['ID'];
  type: SuggestType;
  entity: SearchResultItemEntity;
};

export type SearchResultItemEntity = Product | Customer;

export type SelectedOption = {
  __typename?: 'SelectedOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SelectedOptionInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type SelfCheckoutLocationSettings = {
  __typename?: 'SelfCheckoutLocationSettings';
  locationId: Scalars['ID'];
  location?: Maybe<Location>;
  enabled: Scalars['Boolean'];
  terminalId?: Maybe<Scalars['ID']>;
  terminal?: Maybe<Terminal>;
  onlineCheckoutEnabled: Scalars['Boolean'];
  offlineCheckoutEnabled: Scalars['Boolean'];
  payAtCounterHeading?: Maybe<Scalars['String']>;
  payAtCounterSubheading?: Maybe<Scalars['String']>;
  checkoutSuccessHeading?: Maybe<Scalars['String']>;
};

export type SelfCheckoutSettings = {
  __typename?: 'SelfCheckoutSettings';
  enabled: Scalars['Boolean'];
  locationSettings: Array<SelfCheckoutLocationSettings>;
  logoURL?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']>;
};

export type SendReceiptInput = {
  type: SendReceiptType;
  saleId: Scalars['ID'];
  /** Must be valid email or E.164 for sms */
  to: Scalars['String'];
  receiptId?: Maybe<Scalars['ID']>;
};

export enum SendReceiptType {
  /** EMAIL */
  EMAIL = 'EMAIL'
}

export type SendStocktakeInput = {
  type: SendStocktakeType;
  stocktakeId: Scalars['ID'];
  /** Must be valid email */
  to: Scalars['String'];
};

export enum SendStocktakeType {
  /** EMAIL */
  EMAIL = 'EMAIL'
}

export type ServerConfig = {
  __typename?: 'ServerConfig';
  receiptTemplateTypes: Array<ReceiptTemplateType>;
  availablePayTypes: Array<PayType>;
  internalPayTypes: Array<PayType>;
};

export type Setup2faStrategyInput = {
  /** The strategy to use for 2fa */
  strategy: MfaCodeStrategy;
  /** The identifier for the strategy, eg email address or phone number */
  identifier?: Maybe<Scalars['String']>;
};

export type Setup2faStrategyResult = Success | InvalidMfaStrategyError | InvalidMfaIdentifierError | MfaAlreadySetupError;

export type ShiftLayoutNodesInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Staff = EntityNode & {
  __typename?: 'Staff';
  id: Scalars['ID'];
  hasUserAccount: Scalars['Boolean'];
  userId?: Maybe<Scalars['ID']>;
  user?: Maybe<UserV2>;
  username?: Maybe<Scalars['String']>;
  terminalIds: Array<Scalars['ID']>;
  terminals: Array<Terminal>;
  emailAddress?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  initials: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffFilterParameter = {
  id?: Maybe<IdOperators>;
  hasUserAccount?: Maybe<BooleanOperators>;
  userId?: Maybe<IdOperators>;
  username?: Maybe<StringOperators>;
  terminalIds?: Maybe<IdListOperators>;
  emailAddress?: Maybe<StringOperators>;
  fullName?: Maybe<StringOperators>;
  initials?: Maybe<StringOperators>;
  enabled?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type StaffList = PaginatedList & {
  __typename?: 'StaffList';
  items: Array<Staff>;
  meta: PaginatedListMeta;
};

export type StaffListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<StaffSortParameter>;
  filter?: Maybe<StaffFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type StaffSortParameter = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  emailAddress?: Maybe<SortOrder>;
  fullName?: Maybe<SortOrder>;
  initials?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

/** Returned when there is an error with user input. */
export type StockNumberNotFoundError = ErrorResult & {
  __typename?: 'StockNumberNotFoundError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type StocktakeCreateInput = {
  /** The location which will be doing the stocktake */
  locationId: Scalars['ID'];
  /** A name to help identify the stocktake */
  name: Scalars['String'];
  /** filters used to generate InventoryMovementItems when transitioning to STOCKTAKE_UNDERWAY, empty array will create for all items. null will generate none. */
  filters?: Maybe<Array<Maybe<InventoryMovementFilterInput>>>;
  /** Optional, default timestamp used */
  stocktakeDate?: Maybe<Scalars['DateTime']>;
  /** Optional, default timestamp used */
  dueAt?: Maybe<Scalars['DateTime']>;
};

export type StocktakeDetailsUpdateInput = {
  /** A name to help identify the stocktake */
  name: Scalars['String'];
  /** Can only update  these if not started, filters used to generate InventoryMovementItems when transitioning to STOCKTAKE_UNDERWAY, empty array will create for all items. null will generate none. */
  filters?: Maybe<Array<Maybe<InventoryMovementFilterInput>>>;
  /** Optional, default timestamp used */
  stocktakeDate?: Maybe<Scalars['DateTime']>;
  /** Optional, default timestamp used */
  dueAt?: Maybe<Scalars['DateTime']>;
};

export type StocktakeItem = EntityNode & {
  __typename?: 'StocktakeItem';
  id: Scalars['ID'];
  stocktakeId: Scalars['ID'];
  product: Scalars['ID'];
  stockCode?: Maybe<Scalars['String']>;
  counted?: Maybe<Scalars['Int']>;
  initialExpected?: Maybe<Scalars['Int']>;
  expected?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Float']>;
  status?: Maybe<StocktakeItemStatus>;
  manual: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type StocktakeItemFilterParameter = {
  id?: Maybe<IdOperators>;
  stocktakeId?: Maybe<IdOperators>;
  product?: Maybe<IdOperators>;
  stockCode?: Maybe<StringOperators>;
  counted?: Maybe<NumberOperators>;
  initialExpected?: Maybe<NumberOperators>;
  expected?: Maybe<NumberOperators>;
  cost?: Maybe<NumberOperators>;
  status?: Maybe<StringOperators>;
  manual?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type StocktakeItemList = PaginatedList & {
  __typename?: 'StocktakeItemList';
  items: Array<StocktakeItem>;
  meta: PaginatedListMeta;
};

export type StocktakeItemListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<StocktakeItemSortParameter>;
  filter?: Maybe<StocktakeItemFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type StocktakeItemSortParameter = {
  id?: Maybe<SortOrder>;
  stocktakeId?: Maybe<SortOrder>;
  product?: Maybe<SortOrder>;
  stockCode?: Maybe<SortOrder>;
  counted?: Maybe<SortOrder>;
  initialExpected?: Maybe<SortOrder>;
  expected?: Maybe<SortOrder>;
  cost?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export enum StocktakeItemStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS'
}

/** Operators for filtering on a list of String fields */
export type StringListOperators = {
  inList: Array<Maybe<Scalars['String']>>;
};

export type StringOperators = {
  eq?: Maybe<Scalars['String']>;
  notEq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  regex?: Maybe<Scalars['String']>;
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export enum SuggestType {
  /** Search for products */
  PRODUCT = 'PRODUCT',
  /** Search for customers */
  CUSTOMER = 'CUSTOMER',
  /** Include result for all types */
  ALL = 'ALL'
}

export type SwapLayoutNodesInput = {
  firstNodeId: Scalars['ID'];
  secondNodeId: Scalars['ID'];
};

export type SwapLayoutNodesResult = {
  __typename?: 'SwapLayoutNodesResult';
  firstNode: LayoutNode;
  secondNode: LayoutNode;
};

export type SwitchUserResult = CurrentUserV2 | InvalidCredentialsError | InvalidMfaCodeError | MfaRequiredError;

export type TalinkAuthInputType = {
  talinkUsername: Scalars['String'];
  password: Scalars['String'];
};

export type TalinkStaffAuthInputType = {
  talinkUsername: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type TalinkTokenAuthInputType = {
  token: Scalars['String'];
};

export type Tenant = {
  __typename?: 'Tenant';
  identifier: Scalars['String'];
};

export type Terminal = EntityNode & {
  __typename?: 'Terminal';
  id: Scalars['ID'];
  name: Scalars['String'];
  invoiceSequence: Scalars['Int'];
  openTerminalClosure?: Maybe<TerminalClosure>;
  openTerminalClosureId?: Maybe<Scalars['ID']>;
  terminalOpenTime?: Maybe<Scalars['DateTime']>;
  terminalCloseTime?: Maybe<Scalars['DateTime']>;
  saleSufix?: Maybe<Scalars['String']>;
  salePrefix?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  paymentMethodIds: Array<Scalars['ID']>;
  defaultReceiptTemplateId?: Maybe<Scalars['ID']>;
  printReceiptOnCheckout: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type TerminalClosure = EntityNode & {
  __typename?: 'TerminalClosure';
  id: Scalars['ID'];
  terminalId: Scalars['ID'];
  terminalName?: Maybe<Scalars['String']>;
  terminalOpenDate: Scalars['DateTime'];
  terminalCloseDate?: Maybe<Scalars['DateTime']>;
  isClosed: Scalars['Boolean'];
  payments: Array<TerminalClosurePaymentTotal>;
  cashMovements: Array<CashMovement>;
  saleSummary: TerminalClosureSalesSummary;
  /** Only available if the terminal closure is closed */
  countedPaymentsSummary?: Maybe<Array<TerminalClosurePaymentMethodSummary>>;
  estimatedCashBalance: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type TerminalClosureFilterParameter = {
  id?: Maybe<IdOperators>;
  terminalId?: Maybe<IdOperators>;
  terminalName?: Maybe<StringOperators>;
  terminalOpenDate?: Maybe<DateOperators>;
  terminalCloseDate?: Maybe<DateOperators>;
  isClosed?: Maybe<BooleanOperators>;
  estimatedCashBalance?: Maybe<NumberOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type TerminalClosureList = PaginatedList & {
  __typename?: 'TerminalClosureList';
  items: Array<TerminalClosure>;
  meta: PaginatedListMeta;
};

export type TerminalClosureListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<TerminalClosureSortParameter>;
  filter?: Maybe<TerminalClosureFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type TerminalClosurePaymentMethodSummary = {
  __typename?: 'TerminalClosurePaymentMethodSummary';
  paymentMethodId: Scalars['ID'];
  payTypeId: Scalars['ID'];
  paymentTypeName: Scalars['String'];
  total: Scalars['Decimal'];
  expected: Scalars['Decimal'];
  difference: Scalars['Decimal'];
};

export type TerminalClosurePaymentTotal = {
  __typename?: 'TerminalClosurePaymentTotal';
  paymentMethodId: Scalars['ID'];
  counted: Scalars['Float'];
};

export type TerminalClosurePaymentTotalInput = {
  paymentMethodId: Scalars['ID'];
  total: Scalars['Decimal'];
};

export type TerminalClosurePaymentsSummary = {
  __typename?: 'TerminalClosurePaymentsSummary';
  terminalOpenTime: Scalars['DateTime'];
  terminalId: Scalars['ID'];
  terminalClosureId: Scalars['ID'];
  payments: Array<TerminalClosurePaymentMethodSummary>;
};

export type TerminalClosureSalesSummary = {
  __typename?: 'TerminalClosureSalesSummary';
  totalSales: Scalars['Decimal'];
  totalInvoices: Scalars['Decimal'];
  totalLaybys: Scalars['Decimal'];
  totalPayments: Scalars['Decimal'];
  totalTaxes: Scalars['Decimal'];
  totalDiscounts: Scalars['Decimal'];
  totalRefunds: Scalars['Decimal'];
};

export type TerminalClosureSortParameter = {
  id?: Maybe<SortOrder>;
  terminalId?: Maybe<SortOrder>;
  terminalName?: Maybe<SortOrder>;
  terminalOpenDate?: Maybe<SortOrder>;
  terminalCloseDate?: Maybe<SortOrder>;
  estimatedCashBalance?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};

export type TerminalFilterParameter = {
  id?: Maybe<IdOperators>;
  name?: Maybe<StringOperators>;
  invoiceSequence?: Maybe<NumberOperators>;
  openTerminalClosureId?: Maybe<IdOperators>;
  terminalOpenTime?: Maybe<DateOperators>;
  terminalCloseTime?: Maybe<DateOperators>;
  saleSufix?: Maybe<StringOperators>;
  salePrefix?: Maybe<StringOperators>;
  enabled?: Maybe<BooleanOperators>;
  paymentMethodIds?: Maybe<IdListOperators>;
  defaultReceiptTemplateId?: Maybe<IdOperators>;
  printReceiptOnCheckout?: Maybe<BooleanOperators>;
  createdAt?: Maybe<DateOperators>;
  updatedAt?: Maybe<DateOperators>;
  deletedAt?: Maybe<DateOperators>;
};

export type TerminalList = PaginatedList & {
  __typename?: 'TerminalList';
  items: Array<Terminal>;
  meta: PaginatedListMeta;
};

export type TerminalListOptions = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<TerminalSortParameter>;
  filter?: Maybe<TerminalFilterParameter>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  /** Filter either AND, OR. Defaults to AND. */
  filterOperator?: Maybe<LogicalOperator>;
};

export type TerminalSortParameter = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  invoiceSequence?: Maybe<SortOrder>;
  openTerminalClosureId?: Maybe<SortOrder>;
  terminalOpenTime?: Maybe<SortOrder>;
  terminalCloseTime?: Maybe<SortOrder>;
  saleSufix?: Maybe<SortOrder>;
  salePrefix?: Maybe<SortOrder>;
  defaultReceiptTemplateId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  deletedAt?: Maybe<SortOrder>;
};


export type UpdateAccessTokenInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateCategoryInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['ID']>;
  bannerImage?: Maybe<Scalars['Upload']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type UpdateDepartmentInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<Scalars['Upload']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type UpdateGlobalSettingsInput = {
  taxPercentage?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyInput>;
  timezone?: Maybe<Scalars['String']>;
  cashManagedPaymentMethodId?: Maybe<Scalars['ID']>;
  laybyEnabled?: Maybe<Scalars['Boolean']>;
  defaultLaybyDepositPercentage?: Maybe<Scalars['Int']>;
  minLaybySpend?: Maybe<Scalars['Float']>;
  abn?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  defaultTerminalClosureFloat?: Maybe<Scalars['Float']>;
  automaticOpenTerminalClosure?: Maybe<Scalars['Boolean']>;
  automaticallyEmailTerminalClosureSummary?: Maybe<Scalars['Boolean']>;
  terminalClosureSendToAddress?: Maybe<Scalars['String']>;
  showExpectedClosureCounts?: Maybe<Scalars['Boolean']>;
  removalAiDetails?: Maybe<RemovalAiDetailsInput>;
  removalBgDetails?: Maybe<RemovalBgDetailsInput>;
  automaticBackgroundRemoval?: Maybe<Scalars['Boolean']>;
  automaticallyEmailStockTake?: Maybe<Scalars['Boolean']>;
  stocktakeSendToAddress?: Maybe<Scalars['String']>;
  bgRemovalStrategy?: Maybe<BgRemovalStrategy>;
};

export type UpdateLocationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  retailerLocationKey: Scalars['String'];
};

/**
 * Contains a subset of information that can be updated.
 * Not all fields can be updated, and some fields are only available to some roles
 */
export type UpdateMeInput = {
  /** force disable all 2fa strategies */
  currentPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
};

export type UpdatePaymentMethodInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated, use retailerPayTypeId */
  retailerPayCode?: Maybe<Scalars['String']>;
  retailerPayTypeId?: Maybe<Scalars['ID']>;
  configuration?: Maybe<PaymentMethodConfigurationInput>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateProductImageInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type UpdateProductInput = {
  id: Scalars['ID'];
  taxable?: Maybe<Scalars['Boolean']>;
  stockCode?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  departmentId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['ID']>;
  variantParentId?: Maybe<Scalars['ID']>;
  locationInventoryPricing?: Maybe<Array<InventoryPricingInput>>;
  options?: Maybe<Array<Scalars['String']>>;
  selectedOptions?: Maybe<Array<SelectedOptionInput>>;
  loyaltyPointEarnValue?: Maybe<Scalars['Int']>;
  loyaltyPointRedeemValue?: Maybe<Scalars['Int']>;
  serialNumberRequired?: Maybe<Scalars['Boolean']>;
};

export type UpdateRetailerPayTypeInput = {
  id: Scalars['ID'];
  payCode?: Maybe<Scalars['String']>;
  payDescription?: Maybe<Scalars['String']>;
  payType?: Maybe<Scalars['String']>;
  payOver?: Maybe<Scalars['Boolean']>;
};

export type UpdateSaleInput = {
  id: Scalars['ID'];
  /** Valid id of the customer accociated with the sale */
  customerId?: Maybe<Scalars['ID']>;
  /** Valid id of the retailer staff associated with sale. */
  staffId?: Maybe<Scalars['ID']>;
  /** optional, send a receipt to customer */
  receiptTo?: Maybe<Scalars['String']>;
  /** optional, the the receipt template to use when sending email */
  receiptId?: Maybe<Scalars['ID']>;
  /** Time of sale, by default current time will be assigned */
  saleDate?: Maybe<Scalars['DateTime']>;
  /** One of: OPEN, SAVED, CLOSED, LAYBY, LAYBY_CLOSED, VOIDED */
  state: Scalars['String'];
  lineItems: Array<SaleLineItemInput>;
  /** Optional note to add to the sale */
  note?: Maybe<Scalars['String']>;
  /** An arra yof payments */
  payments: Array<RegisterSalePaymentInput>;
  /** Optional: Store any metadata relevent to the sale */
  metadata?: Maybe<Scalars['JSON']>;
};

export type UpdateSelfCheckoutLocationSettingsInput = {
  locationId: Scalars['ID'];
  terminalId?: Maybe<Scalars['ID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  onlineCheckoutEnabled?: Maybe<Scalars['Boolean']>;
  offlineCheckoutEnabled?: Maybe<Scalars['Boolean']>;
  payAtCounterHeading?: Maybe<Scalars['String']>;
  payAtCounterSubheading?: Maybe<Scalars['String']>;
  checkoutSuccessHeading?: Maybe<Scalars['String']>;
};

export type UpdateSelfCheckoutSettingsInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['Upload']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
};

export type UpdateStaffInput = {
  id: Scalars['ID'];
  /** Must be unique */
  initials: Scalars['String'];
  fullName: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type UpdateStaffUserAccountInput = {
  staffId: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  /** Empty array means all terminals are allowed. */
  terminalIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateTerminalInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  invoiceSequence?: Maybe<Scalars['Int']>;
  defaultReceiptTemplateId?: Maybe<Scalars['ID']>;
  /** The payment methods available at this Terminal */
  paymentMethodIds?: Maybe<Array<Scalars['ID']>>;
  salePrefix?: Maybe<Scalars['String']>;
  saleSufix?: Maybe<Scalars['String']>;
};


export type UploadImageToRetailerBySkuInput = {
  position: Scalars['Int'];
  sku: Scalars['String'];
  file: Scalars['Upload'];
};

export type User = EntityNode & {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  numberAvailableTerminals: Scalars['Int'];
  userAccountType: UserAccountType;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeOnboardingComplete: Scalars['Boolean'];
};

export enum UserAccountType {
  TOWER_STAFF = 'TOWER_STAFF',
  RETAILER_USER = 'RETAILER_USER'
}

export type UserV2 = EntityNode & {
  __typename?: 'UserV2';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  identifier: Scalars['String'];
  enabled2fa: Scalars['Boolean'];
  verified: Scalars['Boolean'];
  roles: Array<Role>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  authenticationMethods: Array<AuthenticationMethod>;
  mfaMethods: Array<MfaMethod>;
};

export type Vendor = {
  __typename?: 'Vendor';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  talinkId: Scalars['String'];
  talinkUsername: Scalars['String'];
  numberAvailableTerminals: Scalars['Int'];
  userAccountType: UserAccountType;
  stripeOnboardingComplete: Scalars['Boolean'];
};

export type Verify2faStrategyResult = Success | InvalidMfaStrategyError | MfaAlreadySetupError | InvalidMfaCodeError | InvalidCredentialsError;
