import React from "react";

type Props = {
  children?: React.ReactNode;
  ErrorHandler: React.ComponentType<{ error: any; errorMessage?: string }>;
};

type State = {
  error?: any;
  hasError?: boolean;
  errorMessage?: string;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined, hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
      errorMessage: error?.message ?? "Error Occoured",
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    //TODO: log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return (
        <this.props.ErrorHandler
          error={this.state.error}
          errorMessage={this.state.errorMessage}
        />
      );
    }

    return this.props.children;
  }
}
