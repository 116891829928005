import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { Page } from "components";
import {
  ReceiptForm,
  ReceiptPageSkeleton,
  SettingCard,
} from "../../components";
import { useModal } from "../../../../utilities/use-modal";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useMutationUpdateReceipt } from "../../utils/receipts";
import { UploadImageDialogue } from "../../../../components/UploadImageDialogue";
import { Receipt } from "@towersystems/roam-common/lib/generated-types";
import { Button } from "../../../../components/Button";

export const EditReceiptPage = () => {
  const navigate = useNavigate();
  const { data }: any = useLoaderData();
  const imageModal = useModal();
  const { updateReceipt: mutation } = useMutationUpdateReceipt();

  return (
    <React.Suspense fallback={<ReceiptPageSkeleton />}>
      <Await resolve={data}>
        {([resolvedReceipt]: [Receipt]) => {
          const id = resolvedReceipt.id;

          const handleOnDeleteImage = async () => {
            return mutation({
              id: id,
              settings: { logo: null },
            })
              .then(() => {
                navigate(".", { replace: true });
                return true;
              })
              .catch(() => false);
          };

          const handleOnUploadImage = async (file: File) => {
            return mutation({
              id: id,
              settings: { logo: file },
            })
              .then(() => {
                navigate(".", { replace: true });
                return true;
              })
              .catch(() => false);
          };

          const logoMarkup = resolvedReceipt.settings.logoURL ? (
            <img
              src={resolvedReceipt.settings.logoURL}
              alt="Logo"
              style={{ maxWidth: "200px", maxHeight: "100%" }}
            />
          ) : (
            <div>No logo uploaded</div>
          );

          return (
            <Page
              title={resolvedReceipt.name}
              titleDescription="Edit Receipt"
              goBack={{ onAction: () => navigate("/settings/receipts") }}
            >
              <Stack spacing={3} divider={<Divider variant="middle" />}>
                <SettingCard title="Receipt Logo" wrapped>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {logoMarkup ? (
                      <Box marginRight={5}>{logoMarkup}</Box>
                    ) : undefined}
                    <Box flex={1} paddingTop={1}>
                      <Button onClick={imageModal.onOpen}>Upload Logo</Button>
                      <Box>
                        <Typography variant="caption">
                          Display a logo in the header of receipt
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </SettingCard>
                <ReceiptForm receipt={resolvedReceipt} />
              </Stack>
              <UploadImageDialogue
                onDelete={handleOnDeleteImage}
                showDelete={Boolean(resolvedReceipt.settings.logoURL)}
                onSubmit={handleOnUploadImage}
                deleteThumbnailSrc={resolvedReceipt.settings.logoURL}
                {...imageModal}
              />
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
