import { SwitchActiveUserFormValues } from "./types";
import { useFormContext } from "react-hook-form";
import {
  CredentialsFieldset,
  SelectMfaMethodFieldset,
  EnterMfaCodeFieldset,
} from "./components";

export interface SwitchActiveUserFormProps {}
export const SwitchActiveUserForm = ({}: SwitchActiveUserFormProps) => {
  const { watch } = useFormContext<SwitchActiveUserFormValues>();

  const mfaStrategy = watch("mfaStrategy");
  const mfaRequired = watch("ui.mfaRequired");
  const mfaCodeSent = watch("ui.mfaCodeSent");

  if (mfaRequired == true) {
    if (!mfaStrategy || !mfaCodeSent) {
      return <SelectMfaMethodFieldset />;
    }
    return <EnterMfaCodeFieldset />;
  }
  return <CredentialsFieldset />;
};
