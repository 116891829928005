import { PaletteMode } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";
import React, { useCallback, useMemo, useState } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { AppContext, Frames } from "../../utilities/app";
import { ServerConfigContext } from "../../utilities/server-config/context";
import { LoggedInFrame, LoggedOutFrame, SettingsFrame } from "components";
import { MeGuard } from "../MeGuard";
import { GlobalSettingsController } from "../GlobalSettingsController";
import { FeaturesContext } from "../../utilities/features";
import { VendorController } from "../VendorController";
import { ActiveUserController } from "../ActiveUserController";
import { SwitchUserProvider } from "../SwitchUserDialog/SwitchUserProvider";

export interface AppProps {
  theme: ThemeOptions;
}

export const LoggedOutApp = ({ theme }: AppProps) => {
  return <LoggedOutFrame></LoggedOutFrame>;
};

export const LoggedInApp = ({ theme }: AppProps) => {
  const loader: any = useLoaderData();
  const [mode, setMode] = useState<PaletteMode>("light");
  const [frameComponent, setFrameComponent] = useState<Frames | undefined>(
    undefined
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "light" : "light"
        );
      },
    }),
    []
  );

  const FrameComponent = useMemo(() => {
    switch (frameComponent) {
      case "settings":
        return SettingsFrame;
      case "dashboard":
      default:
        return LoggedInFrame;
    }
  }, [frameComponent]);

  const setFrameComponentFn = useCallback(
    (frame: Frames | undefined) => {
      setFrameComponent(frame);
    },
    [setFrameComponent]
  );

  const context = {
    frameComponent: frameComponent,
    setFrameComponent: setFrameComponentFn,
    toggleColorMode: colorMode.toggleColorMode,
  };

  if (!loader) {
    return null;
  }

  return (
    <React.Suspense fallback={<div>loading</div>}>
      <Await resolve={loader?.data}>
        {([serverConfig, features]) => {
          return (
            <FeaturesContext.Provider value={features}>
              <ServerConfigContext.Provider value={serverConfig}>
                <AppContext.Provider value={context}>
                  <MeGuard>
                    <ActiveUserController>
                      <VendorController>
                        <GlobalSettingsController>
                          <SwitchUserProvider>
                            <FrameComponent></FrameComponent>
                          </SwitchUserProvider>
                        </GlobalSettingsController>
                      </VendorController>
                    </ActiveUserController>
                  </MeGuard>
                </AppContext.Provider>
              </ServerConfigContext.Provider>
            </FeaturesContext.Provider>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
