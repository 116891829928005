export enum MatchState {
  MatchForced,
  MatchUrl,
  MatchPaths,
  Excluded,
  NoMatch,
}

export interface ItemURLDetails {
  url?: string;
  matches?: boolean;
  exactMatch?: boolean;
  matchPaths?: string[];
  excludePaths?: string[];
}

// copied from polairs directly
function safeEqual(location: string, path: string) {
  return normalizePathname(location) === normalizePathname(path);
}

function normalizePathname(pathname: string) {
  const barePathname = pathname.split("?")[0].split("#")[0];
  return barePathname.endsWith("/") ? barePathname : `${barePathname}/`;
}

function safeStartsWith(location: string, path: string) {
  return normalizePathname(location).startsWith(normalizePathname(path));
}

export const matchedLocation = (p: ItemURLDetails, location: string) => {
  const matchState = matchStateForItem(p, location);
  return (
    matchState === MatchState.MatchForced ||
    matchState === MatchState.MatchUrl ||
    matchState === MatchState.MatchPaths
  );
};

export const matchStateForItem = (
  { url, matches, exactMatch, matchPaths, excludePaths }: ItemURLDetails,
  location: string
) => {
  if (url == null) {
    return MatchState.NoMatch;
  }

  if (matches) {
    return MatchState.MatchForced;
  }

  if (
    matches === false ||
    (excludePaths &&
      excludePaths.some((path: any) => safeStartsWith(location, path)))
  ) {
    return MatchState.Excluded;
  }

  if (
    matchPaths &&
    matchPaths.some((path: any) => safeStartsWith(location, path))
  ) {
    return MatchState.MatchPaths;
  }

  const matchesUrl = exactMatch
    ? safeEqual(location, url)
    : safeStartsWith(location, url);

  return matchesUrl ? MatchState.MatchUrl : MatchState.NoMatch;
};
