import { Product } from "@towersystems/roam-common/lib/generated-types";
import { CardDetailList } from "../../../../components/CardDetailList";

export interface ProductDescriptionDetailsCardProps {
  product: Product;
}

export const ProductDescriptionDetailsCard = ({
  product,
}: ProductDescriptionDetailsCardProps) => {
  return (
    <CardDetailList
      title="Description"
      items={[
        {
          key: "code",
          title: "Retailer Stock Code",
          value: product.stockCode || "",
        },
        {
          key: "barcode",
          title: "Barcode",
          value: product.stockNumber || "",
        },
        {
          key: "department",
          title: "Department",
          value: product.department?.name || "",
        },
        {
          key: "category",
          title: "Category",
          value: product.category?.name || "",
        },

        {
          key: "description",
          title: product.description || "No Description.",
        },
      ]}
    ></CardDetailList>
  );
};
