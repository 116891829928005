import { Sale } from "@towersystems/roam-common/lib/generated-types";
import { usePaginatedList } from "../../../../../../utilities/paginated-list";
import { useSearchQueryProvider } from "../../../../../../utilities/search/hooks";
import { QUERY_SALE_LIST } from "../../../../utils/sales";
import React from "react";
import { useScheduler } from "../../../../../../utilities/use-scheduler";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { SaleRow, SaleRowSkeleton } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { headCells } from "../../constants";

export const AllSalesTabView = () => {
  const search = useSearchQueryProvider();
  const { schedule } = useScheduler(500);
  const navigate = useNavigate();

  const paginatedList = usePaginatedList<Sale>({
    query: QUERY_SALE_LIST as any,
    resultKey: "sales",
    variables: {
      sort: { updatedAt: "DESC" },
      filter: { invoiceNumber: { contains: search.query } },
    },
  });

  React.useEffect(() => {
    handleOnTermInputChange();
  }, [search.query]);

  const handleOnTermInputChange = React.useCallback(() => {
    schedule(() =>
      paginatedList.setVariables({
        filter: { invoiceNumber: { contains: search.query } },
      })
    );
  }, [search.query]);

  const handleOnPageChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      paginatedList.setPage(value);
    },
    [paginatedList.setPage]
  );

  function handleSaleClick(sale: Sale) {
    navigate(`/sales/view/${sale.id}`);
  }

  const items: Sale[] = paginatedList.loading
    ? paginatedList.previousResult?.items || []
    : paginatedList.result?.items || [];

  const listMarkup = paginatedList.loading
    ? [...Array(paginatedList.limit)].map((_, i) => <SaleRowSkeleton key={i} />)
    : items.map((item) => (
        <SaleRow
          key={item.id}
          sale={item}
          onClick={() => handleSaleClick(item)}
        />
      ));

  const totalPages = paginatedList.maxPage;

  const paginationMarkup = (
    <Box sx={{ p: 2, borderColor: "grey.300" }} borderTop={1}>
      <Pagination
        onChange={handleOnPageChange}
        count={totalPages}
        variant="outlined"
        shape="rounded"
      />
    </Box>
  );

  return (
    <Box flex={1}>
      <Table sx={{ minWidth: 1050 }}>
        <TableHead>
          <TableRow>
            {headCells.map((i) => (
              <TableCell
                key={i.id}
                align={i.alignment}
                style={{ width: i.width }}
              >
                {i.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{listMarkup}</TableBody>
      </Table>
      {paginationMarkup}
    </Box>
  );
};
