import { Box, Divider, Paper, Stack } from "@mui/material";
import { DynamicSkeleton, DynamicSkeletonType } from "../DynamicSkeleton";

type CardDetailListItem = {
  title?: DynamicSkeletonType;
  subTitle?: DynamicSkeletonType;
  value?: boolean;
};

export interface CardDetailListSkeletonProps {
  items: CardDetailListItem[];
  divider?: boolean;
  title?: DynamicSkeletonType;
}

export const CardDetailListSkeleton = ({
  items,
  title,
  divider = true,
}: CardDetailListSkeletonProps) => {
  const cardTitleMarkup =
    title !== undefined ? (
      <Box marginBottom={2}>
        <DynamicSkeleton
          content={title}
          typographyProps={{ fontWeight: "bold", textTransform: "uppercase" }}
        />
      </Box>
    ) : undefined;

  return (
    <Box>
      {cardTitleMarkup}
      <Paper variant="outlined">
        <Stack divider={divider ? <Divider /> : undefined}>
          {items.map((item, i) => {
            const titleMarkup = !!item.title ? (
              <DynamicSkeleton
                key={i}
                content={item.title}
                typographyProps={{ variant: "h6", fontWeight: "normal" }}
                skeletonProps={{
                  width: 150,
                }}
              />
            ) : undefined;

            const subtitleMarkup = !!item.subTitle ? (
              <DynamicSkeleton
                content={item.subTitle}
                typographyProps={{ variant: "body2", color: "textSecondary" }}
                skeletonProps={{
                  width: 200,
                }}
              />
            ) : undefined;

            const valueMarkup = item.value ? (
              <DynamicSkeleton
                content={item.value}
                typographyProps={{
                  variant: "h6",
                  fontWeight: "normal",
                }}
                skeletonProps={{
                  width: 150,
                }}
              />
            ) : undefined;

            return (
              <Box padding={2} key={i} display="flex" alignItems={"center"}>
                <Box display="flex" justifyContent={"space-between"} flex={1}>
                  <Box>
                    {titleMarkup}
                    {subtitleMarkup}
                  </Box>
                  <Box>{valueMarkup}</Box>
                </Box>
              </Box>
            );
          })}
        </Stack>
      </Paper>
    </Box>
  );
};
