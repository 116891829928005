import { gql } from "@apollo/client";
import {
  CategoryNoNestingFragment,
  DepartmentNoNestingFragment,
  PaginatedListMetaNoNestingFragment,
  ProductFragment,
  ProductImageNoNestingFragment,
  ProductListFragment,
  ProductLocationInventoryPricingNoNestingFragment,
  ProductNoNestingFragment,
  ProductOptionNoNestingFragment,
  SelectedOptionNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as Yup from "yup";

export const QUERY_PRODUCT_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${ProductNoNestingFragment}
  query products($options: ProductListOptions) {
    products(options: $options) {
      items {
        ...ProductNoNesting
        image {
          id
          path
        }
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const QUERY_PRODUCT_VARIANTS = gql`
  ${ProductFragment}
  ${ProductNoNestingFragment}
  ${ProductOptionNoNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${SelectedOptionNoNestingFragment}
  query productVariants($id: ID!) {
    productVariants(id: $id) {
      ...Product
      variants {
        ...Product
      }
    }
  }
`;

export const MUTATION_CREATE_PRODUCT = gql`
  ${ProductNoNestingFragment}
  mutation createProduct($input: ProductInput!) {
    createProduct(input: $input) {
      ...ProductNoNesting
    }
  }
`;

export const QUERY_PRODUCT = gql`
  ${ProductFragment}
  ${ProductNoNestingFragment}
  ${ProductOptionNoNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${SelectedOptionNoNestingFragment}
  ${CategoryNoNestingFragment}
  ${DepartmentNoNestingFragment}
  ${ProductImageNoNestingFragment}
  query product($id: ID!) {
    product(id: $id) {
      ...Product
      variants {
        id
        displayName
        totalInventory
        locationInventoryPricing {
          retailPrice
        }
      }
      locationInventoryPricing {
        ...ProductLocationInventoryPricingNoNesting
        location {
          name
        }
      }
    }
  }
`;

export const MUTATION_DELETE_PRODUCT = gql`
  mutation softDeleteProduct($id: ID!) {
    softDeleteProduct(id: $id) {
      result
    }
  }
`;

export const MUTATION_UPLOAD_PRODUCT_IMAGE = gql`
  mutation createProductImage($input: CreateProductImageInput!) {
    createProductImage(input: $input) {
      ... on ProductImage {
        id
      }
      ... on MimeTypeError {
        message
      }
    }
  }
`;

export const MUTATION_DELETE_PRODUCT_IMAGE = gql`
  mutation deleteProductImage($imageId: ID!) {
    deleteProductImage(imageId: $imageId) {
      result
    }
  }
`;

export const MUTATION_UPDATE_PRODUCT = gql`
  ${ProductFragment}
  ${ProductNoNestingFragment}
  ${ProductOptionNoNestingFragment}
  ${ProductLocationInventoryPricingNoNestingFragment}
  ${SelectedOptionNoNestingFragment}
  ${CategoryNoNestingFragment}
  ${DepartmentNoNestingFragment}
  ${ProductImageNoNestingFragment}
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ...Product
      variants {
        id
        displayName
        totalInventory
        locationInventoryPricing {
          retailPrice
        }
      }
      locationInventoryPricing {
        ...ProductLocationInventoryPricingNoNesting
        location {
          name
        }
      }
    }
  }
`;

export const defaultCreateProductFormValues = {
  stockCode: "",
  stockNumber: "",
  taxable: false,
  name: "",
  description: "",
  variantParentId: "",
  options: undefined,
  selectedOptions: undefined,
  locationInventoryPricing: undefined,
};

const selectedOptionInputSchema = Yup.object({
  name: Yup.string().required(),
  value: Yup.string().required(),
});

const locationInventoryPricingInputSchema = Yup.object({
  id: Yup.string(),
  retailerLocationKey: Yup.string(),
  stockOnHand: Yup.number().integer(),
  costPrice: Yup.number(),
  retailPrice: Yup.number(),
  rrPrice: Yup.number(),
});

export const createProductFormSchema = Yup.object({
  stockCode: Yup.string(),
  stockNumber: Yup.string().required(),
  taxable: Yup.boolean(),
  name: Yup.string().required(),
  description: Yup.string(),
  variantParentId: Yup.string(),
  options: Yup.array(Yup.string().required()),
  selectedOptions: Yup.array(selectedOptionInputSchema.required()),
  locationInventoryPricing: Yup.array(
    locationInventoryPricingInputSchema.required()
  ),
});

export const ProductFormSections = {
  DETAILS: "Details",
  PRICING: "Pricing",
} as const;
