import { SetupNative2faFormValues } from "./types";
import { FormProvider, useForm } from "react-hook-form";
import { MfaCodeStrategy } from "@towersystems/roam-common/lib/generated-types";
import { schema } from "./constants";
import { yupResolver } from "@hookform/resolvers/yup";

export interface SetupNative2faFormProviderProps {
  children?: React.ReactNode;
  strategies: MfaCodeStrategy[];
}

export const SetupNative2faFormProvider = ({
  strategies = [],
  children,
}: SetupNative2faFormProviderProps) => {
  const methods = useForm<SetupNative2faFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      ui: {
        codeSent: false,
        strategies,
      },
      data: {
        strategy: undefined,
        code: undefined,
      },
    },
    resolver: yupResolver(schema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
