import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { Page, Stack } from "../../../../components";
import React from "react";
import { Button } from "../../../../components/Button";
import {
  useMutationGetLoginLink,
  useMutationOnBoardExpressAccount,
  useMutationOnBoardStandardAccount,
  useMutationUnlinkStripeAccount,
} from "../../utils/stripe";
import { Box, Divider, Typography } from "@mui/material";
import { SettingCard } from "../../components";
import { useAuthenticationProvider } from "../../../../utilities/authentication/hooks";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useCurrentVendorProvider } from "../../../../utilities/vendor/hooks";

export interface StripeLandingPageProps {}

export const StripeLandingPage = ({}: StripeLandingPageProps) => {
  const navigate = useNavigate();
  const { data }: any = useLoaderData();
  const { user } = useAuthenticationProvider();
  const { currentVendor } = useCurrentVendorProvider();

  const { getLoginLink } = useMutationGetLoginLink();
  const { onboardExpressAccount } = useMutationOnBoardExpressAccount();
  const { onboardStandardAccount } = useMutationOnBoardStandardAccount();
  const { unlinkStripeAccount } = useMutationUnlinkStripeAccount();

  const handleOnboardExpressAccount = async () => {
    return onboardExpressAccount()
      .then((res) => {
        if (res) {
          window.location.replace(res);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleUnlinkStripeAccount = async () => {
    return unlinkStripeAccount()
      .then((res) => {
        if (res) {
          navigate(".", { replace: true });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleOnboardStandardAccount = async () => {
    return onboardStandardAccount()
      .then((res) => {
        if (res) {
          window.location.replace(res);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleGetLoginLink = async () => {
    return getLoginLink()
      .then((res) => {
        if (res) {
          window.location.replace(res);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <React.Suspense fallback={<div>loading</div>}>
      <Await resolve={data}>
        {([onBoardingComplete]) => {
          return (
            <Page title="Stripe" titleDescription="Connect Stripe Account">
              <Stack spacing={3} divider={<Divider />}></Stack>
              {onBoardingComplete ? (
                <Stack spacing={3}>
                  <SettingCard wrapped title="Account details">
                    <Stack spacing={3} divider={<Divider />}>
                      <Stack direction={"row"}>
                        <Button onClick={handleGetLoginLink}>
                          Redirect to Stripe to dashboard
                        </Button>
                      </Stack>
                      <Box>
                        <Typography variant="caption">
                          Onoarding Complete
                        </Typography>
                        <Typography fontWeight={"bold"}>
                          {currentVendor?.stripeOnboardingComplete
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </Box>
                    </Stack>
                  </SettingCard>
                  <SettingCard title="Disconnect Account" wrapped>
                    <Box>
                      <Typography>
                        Unlink your Stripe account from platform, this cannot be
                        undone.
                      </Typography>
                      <Button
                        sx={{ marginTop: 2 }}
                        color="destructive"
                        onClick={handleUnlinkStripeAccount}
                      >
                        Disconnect Account
                      </Button>
                    </Box>
                  </SettingCard>
                </Stack>
              ) : (
                <SettingCard
                  title="Connect Account"
                  description={
                    "Connect your Stripe account to enable payments on platform."
                  }
                  wrapped
                >
                  <Stack spacing={3} divider={<Divider />}>
                    <Box>
                      <Typography>
                        Express Account: Offers a simplified and faster
                        onboarding process, ideal for smaller businesses or
                        startups with basic payment needs.
                      </Typography>
                      <Button
                        endIcon={<ExitToAppIcon />}
                        sx={{ marginTop: 2 }}
                        onClick={handleOnboardExpressAccount}
                        color="primary"
                      >
                        Connect Stripe Express Account
                      </Button>
                    </Box>
                    <Box>
                      <Typography>
                        Stripe Account: Provides extensive customization options
                        and control over payment processes for businesses that
                        require advanced features.
                      </Typography>
                      <Button
                        endIcon={<ExitToAppIcon />}
                        sx={{ marginTop: 2 }}
                        onClick={handleOnboardStandardAccount}
                        color="primary"
                      >
                        Connect Existing Stripe Standard Account
                      </Button>
                    </Box>
                  </Stack>
                </SettingCard>
              )}
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
