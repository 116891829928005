import React from "react";
import {
  FlashMessageProps,
  FlashMessagesContext,
} from "../../utilities/flash-messages";
import { FlashMessage } from "./components";

type SnackbarMessage = Pick<FlashMessageProps, "message" | "severity">;

interface FlashMessagesProviderProps {
  children?: React.ReactNode;
}

export function FlashMessagesProvider({
  children,
}: FlashMessagesProviderProps) {
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>(
    []
  );
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<
    SnackbarMessage | undefined
  >(undefined);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const showMessage = (message: SnackbarMessage) => {
    setSnackPack((prev) => [
      ...prev,
      { ...message, keyProp: new Date().getTime() },
    ]);
  };

  const hideMessage = () => {
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <FlashMessagesContext.Provider value={{ showMessage }}>
      {children}
      <FlashMessage
        {...{ open }}
        {...messageInfo}
        onClose={hideMessage}
        onExited={handleExited}
      />
    </FlashMessagesContext.Provider>
  );
}
