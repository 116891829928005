import { SettingCard } from "../../../SettingCard";

export interface LocationSettingsSettingCardProps {
  children?: React.ReactNode;
}

export function LocationSettingsSettingCard({
  children,
}: LocationSettingsSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Location Settings"
      description="Configure the settings for this location."
    >
      {children}
    </SettingCard>
  );
}
