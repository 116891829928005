import { HeadCell } from "./types";

export const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Sale",
    width: 200,
  },
  {
    id: "saleDate",
    numeric: false,
    disablePadding: true,
    label: "Date",
    width: 233,
  },
  {
    id: "customerId",
    numeric: false,
    disablePadding: true,
    label: "Customer",
    width: 316,
  },
  {
    id: "state",
    numeric: false,
    disablePadding: true,
    label: "status",
    alignment: "center",
    width: 133,
  },
  {
    id: "totalPrice",
    numeric: true,
    disablePadding: true,
    label: "Total",
    alignment: "right",
    width: 122,
  },
];
