import { gql } from "@apollo/client";
import {
  CustomerAddressDeepNestingFragment,
  CustomerDeepNestingFragment,
  CustomerListDeepNestingFragment,
  PaginatedListMetaDeepNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import {
  ContactPreference,
  Gender,
} from "@towersystems/roam-common/lib/generated-types";
import { oneOfEnum } from "utilities/address";
import * as Yup from "yup";

export const QUERY_CUSTOMERS_LIST = gql`
  ${CustomerDeepNestingFragment}
  ${PaginatedListMetaDeepNestingFragment}
  ${CustomerAddressDeepNestingFragment}
  ${CustomerListDeepNestingFragment}
  query customers($options: CustomerListOptions) {
    customers(options: $options) {
      ...CustomerListDeepNesting
    }
  }
`;

export const QUERY_CUSTOMER = gql`
  ${CustomerDeepNestingFragment}
  ${CustomerAddressDeepNestingFragment}
  query customer($id: ID!) {
    customer(id: $id) {
      ...CustomerDeepNesting
    }
  }
`;

export const MUTATION_CREATE_CUSTOMER = gql`
  ${CustomerAddressDeepNestingFragment}
  ${CustomerDeepNestingFragment}
  mutation createCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      ...CustomerDeepNesting
    }
  }
`;

export const MUTATION_UPDATE_CUSTOMER = gql`
  ${CustomerAddressDeepNestingFragment}
  ${CustomerDeepNestingFragment}
  mutation updateCustomer($input: CustomerInput!) {
    updateCustomer(input: $input) {
      ...CustomerDeepNesting
    }
  }
`;

export const addressInputSchema = Yup.object({
  id: Yup.string().optional().default(undefined),
  address1: Yup.string().required(),
  address2: Yup.string().optional().nullable(),
  city: Yup.string().required(),
  postcode: Yup.string().required(),
  state: Yup.string().required(),
  country: Yup.string().required(),
}).required();

const customerAddressSchema = addressInputSchema.shape({
  isPrimary: Yup.boolean().optional(),
});

export const customerInputSchema = Yup.object({
  id: Yup.string().optional(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  emailAddress: Yup.string().email().optional(),
  phoneNumber: Yup.string().optional(),
  mobileNumber: Yup.string()
    .matches(
      /^(?:\+?1[-.●]?)?(?:\(\d{3}\)|\d{3})[-.●]?\d{3}[-.●]?\d{4}$/,
      "Invalid mobile number"
    )
    .optional(),
  gender: oneOfEnum(Gender).optional(),
  contactPreference: oneOfEnum(ContactPreference).optional(),
  addressesToAdd: Yup.array(
    customerAddressSchema.required().noUnknown()
  ).optional(),
  addressesToRemove: Yup.array(Yup.string().required()).optional(),
})
  .required()
  .noUnknown();

export const defaultCustomerInputValues = {
  id: undefined,
  firstName: "",
  lastName: "",
  emailAddress: undefined,
  phoneNumber: "",
  mobileNumber: "",
  gender: Gender.UNKNOWN,
  contactPreference: ContactPreference.UNSET,
  addressesToAdd: [],
  addressesToRemove: [],
};

export const CustomerFormSections = {
  DETAILS: "Customer Information",
  SETTINGS: "Settings",
} as const;
