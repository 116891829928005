import { ListItem, useTheme } from "@mui/material";
import React, { memo, useCallback, useId, useRef } from "react";
import { useListbox } from "../../../../utilities/list";

export interface OptionProps {
  value: string;
  children?: string | React.ReactNode;
  selected?: boolean;
  onOptionSelect?(value: string): void;
  disabled?: boolean;
  onAction?(): void;
  isAction?: boolean;
}

export const Option = memo(
  ({
    children,
    value,
    disabled = false,
    selected,
    onAction,
    isAction,
  }: OptionProps) => {
    const theme = useTheme();
    const { onOptionSelect } = useListbox();
    const listItemRef = useRef<HTMLLIElement>(null);
    const domId = useId();

    const mousedownPrevention = useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
    }, []);

    const handleOptionClick = useCallback(
      (evt: React.MouseEvent) => {
        evt.preventDefault();
        evt.stopPropagation();
        onAction && onAction();
        if (listItemRef.current && !isAction) {
          onOptionSelect({
            domId,
            value,
            element: listItemRef.current,
            disabled,
          });
        }
      },
      [domId, onOptionSelect, value, disabled]
    );

    const style = {
      '&[data-focused="true"]': {
        boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
        borderRadius: "4px",
        outline: "2px solid transparent",
      },
    };

    return (
      <ListItem
        ref={listItemRef}
        onMouseDown={mousedownPrevention}
        onClick={disabled ? undefined : handleOptionClick}
        id={domId}
        tabIndex={-1}
        className="list-box__option"
        sx={style}
        role="option"
        data-listbox-option-value={value}
        aria-selected={selected}
        data-listbox-option
      >
        {children}
      </ListItem>
    );
  }
);
