import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Receipt } from "@towersystems/roam-common/lib/generated-types";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../../../components/Badge";
import { NavigateRightButton } from "../../../../components/NavigateRightButton";
import { SettingRowSkeleton } from "../../../../components/SettingRowSkeleton";

export interface ReceiptListProps {
  loading?: boolean;
  items?: Receipt[];
}

const NUM_LOADING_PLACEHOLDERS = 5;

export const ReceiptList = ({ loading, items }: ReceiptListProps) => {
  const navigate = useNavigate();

  const rows: any[] = loading
    ? Array.from({ length: NUM_LOADING_PLACEHOLDERS }).map((_, i) => (
        <SettingRowSkeleton key={i} />
      ))
    : items?.map((i) => {
        return (
          <TableRow
            key={i.id}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/settings/receipts/edit/${i.id}`)}
          >
            <TableCell width={"100%"}>
              <Typography fontWeight="bold">{i.name}</Typography>
              <Typography variant="subdued">
                {capitalize(i.defaultTemplateType)}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              <Stack direction="row" justifyContent={"flex-end"}>
                <Badge state={i.enabled ? "active" : "disabled"} />
                <NavigateRightButton />
              </Stack>
            </TableCell>
          </TableRow>
        );
      }) || [];

  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
