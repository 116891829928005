import { gql } from "@apollo/client";
import { SuccessFragment } from "@towersystems/roam-common/lib/generated-graphql-fragment";
import * as yup from "yup";

export const schema = yup.object().shape(
  {
    staffId: yup.string().required("Staff ID is required"),
    initials: yup.string().required("Initials are required"),
    fullName: yup.string().required("Full name is required"),
    username: yup.string().required("Username is required"),
    password: yup.string().when("password", (val, schema) => {
      if (val?.length > 0) {
        return yup.string().min(6, "Password must be at least 6 characters");
      }
      return yup.string().optional();
    }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .optional(),

    emailAddress: yup
      .string()
      .email("Please enter a valid email address")
      .nullable(),
  },
  [["password", "password"]]
);

export const REMOVE_2FA_STRATEGY_FOR_STAFF = gql`
  ${SuccessFragment}
  mutation Remove2faStrategyForStaff(
    $userId: ID!
    $strategy: MfaCodeStrategy!
  ) {
    remove2faStrategyForStaff(userId: $userId, strategy: $strategy) {
      ...Success
    }
  }
`;
