import { Stack } from "@mui/material";
import { FormLayout, SkeletonPage } from "../../../../components";
import { SettingCard } from "../SettingCard";
import { InputSkeleton } from "../InputSkeleton";

export interface StaffPageSkeletonProps {}

export function StaffPageSkeleton(props: StaffPageSkeletonProps) {
  return (
    <SkeletonPage title titleDescription goBack>
      <Stack spacing={3}>
        <SettingCard title="Staff Information" wrapped>
          <FormLayout>
            <InputSkeleton />
            <InputSkeleton />
          </FormLayout>
        </SettingCard>
        <SettingCard title="Settings" wrapped>
          <InputSkeleton />
        </SettingCard>
      </Stack>
    </SkeletonPage>
  );
}
