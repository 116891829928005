import {
  FormControlLabel,
  Checkbox,
  Box,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { LocationFormValues } from "../../utils/locations/types";
import { Controller, useFormContext } from "react-hook-form";

export interface PaymentMethodSettingsFormProps {}

export function PaymentMethodSettingsForm(
  props: PaymentMethodSettingsFormProps
) {
  const { control } = useFormContext<LocationFormValues>();

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"center"}
      >
        <Box>
          <Controller
            name="enabled"
            control={control}
            render={({ field }) => (
              <Switch {...field} checked={field.value} color="primary" />
            )}
          />
        </Box>
        <Box flex={1} paddingTop={1}>
          <Typography fontWeight={"bold"}>Enabled</Typography>
          <Typography>Enable this payment method to be used</Typography>
        </Box>
      </Stack>
    </Box>
  );
}
