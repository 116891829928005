import { createContext } from "react";
import { ShowMessageProps } from "./types";

export interface FlashMessagesContextType {
  showMessage: (props: ShowMessageProps) => void;
}

export const FlashMessagesContext = createContext<
  FlashMessagesContextType | undefined
>(undefined);
