import {
  FormControlLabel,
  Checkbox,
  Switch,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ReceiptFormValues } from "../../utils/receipts/types";

export function ReceiptSettingsForm() {
  const { control } = useFormContext<ReceiptFormValues>();

  return (
    <>
      <Stack spacing={3}>
        <Box>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <Box>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} color="primary" />
                )}
              />
            </Box>
            <Box flex={1} paddingTop={1}>
              <Typography fontWeight={"bold"}>Enabled</Typography>
              <Typography>Allow receipt to be used</Typography>
            </Box>
          </Stack>
        </Box>

        <Box>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <Box>
              <Controller
                name="settings.displayCustomerDetails"
                control={control}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} color="primary" />
                )}
              />
            </Box>
            <Box flex={1} paddingTop={1}>
              <Typography fontWeight={"bold"}>
                Display Customer Details
              </Typography>
              <Typography>Display customer details on the receipt</Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
