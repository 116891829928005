import { useMutation, useQuery } from "@apollo/client";
import {
  AccessTokenSession,
  Deleted,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import {
  MUTATION_DELETE_ACCESS_TOKEN,
  MUTATION_GENERATE_ACCESS_TOKEN,
  QUERY_ALL_ACCESS_TOKENS,
} from "./constants";

export const useQueryAccessTokens = () => {
  const { loading, error, data, refetch } = useQuery<{
    accessTokens: AccessTokenSession[];
  }>(QUERY_ALL_ACCESS_TOKENS);

  return {
    items: data?.accessTokens,
    loading,
    error,
    refetch,
  };
};

export const useGenerateAccessToken = () => {
  const [m] = useMutation<{
    generateAccessToken: AccessTokenSession;
  }>(MUTATION_GENERATE_ACCESS_TOKEN);

  const generateAccessToken = useCallback(
    (name: string) => {
      return m({ variables: { input: { name } } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.generateAccessToken;
      });
    },
    [m]
  );

  return {
    generateAccessToken,
  };
};

export const useDeleteAccessToken = () => {
  const [m] = useMutation<{
    deleteAccessToken: Deleted;
  }>(MUTATION_DELETE_ACCESS_TOKEN);

  const deleteAccessToken = useCallback(
    (id: string) => {
      return m({ variables: { id } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.deleteAccessToken;
      });
    },
    [m]
  );
  return {
    deleteAccessToken,
  };
};
