import { Box, Typography } from "@mui/material";
import styles from "./EnterMfaCodeFieldset.module.scss";
import { Controller, useFormContext } from "react-hook-form";
import OtpInput from "react18-input-otp";
import { SwitchActiveUserFormValues } from "../../types";
import { Stack } from "../../../Stack";

export interface EnterMfaCodeFieldsetProps {}

export const EnterMfaCodeFieldset = ({}: EnterMfaCodeFieldsetProps) => {
  const formContext = useFormContext<SwitchActiveUserFormValues>();
  const { control, formState } = formContext;
  const { errors } = formState;

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h6">Enter MFA Code</Typography>
          <Typography variant="body1">
            Please enter code from your chosen MFA method.
          </Typography>
        </Box>
        <Box className={styles.InputWrapper}>
          <Controller
            control={control}
            name="mfaCode"
            render={({ field: { onChange, value } }) => {
              return (
                <OtpInput
                  inputStyle={styles.Input}
                  value={value}
                  onChange={onChange}
                  numInputs={6}
                />
              );
            }}
          />
        </Box>
        {errors.root?.serverError &&
          errors.root.serverError.message !== `MFA_REQUIRED_ERROR` && (
            <Typography color="error">
              {errors.root.serverError.message}
            </Typography>
          )}
      </Stack>
    </Box>
  );
};
