import { useMutation } from "@apollo/client";
import {
  Terminal,
  UpdateTerminalInput,
} from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import { MUTATION_UPDATE_TERMINAL } from "./constants";

export const useMutationUpdateTerminal = () => {
  const [m] = useMutation<{
    updateTerminal: Terminal;
  }>(MUTATION_UPDATE_TERMINAL);

  const updateTerminal = useCallback(
    (input: UpdateTerminalInput) => {
      return m({ variables: { input } }).then((d) => {
        if (!d.data) {
          throw new Error();
        }
        return d.data?.updateTerminal;
      });
    },
    [m]
  );
  return {
    updateTerminal,
  };
};
