import { useFormContext } from "react-hook-form";
import { SetupNative2faFormValues } from "./types";
import { SelectMfaStrategyFieldset } from "./components";
import { EnterMfaIdentifierFieldset } from "./components/EnterMfaIdentifierFieldset";
import { VerifyMfaCodeFieldset } from "./components/VerifyMfaCodeFieldset";

export interface SetupNative2faFormProps {
  onSubmit: (
    formValues: SetupNative2faFormValues
  ) => Promise<unknown> | unknown;
}

export const SetupNative2faForm = ({ onSubmit }: SetupNative2faFormProps) => {
  const formContext = useFormContext<SetupNative2faFormValues>();
  const { watch, handleSubmit } = formContext;

  const watchedStrategy = watch("data.strategy");
  const watchedCodeSent = watch("ui.codeSent");

  if (!watchedStrategy) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {<SelectMfaStrategyFieldset />}
      </form>
    );
  }

  if (!watchedCodeSent) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {<EnterMfaIdentifierFieldset />}
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VerifyMfaCodeFieldset />
    </form>
  );
};
