import { Box, ButtonBase, Menu, MenuItem, Typography } from "@mui/material";
import { useAuthenticationProvider } from "../../utilities/authentication/hooks";
import { useGlobalSettingsProvider } from "../../utilities/global-settings/hooks";
import { Stack } from "../Stack";
import { Avatar } from "../Avatar";
import { useState } from "react";
import styles from "./UserAvatar.module.scss";
import {
  useActiveUserProvider,
  useSwitchActiveUserProvider,
} from "../../utilities/active-user";
import { useNavigate } from "react-router-dom";

export const UserAvatar = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { activeUser } = useActiveUserProvider();
  const { user, logout } = useAuthenticationProvider();
  const globalSettings = useGlobalSettingsProvider();
  const { modal: switchActiveUserModal } = useSwitchActiveUserProvider();
  const navigate = useNavigate();

  const initials = user?.identifier?.substring(0, 2).toUpperCase();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;

  const rolesStrings = activeUser.roles.map((role) => role.code);
  const formattedRolesString = `(${rolesStrings.join(", ")})`;

  return (
    <Box>
      <Box
        component={ButtonBase}
        aria-describedby={id}
        onClick={handleClick as any}
        className={styles.Avatar}
      >
        <Stack direction="row" spacing={1}>
          <Avatar initials={initials} />
          <Stack>
            <Box>
              <Typography
                textAlign={"left"}
                fontSize="small"
                color="GrayText"
                fontWeight={"bold"}
              >
                {`${user?.identifier} ${formattedRolesString}`}
              </Typography>
            </Box>
            <Box>
              <Typography textAlign={"left"} fontSize="small" color="GrayText">
                {globalSettings.businessName}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Menu
        style={{ width: 300, marginTop: 13 }}
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          style={{ width: 300 }}
          onClick={() => {
            navigate("/me");
            handleClose();
          }}
        >
          My Profile Settings
        </MenuItem>
        <MenuItem
          style={{ width: 300 }}
          onClick={() => {
            switchActiveUserModal.onOpen();
            handleClose();
          }}
        >
          Switch User
        </MenuItem>
        <MenuItem style={{ width: 300 }} onClick={logout}>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};
