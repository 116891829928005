import { TextField } from "@mui/material";
import { FormLayout } from "components";
import { TerminalFormValues } from "modules/settings/utils/terminals/types";
import { useFormContext } from "react-hook-form";

export interface TerminalInvoiceFormProps {}

export function TerminalInvoiceForm(props: TerminalInvoiceFormProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<TerminalFormValues>();
  
  return (
    <FormLayout>
      <TextField
        label="Invoice Sequence"
        fullWidth
        {...register("invoiceSequence")}
        error={Boolean(errors.invoiceSequence)}
        helperText={errors.invoiceSequence?.message}
      />
      <TextField
        label="Invoice Prefix"
        fullWidth
        {...register("salePrefix")}
        error={Boolean(errors.salePrefix)}
        helperText={errors.salePrefix?.message}
      />
      <TextField
        label="Invoice Sufix"
        fullWidth
        {...register("saleSufix")}
        error={Boolean(errors.saleSufix)}
        helperText={errors.saleSufix?.message}
      />
    </FormLayout>
  );
}
