import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { schema } from "./constants";
import { SwitchActiveUserFormValues, SwitchUserStrategyType } from "./types";
import { UserV2 } from "@towersystems/roam-common/lib/generated-types";
import React from "react";

export interface SwitchActiveUserFormProviderProps {
  children?: React.ReactNode;
  user?: UserV2;
}

export const SwitchActiveUserFormProvider = ({
  children,
  user,
}: SwitchActiveUserFormProviderProps) => {
  const strategy = React.useMemo(() => {
    return user
      ? user.authenticationMethods[0]?.strategy || "native"
      : "talink";
  }, [user]);

  const methods = useForm<SwitchActiveUserFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      identifier: user?.identifier ?? "",
      password: "",
      strategy: strategy as SwitchUserStrategyType,
      mfaCode: "",
      mfaStrategy: "",
      ui: {
        mfaCodeSent: false,
        mfaRequired: false,
        strategies: [],
      },
    },
    resolver: yupResolver(schema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
