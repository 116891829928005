import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import { NavigateRightButton } from "../NavigateRightButton";
import { Skeleton } from "../Skeleton";

export interface SettingRowSkeletonProps {}

export function SettingRowSkeleton(props: SettingRowSkeletonProps) {
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <div style={{ paddingLeft: 10 }}>
          <Skeleton variant="rectangular" height={24} width={24} />
        </div>
      </TableCell>
      <TableCell width={"100%"}>
        <Typography fontWeight={"bold"} width={100}>
          <Skeleton />
        </Typography>
        <Typography width={200}>
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell align="right">
        <Stack direction="row" justifyContent={"flex-end"}>
          <NavigateRightButton />
        </Stack>
      </TableCell>
    </TableRow>
  );
}
