import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { SkeletonBadge } from "../SkeletonBadge";
import { Skeleton } from "../Skeleton";

export interface ResourceListSkeletonProps {}

export const ResourceListSkeleton = ({}: ResourceListSkeletonProps) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {[...Array(20)].map((_, i) => (
            <RowSkeleton key={i} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function RowSkeleton() {
  return (
    <TableRow>
      <TableCell width={"60%"}>
        <Typography fontWeight="bold" width={150}>
          <Skeleton width={150} />
        </Typography>
        <Typography variant="subdued" width={200}>
          <Skeleton width={200} />
        </Typography>
      </TableCell>
      <TableCell align="center">
        <SkeletonBadge />
      </TableCell>
      <TableCell>
        <Typography fontSize={14}>
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          <Skeleton />
        </Typography>
      </TableCell>
    </TableRow>
  );
}
