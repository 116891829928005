import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { Sale } from "@towersystems/roam-common/lib/generated-types";
import { Badge, Status } from "../../../../components";
import { moneyToFormattedString } from "../../../../utilities/money";
import styles from "./SaleRow.module.scss";
import { formatDate } from "./utils";

export interface SaleRowProps {
  sale: Sale;
  onClick?: () => void;
}

export function SaleRow({ sale, onClick }: SaleRowProps) {
  return (
    <TableRow key={sale.id} className={styles.Row} onClick={onClick}>
      <TableCell>
        <Typography fontWeight={"bold"}>#{sale.invoiceNumber}</Typography>
      </TableCell>
      <TableCell>
        <Box>
          <Typography variant="subdued" fontSize={15} textOverflow="ellipsis">
            {formatDate(sale.saleDate)}
          </Typography>
          <Box>
            <Typography variant="subdued" fontWeight={"bold"} fontSize={12}>
              {sale.location?.name || "Unknown Location"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subdued" fontSize={12}>
              {sale.terminal?.name || "Unknown Terminal"}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        {sale.customer?.id ? (
          <Typography fontSize={14}>
            {sale.customer?.firstName} {sale.customer?.lastName}
          </Typography>
        ) : undefined}
        {sale.customer?.emailAddress ? (
          <Typography variant="subdued" fontSize={14}>
            {sale.customer?.emailAddress}
          </Typography>
        ) : undefined}
        <Typography></Typography>
      </TableCell>
      <TableCell align="center">
        <Badge status={sale.state as Status}></Badge>
      </TableCell>
      <TableCell align="right">
        <Typography>{moneyToFormattedString(sale.totalPrice)}</Typography>
      </TableCell>
    </TableRow>
  );
}
