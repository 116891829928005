import { MfaMethod } from "@towersystems/roam-common/lib/generated-types";
import { Box, ButtonBase, Typography } from "@mui/material";
import { MfaStrategyIcon } from "../MfaStrategyIcon";
import { Stack } from "..";
import { NavigateRightButton } from "../NavigateRightButton";

export interface VerifiedMfaListItemProps {
  mfaMethod: MfaMethod;
  onClick?: () => void;
}

export const VerifiedMfaListItem = ({
  mfaMethod,
  onClick,
}: VerifiedMfaListItemProps) => {
  return (
    <Box
      component={ButtonBase}
      style={{
        cursor: "pointer",
      }}
      display="flex"
      flexDirection={"row"}
      alignItems={"center"}
      sx={{ p: 2 }}
      onClick={onClick}
    >
      <Box>{<MfaStrategyIcon mfaMethod={mfaMethod} />}</Box>
      <Box
        sx={{
          flex: 1,
          flexGrow: 1,
          textAlign: "left",
        }}
      >
        <Box>
          <Typography>{mfaMethod.strategy}</Typography>
          <Typography>{mfaMethod.identifier}</Typography>
        </Box>
      </Box>
      <Box>
        <Stack direction="row" justifyContent={"flex-end"}>
          <NavigateRightButton />
        </Stack>
      </Box>
    </Box>
  );
};
