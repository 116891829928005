import React, { useState } from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { Page, Stack } from "../../../../components";
import { formatDateToCalendar } from "@towersystems/roam-common/lib/shared-utils";
import { Badge } from "../../../../components/Badge";
import { Box, Typography } from "@mui/material";
import { moneyToFormattedString } from "../../../../utilities/money";
import { SaleItemDetailsCard } from "../../components/SaleItemDetailsCard";
import { SaleTotalsDetailCard } from "../../components/SaleTotalsDetailCard";
import { SalePaymentsDetailCard } from "../../components/SalePaymentsDetailCard";
import { SaleCustomerDetailsCard } from "../../components/SaleCustomerDetailsCard";
import { Button } from "../../../../components/Button";
import { useDownloadReceipt } from "../../utils/receipts";
import { SalePageSkeleton } from "../../components/SalePageSkeleton";

export interface SalePageProps {}

export const SalePage = ({}: SalePageProps) => {
  const { data }: any = useLoaderData();
  const navigate = useNavigate();
  const { downloadReceipt } = useDownloadReceipt();
  const [loading, setLoading] = useState(false);

  return (
    <React.Suspense fallback={<SalePageSkeleton />}>
      <Await resolve={data}>
        {([sale]) => {
          const isSelfCheckoutSale = sale.source === "WEB_CHECKOUT";
          const isActiveCartSession =
            sale.state === "SAVED" && isSelfCheckoutSale;

          const title = isActiveCartSession
            ? `Active Cart Session`
            : `Sale #${sale?.invoiceNumber}`;

          const badgeMarkup = (
            <Stack direction={"row"} spacing={1}>
              <Badge status={sale.state} />
              <Badge status={sale.source} />
            </Stack>
          );

          const secondaryTitle = (
            <Box>
              <Typography fontWeight="bold">
                {moneyToFormattedString(sale.totalPrice)}
              </Typography>

              {sale.location ? (
                isSelfCheckoutSale ? (
                  <Typography>{`${formatDateToCalendar(sale.saleDate)} at ${
                    sale.location.name
                  }`}</Typography>
                ) : (
                  <Typography>{`${formatDateToCalendar(sale.saleDate)} at ${
                    sale.location.name
                  }, by ${sale.staff?.fullName}`}</Typography>
                )
              ) : undefined}
            </Box>
          );

          let buttons = [];

          if (sale.state.includes(["CLOSED"])) {
            buttons.push(
              <Button
                key="download-receipt"
                variant="posTile"
                color="primary"
                onClick={() => {
                  setLoading(true);
                  downloadReceipt(sale.id)
                    .catch((err) => console.log(err))
                    .finally(() => setLoading(false));
                }}
                disabled={loading}
              >
                Download Receipt
              </Button>
            );
          }

          return (
            <Page
              title={title}
              titleDescription={secondaryTitle}
              goBack={{ onAction: () => navigate("/sales") }}
              badges={badgeMarkup}
            >
              {buttons.length >= 1 ? (
                <Stack style={{ marginBottom: 32 }} spacing={4}>
                  {buttons}
                </Stack>
              ) : undefined}
              <Stack spacing={8}>
                <SaleItemDetailsCard sale={sale} />
                <SaleTotalsDetailCard sale={sale} />
                <SaleCustomerDetailsCard sale={sale} />
                <SalePaymentsDetailCard sale={sale} />
              </Stack>
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
