import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
} from "@mui/material";
import { PropsWithChildren } from "react";
import { Section, Summary } from "./components";

// MuiAccordionProps requires children but it makes more sense to be optional
export type AccordionProps = PropsWithChildren<
  Omit<MuiAccordionProps, "children"> & {
    title?: string;
  }
>;

export function Accordion({ title, children, ...props }: AccordionProps) {
  const summaryMarkup = title ? <Summary>{title}</Summary> : null;

  const childrenMarkup = children ? (
    <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
  ) : null;

  return (
    <MuiAccordion {...props}>
      {summaryMarkup}
      {childrenMarkup}
    </MuiAccordion>
  );
}

Accordion.Summary = Summary;
Accordion.Section = Section;
