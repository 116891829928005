import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { UseModalReturn } from "../../../../utilities/use-modal";
import { Accordion, Sheet, Stack } from "../../../../components";
import { useCallback } from "react";
import { Staff } from "@towersystems/roam-common/lib/generated-types";
import {
  MUTATION_CREATE_STAFF,
  defaultStaffFormValues,
  staffFormSchema,
} from "../../utils/staffs";
import { StaffDetailsForm } from "../StaffDetailsForm";
import { StaffSettingsForm } from "../StaffSettingsForm";
import { useMutation } from "@apollo/client";

export interface CreateStaffSheetProps extends UseModalReturn {
  onSubmitSuccess(staff: Staff): void;
}

export const CreateStaffSheet = ({
  onSubmitSuccess,
  ...drawerController
}: CreateStaffSheetProps) => {
  const [m] = useMutation<{ createStaff: Staff }>(MUTATION_CREATE_STAFF);
  const methods = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultStaffFormValues,
    resolver: yupResolver(staffFormSchema),
  });

  const { handleSubmit, formState } = methods;
  const { isValid } = formState;

  const handleOnSubmit = useCallback(async (formValues: any) => {
    try {
      const result = await m({ variables: { input: formValues } });
      return (
        result.data?.createStaff && onSubmitSuccess(result.data?.createStaff)
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const primaryAction = {
    content: "Save",
    onAction: handleSubmit(handleOnSubmit),
    disabled: !isValid,
  };

  const secondaryAction = {
    content: "Cancel",
    onAction: drawerController.onClose,
  };

  return (
    <Sheet
      title="Add Staff"
      {...{ primaryAction, secondaryAction }}
      onTransitionEnd={methods.reset}
      {...drawerController}
    >
      <FormProvider {...methods}>
        <Stack fullWidth spacing={2}>
          <Accordion>
            <Accordion.Section>
              <StaffDetailsForm />
            </Accordion.Section>
          </Accordion>
          <Accordion>
            <Accordion.Section>
              <StaffSettingsForm />
            </Accordion.Section>
          </Accordion>
        </Stack>
      </FormProvider>
    </Sheet>
  );
};
