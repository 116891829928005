import { Stack } from "components";

export interface GroupProps {
  children?: React.ReactNode;
}

export function Group({ children }: GroupProps) {
  return (
    <Stack fullWidth direction="row" alignItems="start" spacing={2}>
      {children}
    </Stack>
  );
}
