import * as Yup from "yup";
import { LoginFormValues } from "./types";

export const defaultLoginFormValues: LoginFormValues = {
  strategy: "talink",
  staffUsername: "",
  talinkUsername: "",
  password: "",
  rememberMe: false,
  mfaCode: "",
  mfaStrategy: "",
};

export const strategiesMap: {
  [key: string]: { title: string; description: string };
} = {
  EMAIL: {
    title: "Email",
    description: "We will send a 6 digit code to your email address",
  },
  SMS: {
    title: "SMS",
    description: "We will send a 6 digit code to your phone number",
  },
};

export const loginFormSchema = Yup.object({
  talinkUsername: Yup.string().required("Please enter your talink username"),
  password: Yup.string().required("Please enter your password"),
  rememberMe: Yup.boolean(),
  strategy: Yup.string(),
  staffUsername: Yup.string().when("strategy", {
    is: "native",
    then: Yup.string().required("Please enter your staff username"),
  }),
  mfaStrategy: Yup.string().optional(),
  mfaCode: Yup.string().when("mfaStrategy", {
    is: (strategy: string) => !!strategy,
    then: Yup.string().required("Please enter your 6 digit code"),
  }),
})
  .required()
  .noUnknown();
