import { yupResolver } from "@hookform/resolvers/yup";
import { initialValues, schema } from "./constants";
import { UpdatePasswordFormValues } from "./types";
import { FormProvider, useForm } from "react-hook-form";

export interface UpdatePasswordFormProviderProps {
  children?: React.ReactNode;
}

export const UpdatePasswordFormProvider = ({
  children,
}: UpdatePasswordFormProviderProps) => {
  const methods = useForm<UpdatePasswordFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};
