import { useMutation } from "@apollo/client";
import { MUTATION_DOWNLOAD_RECEIPT } from "./constants";
import { useCallback } from "react";
import { UseDownloadReceiptProps, UseDownloadReceiptResponse } from "./types";
import { DownloadReceiptPdfBase64StringResponse } from "@towersystems/roam-common/lib/generated-types";

export const useDownloadReceipt =
  ({}: UseDownloadReceiptProps = {}): UseDownloadReceiptResponse => {
    const [q] = useMutation<{
      downloadReceiptPdfBase64String: DownloadReceiptPdfBase64StringResponse;
    }>(MUTATION_DOWNLOAD_RECEIPT);
    const fn = useCallback(
      async (saleId: string, receiptId?: string) => {
        return q({ variables: { saleId, receiptId } }).then((i) => {
          const { data: base64String, fileName } =
            i.data?.downloadReceiptPdfBase64String || {};

          if (!base64String || !fileName) {
            return;
          }

          const binaryString = atob(base64String);
          const binaryData = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            binaryData[i] = binaryString.charCodeAt(i);
          }

          // Create a Blob object from the binary data array
          const blob = new Blob([binaryData], { type: "application/pdf" });

          // Create a link element with a download attribute
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;

          // Add the link element to the DOM and click it to start the download
          document.body.appendChild(link);
          link.click();

          // Clean up by removing the link element and revoking the object URL
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
        });
      },
      [q]
    );
    return {
      downloadReceipt: fn,
    };
  };
