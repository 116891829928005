import {
  TypographyProps,
  SkeletonProps,
  Typography,
  Skeleton,
} from "@mui/material";
import { DynamicSkeletonType } from "./types";

type DynamicSkeletonProps = {
  content: DynamicSkeletonType;
  typographyProps?: TypographyProps;
  skeletonProps?: SkeletonProps;
};

export function DynamicSkeleton({
  content,
  typographyProps = {},
  skeletonProps = {},
}: DynamicSkeletonProps) {
  if (content === true) {
    return (
      <Typography {...typographyProps}>
        <Skeleton {...skeletonProps} />
      </Typography>
    );
  } else if (typeof content === "string") {
    return <Typography {...typographyProps}>{content}</Typography>;
  }

  return <>{content}</>;
}
