import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  Skeleton,
  SkeletonPage,
  TileButtonSkeleton,
} from "../../../../components";
import { CardDetailListSkeleton } from "../../../../components/CardDetailListSkeleton";

export interface SalePageSkeletonProps {}

export function SalePageSkeleton(props: SalePageSkeletonProps) {
  const secondaryTitle = (
    <Box>
      <Typography fontWeight="bold">
        <Skeleton width={48} />
      </Typography>
      <Typography>
        <Skeleton width={300} />
      </Typography>
    </Box>
  );

  return (
    <SkeletonPage title titleDescription={secondaryTitle} badges={1} goBack>
      <Stack style={{ marginBottom: 32 }} spacing={4}>
        <TileButtonSkeleton />
      </Stack>
      <Stack spacing={8}>
        <CardDetailListSkeleton
          title="Items"
          items={[{ title: true, subTitle: true, value: true }]}
        />
        <CardDetailListSkeleton
          title="Totals"
          items={[
            { title: "Discount", value: true },
            { title: "Tax", value: true },
            { title: "Total", value: true },
          ]}
        />
        <CardDetailListSkeleton
          title="Customer"
          items={[{ title: true, subTitle: true }]}
        />
        <CardDetailListSkeleton
          title="Payments"
          items={[{ title: true, value: true }]}
        />
      </Stack>
    </SkeletonPage>
  );
}
