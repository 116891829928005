import { useContext } from "react";
import { FlashMessagesContext } from "./context";

export const useFlashMessages = () => {
  const flashMessages = useContext(FlashMessagesContext);
  if (!flashMessages) {
    throw new Error(
      "No FlashMessages context was provided. Your component must be wrapped in a <FlashMessagesProvider> component."
    );
  }
  return flashMessages;
};
