import { TextField } from "@mui/material";
import { FormLayout } from "components";
import { useFormContext } from "react-hook-form";
import { TerminalFormValues } from "../../utils/terminals/types";

export interface TerminalDetailsFormProps {}

export function TerminalDetailsForm(props: TerminalDetailsFormProps) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<TerminalFormValues>();

  const nameError = errors.name?.message;

  return (
    <FormLayout>
      <TextField
        label="Name"
        fullWidth
        {...register("name")}
        error={!!nameError}
        helperText={nameError}
      />
    </FormLayout>
  );
}
