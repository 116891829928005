import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { FormLayout } from "components";
import { TerminalFormValues } from "modules/settings/utils/terminals/types";
import { Controller, useFormContext } from "react-hook-form";

export interface TerminalSettingsFormProps {}

export function TerminalSettingsForm(props: TerminalSettingsFormProps) {
  const { control } = useFormContext<TerminalFormValues>();

  return (
    <FormLayout>
      <Stack spacing={3}>
        <Box>
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <Box>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} color="primary" />
                )}
              />
            </Box>
            <Box flex={1} paddingTop={1}>
              <Typography fontWeight={"bold"}>Terminal Enabled</Typography>
              <Typography>Allow customers to use this terminal</Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </FormLayout>
  );
}
