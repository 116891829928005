import { gql } from "@apollo/client";
import {
  CustomerNoNestingFragment,
  LocationNoNestingFragment,
  PaginatedListMetaNoNestingFragment,
  PaymentNoNestingFragment,
  ProductNoNestingFragment,
  SaleAdjustmentNoNestingFragment,
  SaleFragment,
  SaleHistoryEntryNoNestingFragment,
  SaleLineItemNoNestingFragment,
  SaleNoNestingFragment,
  StaffNoNestingFragment,
  TerminalNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";

export const QUERY_SALE_LIST = gql`
  ${PaginatedListMetaNoNestingFragment}
  ${SaleNoNestingFragment}
  ${CustomerNoNestingFragment}
  query sales($options: SaleListOptions) {
    sales(options: $options) {
      items {
        ...SaleNoNesting
        customer {
          ...CustomerNoNesting
        }
        lineItems {
          id
        }
        location {
          name
        }
        terminal {
          name
        }
      }
      meta {
        ...PaginatedListMetaNoNesting
      }
    }
  }
`;

export const QUERY_SALE = gql`
  ${SaleFragment}
  ${TerminalNoNestingFragment}
  ${LocationNoNestingFragment}
  ${CustomerNoNestingFragment}
  ${StaffNoNestingFragment}
  ${SaleLineItemNoNestingFragment}
  ${PaymentNoNestingFragment}
  ${ProductNoNestingFragment}
  ${SaleAdjustmentNoNestingFragment}
  ${SaleHistoryEntryNoNestingFragment}
  query sale($id: ID!) {
    sale(id: $id) {
      ...Sale
      lineItems {
        ...SaleLineItemNoNesting
        product {
          ...ProductNoNesting
          image {
            path
          }
        }
        adjustments {
          ...SaleAdjustmentNoNesting
        }
      }
    }
  }
`;
