import { LandingPage } from "./pages";

export const routes = [
  {
    index: true,
    path: "/",

    element: <LandingPage />,
  },
];
