import { useEffect, useState } from "react";
import {
  GlobalSettingsContext,
  UseGlobalSettingsContext,
} from "../../utilities/global-settings/context";
import { useGlobalSettings } from "../../utilities/global-settings/hooks";

export interface GlobalSettingsControllerProps {
  children?: React.ReactNode;
}

export const GlobalSettingsController = ({
  children,
}: GlobalSettingsControllerProps) => {
  const [error, setError] = useState(false);
  const useGlobalSettingsRepsonse = useGlobalSettings();
  const { globalSettings, queryGlobalSettings } = useGlobalSettingsRepsonse;

  useEffect(() => {
    setError(false);
    queryGlobalSettings().catch((e) => {
      setError(true);
    });
  }, []);

  if (error) {
    return (
      <div>
        <span>Failed to load settings.</span>
      </div>
    );
  }

  const content = globalSettings ? (
    <GlobalSettingsContext.Provider value={globalSettings}>
      {children}
    </GlobalSettingsContext.Provider>
  ) : null;

  return (
    <UseGlobalSettingsContext.Provider value={useGlobalSettingsRepsonse}>
      {content}
    </UseGlobalSettingsContext.Provider>
  );
};
