import { useEffect, useState } from "react";
import { View } from "react-native";
import { MeContext } from "../../utilities/authentication";
import { useAuthenticationProvider } from "../../utilities/authentication/hooks";

export interface MeGuardProps {
  children?: React.ReactNode | React.ReactNode[] | null;
}

// ensure that there is a user and that the user is loaded
export const MeGuard = ({ children }: MeGuardProps) => {
  const [error, setError] = useState<boolean>(false);
  const authentication = useAuthenticationProvider();

  useEffect(() => {
    setError(false);
    authentication.me().catch((e) => setError(true));
  }, []);

  if (error && !authentication.user) {
    return <View>put some error here</View>;
  }

  if (!authentication.user) return null;

  return (
    <MeContext.Provider value={{ me: authentication.user }}>
      {children}
    </MeContext.Provider>
  );
};
