import { SettingCard } from "../../../SettingCard";

export interface LocationInformationSettingCardProps {
  children?: React.ReactNode;
}

export function LocationInformationSettingCard({
  children,
}: LocationInformationSettingCardProps) {
  return (
    <SettingCard
      wrapped
      title="Location Information"
      description="Name this location to make it identifiable to your staff."
    >
      {children}
    </SettingCard>
  );
}
