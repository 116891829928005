import { Stack } from "components";
import { Group } from "./components";

export interface FormLayoutProps {
  children?: React.ReactNode;
}

export function FormLayout({ children }: FormLayoutProps) {
  return <Stack spacing={2}>{children}</Stack>;
}

FormLayout.Group = Group;
