import { Controller, useFormContext } from "react-hook-form";
import { SelfCheckoutSettingsFormValues } from "./types";
import { Box, Divider, MenuItem, Switch, Typography } from "@mui/material";
import { Stack } from "../../../../components/Stack";
import { SettingCard } from "../SettingCard";
import { PaymentMethod } from "@towersystems/roam-common/lib/generated-types";
import React from "react";
import { Select } from "../../../../components";

export interface SelfCheckoutSettingsFormProps {
  paymentMethods: PaymentMethod[];
}

export const SelfCheckoutSettingsForm = ({
  paymentMethods,
}: SelfCheckoutSettingsFormProps) => {
  const { control } = useFormContext<SelfCheckoutSettingsFormValues>();

  const paymentMethodOptions = React.useMemo(
    () =>
      paymentMethods?.map((l) => ({
        label: l.name || "Unknown Terminal Name",
        value: l.id,
      })) ?? [],
    [paymentMethods]
  );

  return (
    <Stack spacing={3} divider={<Divider />}>
      <SettingCard
        wrapped
        title="General"
        description="Configure general self checkout settings"
      >
        <Stack spacing={3}>
          <Box>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"center"}
            >
              <Box>
                <Controller
                  name="enabled"
                  control={control}
                  render={({ field }) => (
                    <Switch {...field} checked={field.value} color="primary" />
                  )}
                />
              </Box>
              <Box flex={1} paddingTop={1}>
                <Typography fontWeight={"bold"}>
                  Self Checkout Enabled
                </Typography>
                <Typography variant="caption">
                  Allow customers to self checkout using the Roam app
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </SettingCard>
      <SettingCard
        wrapped
        title="Payment Method"
        description="
      Select the payment method that will be used for self checkout"
      >
        <Box>
          <Controller
            control={control}
            name="paymentMethodId"
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  label="Select Payment Method"
                  fullWidth
                  placeholder="Select Payment Method"
                  options={paymentMethodOptions}
                  leadingChildren={
                    <MenuItem disabled value={null as any}>
                      <em>Select Payment Method</em>
                    </MenuItem>
                  }
                  value={value}
                  onChange={onChange}
                ></Select>
              );
            }}
          />
        </Box>
      </SettingCard>
    </Stack>
  );
};
