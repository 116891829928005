import { defer, RouteObject } from "react-router-dom";
import { PageErrorOccurred } from "../../components";
import { queryClient } from "../../utilities/query-client";
import {
  ApiKeysPage,
  BackgroundEraserPage,
  EditReceiptPage,
  EditStaffPage,
  LocationsPage,
  PaymentMethodsPage,
  ReceiptsPage,
  SettingsPage,
} from "./pages";
import { EditLocationPage } from "./pages/EditLocationPage";
import { EditPaymentMethodPage } from "./pages/EditPaymentMethodPage";
import { EditTerminalPage } from "./pages/EditTerminalPage";
import { GeneralSettingsPage } from "./pages/GeneralSettingsPage";
import { StaffsPage } from "./pages/StaffsPage";
import { TerminalsPage } from "./pages/TerminalsPage";
import { QUERY_CUSTOMER } from "./utils/customers";
import { QUERY_GLOBAL_SETTINGS } from "./utils/global-settings";
import { QUERY_LOCATION, QUERY_LOCATIONS_LIST } from "./utils/locations";
import {
  QUERY_PAYMENT_METHOD,
  QUERY_PAYMENT_METHODS,
} from "./utils/payment-methods";
import { QUERY_RECEIPT } from "./utils/receipts";
import { QUERY_RETAILER_PAY_TYPES } from "./utils/retailer-pay-types";
import { QUERY_STAFF } from "./utils/staffs";
import { QUERY_TERMINAL, QUERY_TERMINALS_LIST } from "./utils/terminals";
import { StripeLandingPage } from "./pages/StripeLandingPage";
import { QUERY_ONBOARDING_COMPLETE } from "./utils/stripe";
import { SelfCheckoutSettingsPage } from "./pages/SelfCheckoutSettingsPage";
import {
  QUERY_SELF_CHECKOUT_LOCATION_SETTINGS,
  QUERY_SELF_CHECKOUT_LOCATION_URL,
  QUERY_SELF_CHECKOUT_SETTINGS,
} from "./utils/self-checkout-settings";
import { AddSelfCheckoutLocationSettingPage } from "./pages/AddSelfCheckoutLocationSettingPage";
import { EditSelfCheckoutLocationSettingPage } from "./pages/EditSelfCheckoutLocationSettingPage";
import { gql } from "@apollo/client";
import {
  RemovalAiDetailsNoNestingFragment,
  RemovalBgDetailsNoNestingFragment,
} from "@towersystems/roam-common/lib/generated-graphql-fragment";
import { UpgradeStaffAccountPage } from "./pages/UpgradeStaffAccountPage";
import { RouteObjectWithPermissions } from "../../routes";

const commonRouteProps = {
  errorElement: <PageErrorOccurred />,
};

export const QUERY_BACKGROUND_ERASER = gql`
  ${RemovalAiDetailsNoNestingFragment}
  ${RemovalBgDetailsNoNestingFragment}
  query backgroundEraser {
    globalSettings {
      automaticBackgroundRemoval
      bgRemovalStrategy
      removalBgDetails {
        ...RemovalBgDetailsNoNesting
      }
      removalAiDetails {
        ...RemovalAiDetailsNoNesting
      }
    }
  }
`;

export const routes: RouteObjectWithPermissions[] = [
  {
    ...commonRouteProps,
    path: "settings",
    element: <SettingsPage />,
    permissions: ["ManageSettings"],
    children: [
      {
        index: true,
        element: <GeneralSettingsPage />,
        loader: async () => {
          const paymentMethods = queryClient({
            query: QUERY_PAYMENT_METHODS,
            variables: {
              limit: 9999,
            },
          }).then((res) => res.data.paymentMethods.items);

          const globalSettings = queryClient({
            query: QUERY_GLOBAL_SETTINGS,
            variables: {
              limit: 9999,
            },
          }).then((res) => res.data.globalSettings);

          return defer({
            data: Promise.all([globalSettings, paymentMethods]),
          });
        },
      },
      {
        path: "payment-methods",
        element: <PaymentMethodsPage />,
        loader: async ({ params }) => {
          const retailerPayTypes = queryClient({
            query: QUERY_RETAILER_PAY_TYPES,
            variables: { limit: 9999 },
          }).then((res) => res.data.retailerPayTypes.items);
          return defer({
            data: Promise.all([retailerPayTypes]),
          });
        },
      },
      {
        path: "api-keys",
        element: <ApiKeysPage />,
      },
      {
        index: true,
        path: "background-eraser",
        element: <BackgroundEraserPage />,
        loader: async () => {
          const globalSettings = queryClient({
            query: QUERY_BACKGROUND_ERASER,
            variables: {
              limit: 9999,
            },
          }).then((res) => res.data.globalSettings);

          return defer({
            data: Promise.all([globalSettings]),
          });
        },
      },
      {
        path: "locations",
        element: <LocationsPage />,
      },
      {
        path: "staff",
        element: <StaffsPage />,
      },
      {
        path: "staff/edit/:id",
        element: <EditStaffPage />,
        loader: async ({ params }) => {
          const staff = queryClient({
            query: QUERY_STAFF,
            variables: { id: params.id },
          }).then((res) => res.data.staff);

          return defer({
            data: Promise.all([staff]),
          });
        },
      },

      {
        path: "staffs/edit/:id/migrate-account",
        element: <UpgradeStaffAccountPage />,
        loader: async ({ params }) => {
          const staff = queryClient({
            query: QUERY_STAFF,
            variables: { id: params.id },
          }).then((res) => res.data.staff);

          return defer({
            data: Promise.all([staff]),
          });
        },
      },

      {
        path: "terminals",
        element: <TerminalsPage />,
        loader: async () => {
          const paymentMethods = queryClient({
            query: QUERY_PAYMENT_METHODS,
          }).then((res) => res.data.paymentMethods.items);

          return {
            data: Promise.all([paymentMethods]),
          };
        },
      },
      {
        path: "terminals/edit/:id",
        element: <EditTerminalPage />,
        loader: async ({ params }) => {
          const terminal = queryClient({
            query: QUERY_TERMINAL,
            variables: { id: params.id },
          }).then((res) => res.data.terminal);

          const paymentMethods = queryClient({
            query: QUERY_PAYMENT_METHODS,
          }).then((res) => res.data.paymentMethods.items);

          const globalSettings = queryClient({
            query: QUERY_GLOBAL_SETTINGS,
            variables: {
              limit: 9999,
            },
          }).then((res) => res.data.globalSettings);

          return defer({
            data: Promise.all([terminal, paymentMethods, globalSettings]),
          });
        },
      },
      {
        path: "locations/edit/:id",
        element: <EditLocationPage />,
        loader: async ({ params }) => {
          const location = queryClient({
            query: QUERY_LOCATION,
            variables: { id: params.id },
          }).then((res) => res.data.location);

          return defer({
            data: location,
          });
        },
      },
      {
        path: "payment-methods/edit/:id",
        element: <EditPaymentMethodPage />,
        loader: async ({ params }) => {
          const paymentMethod = queryClient({
            query: QUERY_PAYMENT_METHOD,
            variables: { id: params.id },
          }).then((res) => res.data.paymentMethod);

          const locations = queryClient({
            query: QUERY_LOCATIONS_LIST,
            variables: { limit: 9999 },
          }).then((res) => res.data.locations.items);

          const retailerPayTypes = queryClient({
            query: QUERY_RETAILER_PAY_TYPES,
            variables: { limit: 9999 },
          }).then((res) => res.data.retailerPayTypes.items);

          return defer({
            data: Promise.all([paymentMethod, locations, retailerPayTypes]),
          });
        },
      },
      {
        path: "receipts",
        element: <ReceiptsPage />,
      },
      {
        path: "receipts/edit/:id",
        element: <EditReceiptPage />,
        loader: async ({ params }) => {
          const receipt = queryClient({
            query: QUERY_RECEIPT,
            variables: { id: params.id },
          }).then((res) => res.data.receipt);

          return defer({
            data: Promise.all([receipt]),
          });
        },
      },
      {
        path: "stripe",
        errorElement: <PageErrorOccurred />,
        element: <StripeLandingPage />,
        loader: async ({ params }) => {
          const onBoardingComplete = queryClient({
            query: QUERY_ONBOARDING_COMPLETE,
          }).then((r) => r.data.onBoardingComplete);

          return defer({
            data: Promise.all([onBoardingComplete]),
          });
        },
      },

      {
        path: "self-checkout",
        errorElement: <PageErrorOccurred />,
        element: <SelfCheckoutSettingsPage />,
        loader: async () => {
          const selfCheckoutSettings = queryClient({
            query: QUERY_SELF_CHECKOUT_SETTINGS,
          }).then((res) => res.data.selfCheckoutSettings);

          const paymentMethods = queryClient({
            query: QUERY_PAYMENT_METHODS,
            variables: { limit: 9999 },
          }).then((res) => res.data.paymentMethods.items);

          return defer({
            data: Promise.all([selfCheckoutSettings, paymentMethods]),
          });
        },
      },

      {
        path: "self-checkout/locations/new",
        element: <AddSelfCheckoutLocationSettingPage />,
        errorElement: <PageErrorOccurred />,
        loader: async () => {
          const selfCheckoutSettings = queryClient({
            query: QUERY_SELF_CHECKOUT_SETTINGS,
          }).then((res) => res.data.selfCheckoutSettings);

          const locations = queryClient({
            query: QUERY_LOCATIONS_LIST,
            variables: { limit: 9999 },
          }).then((res) => res.data.locations.items);

          const terminals = queryClient({
            query: QUERY_TERMINALS_LIST,
            variables: { limit: 9999 },
          }).then((res) => res.data.terminals.items);

          return defer({
            data: Promise.all([selfCheckoutSettings, locations, terminals]),
          });
        },
      },

      {
        path: "self-checkout/locations/:locationId/edit",
        element: <EditSelfCheckoutLocationSettingPage />,
        errorElement: <PageErrorOccurred />,
        loader: async ({ params }) => {
          const selfCheckoutSettings = queryClient({
            query: QUERY_SELF_CHECKOUT_SETTINGS,
          }).then((res) => res.data.selfCheckoutSettings);

          const locationSettings = queryClient({
            query: QUERY_SELF_CHECKOUT_LOCATION_SETTINGS,
            variables: { locationId: params.locationId },
          }).then((res) => res.data.selfCheckoutLocationSettings);

          const terminals = queryClient({
            query: QUERY_TERMINALS_LIST,
            variables: { limit: 9999 },
          }).then((res) => res.data.terminals.items);

          const url = queryClient({
            query: QUERY_SELF_CHECKOUT_LOCATION_URL,
            variables: { locationId: params.locationId },
          }).then((res) => res.data.selfCheckoutLocationURL);

          return defer({
            data: Promise.all([
              selfCheckoutSettings,
              locationSettings,
              terminals,
              url,
            ]),
          });
        },
      },
    ],
  },
];
