import { Divider, Stack } from "@mui/material";
import { SkeletonPage } from "../../../../components";
import { CashManagedSettingCard } from "../CashManagedSettingCard";
import { InputSkeleton } from "../InputSkeleton";
import { SettingCardSkeleton } from "../SettingCardSkeleton";

export interface GeneralSettingsPageSkeletonProps {}

export function GeneralSettingsPageSkeleton(
  props: GeneralSettingsPageSkeletonProps
) {
  return (
    <SkeletonPage title="General Settings">
      <Stack spacing={3} divider={<Divider />}>
        <SettingCardSkeleton wrapped title="Account details">
          <InputSkeleton />
        </SettingCardSkeleton>

        <SettingCardSkeleton wrapped title="Business details">
          <InputSkeleton />
          <InputSkeleton />
          <InputSkeleton />
        </SettingCardSkeleton>

        <CashManagedSettingCard>
          <InputSkeleton />
        </CashManagedSettingCard>
      </Stack>
    </SkeletonPage>
  );
}
