import { PaymentMethod } from "@towersystems/roam-common/lib/generated-types";
import { Page } from "components";
import React, { useCallback } from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { useFlashMessages } from "utilities/flash-messages";
import {
  EditPaymentMethodForm,
  PaymentMethodPageSkeleton,
} from "../../components";

export const EditPaymentMethodPage = () => {
  const { showMessage } = useFlashMessages();
  const navigate = useNavigate();
  const { data }: any = useLoaderData();

  const handleOnSubmitSuccess = useCallback(
    (_pm: PaymentMethod) => {
      showMessage({
        message: "Payment Method updated successfully",
        severity: "success",
      });
    },
    [navigate, showMessage]
  );

  return (
    <React.Suspense fallback={<PaymentMethodPageSkeleton />}>
      <Await resolve={data}>
        {([resolvedPaymentMethod, locations, retailerPayTypes]) => {
          return (
            <Page
              title={resolvedPaymentMethod.name}
              titleDescription="Edit Payment Method"
              goBack={{ onAction: () => navigate("/settings/payment-methods") }}
            >
              <EditPaymentMethodForm
                retailerPayTypes={retailerPayTypes}
                paymentMethod={resolvedPaymentMethod}
                onSubmitSuccess={handleOnSubmitSuccess}
              />
            </Page>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
