import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, TextField } from "@mui/material";
import { Location } from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "components";
import { UseModalReturn } from "../../../../utilities/use-modal";
import {
  defaultLocationFormValues,
  locationFormSchema,
} from "../../utils/locations";
import { useMutationCreateLocation } from "../../utils/locations/hooks";
import { LocationFormValues } from "../../utils/locations/types";

export interface CreateLocationModalProps extends UseModalReturn {
  onSubmitSuccess(location: Location): void;
}

export function CreateLocationModal({
  onSubmitSuccess,
  ...modalProps
}: CreateLocationModalProps) {
  const methods = useForm<LocationFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultLocationFormValues,
    resolver: yupResolver(locationFormSchema),
  });

  const { register, handleSubmit, formState } = methods;
  const { errors, isValid } = formState;

  const { createLocation } = useMutationCreateLocation();

  const handleOnSubmit = useCallback(
    async (formValues: LocationFormValues) => {
      try {
        const result = await createLocation({
          retailerLocationKey: formValues.retailerLocationKey,
          name: formValues.name || "",
        });
        onSubmitSuccess && onSubmitSuccess(result);
      } catch (error) {
        console.error(error);
      }
    },
    [createLocation]
  );

  const primaryAction = {
    content: "Add New Location",
    onAction: handleSubmit(handleOnSubmit),
    disabled: !isValid,
  };

  return (
    <Modal title="Add Location" {...{ primaryAction }} {...modalProps}>
      <Stack spacing={3}>
        <TextField
          label="Name"
          fullWidth
          {...register("name")}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
        />
        <TextField
          label="Retailer Location Key"
          fullWidth
          {...register("retailerLocationKey")}
          error={Boolean(errors.retailerLocationKey)}
          helperText={errors.retailerLocationKey?.message}
        />
      </Stack>
    </Modal>
  );
}
