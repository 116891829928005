import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Customer } from "@towersystems/roam-common/lib/generated-types";

export interface CustomersListProps {
  items?: Customer[];
  loading?: boolean;
}

export const CustomersList = ({ items }: CustomersListProps) => {
  const rows = items?.map((i, index) => {
    const fullName = `${i.firstName} ${i.lastName}`;

    return (
      <TableRow key={index}>
        <TableCell width={"100%"}>
          <Typography fontSize="small" color="GrayText">
            {fullName}
          </Typography>
        </TableCell>
        <TableCell />
        <TableCell>
          <IconButton
            component={Link}
            to={`/settings/customers/edit/${i.id}`}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Table>
      <TableBody>{rows}</TableBody>
    </Table>
  );
};
