import { Receipt } from "@towersystems/roam-common/lib/generated-types";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "components";
import { useFlashMessages } from "../../../../utilities/flash-messages";
import { useModal } from "../../../../utilities/use-modal";
import { ReceiptList } from "../../components";
import { CreateReceiptSheet } from "../../components/CreateReceiptSheet";
import { useQueryReceipts } from "../../utils/receipts/hooks";
import { Paper } from "@mui/material";

export const ReceiptsPage = () => {
  const { items, loading } = useQueryReceipts();

  const addReceiptModal = useModal();
  const { showMessage } = useFlashMessages();
  const navigate = useNavigate();

  const handleOnSubmitSuccess = useCallback(
    ({ id }: Receipt) => {
      showMessage({
        message: "Receipt created successfully",
        severity: "success",
      });
      navigate(`/settings/receipts/edit/${id}`);
    },
    [navigate, showMessage]
  );

  const primaryAction = {
    content: "Add Receipt",
    onAction: addReceiptModal.onOpen,
  };

  return (
    <Page
      title="Receipts"
      titleDescription="Create and manage your receipts"
      primaryAction={primaryAction}
    >
      <Paper variant="outlined">
        <ReceiptList items={items as any} loading={loading} />
      </Paper>
      <CreateReceiptSheet
        onSubmitSuccess={handleOnSubmitSuccess}
        {...addReceiptModal}
      />
    </Page>
  );
};
