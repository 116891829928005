import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { UseFormRegisterReturn } from "react-hook-form";
import { NumericFormat } from "react-number-format";

export type CurrencyFieldProps = Partial<UseFormRegisterReturn<string>> &
  Pick<TextFieldProps, "label" | "error" | "fullWidth">;

/**
 * CurrencyField is a react-hook-form compliant textfield that formats
 * the value as currency
 * @param props - react-hook-form register props
 * @returns
 */
export function CurrencyField(props: CurrencyFieldProps) {
  return (
    <NumericFormat
      allowNegative={false}
      decimalScale={2}
      thousandSeparator
      {...props}
      customInput={(p: Record<string, unknown>) => (
        <TextField
          {...p}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      )}
    />
  );
}
