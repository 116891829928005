import { Skeleton, useTheme } from "@mui/material";

export interface TileButtonSkeletonProps {}

export function TileButtonSkeleton(props: TileButtonSkeletonProps) {
  return (
    <Skeleton
      variant="rectangular"
      width={230}
      height={130}
      style={{ borderRadius: "18px" }}
    />
  );
}
