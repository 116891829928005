import { Typography } from "@mui/material";
import { Modal } from "../../../../components";
import { useActiveUserProvider } from "../../../../utilities/active-user";
import { useRemove2faProvider } from "../../utils/2fa/hooks";
import React from "react";
import { Remove2faFormValues } from "../Remove2faForm/types";
import { Remove2faForm } from "../Remove2faForm";
import { Remove2faFormProvider } from "../Remove2faForm/Remove2faFormProvider";
import {
  MfaCodeStrategy,
  Remove2faStrategyResult,
  Success,
} from "@towersystems/roam-common/lib/generated-types";
import { useMutation } from "@apollo/client";
import { requestMfaCodeMutation as RequestMfaCodeMutation } from "../../../../utilities/authentication/constants";
import { useCurrentVendor } from "../../../../utilities/vendor/hooks";
import { useFormContext } from "react-hook-form";
import { Remove2faStrategyMutation } from "../../utils";
import { userIsAdmin } from "../../../../utilities/user-is-admin";

export interface Remove2faModalProps {}
export interface Remove2faProps {}

const Remove2fa = ({}: Remove2faProps) => {
  const formContext = useFormContext<Remove2faFormValues>();
  const { setError, setValue } = formContext;
  const { currentVendor } = useCurrentVendor();
  const { activeUser } = useActiveUserProvider();

  const [requestMfaCodeFn] = useMutation<{
    requestMfaCode: Success;
  }>(RequestMfaCodeMutation);

  const [removeMfaStrategyFn] = useMutation<{
    remove2faStrategy: Remove2faStrategyResult;
  }>(Remove2faStrategyMutation);

  const handleOnSubmit = React.useCallback(
    (values: Remove2faFormValues) => {
      const { data, ui } = values;

      if (false === ui.codeSent) {
        const currentUserIsOwner = userIsAdmin(activeUser);

        let credentialsInput;

        if (currentUserIsOwner) {
          credentialsInput = {
            ["talink"]: {
              password: data.password,
              talinkUsername: activeUser.identifier,
            },
          };
        } else {
          credentialsInput = {
            ["native"]: {
              password: data.password,
              username: activeUser.identifier,
              talinkUsername: currentVendor?.talinkUsername,
            },
          };
        }

        return requestMfaCodeFn({
          variables: {
            input: credentialsInput,
            mfaStrategy: data.strategy,
          },
        })
          .then((result) => {
            if (result.data) {
              setValue("ui.codeSent", true);
            }
          })
          .catch((err) => {
            setError("root.serverError", {
              type: "400",
              message: err.message,
            });
          });
      } else {
        return removeMfaStrategyFn({
          variables: {
            strategy: data.strategy,
            code: data.code,
          },
        })
          .then((result) => {
            if (result.data) {
              if ("errorCode" in result.data?.remove2faStrategy) {
                setError("root.serverError", {
                  type: "400",
                  message: result.data.remove2faStrategy.errorCode,
                });
                return;
              } else {
                window.location.reload();
              }
            }
          })
          .catch((err) => {
            setError("root.serverError", {
              type: "400",
              message: err.message,
            });
          });
      }
    },
    [activeUser, currentVendor]
  );

  return <Remove2faForm onSubmit={handleOnSubmit} />;
};

export const Remove2faModal = ({}: Remove2faModalProps) => {
  const { strategyCode, modal } = useRemove2faProvider();
  const { activeUser } = useActiveUserProvider();

  const userStrategy = React.useMemo(() => {
    if (strategyCode) {
      return activeUser.mfaMethods.find(
        (mfaMethod) =>
          mfaMethod.verified === true && mfaMethod.strategy === strategyCode
      );
    }
  }, [activeUser, strategyCode]);

  const content = userStrategy ? (
    <Remove2faFormProvider strategy={userStrategy.strategy as MfaCodeStrategy}>
      <Remove2fa />
    </Remove2faFormProvider>
  ) : (
    <Typography>
      You do not have a verified {strategyCode} MFA method.
    </Typography>
  );

  return (
    <Modal {...modal} title="Remove MFA">
      {content}
    </Modal>
  );
};
