import { FormProvider, useForm } from "react-hook-form";
import { LoginForm } from "../../components";
import {
  defaultLoginFormValues,
  loginFormSchema,
  LoginFormValues,
} from "../../utils/login";
import { yupResolver } from "@hookform/resolvers/yup";
import { Page } from "../../../../components";
import { Box, Typography, useMediaQuery } from "@mui/material";
import styles from "./LoginPage.module.scss";
import Background from "../../../../assets/login-small-placeholder-bg.jpg";
import { classNames } from "@towersystems/roam-common/lib/css";

export interface LoginPageProps {}

export const LoginPage = ({}: LoginPageProps) => {
  const methods = useForm<LoginFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: defaultLoginFormValues,
    resolver: yupResolver(loginFormSchema),
  });

  const matches = useMediaQuery("(min-width:1200px)");
  const version = process.env.REACT_APP_VERSION;

  return (
    <Page
      documentTitle={"Login"}
      divider={false}
      pageClassName={styles.LoginPage}
    >
      <Box className={styles.ContainerWrapper}>
        <Box className={styles.Container}>
          <Box className={styles.ContainerInner}>
            {matches && (
              <Box
                className={styles.LoginPageBackground}
                style={{ backgroundImage: `url(${Background})` }}
              ></Box>
            )}
            <Box
              className={classNames(
                styles.LoginPageMain,
                !matches && styles.LoginPageMainFullWidth
              )}
              sx={{ height: "100%" }}
            >
              <Box>
                <FormProvider {...methods}>
                  <LoginForm />
                </FormProvider>
              </Box>
            </Box>
          </Box>
          <Typography variant="caption">{`© 2023 Towersystems Pty Ltd ${"\u{00B7}"} V.${version}`}</Typography>
        </Box>
      </Box>
    </Page>
  );
};
