import { ApolloProvider } from "@apollo/client/react";
import { useEffect, useMemo, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { client } from "./apollo";
import "./App.css";
import { AuthProvider, ErrorBoundary, PageErrorOccurred } from "components";
import {
  RouteObjectWithPermissions,
  protectedRoutes,
  publicRoutes,
} from "./routes";
import { useAuthentication } from "./utilities/authentication/hooks";
import "./utilities/polyfills";
import { theme } from "./utilities/theme";
import { CssBaseline } from "@mui/material";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import "react-tabs/style/react-tabs.css";
import { USER_TOKEN_KEY } from "./utilities/authentication/constants";
import { TalinkLoginHighjack } from "./components/TalinkLoginHighjack";
import { AuthContextType } from "./utilities";

function App2() {
  const authentication = useAuthentication();
  const { bootstraped, bootstrapAsync, user, hasPermission } = authentication;
  const { isLoggedIn } = authentication;

  useEffect(() => {
    if (false === bootstraped) {
      bootstrapAsync().catch((err) => console.log(err));
    }
  }, [bootstraped]);

  const routes = useMemo(() => {
    return isLoggedIn ? createRouter(true, hasPermission) : createRouter(false);
  }, [isLoggedIn, user]);

  if (!bootstraped) {
    return null;
  }

  return (
    <TalinkLoginHighjack>
      <AuthProvider authentication={authentication}>
        <RouterProvider router={routes}></RouterProvider>
      </AuthProvider>
    </TalinkLoginHighjack>
  );
}

function Boot() {
  const search = window.location.search;
  const params = useMemo(() => new URLSearchParams(search), []);
  const auth = params.get("auth");

  const [booted, setBooted] = useState(false);

  useEffect(() => {
    if (auth) {
      localStorage.setItem(USER_TOKEN_KEY, auth);
      window.history.replaceState(null, "", "/");
    }
    setBooted(true);
  }, [auth]);

  return (
    <>
      <ErrorBoundary ErrorHandler={PageErrorOccurred}>
        <StyledEngineProvider injectFirst>
          <CssVarsProvider theme={theme}>
            <CssBaseline />
            <ApolloProvider client={client}>
              {booted && <App2 />}
            </ApolloProvider>
          </CssVarsProvider>
        </StyledEngineProvider>
      </ErrorBoundary>
    </>
  );
}

const createRouter = (
  isLoggedIn: boolean,
  hasPermission?: AuthContextType["hasPermission"]
) => {
  const routes = isLoggedIn
    ? filterRoutes(protectedRoutes, hasPermission)
    : publicRoutes;

  return createBrowserRouter(routes);
};

const filterRoutes = (
  routes: RouteObjectWithPermissions[],
  hasPermission?: AuthContextType["hasPermission"]
): RouteObjectWithPermissions[] => {
  if (!hasPermission) {
    return [];
  }

  return routes.reduce(function filter(
    prev: RouteObjectWithPermissions[],
    item: RouteObjectWithPermissions
  ): RouteObjectWithPermissions[] {
    // Checks if the user logged in has permissions to access certain routes. uses recursion (recursion = function that calls itself)
    if (hasPermission(item.permissions || [])) {
      if (item.children && item.children.length > 0) {
        return [
          ...prev,
          {
            ...item,
            children: item.children.reduce(filter, []),
          },
        ];
      } else {
        return [...prev, item];
      }
    }
    return prev;
  },
  []);
};

export default Boot;
