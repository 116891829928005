import { Box, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { Skeleton } from "../../../../components";

interface SaleRowSkeletonProps {}

export function SaleRowSkeleton(props: SaleRowSkeletonProps) {
  return (
    <TableRow>
      <TableCell>
        <Typography>
          <Skeleton />
        </Typography>
      </TableCell>
      <TableCell>
        <Box>
          <Typography variant="subdued" fontSize={15} textOverflow="ellipsis">
            <Skeleton />
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography fontSize={14}>
          <Skeleton width={200} style={{ maxWidth: "40%" }} />
        </Typography>
        <Typography variant="subdued" fontSize={14}>
          <Skeleton width={250} style={{ maxWidth: "80%" }} />
        </Typography>
        <Typography></Typography>
      </TableCell>
      <TableCell>
        <Skeleton
          style={{
            width: 40,
            height: 20,
          }}
        ></Skeleton>
      </TableCell>
      <TableCell align="right">
        <Typography>
          <Skeleton />
        </Typography>
      </TableCell>
    </TableRow>
  );
}
