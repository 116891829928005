import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, FormGroup, Stack } from "@mui/material";
import {
  Receipt,
  ReceiptInput,
} from "@towersystems/roam-common/lib/generated-types";
import { ContextualSaveBar } from "components";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRevalidator } from "react-router-dom";
import { useFlashMessages } from "utilities/flash-messages";
import {
  defaultReceiptFormValues,
  receiptFormSchema,
  toReceiptInput,
  useMutationUpdateReceipt,
} from "../../utils/receipts";
import { ReceiptFormValues } from "../../utils/receipts/types";
import { ReceiptDetailsForm } from "../ReceiptDetailsForm";
import { ReceiptSettingsForm } from "../ReceiptSettingsForm";
import { ReceiptInformationSettingCard } from "./components";
import { ReceiptSettingsSettingCard } from "./components/ReceiptSettingsSettingCard";

export interface ReceiptFormProps {
  receipt: Receipt;
}

export const ReceiptForm = ({ receipt }: ReceiptFormProps) => {
  const { updateReceipt } = useMutationUpdateReceipt();
  const { showMessage } = useFlashMessages();

  const revalidator = useRevalidator();

  const methods = useForm<ReceiptFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: { ...defaultReceiptFormValues, ...toReceiptInput(receipt) },
    resolver: yupResolver(receiptFormSchema),
  });

  const { handleSubmit, formState } = methods;
  const { isDirty } = formState;

  const handleOnSubmit = useCallback(
    async (input: ReceiptFormValues) => {
      await updateReceipt({ ...input, id: receipt.id } as ReceiptInput); // generated graphql types are unhappy with nulls
      showMessage({
        message: "Receipt updated successfully",
        severity: "success",
      });
      methods.reset(input); // reset defaults to the updated values
      revalidator.revalidate(); // updates prefetched data
    },
    [updateReceipt]
  );

  const contextualSaveBar = isDirty ? (
    <ContextualSaveBar
      saveAction={{
        onAction: handleSubmit(handleOnSubmit, console.log),
      }}
      cancelAction={{ onAction: () => methods.reset() }}
    />
  ) : undefined;

  return (
    <>
      {contextualSaveBar}
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack spacing={3} divider={<Divider />}>
          <ReceiptInformationSettingCard>
            <FormProvider {...methods}>
              <Stack spacing={3}>
                <ReceiptDetailsForm />
              </Stack>
            </FormProvider>
          </ReceiptInformationSettingCard>
          <ReceiptSettingsSettingCard>
            <FormProvider {...methods}>
              <FormGroup>
                <ReceiptSettingsForm />
              </FormGroup>
            </FormProvider>
          </ReceiptSettingsSettingCard>
        </Stack>
      </form>
    </>
  );
};
